import React, { useEffect, useState } from "react";
import MissionHeader from "../../Missions/Components/MissionHeader";
import MissionDate from "../../Missions/Components/MissionDate";
import { useSelector } from "react-redux";
import { ROLES } from "../../../constants/roles";
const MissionElementES = ({ mission, addClass = null, onClick }) => {
    const missionsStatus = useSelector((state) => state.params.missionsStatus);
    const [status, setStatus] = useState({ label: '', value: 0, color: '' });
    const connectedUser = useSelector((state) => state.user);
    useEffect(() => {
        getStatus();
    }, [mission]);
    const getIndication = () => {
        if (!missionsStatus)
            return;
        if ([ROLES.RM].some((r) => connectedUser.data.roles.indexOf(r) >= 0)) {
            return missionsStatus.rmSub.find((s) => s.value === parseInt(mission.rm_sub_status));
        }
        else {
            return missionsStatus.clientSub.find((s) => s.value === parseInt(mission.customer_sub_status));
        }
    };
    const getStatus = () => {
        let status = { label: '', value: 0, color: '' };
        if (!missionsStatus)
            return status;
        const mStatus = mission && mission.rm_status_value ? { statusRm: mission.rm_status_value } : null;
        if (!mStatus) {
            setStatus(status);
            return;
        }
        if (!missionsStatus.rm) {
            setStatus(status);
            return;
        }
        status = missionsStatus.rm.find((st) => `${st.value}` === mStatus.statusRm);
        setStatus(status);
    };
    const displayEndDate = () => {
        if (!status)
            return false;
        if (status.value === 700)
            return true;
        return ((status.value >= 400 && status.value <= 800));
    };
    return (React.createElement("div", { className: "missionElement", onClick: () => onClick(mission.ref) },
        React.createElement("div", { className: addClass ? `missionHeader missionHeader-${addClass}` : 'missionHeader' },
            React.createElement(MissionHeader, { showIcon: true, affiliate: mission.customer_filiale, candidatures: [], showCandidatureIcon: true, mainContact: { firstName: mission.customer_first_name, lastName: mission.customer_lastname_name }, logo: { content: mission.customer_logo ? `${process.env.REACT_APP_IDENTITY_API_URL}/documents/file${mission.customer_logo}` : null }, title: mission.customer_name, jobTitle: mission.object, indication: getIndication(), clientDescription: mission.jobs, status: status, statusPosition: 'end' }),
            React.createElement(MissionDate, { startNow: mission.start_now ? mission.start_now : false, startDate: mission.start_date, endDate: displayEndDate() ? mission.endDate : null, duration: mission.duration, withIcons: true, isFinish: status && status.value === 700 }))));
};
export default MissionElementES;
