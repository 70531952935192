const isString = (value) => typeof value === 'string' || value instanceof String;
export const formatArrayObjFromKeys = (key, arr) => arr.map((el) => getSingleObjectOnlyKeys(key, el));
export const getSingleObjectOnlyKeys = (keys, obj) => {
    let formatedObj = {};
    for (let i = 0; i < keys.length; i++) {
        if (isString(keys[i])) {
            const str = keys[i];
            if (str === "id")
                formatedObj["id"] = obj["@id"];
            else if (str === "_id")
                formatedObj["_id"] = obj["id"];
            else
                formatedObj[str] = obj[str];
        }
        else if (keys[i].hasOwnProperty('name')) {
            const type = keys[i].type;
            if (type === 'obj') {
                if (obj[keys[i].name]) {
                    formatedObj[keys[i].name] = getSingleObjectOnlyKeys(keys[i].value, obj[keys[i].name]);
                }
                else {
                    formatedObj[keys[i].name] = null;
                }
            }
            else {
                formatedObj[keys[i].name] = obj[keys[i].name].length === 0 ? [] : formatArrayObjFromKeys(keys[i].value, obj[keys[i].name]);
            }
        }
    }
    return formatedObj;
};
export const getOnlyKey = (keys, datas) => {
    if (!keys)
        return datas;
    return datas;
};
export const replaceId = (data) => {
    return ({ ...data, _id: data.id, id: data['@id'] });
};
