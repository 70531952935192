import React from 'react';
import DisplayForm from "../../../../Components/WHForm/DisplayForm";
import { Form } from "react-final-form";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import CommentFormFields from "./commentFormFields";
import { useDispatch } from "react-redux";
import { setQualifButtonEnable } from "../../../../redux/reloader/action";
const ButtonForm = ({ onClick }) => {
    const { t } = useTranslation("form");
    return (React.createElement("div", { className: "content" },
        React.createElement(Button, { "data-testid": "addCommentButton", className: "roundBlock-addBtn", onClick: () => onClick() }, t("comment.add"))));
};
const CommentFormContainer = ({ onSubmit, data }) => {
    const { shouldDisplayForm, displayFormToggle } = DisplayForm();
    const dispatch = useDispatch();
    const submitForm = (data) => {
        dispatch(setQualifButtonEnable(false));
        onSubmit(data).then(() => {
            displayFormToggle();
        });
    };
    return (React.createElement("div", null, shouldDisplayForm ? (React.createElement("div", { "data-testid": "displayForm" },
        React.createElement(Form, { onSubmit: submitForm, initialValues: data ? data.profile : {}, render: ({ handleSubmit }) => {
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(CommentFormFields, { name: "commentaires.message", shouldDisplayForm: shouldDisplayForm, toggle: () => displayFormToggle() })));
            } }))) : (React.createElement(ButtonForm, { onClick: displayFormToggle }))));
};
export default CommentFormContainer;
