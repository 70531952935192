import React from "react";
import { Field } from "redux-form";
import { WhFieldTxt, WhFieldSelect } from "../../../../Components/WHForm/WhFields";
import { change, reduxForm } from "redux-form";
import { Button } from "reactstrap";
import { Form } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import GoogleMapWithoutLoadScript from "../../../../Components/GoogleMap/GoogleMapWithouLoadScript";
const FreelanceHeaderForm = ({ handleSubmit, initialValues, expertises, disabled }) => {
    const { t } = useTranslation(["rmfreelance"]);
    const dispatch = useDispatch();
    const form = useSelector((state) => state.form["freelance_header_form"]);
    const onUpdateGoogleMap = (cityName, lat, long, zipcode) => {
        dispatch(change("freelance_header_form", "address.mobilityCity", cityName));
        dispatch(change("freelance_header_form", "googleMapCity", cityName));
        dispatch(change("freelance_header_form", "address.mobilityLat", lat));
        dispatch(change("freelance_header_form", "address.mobilityLng", long));
        if (zipcode) {
            dispatch(change("freelance_header_form", "address.mobilityZipCode", zipcode));
        }
    };
    return (React.createElement(Form, { onSubmit: handleSubmit, initialvalues: initialValues },
        React.createElement("div", { className: "castorForm-formRow mt-5 mb-5 freelanceHeaderForm" },
            React.createElement(Field, { name: "availability", component: WhFieldTxt, disabled: disabled, placeholder: t("rmfreelance:freelancedit.disponibility"), label: t("rmfreelance:freelancedit.disponibility"), type: "date", componentName: "freelance_edit", className: "mb-0" }),
            React.createElement(Field, { name: "tjm", component: WhFieldTxt, disabled: disabled, placeholder: t("rmfreelance:freelancedit.TJM"), type: "text", label: t("rmfreelance:freelancedit.TJM"), iconafter: "€" }),
            React.createElement(Field, { name: "expertise", component: WhFieldSelect, disabled: disabled, placeholder: t("rmfreelance:freelancedit.select"), options: expertises, label: t("rmfreelance:freelancedit.experience"), className: "mb-0" }),
            React.createElement(Field, { name: "address.mobilityRayon", component: WhFieldTxt, disabled: disabled, placeholder: t("rmfreelance:freelancedit.radius"), type: "text", label: t("rmfreelance:freelancedit.radius"), iconafter: "Km", className: "mb-0" }),
            React.createElement("div", null,
                React.createElement(Field, { label: t("rmfreelance:freelancedit.mobility"), disabled: disabled, placeholder: t("rmfreelance:freelancedit.mobility"), name: "address.mobilityCity", component: GoogleMapWithoutLoadScript, inputClassName: "castorForm-inputContainer big mb-0 googlemap-input", defaultCity: form ? form.values.address.mobilityCity : null, onUpdate: onUpdateGoogleMap, type: "mission" })),
            !disabled && (React.createElement(Button, { style: { height: "50px" }, className: "ml-3 btn-whiteButton" }, "Enregistrer")))));
};
export default reduxForm({})(FreelanceHeaderForm);
