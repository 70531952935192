import { SET_LANG } from "/wh/redux/lang/constant";
import { handleActions } from 'redux-actions';
import countries from 'i18n-iso-countries';
const defaultState = {
    lang: 'fr',
    countries: []
};
const LangReducer = handleActions({
    [SET_LANG]: (state, action) => {
        if (action.payload.lang === 'en')
            countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
        else
            countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
        const currentCountries = countries.getNames(action.payload.lang);
        let countriesToArray = [];
        for (let [key, value] of Object.entries(currentCountries)) {
            countriesToArray.push({ value: key, label: value });
        }
        return { ...state, lang: action.payload.lang, countries: countriesToArray };
    },
}, defaultState);
export default LangReducer;
