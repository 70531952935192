import { FILTER_ASYNC_SELECT, FILTER_TYPE_TEXT } from "../../../../../Components/Filters/constants";
import moment from "moment";
import { MAX } from "../../../../../Shared/maxResults";
import { uniqueArray } from "../../../../../Shared/utils/utils";
const headerFilters = [
    {
        name: "query",
        label: "Rechercher une mission",
        type: "text",
        placeholder: "Entrez les termes de recherche",
        className: "label-input filter filter-with-marin-right-small label-input-text",
        filterType: FILTER_TYPE_TEXT,
        field: "text",
        customComponentParameters: {
            customComponentName: "default",
            isSearchable: true,
            isMulti: false
        },
        formatFilterForApi: value => {
            return { name: "query", value: value.replace(/\//gi, "\\/") };
        },
        formatCurrent: obj => {
            return obj.value.value;
        },
        asyncQueryParameters: {
            query: {
                api: process.env.REACT_APP_IDENTITY_GQL_URL
                    ? process.env.REACT_APP_IDENTITY_GQL_URL
                    : "",
                table: "societies",
                entity: "Society",
                list: true,
                query: {
                    fields: [
                        "id",
                        "_id",
                        "name",
                        "mainContact{firstName lastName}",
                        "affiliate"
                    ],
                    maxResults: 30,
                    page: 0,
                    orderBy: { name: "name" },
                    order: "ASC"
                }
            },
            formatQueryResult: obj => obj,
            params: {
                type: "graphql",
                filter_name: "name",
                defaultFilters: [
                    { value: { mainContact_deletedAt: false }, name: "exists" },
                    {
                        name: "missions_missionStatus_statusRm_list",
                        value: [100, 200, 250, 300, 400, 500, 600, 700, 800]
                    },
                    {
                        name: "mainContact_type",
                        value: "CLIENT"
                    }
                ]
            },
            formatResult: obj => obj.map(o => {
                return {
                    label: o.name,
                    displayLabel: `${o.name}${o.affiliate ? ` - ${o.affiliate}` : ""} (${o.mainContact.lastName} ${o.mainContact.firstName})`,
                    value: o.name,
                    filterName: "name"
                };
            })
        }
    },
    {
        name: "rm_fullname",
        label: "rm",
        placeholder: "Entrez un Resource Manager",
        className: "label-input filter filter-with-marin-right-small",
        filterType: FILTER_ASYNC_SELECT,
        field: "text",
        customComponentParameters: {
            customComponentName: "default",
            isSearchable: true,
            isMulti: false
        },
        formatFilterForApi: value => {
            return { name: "rm_fullname", value: value };
        },
        formatCurrent: obj => {
            return obj.value.label;
        },
        asyncQueryParameters: {
            query: {
                api: process.env.REACT_APP_IDENTITY_GQL_URL
                    ? process.env.REACT_APP_IDENTITY_GQL_URL
                    : "",
                table: "societies",
                entity: "Society",
                list: true,
                query: {
                    fields: ["id", "_id", "rm{firstName lastName id _id}"],
                    maxResults: MAX,
                    page: 0,
                    orderBy: { name: "rm_lastName" },
                    order: "ASC"
                }
            },
            formatQueryResult: obj => obj,
            params: {
                type: "graphql",
                filter_name: "rm_lastName",
                defaultFilters: [
                    {
                        value: { rm_deletedAt: false, mainContact_deletedAt: false },
                        name: "exists"
                    },
                    { name: "missions_missionStatus_statusRm_list", value: [300] },
                    {
                        name: "mainContact_type",
                        value: "CLIENT"
                    }
                ]
            },
            formatResult: obj => {
                const results = obj
                    .filter(o => o.value !== "none none")
                    .map(o => ({
                    label: `${o.value}`,
                    value: o.value,
                    filterName: "rm_lastName"
                }));
                return uniqueArray(results);
            }
        }
    }
];
const filters = [
    {
        name: "activities_name",
        formatFilterForApi: values => {
            return { name: "activities_name", value: values };
        },
        label: "activity_sector",
        className: "label-input filter filter-with-marin-right-small",
        filterType: FILTER_ASYNC_SELECT,
        customComponentParameters: {
            isSearchable: true,
            isMulti: true,
            customComponentName: "activity_sector"
        },
        formatCurrent: obj => obj,
        asyncQueryParameters: {
            query: {
                api: process.env.REACT_APP_IDENTITY_GQL_URL
                    ? process.env.REACT_APP_IDENTITY_GQL_URL
                    : "",
                table: "activitySectors",
                entity: "ActivitySector",
                list: true,
                query: {
                    fields: ["id", "_id", "name"],
                    maxResults: MAX,
                    page: 0
                }
            },
            params: {
                type: "graphql",
                filter_name: "name",
                defaultFilters: []
            },
            formatResult: obj => obj.map(o => ({
                label: o.value,
                value: o.value,
                displayLabel: `${o.value}`,
                filterName: "society_activitySector_list"
            }))
        }
    },
    {
        name: "city",
        label: "Ville",
        placeholder: "Entrez votre ville",
        filterType: FILTER_ASYNC_SELECT,
        customComponentParameters: {
            isSearchable: true,
            isMulti: true,
            customComponentName: "location"
        },
        formatFilterForApi: values => {
            return { name: "city", value: values };
        },
        formatCurrent: obj => {
            return obj;
        },
        asyncQueryParameters: {
            query: {
                api: process.env.REACT_APP_IDENTITY_GQL_URL
                    ? process.env.REACT_APP_IDENTITY_GQL_URL
                    : "",
                table: "missions",
                entity: "Mission",
                list: true,
                query: {
                    fields: ["city"],
                    maxResults: 30,
                    page: 0,
                    orderBy: { name: "city" },
                    order: "ASC"
                }
            },
            params: {
                type: "graphql",
                filter_name: "missioncity",
                defaultFilters: [
                    {
                        name: "missionStatus_statusRm_list",
                        value: [100, 200, 250, 300, 400, 500, 600, 700, 800]
                    }
                ],
                pageSize: 30,
                page: 1
            },
            formatResult: obj => {
                const results = obj.map(o => ({
                    label: `${o.value}`,
                    value: o.value,
                    filterName: "city"
                }));
                return uniqueArray(results);
            }
        }
    },
    {
        name: "startDate",
        defaultName: "search.beginAvailable",
        formatFilterForApi: value => {
            return {
                name: `start_date`,
                value: { from: moment(value).format("YYYY-MM-DDTHH:mm:ss.SSSZ") }
            };
        },
        formatCurrent: obj => {
            return moment(obj.value.hasOwnProperty("value") ? obj.value.value : obj.value).format("DD-MM-YYYY");
        },
        label: "search.beginAvailable",
        filterType: FILTER_TYPE_TEXT,
        className: "label-input filter filter-with-marin-right-small",
        field: "text",
        type: "date",
        dateType: "after"
    }
];
export default { headerFilters, filters };
