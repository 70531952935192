import React, { Fragment, useEffect, useState } from 'react';
const PopOver = ({ text, condition, render, className, sliceNumber }) => {
    const [isHover, setIsHover] = useState(false);
    const [formattedText, setFormatedText] = useState('');
    useEffect(() => {
        if (text.length >= 20) {
            let limitText = sliceNumber ? sliceNumber : 17;
            let nextText = text.slice(0, limitText);
            if (text.length > limitText) {
                nextText += '...';
            }
            setFormatedText(nextText);
        }
        else
            setFormatedText(text);
    }, [text]);
    return (React.createElement(Fragment, null,
        isHover && condition && condition(text) &&
            React.createElement("div", { className: isHover ? className : '' },
                React.createElement("span", null, text)),
        render({ setIsHover, formattedText })));
};
export default PopOver;
