import React from "react";
import { withRouter } from "react-router-dom";
import { FreelanceListBody } from "./FreelanceListBodyES";
import LoadFreelanceListES from "../LoadFreelanceListES";
export const FreelanceList = ({ history }) => {
    return (React.createElement(LoadFreelanceListES, { defaultFilters: [
            { name: "qualified", value: "true" },
            { name: "enabled", value: "true" }
        ], facetsConfig: [
            { name: "mobility_city", size: 1000, sort: { value: "asc" } },
            { name: "expertise", size: 1000 }
        ], render: ({ data, loadNextPage, hasNextPage, facets, totalItems, isLoading, setRequestFilters }) => {
            return (React.createElement("div", { className: `freelance_list_container rightContainer rightContainer--withHeadFilters width-selectable-filters littleHeader` },
                React.createElement(FreelanceListBody, { data: data, facets: facets, totalItems: totalItems, loadNextPage: loadNextPage, setRequestFilters: setRequestFilters, hasNextPage: hasNextPage, isNextPageLoading: isLoading })));
        } }));
};
export default withRouter(FreelanceList);
