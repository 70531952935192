export const USERS = "/api/users";
export const LOGIN = "/api/login_check";
export const SOCIETY = "/api/societies";
export const CANDIDATURE = "/api/candidatures";
export const MISSIONS = "/api/missions";
export const GEOMETRIES = "/api/geometries";
export const MISSIONSEVENT = "/api/mission_events";
export const PACKMEN = "/api/pack_men";
export const PROFILES = "/api/profiles";
export const NEWSLETTER = "/api/newsletters";
export const MISSIONSES = process.env.REACT_APP_ELASTIC_VER === 'V1' ? `${process.env.REACT_APP_ELASTIC}/castor-mission/search.json` : `${process.env.REACT_APP_ELASTIC_V2}/castor-mission-${process.env.REACT_APP_ES_ENV}/_search`;
export const FREELANCEES = process.env.REACT_APP_ELASTIC_VER === 'V1' ? `${process.env.REACT_APP_ELASTIC}/castor-freelance/search.json` : `${process.env.REACT_APP_ELASTIC_V2}/castor-freelances-${process.env.REACT_APP_ES_ENV}/_search`;
;
export const CLIENTS = process.env.REACT_APP_ELASTIC_VER === 'V1' ? `${process.env.REACT_APP_ELASTIC}/castor-customers/search.json` : `${process.env.REACT_APP_ELASTIC_V2}/castor-customers-${process.env.REACT_APP_ES_ENV}/_search`;
export const NOTIFICATIONS = `${process.env.REACT_APP_NOTIFICATIONS}`;
