import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import LoadFreelanceListES from "../LoadFreelanceListES";
import NewFreelanceListBodyES from "./NewFreelanceListBodyES";
export const NewFreelanceListES = ({ history }) => {
    const [scrollClass, setScrollClass] = useState("");
    useScrollPosition(({ prevPos, currPos }) => {
        if (currPos.y < 50) {
            setScrollClass("scrolled");
        }
        else {
            setScrollClass("");
        }
    });
    return (React.createElement(LoadFreelanceListES, { defaultFilters: [
            { name: "qualified", value: "false" },
            { name: "enabled", value: "true" },
        ], facetsConfig: [
            { name: "mobility_city", size: 1000, sort: { value: "asc" } },
            { name: "expertise", size: 1000 },
        ], render: ({ data, updateSort, loadNextPage, hasNextPage, reloadData, facets, totalItems, isLoading, setRequestFilters, }) => {
            return (React.createElement("div", { className: `freelance_list_container rightContainer rightContainer--withHeadFilters width-selectable-filters littleHeader ${scrollClass} ` },
                React.createElement(NewFreelanceListBodyES, { data: data, updateSort: updateSort, history: history, loadNextPage: loadNextPage, hasNextPage: hasNextPage, isLoading: isLoading, isNextPageLoading: isLoading, reloadData: reloadData, totalItems: totalItems, setRequestFilters: setRequestFilters, facets: facets })));
        } }));
};
export default withRouter(NewFreelanceListES);
