import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFilter } from "../../../../../Hooks/useFilter";
import { NormalFilters } from "../../../../SearchFilter/FiltersList";
import ApiService from "../../../../../Services/ApiService";
import { SOCIETY } from "../../../../../Services/contants";
import { editClientNotif } from "../../../../../Services/notif/notif";
import { formatUserName } from "../../../../../helper/formatUserName";
const NewAssignRm = ({ label, rm, load, id, directUpdate, changePrecalState }) => {
    const { t } = useTranslation('notif');
    const { filters, currentFilters, setCurrentFilters } = useFilter('assignRm', 'graphql');
    useEffect(() => {
        setCurrentFilters([{ name: 'rm', getValue: true, value: { label: `${formatUserName(rm.firstName, rm.lastName)}`, value: rm.id, filterName: 'rm' } }]);
    }, [rm]);
    const assignRm = (rm) => {
        const societyService = new ApiService(SOCIETY);
        societyService.update({ _id: id, rm: rm
        }).then(() => {
            if (changePrecalState)
                changePrecalState();
            load();
            editClientNotif.success();
        }).catch((e) => {
            console.error(e);
            editClientNotif.failed();
        });
    };
    const updateFiltersOverload = (newValue) => {
        const rm = newValue['rm'];
        assignRm(rm.value);
    };
    return (React.createElement("div", { className: "AssignRMContainer flex-directionColumn flex-justifyCenter flex-alignStart" },
        React.createElement("div", { className: "AssignRMContainer-title" },
            React.createElement("p", { className: 'size-bigSubtitle weight-bold brightColor' }, label),
            React.createElement("p", { className: 'size-missionBig weight-light brightColor' }, t("rmclient:clientedit.edit"))),
        filters && (React.createElement(NormalFilters, { currentValues: currentFilters, filters: filters, updateFilters: updateFiltersOverload }))));
};
export default NewAssignRm;
