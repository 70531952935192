const QueryBuilder = state => {
    if (!state)
        return {};
    const { pageSize, page, sorted, filtered } = state;
    const params = {};
    if (pageSize)
        params.itemsPerPage = pageSize;
    if (page)
        params.page = page;
    params.order = {};
    if (sorted) {
        sorted.map(el => {
            params.order[el.id] = el.desc ? "desc" : "asc";
            return null;
        });
    }
    if (filtered) {
        filtered.map(el => {
            switch (el.id) {
                case "statusName":
                    params["status"] = el.value;
                    break;
                case "property.statusName":
                    params["property.status"] = el.value;
                    break;
                case "organisation.id":
                    params["organisation.id"] = el.value;
                    break;
                case "session.organisation.id":
                    params["session.organisation.id"] = el.value;
                    break;
                case "roles":
                    params["roles"] = el.value;
                    break;
                default:
                    params[el.id] = el.value;
                    break;
            }
            return null;
        });
    }
    return params;
};
export default QueryBuilder;
