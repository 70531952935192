import React, { Fragment } from 'react';
const TextareaField = ({ onChange, name, value, label = '', placeholder = '', disabled = false, meta, maxLength }) => {
    return (React.createElement("div", { className: "textareaContainer" },
        React.createElement("label", null,
            React.createElement("span", null, label),
            React.createElement("textarea", { "data-testid": "form_textarea_field", className: "textarea_field", id: name, name: name, onChange: onChange, disabled: disabled, value: value, placeholder: placeholder, maxLength: maxLength })),
        meta.touched && meta.error && (React.createElement("span", { className: "form__form-group-error formError" }, meta.error))));
};
const Fieldtextarea = ({ input, label = '', placeholder = '', disabled = false, meta, maxLength = 200 }) => {
    return (React.createElement(Fragment, null,
        React.createElement(TextareaField, Object.assign({}, input, { label: label, meta: meta, placeholder: placeholder, disabled: disabled, maxLength: maxLength }))));
};
export default Fieldtextarea;
