import React from 'react';
import FormWrapper from "../../../Components/Form/FormWrapper";
import { Field } from 'redux-form';
import { WhTextarea, WhValidator } from "../../../Components/WHForm/WhFields/index";
import { useTranslation } from "react-i18next";
const RefuseMission = ({ onSubmit, close }) => {
    const { t } = useTranslation('missions');
    return (React.createElement("div", { className: "rm-mission-edit-form sidebarBody delete-mission-client" },
        React.createElement(FormWrapper, { onSubmit: onSubmit, form: "refusemissionclient" },
            React.createElement("p", { className: "size-big weight-light mt-5 mb-20" }, t('missions.refuse.question')),
            React.createElement("div", { className: 'form-content' },
                React.createElement(Field, { name: "comment", required: true, component: WhTextarea, validate: [WhValidator.required], label: t('missions.refuse.comment') })),
            React.createElement("div", { className: 'btn-container flex-row' },
                React.createElement("button", { onClick: () => close(false) }, t('missions.endMission.cancel')),
                React.createElement("button", { type: "submit" }, t('missions.endMission.confirm'))))));
};
export default RefuseMission;
