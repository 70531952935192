import React, { Fragment } from "react";
import Loading from "../../../Components/Loading";
import { useSelector } from "react-redux";
import { useStoredElasticSearch } from "../../../Hooks/useStoresElasticSearchFreelance";
const LoadFreelanceListES = ({ render, defaultFilters = [], facetsConfig }) => {
    const user = useSelector((state) => state.user.data);
    const { data, facets, isLoading, loadData, reloadData, setRequestFilters, setDefaultFiltersOverride, totalItems, hasNextPage, loadNextPage, isNextPageLoading, updateSort } = useStoredElasticSearch(defaultFilters, facetsConfig);
    return (React.createElement(Fragment, null,
        isLoading && React.createElement(Loading, { className: "load-zIndexMin" }),
        data &&
            render({
                data,
                totalItems,
                facets,
                updateSort,
                reloadData,
                setRequestFilters,
                isLoading,
                loadData,
                loadNextPage,
                setDefaultFiltersOverride,
                isNextPageLoading,
                hasNextPage,
                user
            })));
};
export default LoadFreelanceListES;
