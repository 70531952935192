import { useState, useEffect } from 'react';
import { filtersConfig, filtersGraphQlConfig } from "../Views/SearchFilter/FilterConfig/new";
export const useFilter = (name, apiType = 'rest') => {
    const [filtersName] = useState(name);
    const [headerFilters, setHeaderFilters] = useState([]);
    const [filters, setFilters] = useState([]);
    const [currentFilters, setCurrentFilters] = useState([]);
    const [filterRequest, setFilterRequest] = useState([]);
    useEffect(() => {
        if (filtersName === '')
            return;
        const { headerFilters, filters } = apiType === 'rest' ? filtersConfig[`${filtersName}`] : filtersGraphQlConfig[`${filtersName}`];
        setHeaderFilters(headerFilters);
        setFilters(filters);
    }, [filtersName]);
    const removeEmpty = (cf) => {
        if (Array.isArray(cf.value) && cf.value.length === 0)
            return false;
        if (!cf.value || cf.value === 'toRemoveWhenFetch')
            return false;
        if (cf.value.hasOwnProperty('value') && cf.value.value === 'toRemoveWhenFetch')
            return false;
        return true;
    };
    useEffect(() => {
        let addedFilters = [];
        const newFilterRequests = currentFilters.filter(removeEmpty).map((cf) => {
            let filter = filters.find((uf) => uf && uf.name === cf.name);
            if (!filter) {
                let filter = headerFilters.find((uf) => uf.name === cf.name);
                if (filter.hasOwnProperty('addedFiltersForApi')) {
                    addedFilters.push(...filter.addedFiltersForApi);
                }
                return filter.formatFilterForApi(cf.value.value);
            }
            return filter.formatFilterForApi(cf.value);
        });
        setFilterRequest([...newFilterRequests, ...addedFilters]);
    }, [currentFilters]);
    const updateHeaderFilters = (newFilters) => {
        const updatedCurrentFilters = [...currentFilters];
        const f = Object.entries(newFilters).map((obj) => {
            const [name, value] = obj;
            const idx = updatedCurrentFilters.findIndex((uc) => uc.name === name);
            if (idx > -1)
                updatedCurrentFilters.splice(idx, 1);
            return value ? ({ name: name, value: value.hasOwnProperty('value') ? value : { value: value } }) : null;
        });
        setCurrentFilters([...updatedCurrentFilters, ...f.filter((f) => f.value.value)]);
    };
    const addUpdateFilters = (newFilters, filterName) => {
        const value = newFilters.value;
        const name = filterName;
        const updatedFilters = [...currentFilters];
        const idx = updatedFilters.findIndex((uf) => uf.name === name);
        if (idx === -1)
            updatedFilters.push({ name: name, value: value });
        else {
            if (Array.isArray(value) && value.length === 0)
                updatedFilters.splice(idx, 1);
            else {
                updatedFilters[idx] = { name, value };
            }
        }
        setCurrentFilters(updatedFilters);
    };
    const updateNormalFilters = (newFilters, filter) => {
        const value = newFilters[filter.name];
        const name = filter.name;
        const updatedFilters = [...currentFilters];
        const idx = updatedFilters.findIndex((uf) => uf.name === name);
        if (idx === -1)
            updatedFilters.push({ name: name, value: value });
        else {
            if (Array.isArray(value) && value.length === 0)
                updatedFilters.splice(idx, 1);
            else {
                updatedFilters[idx] = { name, value };
            }
        }
        setCurrentFilters(updatedFilters);
    };
    const updateFilters = (newFilters, filter, isHead = false) => {
        isHead ? updateHeaderFilters(newFilters) : updateNormalFilters(newFilters, filter);
    };
    const removeValueFromCurrentFilter = (filter, currentFilterIdx, value) => {
        const updatedFilters = [...currentFilters];
        const updatedValue = [...filter.value];
        const idx = filter.value.findIndex((v) => v === value);
        if (idx > -1) {
            updatedValue.splice(idx, 1);
            if (updatedValue.length === 0) {
                updatedFilters.splice(currentFilterIdx, 1);
                setCurrentFilters(updatedFilters);
                return;
            }
            updatedFilters[currentFilterIdx].value = updatedValue;
            setCurrentFilters(updatedFilters);
            return;
        }
    };
    const isObject = (o) => {
        return o !== null && typeof o === 'object' && Array.isArray(o) === false;
    };
    const removeMultipleFilters = (arrFilters) => {
        const updatedFilters = [...currentFilters];
        arrFilters.map((f) => {
            const currentFilterIdx = updatedFilters.findIndex((uf) => uf.name === f.name);
            updatedFilters.splice(currentFilterIdx, 1);
        });
        setCurrentFilters(updatedFilters);
    };
    const removeFilters = (filterName, value) => {
        const updatedFilters = [...currentFilters];
        const currentFilterIdx = updatedFilters.findIndex((uf) => uf.name === filterName);
        const filter = updatedFilters[currentFilterIdx];
        if (filter && filterName === 'query') {
            updatedFilters.splice(currentFilterIdx, 1);
            setCurrentFilters(updatedFilters);
            return;
        }
        if (Array.isArray(filter.value)) {
            removeValueFromCurrentFilter(filter, currentFilterIdx, value);
        }
        else if (isObject(filter.value)) {
            updatedFilters.splice(currentFilterIdx, 1);
            setCurrentFilters(updatedFilters);
        }
    };
    return {
        headerFilters,
        filters,
        setCurrentFilters,
        removeFilters,
        removeMultipleFilters,
        filterRequest,
        updateFilters,
        addUpdateFilters,
        currentFilters
    };
};
