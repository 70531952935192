import React, { useState } from "react";
import { CarouselItem } from 'reactstrap';
import * as referenceService from '../../../Services/references';
import { SubmissionError } from 'redux-form';
import Notif from "../../Notif/index";
import DisplayForm from '../../WHForm/DisplayForm';
import Carousel from '../../Carousel/index';
import ReferenceItem from './referenceItem';
import ReferencesForm from './referenceForm';
import FormWrapper from '../../Form/FormWrapper';
import Loading from "../../Loading";
import { useTranslation } from "react-i18next";
import shallowequal from 'shallowequal';
import { useDispatch, useSelector } from "react-redux";
import { setQualifButtonEnable } from "../../../redux/reloader/action";
const Reference = ({ user, callback, data, changePrecalState, disabled = false }) => {
    const { shouldDisplayForm, displayFormToggle } = DisplayForm();
    const [animating, setAnimating] = useState(false);
    const [initialRef, setInitalRef] = useState(null);
    const [loaded, setLoaded] = useState(true);
    const { t } = useTranslation('notif');
    const dispatch = useDispatch();
    const form = useSelector(state => state.form['freelance_refs_form']);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const onSubmit = (data) => {
        dispatch(setQualifButtonEnable(false));
        setLoaded(false);
        if (!shallowequal(form.initial, form.values) && changePrecalState) {
            changePrecalState();
        }
        const edit = (!data.id) ? referenceService.create : referenceService.update;
        const toSubmitData = {
            ...data,
            profile: data.profile && data.profile.id ? data.profile.id : user.profile.id
        };
        try {
            return edit(toSubmitData).then(() => {
                if (callback)
                    callback();
                displayFormToggle();
                Notif({ color: "success", message: t('update_success'), duration: 100 });
            }).catch((error) => {
                throw new SubmissionError(error);
            });
        }
        catch (error) {
            Notif({
                color: "warning",
                message: t('update_failed'),
                duration: 100
            });
            throw new SubmissionError(error);
        }
        finally {
            setLoaded(true);
        }
    };
    const remove = async (id) => {
        setLoaded(false);
        await referenceService.remove(id);
        if (callback)
            callback();
        setLoaded(true);
    };
    const updateRef = (ref) => {
        setInitalRef(ref);
        displayFormToggle();
    };
    const addNewRef = () => {
        setInitalRef(null);
        displayFormToggle();
    };
    const slides = data.map((ref, index) => {
        return (React.createElement(CarouselItem, { className: "custom-tag", tag: "div", key: index, onExiting: onExiting, onExited: onExited },
            React.createElement(ReferenceItem, { freelanceRef: ref, onRemove: remove, disabled: disabled, onUpdate: updateRef })));
    });
    return (React.createElement("div", { className: "loadingContainer" },
        React.createElement("div", { className: "roundBlock carouselRefs" },
            React.createElement("div", { className: 'headerFreeItem wrapHeader' },
                React.createElement("h2", { className: "thinTitle" }, "Mes r\u00E9f\u00E9rences"),
                React.createElement("p", { className: "roundBlockSubtitle" }, "3 r\u00E9f\u00E9rences maximum")),
            loaded ?
                React.createElement("div", null, shouldDisplayForm ?
                    React.createElement("div", null,
                        React.createElement("div", { "data-testid": "showForm" },
                            React.createElement(FormWrapper, { onSubmit: onSubmit, form: 'freelance_refs_form', initialValues: initialRef },
                                React.createElement(ReferencesForm, { toggle: () => displayFormToggle() }))))
                    :
                        React.createElement("div", { className: "content paddingBottom" },
                            data && data.length > 1 ?
                                React.createElement("div", null,
                                    React.createElement(Carousel, { items: data, slides: slides, animating: animating }))
                                :
                                    React.createElement("div", null, data.map((ref, index) => {
                                        return (React.createElement("div", { key: index },
                                            React.createElement(ReferenceItem, { freelanceRef: ref, disabled: disabled, onRemove: remove, onUpdate: updateRef })));
                                    })),
                            React.createElement("span", { "data-test-id": 'removeButton' }),
                            !disabled && data.length < 3 && (React.createElement("span", { "data-testid": 'showMoreForm', onClick: () => addNewRef(), className: 'greyButton roundBlock-addBtn' }, "Ajouter une r\u00E9f\u00E9rence"))))
                :
                    React.createElement(Loading, null))));
};
export default Reference;
