import { FILTER_ASYNC_SELECT } from "../../../constants";
import { formatUserName } from "../../../../../helper/formatUserName";
const headerFilters = [
    {
        name: "lastName",
        label: 'rm',
        placeholder: 'Entrez un Resource Manager',
        className: 'label-input filter filter-with-marin-right-small',
        filterType: FILTER_ASYNC_SELECT,
        field: 'text',
        customComponentParameters: {
            customComponentName: 'default',
            isSearchable: true,
            isMulti: false,
        },
        formatFilterForApi: (value) => {
            return ({ name: "lastName", value: value });
        },
        formatCurrent: (obj) => {
            return obj.value.label;
        },
        asyncQueryParameters: {
            query: {
                api: process.env.REACT_APP_IDENTITY_GQL_URL ? process.env.REACT_APP_IDENTITY_GQL_URL : "",
                table: 'users',
                entity: 'User',
                list: true,
                query: {
                    fields: ["id", "_id", "firstName", "lastName"],
                    maxResults: 30,
                    page: 0,
                    orderBy: { name: 'lastName' }, order: 'ASC',
                }
            },
            formatQueryResult: (obj) => obj,
            params: {
                type: 'graphql',
                filter_name: 'lastName',
                defaultFilters: [{ value: { deletedAt: false }, name: "exists" }, {
                        name: "type",
                        value: "RM"
                    }]
            },
            formatResult: (obj) => {
                return obj.map((o) => ({
                    label: formatUserName(o.firstName, o.lastName),
                    value: o.lastName,
                    filterName: 'lastName'
                }));
            }
        }
    }
];
const filters = [];
export default { headerFilters, filters };
