import { useEffect, useState } from "react";
import { formatGraphQlData } from "../Shared/rewriteArray";
import GraphQl from '../Shared/graphql';
export const usePhotoFromSocietyOrUser = author => {
    const [staticAuthor, setStaticAuthor] = useState(author);
    const [photo, setPhoto] = useState(undefined);
    const [societyData, setSocietyData] = useState([]);
    const [societyLoaded, setSocietyLoaded] = useState(false);
    const getRequest = async (stored) => {
        await GraphQl(stored).then((data) => {
            const datas = formatGraphQlData(data.datas);
            setSocietyData(datas);
            setSocietyLoaded(true);
        }).catch((_e) => {
            setSocietyData([]);
            setSocietyLoaded(true);
        });
    };
    useEffect(() => {
        if (!staticAuthor || !staticAuthor._id)
            return;
        setSocietyLoaded(false);
        let stored = {
            api: process.env.REACT_APP_IDENTITY_GQL_URL
                ? process.env.REACT_APP_IDENTITY_GQL_URL
                : "",
            table: "societies",
            entity: "Society",
            list: true,
            query: {
                maxResults: 1,
                page: 0,
                fields: ["id", "_id", "name", "photo{_id, id content}"],
                filters: [
                    {
                        value: `${author.id}`,
                        name: "mainContact"
                    }
                ]
            }
        };
        getRequest(stored);
    }, [staticAuthor]);
    useEffect(() => {
        if (!societyLoaded)
            return;
        let content = undefined;
        if (societyLoaded &&
            societyData &&
            societyData.length === 0 &&
            author.photo)
            content = author.photo.content;
        if (societyData.length > 0 && societyData[0].photo)
            content = societyData[0].photo.content;
        setPhoto(content);
    }, [societyLoaded]);
    return {
        photo
    };
};
