import React from 'react';
import PackManElement from "./packManElement";
export const columns = [
    {
        Header: '',
        sortable: false,
        accessor: '',
        default: true,
        filterable: false,
        Cell: (row) => (React.createElement(PackManElement, { firstName: row.original.author.firstName, lastName: row.original.author.lastName, picture: row.original.author.photo, created: row.original.created, help: row.original.help, comment: row.original.comment, value: row.original.value }))
    }
];
