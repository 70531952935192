import React, { Fragment } from 'react';
import { Form } from 'reactstrap';
import { reduxForm } from 'redux-form';
import PortfolioItem from './portfolioItem';
import { FieldArray } from 'redux-form';
const PortfolioList = ({ fields, disabled = false, displayForm, onUpdate, portfolios, onRemove }) => {
    const checkIfNewItem = (idx) => {
        const newItem = portfolios.portfolios[idx] !== undefined ? false : true;
        return newItem;
    };
    const fieldRemove = async (idx) => {
        await fields.remove(idx);
        onRemove();
    };
    const cancelItem = (idx) => {
        (!portfolios.portfolios[idx]) ? fields.remove(idx) : fields.get(idx).url = portfolios.portfolios[idx].url;
    };
    return (React.createElement(Fragment, null, portfolios && (React.createElement(Fragment, null,
        fields.map((_field, idx) => {
            return (React.createElement("div", { "data-testid": "roundBlock", key: idx, className: "roundBlock roundBlock-center portfolioItem" },
                React.createElement(PortfolioItem, { key: `portfolios_${idx}`, name: _field, displayForm: displayForm, disabled: disabled, onUpdate: onUpdate, portfolio: portfolios.portfolios[idx], isNewItem: checkIfNewItem(idx), fieldRemove: () => fieldRemove(idx), cancelItem: () => cancelItem(idx) })));
        }),
        !disabled && fields.length < 4 && (React.createElement("div", { className: "roundBlock roundBlock-center" },
            React.createElement("div", { onClick: () => fields.push(), className: 'addPortfolioButton flex-alignCenter flex-alignCenter flex-directionColumn' },
                React.createElement("span", { className: 'plus' }, "+"),
                React.createElement("span", { className: "text" }, "Ajouter une r\u00E9alisation"))))))));
};
const portfolioForm = ({ handleSubmit, disabled = false, initialValues, displayForm, onUpdate }) => {
    const onRemove = () => {
        handleSubmit();
    };
    return (React.createElement(Form, { onSubmit: handleSubmit, initialvalues: initialValues },
        React.createElement("div", { className: "roundBlocksContainer roundBlocksContainer--twoCol" },
            React.createElement(FieldArray, { name: 'portfolios', disabled: disabled, component: PortfolioList, displayForm: displayForm, onUpdate: onUpdate, portfolios: initialValues, onRemove: onRemove }))));
};
export default reduxForm()(portfolioForm);
