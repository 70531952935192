import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field, Form } from "react-final-form";
import { FILTER_ASYNC_SELECT } from "../../Components/Filters/constants";
import { FilterAsyncSelect } from "./FilterFormComponent/FilterAsyncSelect";
import FFText from "../../Components/FinalForm/FFText";
import { FILTER_TYPE_SELECT, FILTER_TYPE_TEXT, RANGE_FILTER, GOOGLE_AUTOCOMPLETE } from "./constants";
import { FilterSelect } from "./FilterFormComponent/FilterSelect";
import FFRangeFilter from "./FilterFormComponent/RangeFilter";
import { isEmptyObj } from "../../helper/isEmpty";
import GoogleMapPlaces from "../../Components/GoogleMap/GoogleMap";
const FilterElement = ({ filter, facets, currentValue, submit, mutators }) => {
    const { name, filterType, placeholder } = filter;
    const { t } = useTranslation("filter");
    const getOptions = () => {
        if (!facets || !facets.hasOwnProperty(filter.name)) {
            return filter.options;
        }
        let arr = facets[filter.name];
        return arr[0].data.map((v) => ({
            value: v.value,
            label: t(`filters.${v.value}`) !== `filters.${v.value}`
                ? t(`filters.${v.value}`)
                : v.value,
        }));
    };
    return (React.createElement(Fragment, null,
        filterType === FILTER_ASYNC_SELECT && (React.createElement(Field, { name: name, label: t(filter.label) }, (props) => {
            return (React.createElement(FilterAsyncSelect, { filter: filter, facets: facets, currentValue: currentValue, props: props }));
        })),
        filterType === FILTER_TYPE_SELECT && (React.createElement(Field, { name: name, label: t(filter.label) }, (props) => {
            return (React.createElement(FilterSelect, Object.assign({}, props, { currentValue: currentValue, placeholder: placeholder, label: t(filter.label), options: getOptions() })));
        })),
        filterType === FILTER_TYPE_TEXT && (React.createElement(FFText, { name: name, className: filter.className, label: t(filter.label), placeholder: filter.placeholder, type: filter.type, containerSize: "mid" })),
        filterType === RANGE_FILTER && (React.createElement(FFRangeFilter, { filter: filter, values: currentValue, submit: submit })),
        filterType === GOOGLE_AUTOCOMPLETE && (React.createElement("div", { style: { flex: 1 } },
            React.createElement(Field, { name: name }, ({ input, meta: { touched, error, submitError } }) => (React.createElement(GoogleMapPlaces, { input: input, type: 'mission', onUpdate: mutators.setPlaces, placeholder: "Tapez et sélectionnez", inputClassName: "castorForm-inputContainer big googlemap-input", label: t(filter.label) },
                React.createElement(Fragment, null,
                    submitError && React.createElement("span", { className: "form__form-group-error formError" }, error),
                    touched && error && (React.createElement("span", { className: "form__form-group-error formError" }, error))))))))));
};
const GroupedFilterForm = ({ filters, isImmediate, updateFilters, removeFilters = null, facets, formValues, handleSubmit, setValueToNull, currentValues, }) => {
    const { t } = useTranslation("common");
    removeFilters = null;
    useEffect(() => {
        if (!isImmediate)
            return;
        let obj = {};
        for (const [key, value] of Object.entries(formValues)) {
            if (value && value.value) {
                obj = { ...obj, [key]: value };
            }
            if (key === "availability" && value && !value.hasOwnProperty("value")) {
                obj = { ...obj, [key]: value };
            }
        }
        if (!isEmptyObj(obj) && formValues) {
            if (updateFilters)
                updateFilters(obj, null, true);
        }
    }, [formValues]);
    useEffect(() => {
        Object.entries(formValues).map((obj) => {
            const [name] = obj;
            const idx = currentValues.findIndex((uc) => uc.name === name);
            if (idx === -1 && name === "query") {
                setValueToNull(name, "");
            }
            if (idx === -1 && name !== "query")
                setValueToNull(name, {
                    name: name,
                    value: undefined,
                    filterName: name,
                });
        });
    }, [currentValues]);
    return (React.createElement("form", { onSubmit: handleSubmit },
        filters.map((f, idx) => (React.createElement(FilterElement, { key: `groupedfilterIdx-${idx}`, filter: f, facets: facets, placeholder: f.placeholder, currentValue: formValues.hasOwnProperty(f.name)
                ? formValues[`${f.name}`]
                : undefined }))),
        !isImmediate && (React.createElement("button", { type: "submit", className: "filterList-save castorBtn-primary" }, t("search")))));
};
const QueryFormComponent = ({ filter, setValueToNull, handleSubmit, values, currentValues, removeFilters = null, updateFilters, }) => {
    const { t } = useTranslation("filter");
    const [prevQuery, setPrevQuery] = useState(null);
    const removeQueryFromFilters = () => {
        if (removeFilters)
            removeFilters("query", {});
    };
    useEffect(() => {
        if (values && !values.hasOwnProperty("query") && prevQuery) {
            removeQueryFromFilters();
            setPrevQuery(null);
        }
        if (values && values.hasOwnProperty("query") && values.query !== "") {
            updateFilters(values);
            setPrevQuery(values.query);
        }
    }, [values]);
    useEffect(() => {
        Object.entries(values).map((obj) => {
            const [name] = obj;
            const idx = currentValues.findIndex((uc) => uc.name === name);
            if (idx === -1 && name === "query") {
                setValueToNull(name, "");
            }
            if (idx === -1 && name !== "query")
                setValueToNull(name, {
                    name: name,
                    value: undefined,
                    filterName: name,
                });
        });
    }, [currentValues]);
    return (React.createElement("form", { onSubmit: handleSubmit },
        React.createElement(FFText, { name: filter.name, className: filter.className, placeholder: filter.placeholder, label: t(filter.label), type: filter.type, containerSize: "mid" })));
};
export const QueryFilters = ({ filter, removeFilters, currentValues, updateFilters, }) => {
    const mutators = {
        setValueToNull: (arg, state, utils) => {
            utils.changeValue(state, arg[0], () => arg[1]);
        },
    };
    const sendFilters = (values) => {
        updateFilters(values, null, true);
    };
    const onSubmit = () => { };
    return (React.createElement(Fragment, null, filter && (React.createElement(Form, { mutators: { ...mutators }, onSubmit: onSubmit, render: ({ handleSubmit, values, form: { mutators } }) => {
            return (React.createElement(QueryFormComponent, { filter: filter, values: values, currentValues: currentValues, setValueToNull: mutators.setValueToNull, updateFilters: sendFilters, handleSubmit: handleSubmit, removeFilters: removeFilters }));
        } }))));
};
export const GroupedFiltersList = ({ filters, isImmediate = false, removeFilters = null, facets = null, updateFilters, currentValues, }) => {
    const mutators = {
        setValueToNull: (arg, state, utils) => {
            utils.changeValue(state, arg[0], () => arg[1]);
        },
    };
    const sendFilters = (values) => {
        updateFilters(values, null, true);
    };
    const onSubmit = (values) => {
        if (values.hasOwnProperty("query")) {
            const { query, ...rest } = values;
            updateFilters(rest, null, true);
            return;
        }
        updateFilters(values, null, true);
        return;
    };
    return (React.createElement(Form, { mutators: { ...mutators }, onSubmit: onSubmit, render: ({ handleSubmit, values, form: { mutators } }) => {
            return (React.createElement(GroupedFilterForm, { updateFilters: sendFilters, filters: filters, isImmediate: isImmediate, removeFilters: removeFilters, facets: facets, formValues: values, currentValues: currentValues, setValueToNull: mutators.setValueToNull, handleSubmit: handleSubmit }));
        } }));
};
const FilterFormWithImmediateUpdate = ({ handleSubmit, facets, currentValue, filter, updateFilters, values, submit, mutators }) => {
    useEffect(() => {
        if (values && values.hasOwnProperty(filter.name) && values[filter.name])
            if (filter.filterType != GOOGLE_AUTOCOMPLETE) {
                updateFilters(values, filter);
            }
    }, [values]);
    return (React.createElement("form", { onSubmit: handleSubmit },
        React.createElement(FilterElement, { filter: filter, facets: facets, submit: submit, currentValue: currentValue, mutators: mutators })));
};
const FormFilterWrapper = ({ filter, facets, updateFilters, idx, currentValue, }) => {
    const [formattedCurrentValue, setFormattedCurrentValue] = useState(null);
    const formatCurrentFilters = () => {
        const name = filter.name;
        if (filter.type === FILTER_ASYNC_SELECT) {
            setFormattedCurrentValue({
                [name]: currentValue ? currentValue.value : [],
            });
        }
        if (filter.type === RANGE_FILTER) {
            setFormattedCurrentValue({
                [`${filter.name}`]: [filter.minRange, filter.maxRange],
            });
        }
        if (filter.type === FILTER_TYPE_SELECT) {
            setFormattedCurrentValue({
                [name]: currentValue ? currentValue.value : undefined,
            });
        }
    };
    useEffect(() => {
        formatCurrentFilters();
    }, [currentValue]);
    const onSubmit = (data) => {
        updateFilters({
            [`${filter.name}`]: {
                min: data[0] ? data[0] : filter.minRange,
                max: data[1] ? data[1] : filter.maxRange,
            },
        }, filter);
    };
    const mutators = {
        setPlaces: (_args) => {
            if (filter.filterType === GOOGLE_AUTOCOMPLETE) {
                updateFilters({
                    [`${filter.name}`]: {
                        label: _args[0],
                        value: {
                            lat: _args[1],
                            lng: _args[2]
                        }
                    },
                }, filter);
            }
        }
    };
    return (React.createElement(Form, { key: `normalFilterIdx-${idx}`, initialValues: formattedCurrentValue, mutators: mutators, onSubmit: onSubmit, render: ({ handleSubmit, values, form: { submit, mutators } }) => {
            return (React.createElement(FilterFormWithImmediateUpdate, { handleSubmit: handleSubmit, currentValue: currentValue, filter: filter, facets: facets, submit: submit, values: values, updateFilters: updateFilters, mutators: mutators }));
        } }));
};
export const NormalFilters = ({ filters, updateFilters, currentValues, facets = null, }) => {
    return (React.createElement("div", { className: "filterList castorForm-formRow" }, filters.map((f, idx) => {
        return (React.createElement(FormFilterWrapper, { key: `formFilterwrapper-${idx}`, filter: f, facets: facets, updateFilters: updateFilters, idx: idx, currentValue: currentValues
                ? currentValues.find((cv) => cv.name === f.name)
                : null }));
    })));
};
