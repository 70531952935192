import React, { useState, Fragment } from "react";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { useSelector } from "react-redux";
import { WhValidator } from "../../../Components/WHForm/WhFields/index";
import { Button, Popover, PopoverBody } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { durationTable } from "../../../Shared/constants";
import FFCheckbox from "../../../Components/FinalForm/FFCheckbox";
import FFSelect from "../../../Components/FinalForm/FFSelect";
import FFText from "../../../Components/FinalForm/FFText";
import { NewTagsForm } from "../../../Components/Form/FFTags";
import CreateMissionCounter from "./CreateMissionCounter";
import { AccessEnum, checkAccess } from "../../../Services/Access/access";
import { formMutators } from "../../../Components/FinalForm/FFTagsMutators";
import GoogleMapPlaces from "../../../Components/GoogleMap/GoogleMap";
import { FilterAsyncSelect } from "../../SearchFilter/FilterFormComponent/FilterAsyncSelect";
import { FieldDraftEditor } from "../../../Components/WHForm/WhFields/FieldDraftEditor";
const FFCreateMission = ({ onSubmit, close, permissions = [], entities, entitiesLoaded, role, packed = null }) => {
    const sectors = useSelector((state) => state.sectors.sectors);
    const [currentCounter, setCurrentCounter] = useState(null);
    const ClientAsyncData = {
        customComponentParameters: {
            customComponentName: 'default',
            isSearchable: true,
            isMulti: false
        },
        asyncQueryParameters: {
            query: {
                api: process.env.REACT_APP_IDENTITY_GQL_URL
                    ? process.env.REACT_APP_IDENTITY_GQL_URL
                    : "",
                table: "societies",
                entity: "Society",
                list: true,
                query: {
                    fields: ["name", "id", "_id", "nbPackHelp", "nopackNoHelp", "mainContact{firstName lastName}", "affiliate"],
                    maxResults: 30,
                    page: 0,
                },
            },
            formatQueryResult: (obj) => obj,
            params: {
                type: 'elasticsearchcustomer',
                filter_name: 'name',
                defaultFilters: [
                    {
                        value: "true",
                        name: "qualified",
                    },
                    {
                        value: "true",
                        name: "main_contact_enabled"
                    }
                ],
            },
            formatResult: (obj) => obj.map((o) => {
                return ({
                    label: `${o.name}${o.affiliate ? ` - ${o.affiliate}` : ''} (${o.main_contact_lastname} ${o.main_contact_firstname})`,
                    displayLabel: `${o.name}${o.affiliate ? ` - ${o.affiliate}` : ''} (${o.main_contact_lastname} ${o.main_contact_firstname})`,
                    value: `/api/societies/${o.ref}`,
                    all: o,
                    filterName: 'name'
                });
            })
        }
    };
    const mutators = {
        setMissionHelped: (_args, state, utils) => {
            utils.changeValue(state, "helped", () => _args[0]);
        },
        setRmMissionDraft: (_args, state, utils) => {
            utils.changeValue(state, "status", () => 101);
        },
        setRmMissionSend: (_args, state, utils) => {
            utils.changeValue(state, "status", () => 102);
        },
        setRmMissionPublish: (_args, state, utils) => {
            utils.changeValue(state, "status", () => 106);
        },
        setClientMissionDraft: (_args, state, utils) => {
            utils.changeValue(state, "status", () => 201);
        },
        setClientMissionSend: (_args, state, utils) => {
            utils.changeValue(state, "status", () => 202);
        },
        setCKEditorValue: (_args, state, utils) => {
            utils.changeValue(state, "description", () => _args[0]);
        },
        setPlaces: (_args, state, utils) => {
            utils.changeValue(state, "city", () => _args[0]);
            utils.changeValue(state, "falseCity", () => _args[0]);
            utils.changeValue(state, "latitude", () => _args[1]);
            utils.changeValue(state, "longitude", () => _args[2]);
        }
    };
    const { t } = useTranslation("form");
    const onSelectCounter = value => {
        setCurrentCounter(value);
    };
    const [popoverOpen, setPopoverOpen] = useState(false);
    const togglePop = () => setPopoverOpen(!popoverOpen);
    const getBTN = mutators => ({
        ["ROLE_ADMIN"]: (React.createElement("div", { className: "mission-btns" },
            React.createElement("div", { className: "container-flex container-flex-row container-flex-center" },
                React.createElement("button", { className: "mission-btn mission-cancel", type: "button", onClick: () => close() }, t("btn.cancel")),
                React.createElement("button", { type: "submit", className: "mission-btn mission-brouillon", onClick: mutators.setRmMissionDraft }, t("btn.register_draft"))),
            React.createElement("div", { className: "container-flex container-flex-row container-flex-center" },
                React.createElement("button", { type: "submit", className: "mission-btn mission-client", onClick: mutators.setRmMissionSend }, t("btn.send_client")),
                checkAccess(AccessEnum.PUBLISH_MISSION, permissions) && (React.createElement("button", { type: "submit", className: "mission-btn mission-publish", onClick: mutators.setRmMissionPublish }, t("btn.publish")))))),
        ["ROLE_CLIENT"]: (React.createElement("div", { className: "mission-btns" },
            React.createElement("div", { className: "container-flex container-flex-row container-flex-center" },
                React.createElement("button", { className: "mission-btn mission-cancel", type: "button", onClick: () => close(false) }, t("btn.cancel")),
                React.createElement("button", { type: "submit", className: "mission-btn mission-brouillon", onClick: mutators.setClientMissionDraft }, t("btn.register_draft")),
                React.createElement("button", { type: "submit", className: "mission-btn mission-publish", onClick: mutators.setClientMissionSend }, t("btn.send")))))
    });
    const onSubmitForm = (values) => {
        if ((!values.hasOwnProperty('latitude') || (!values.hasOwnProperty('longitude')))) {
            alert("Tapez une ville puis sélectionnez la dans la liste proposée.");
            return;
        }
        if (!values.hasOwnProperty('falseCity') || (values.hasOwnProperty('falseCity') && values.city !== values.falseCity)) {
            alert("Tapez une ville puis sélectionnez la dans la liste proposée.");
            return;
        }
        let { falseCity, ...updatedValues } = values;
        if (updatedValues.startNow && updatedValues.startNow === true) {
            updatedValues.startDate = new Date();
        }
        onSubmit(updatedValues);
    };
    return (React.createElement("div", { className: "rm-mission-create-form sidebarBody" }, !entitiesLoaded && (React.createElement(Form, { mutators: { ...mutators, ...formMutators, ...arrayMutators }, initialValues: {
            public: false,
            hiddenSociety: false,
            startNow: false
        }, onSubmit: onSubmitForm, render: ({ handleSubmit, values, form: { mutators } }) => {
            return (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement("p", { className: "size-big weight-light" }, t("createmission.counterquestion")),
                React.createElement("div", { className: "container" },
                    React.createElement(CreateMissionCounter, { number: values.society
                            ? values.society.all.nb_pack_help
                            : packed
                                ? packed.helped
                                : 0, onUpdate: () => {
                            mutators.setMissionHelped(true);
                            onSelectCounter(true);
                        }, isSelected: !!currentCounter }),
                    React.createElement(CreateMissionCounter, { number: values.society
                            ? values.society.all.nb_pack_no_help
                            : packed
                                ? packed.noHelped
                                : 0, onUpdate: () => {
                            mutators.setMissionHelped(false);
                            onSelectCounter(false);
                        }, isSelected: !currentCounter, counterwith: false })),
                React.createElement("div", { className: "separator-v mt-25 mb-25" }),
                React.createElement("p", { className: "size-big weight-light mt-5 mb-20" }, t("createmission.description")),
                React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                    React.createElement(FFCheckbox, { name: "public", label: `${t("createmission.maskpublic")}` })),
                React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                    React.createElement(FFCheckbox, { name: "hiddenSociety", label: `${t("createmission.hiddenSociety")}` })),
                role !== "ROLE_CLIENT" && (React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                    React.createElement(Field, { name: "society", validate: WhValidator.required, label: `${t("createmission.client")} *` }, props => (React.createElement(FilterAsyncSelect, { props: props, currentValue: values.society, filter: ClientAsyncData }))))),
                React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                    React.createElement(FFText, { name: "title", validate: WhValidator.required, label: `${t("createmission.objectmission")} *`, type: "text", containerSize: "big" })),
                React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                    React.createElement(FFText, { name: "job", validate: WhValidator.required, label: `${t("createmission.job")} *`, type: "text", containerSize: "big" })),
                React.createElement("div", { className: "castorForm-formRow mt-5 mb-5 castorForm-googlemap-container" },
                    React.createElement(Field, { name: "city", validate: WhValidator.required }, ({ input, meta: { touched, error } }) => (React.createElement(GoogleMapPlaces, { input: input, type: 'mission', inputClassName: "castorForm-inputContainer big googlemap-input", onUpdate: mutators.setPlaces, label: `${t("createmission.place")} *` },
                        React.createElement(Fragment, null, touched && error && (React.createElement("span", { className: "form__form-group-error formError" }, error))))))),
                React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                    React.createElement(FFSelect, { name: "expertise", validate: WhValidator.required, label: `${t("createmission.expertise")} *`, options: [
                            { value: "junior", label: t("expertise.junior") },
                            { value: "confirmed", label: t("expertise.confirmed") },
                            { value: "expert", label: t("expertise.expert") }
                        ] })),
                React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                    React.createElement(FFSelect, { name: "activitySector", validate: WhValidator.required, isSearchable: true, label: `${t("society.activity")} *`, options: sectors
                            ? sectors.map(s => ({ label: s.name, value: s.id }))
                            : [] })),
                React.createElement("div", { className: "separator-v mt-20 mb-15" }),
                React.createElement("p", { className: "size-big weight-light mt-5 mb-20" }, `${t("createmission.descriptionmission")}*`),
                React.createElement("p", { className: "size-small weight-light secondaryColor mb-10" },
                    " ",
                    `${t("createmission.editor_text")}`),
                React.createElement("div", { className: "castorForm-formRow ck-form-row flex-directionColumn mt-5 mb-5" },
                    React.createElement(Field, { name: "description", validate: WhValidator.requiredCkEditor }, props => (React.createElement(FieldDraftEditor, Object.assign({}, props, { onUpdate: mutators.setCKEditorValue }))))),
                React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                    React.createElement("p", { className: "size-big weight-light mb-20" }, t("createmission.planning"))),
                React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                    !values.startNow && (React.createElement(FFText, { name: "startDate", validate: values.startNow ? null : WhValidator.required, label: `${t("createmission.startDate")} *`, type: "date", containerSize: "mid" })),
                    React.createElement(FFCheckbox, { name: "startNow", label: t("createmission.now"), superLabel: t("createmission.begin") })),
                React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                    React.createElement(FFSelect, { name: "duration", validate: WhValidator.required, label: `${t("createmission.duration")} *`, options: durationTable(), containerSize: "mid" }),
                    React.createElement("div", { className: `castorForm-inputContainer castorForm-inputContainer--blank mid` })),
                React.createElement("div", { className: "separator-v mt-15 mb-15" }),
                React.createElement("p", { className: "size-big weight-light mb-20" }, "Tags"),
                React.createElement("div", { className: "flex-row mb-15" },
                    React.createElement("p", { className: "weight-light size-small mb-0" }, t("createmission.add_tags_text")),
                    React.createElement(Button, { id: "Popover1", type: "button", className: "popoverButton" }, "?"),
                    React.createElement(Popover, { placement: "bottom", isOpen: popoverOpen, target: "Popover1", toggle: togglePop },
                        React.createElement(PopoverBody, null, t("createmission.tags_draft_text")))),
                React.createElement("div", { className: "castorForm-formRow mb-5 tagsFormContainer" },
                    React.createElement(NewTagsForm, { maxValue: 15, mutator: mutators.setUpperCase })),
                role === "ROLE_CLIENT" && (React.createElement(Fragment, null,
                    React.createElement("div", { className: "separator-v mt-15 mb-15" }),
                    React.createElement("div", { className: "mission-contact mb-15" },
                        React.createElement("p", null, t("createmission.serviceterm")),
                        React.createElement("a", { className: "contact-nav", target: "_blank", href: "mailto:contact@castor-network.com" }, t("createmission.contactUs"))))),
                React.createElement("div", { className: "btn-container flex-row" }, getBTN(mutators)[role])));
        } }))));
};
export default FFCreateMission;
