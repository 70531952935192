import { MISSIONS } from "../../../Services/contants";
import ApiService from "../../../Services/ApiService";
import { setEndDate } from "../../../Shared/constants";
import moment from 'moment';
export const createMission = (data) => {
    const endDate = setEndDate(data.duration.value, data.startDate);
    let formatedData = {
        ...data,
        public: !data.public,
        expertise: data.expertise.value,
        city: data.city,
        duration: data.duration.value,
        geometry: {
            latitude: data.latitude,
            longitude: data.longitude
        },
        endDate: endDate,
        activitySector: data.activitySector.value,
        society: data.society.value,
        startDate: moment(data.startDate).format('YYYY-MM-DD'),
    };
    const missionService = new ApiService(MISSIONS);
    return missionService.create({ ...formatedData });
};
