export const candidatureStatutClient = {
    waiting: "clstate.waiting",
    accepted: "clstate.accepted",
    refused: "clstate.refused",
    none: "Aucun choix"
};
export const candidatureColorClient = {
    accepted: '#79d77c',
    waiting: '#1bb1f5',
    refused: '#fe5c5c',
    none: ''
};
