import React from "react";
import ButtonBottomForm from '../../WHForm/ButtonBottomForm';
import { Field } from 'redux-form';
import { WhFieldTxt } from '../../WHForm/WhFields';
const ReferenceForm = ({ toggle }) => {
    return (React.createElement("div", null,
        React.createElement(ButtonBottomForm, { toggle: toggle, display: true },
            React.createElement("div", { className: 'form-content' },
                React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                    React.createElement(Field, { label: "Société*", required: true, name: "societyName", className: 'label-input', type: "text", component: WhFieldTxt }),
                    React.createElement(Field, { label: "Poste*", required: true, name: "job", className: 'label-input', type: "text", component: WhFieldTxt })),
                React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                    React.createElement(Field, { label: "Nom du contact*", required: true, name: "contactName", className: 'label-input', type: "text", component: WhFieldTxt }),
                    React.createElement(Field, { label: "Email du contact", required: true, name: "contactEmail", className: 'label-input', type: "text", component: WhFieldTxt }),
                    React.createElement(Field, { label: "Téléphone du contact*", required: true, name: "contactPhone", className: 'label-input', type: "text", component: WhFieldTxt }))))));
};
export default ReferenceForm;
