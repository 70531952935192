import React from "react";
import Sidebar from "../../../Components/Sidebar/View";
import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";
import FFTextArea from "../../../Components/FinalForm/FFTextArea";
import Loading from "../../../Components/Loading";
const DeleteAccount = ({ onClose, current, onSubmitForm }) => {
    const onSubmit = (data) => {
        const updatedData = { ...data };
        if (!updatedData.canceledReason)
            updatedData.canceledReadon = "Aucune raison précisée";
        onSubmitForm(updatedData);
    };
    const { t } = useTranslation('sidebar');
    return (React.createElement("div", { className: "rm-mission-edit-form sidebarBody delete-mission-client" },
        React.createElement(Form, { onSubmit: onSubmit, render: ({ handleSubmit }) => {
                return (React.createElement("form", { onSubmit: handleSubmit },
                    current.matches('deleteAccount.submit') && React.createElement(Loading, null),
                    React.createElement("div", { className: 'form-content' },
                        React.createElement("p", { className: "size-big weight-light mt-5 mb-20" }, t('client_account.delete_account.content_good')),
                        React.createElement(FFTextArea, { name: "canceledReason", label: t("client_account.delete_account.helper") })),
                    React.createElement("div", { className: "btn-container flex-row" },
                        React.createElement("button", { onClick: onClose }, t("btn.cancel")),
                        React.createElement("button", { type: "submit" }, t("btn.confirm")))));
            } })));
};
const SideBarClient = ({ current, send, user }) => {
    const { t } = useTranslation('sidebar');
    return (React.createElement(Sidebar, { showSidebar: current.matches('deleteAccount'), close: () => send('CLOSE'), title: t(current.context.sideBarTitle) }, current.matches('deleteAccount') &&
        React.createElement(DeleteAccount, { current: current, onClose: () => send('CLOSE'), onSubmitForm: (data) => send('SUBMIT', { data: { id: user._id, reason: data.canceledReason } }) })));
};
export default SideBarClient;
