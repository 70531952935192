import React, { useState, useEffect } from "react";
import { Container, Nav } from "reactstrap";
import MenuParent from "./MenuParent";
import MenuChild from "./MenuChild";
import { AccessEnum, checkAccess } from "../../../Services/Access/access";
import { useDispatch, useSelector } from "react-redux";
import { fetchCounts } from "../../../redux/count/action";
import app from './../../RessourceManager/Notifications/feathers';
const MenuSideBar = ({ permissions = [], menuOpen, toggleRmMenu }) => {
    const user = useSelector((state) => state.user.data);
    const missionCountReload = useSelector((state) => state.reloader.missionCountReload);
    const [nbClientNotifications, setNbClientNotifications] = useState(0);
    const [nbFreelanceNotifications, setNbFreelanceNotifications] = useState(0);
    const dispatch = useDispatch();
    const sideBar = [
        {
            text: "dashboard.main",
            icon: "icon-dashboard",
            linkPath: "/rm/dashboard/freelance",
            childs: [
                {
                    text: "dashboard.freelances",
                    linkPath: "/rm/dashboard/freelance",
                },
                {
                    text: "dashboard.clients",
                    linkPath: "/rm/dashboard/client",
                },
            ],
        },
        {
            text: "freelances.freelance",
            icon: "icon-compte",
            linkPath: "/rm/freelances/new_freelances_list",
            childs: [
                {
                    text: "freelances.new",
                    linkPath: "/rm/freelances/new_freelances_list",
                    countNumber: "newFreelance",
                },
                {
                    text: "freelances.list",
                    linkPath: "/rm/freelances/freelances_list",
                },
            ],
        },
        {
            text: "clients.client",
            icon: "icon-client path1",
            linkPath: "/rm/clients/new_clients_list",
            childs: [
                {
                    text: "clients.new",
                    linkPath: "/rm/clients/new_clients_list",
                    countNumber: "newClients",
                },
                {
                    text: "clients.list",
                    linkPath: "/rm/clients/clients_list",
                },
            ],
        },
        {
            text: "missions.mission",
            icon: "icon-mission",
            linkPath: "/rm/missions/missions_list",
            childs: [
                {
                    text: "missions.validate",
                    linkPath: "/rm/missions/validate_missions_list",
                    countNumber: "validateMission",
                },
                {
                    text: "missions.list",
                    linkPath: "/rm/missions/missions_list",
                },
                {
                    text: "missions.pending",
                    linkPath: "/rm/missions/pending_missions_list",
                },
            ],
        },
        {
            text: "ressources.main",
            icon: "icon-rm",
            linkPath: "/rm/managers",
            childs: [],
        },
        {
            text: "statistiques.main",
            icon: "icon-stat",
            linkPath: "/rm/statistics",
            childs: [],
        },
    ];
    const [currentOpenIdx, setCurrentOpenIdx] = useState(null);
    const counts = useSelector((state) => state.counts);
    useEffect(() => {
        dispatch(fetchCounts());
    }, []);
    useEffect(() => {
        if (!missionCountReload)
            return;
        dispatch(fetchCounts());
    }, [missionCountReload]);
    const onClick = (idx, children) => {
        if (!children && toggleRmMenu)
            toggleRmMenu();
        setCurrentOpenIdx(idx);
    };
    const getCountNumber = (child) => {
        if (!child || !child.text)
            return -1;
        if (child.text === "missions.validate")
            return counts.mission_validates;
        if (child.text === "clients.new")
            return counts.new_clients;
        if (child.text === "freelances.new")
            return counts.new_freelances;
        if (child.text === "dashboard.freelances")
            return nbFreelanceNotifications;
        if (child.text === "dashboard.clients")
            return nbClientNotifications;
        return -1;
    };
    const updatedMessage = () => {
        app.io.emit('find', 'messages', { $limit: 1, "tos.sub": user._id, "tos.status": 'toread', "from.role": "CLIENT", canalPush: true, $sort: { date: -1 } }, (error, results) => {
            if (error) {
                console.log("MenuSideBar::updateMessage::Error while fetching notification::", error);
                return;
            }
            setNbClientNotifications(results.total);
        });
        app.io.emit('find', 'messages', { $limit: 1, "tos.sub": user._id, "tos.status": 'toread', "from.role": "FREELANCE", canalPush: true, $sort: { date: -1 } }, (error, results) => {
            if (error) {
                console.log("MenuSideBar::updateMessage::Error while fetching notification::", error);
                return;
            }
            setNbFreelanceNotifications(results.total);
        });
    };
    useEffect(() => {
        updatedMessage();
        app.service('messages')
            .on('created', updatedMessage);
        app.service('messages')
            .on('updated', updatedMessage);
        app.service('messages')
            .on('patched', updatedMessage);
        return () => app.service('messages').removeListener('created', updatedMessage);
    }, []);
    return (React.createElement(Container, { className: menuOpen ? "MenuSideBar openSidebar" : "MenuSideBar" },
        React.createElement(Nav, { vertical: true }, sideBar.map((sb, idx) => {
            if (sb.text === "statistiques.main" &&
                !checkAccess(AccessEnum.STATISTIQUES, permissions))
                return null;
            return (React.createElement(MenuParent, { text: sb.text, icon: sb.icon, linkPath: sb.linkPath, idx: idx, onClick: onClick, isOpen: currentOpenIdx === idx, key: idx }, sb.childs.length > 0 &&
                sb.childs.map((c, idx) => {
                    return (React.createElement(MenuChild, { toggleRmMenu: () => toggleRmMenu(), text: c.text, linkPath: c.linkPath, key: idx, countNumber: getCountNumber(c) }));
                })));
        }))));
};
export default MenuSideBar;
