import React, { useState, useEffect } from "react";
import FreelanceHeaderForm from './FreelanceHeaderForm';
import { PROFILES } from "../../../../Services/contants";
import ApiService from "../../../../Services/ApiService";
import { formatExpertiseValue } from '../../../../Shared/formatSelectValue';
import Notif from "../../../../Components/Notif";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import shallowequal from 'shallowequal';
import moment from 'moment';
import Loading from "../../../../Components/Loading";
import { setQualifButtonEnable } from "../../../../redux/reloader/action";
const FreelanceHeaderEdit = ({ profile, changePrecalState, disabled = false }) => {
    const [formData, setFormData] = useState(null);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation(['notif', 'document']);
    const dispatch = useDispatch();
    const form = useSelector(state => state.form['freelance_header_form']);
    const params = useSelector(state => state.params);
    useEffect(() => {
        let formatData = { ...profile };
        formatData.availability = formatData.availability ? formatData.availability : null;
        formatData.expertise = formatExpertiseValue(params.expertises, profile.expertise);
        formatData.googleMapCity = formatData.address.mobilityCity;
        setFormData(formatData);
    }, []);
    const onSubmit = async (data) => {
        dispatch(setQualifButtonEnable(false));
        if (!data.hasOwnProperty('googleMapCity') || (data.googleMapCity !== data.address.mobilityCity)) {
            alert("Tapez une ville puis sélectionnez la dans la liste proposée.");
            return;
        }
        setLoading(true);
        const profileService = new ApiService(PROFILES);
        const { status, commentaires, profileDocuments, ...submitData } = data;
        submitData.expertise = submitData.expertise ? submitData.expertise.value : null;
        submitData.address.mobilityRayon = submitData.address.mobilityRayon ? parseInt(submitData.address.mobilityRayon) : null;
        submitData.tjm = submitData.tjm ? parseInt(submitData.tjm) : null;
        submitData.availability = data.availability ? moment(data.availability).format("YYYY-MM-DD") : null;
        submitData.address = { ...submitData.address, country: submitData.address.country.value };
        submitData.qualified = profile.qualified;
        let initialValues = { ...form.initial, availability: moment(form.initial.availability).toJSON() };
        let values = { ...form.values, availability: moment(form.values.availability).toJSON() };
        if (!shallowequal(initialValues, values)) {
            await changePrecalState();
            profileService.update(submitData).then(() => {
                setLoading(false);
                Notif({ color: "success", message: t('notif:success_edit'), duration: 100 });
            });
        }
    };
    return (React.createElement("div", null,
        loading && React.createElement(Loading, null),
        React.createElement(FreelanceHeaderForm, { onSubmit: onSubmit, disabled: disabled, expertises: params.expertises, initialValues: formData, form: 'freelance_header_form', isDirty: 'freelance_header_form' })));
};
export default FreelanceHeaderEdit;
