import React, { Fragment, useState } from "react";
import RmCandidatureSnapshotContainer from "./RmCandidatureSnapshot";
import ReactTable from "react-table-6";
import { RmCandidatureSubComponent } from "./RmCandidatureSnapshot";
import { useTranslation } from "react-i18next";
import FreelanceSearch from "../../RessourceManager/MissionLists/FreelanceSearch";
import ApiService from "../../../Services/ApiService";
import { CANDIDATURE, MISSIONSEVENT } from "../../../Services/contants";
import RmStatusSelect from "./RmStatusSelect";
import ContactTypeSelect from "./ContactTypeSelect";
import { addCandidature, missionCandidatureNotif } from "../../../Services/notif/notif";
import { rmEvent } from "./rmEventConstant";
import { candidatureStatutClient } from "../../../Shared/utils/candidatureutils";
import { isEmptyObj } from "../../../helper/isEmpty";
import Loading from "../../../Components/Loading";
import { getApiError } from "../../../Shared/utils/getApiErrorTranslations";
const RmCandidatureTableNew = ({ candidatures, missionId, readonly = true, callback, missionTitle = "", actions, rowData, }) => {
    const { t } = useTranslation("candidatures");
    const [loadFreelance, setLoadFreelance] = useState(false);
    const updateMissionsStatus = (code, candidatureId) => {
        const missionService = new ApiService(MISSIONSEVENT);
        setLoadFreelance(true);
        missionService
            .create({
            mission: "/api/missions/" + missionId,
            code: code,
            candidature: "/api/candidatures/" + candidatureId,
        })
            .then(() => {
            if (callback)
                callback();
            setLoadFreelance(false);
            missionCandidatureNotif.success();
        })
            .catch(() => {
            setLoadFreelance(false);
            missionCandidatureNotif.failed();
        });
    };
    const onAddFreelance = (data) => {
        if (isEmptyObj(data))
            return;
        setLoadFreelance(true);
        const candidatureService = new ApiService(CANDIDATURE);
        candidatureService
            .create({
            mission: "/api/missions/" + missionId,
            user: "/api/users/" + data.value,
            type: "par le rm",
            contactType: "NONE",
            tjm: "300",
            statusRm: "waiting",
            statusClient: "waiting",
            statusFl: "1000",
        })
            .then((_data) => {
            updateMissionsStatus(114, _data.id);
            addCandidature.success();
        })
            .catch((error) => {
            setLoadFreelance(false);
            addCandidature.failed(getApiError(error));
        });
    };
    const colDefaultConfig = {
        sortable: false,
        accessor: "",
        default: true,
        filterable: false,
    };
    const defaultColumns = [
        {
            Header: () => (React.createElement("div", { className: "weight-bold size-small" }, t("header.freelance"))),
            ...colDefaultConfig,
            width: 160,
            Cell: (props) => {
                const onHover = () => {
                    actions.setExpandRow(props.index);
                };
                return (React.createElement(RmCandidatureSnapshotContainer, { user: props.original.user, onHover: onHover }));
            },
        },
        {
            Header: () => {
                return (React.createElement("div", { className: "weight-bold size-small" }, t("header.rmchoice")));
            },
            ...colDefaultConfig,
            accessor: "statusRm",
            width: 130,
            Cell: (row) => (React.createElement(RmStatusSelect, { row: row, readonly: readonly, rowData: rowData, actions: actions })),
        },
        {
            Header: () => {
                return (React.createElement("div", { className: "weight-bold size-small" }, t("header.clientchoice")));
            },
            ...colDefaultConfig,
            accessor: "statusClient",
            width: 150,
            Cell: (row) => {
                return (React.createElement("div", { className: `flex-selfStart weight-light candidature-client-cell candidature-client-cell-${row.value}` },
                    React.createElement("div", null, `${t(candidatureStatutClient[row.value])}`),
                    React.createElement("div", null, `${t(row.original.statusClientToValid
                        ? candidatureStatutClient[row.original.statusClientToValid]
                        : candidatureStatutClient["none"])}`)));
            },
        },
        {
            Header: () => {
                return (React.createElement("div", { className: "weight-bold size-small" }, t("header.status")));
            },
            ...colDefaultConfig,
            accessor: "statusFl",
            width: 80,
            Cell: (row) => {
                const status = {
                    "1100": "Attente",
                    "1200": "En attente d'activation",
                    "1300": "En cours",
                    "1400": "Annulé",
                    "1500": "Terminée",
                };
                let label = row.value ? status[row.value] : "aucun";
                if (row.original.indicationFl === "Attente validation dossier")
                    label = "Accepter";
                return (React.createElement("div", { className: `size-tiny weight-light candidature-freelance-cell candidature-freelance-cell-${row.value}` }, label));
            },
        },
        {
            Header: () => {
                return (React.createElement("div", { className: "weight-bold size-small" }, t("header.contactclient")));
            },
            ...colDefaultConfig,
            accessor: "contactType",
            width: 180,
            Cell: (row) => (React.createElement(ContactTypeSelect, { row: row, missionTitle: missionTitle, rowData: rowData, actions: actions })),
        },
        {
            Header: () => {
                return React.createElement("div", { className: "weight-bold size-small" });
            },
            ...colDefaultConfig,
            accessor: "_id",
            width: 25,
            Cell: (row) => {
                const deleteCandidature = () => {
                    actions.deleteCandidature(row.value);
                };
                if (row.original.user.firstName !== 'none' &&
                    (readonly ||
                        row.original.statusFl === "1400" ||
                        row.original.type === "candidature" ||
                        row.original.statusRm !== rmEvent["ATTENTE"])) {
                    return React.createElement("div", null);
                }
                return (React.createElement("div", { className: `size-small weight-light`, onClick: deleteCandidature },
                    React.createElement("i", { className: "icon-trash" })));
            },
        },
        {
            Header: () => {
                return React.createElement("div", null);
            },
            ...colDefaultConfig,
            width: 0,
            className: "flex-directionColumn flex-alignCenter p-0 validationButtons",
            Cell: (row) => {
                const canSaveOrDelete = () => {
                    if (!rowData.rows.hasOwnProperty(row.index.toString()))
                        return false;
                    let r = rowData.rows[row.index];
                    if (r.previousRmValue !==
                        r.statusRm)
                        return true;
                    if (r.contactType !== row.original.contactType)
                        return true;
                    return false;
                };
                const register = () => {
                    if (!canSaveOrDelete())
                        return;
                    actions.save(row);
                };
                const cancel = () => {
                    actions.cancel(row.index);
                };
                return (React.createElement(Fragment, null, canSaveOrDelete() && (React.createElement(Fragment, null,
                    React.createElement("div", { onClick: cancel, className: "btn-cancel" }),
                    React.createElement("div", { onClick: register, className: "btn-register" })))));
            },
        },
    ];
    return (React.createElement("div", { className: "candidatureTable" },
        !readonly && (React.createElement("div", null,
            React.createElement(FreelanceSearch, { onSubmit: onAddFreelance, loadFreelance: loadFreelance, candNumber: candidatures.length, candidatures: candidatures }))),
        loadFreelance && React.createElement(Loading, { className: 'zIndex-5' }),
        candidatures.length > 0 && (React.createElement("div", { onMouseLeave: actions.resetExpanded },
            React.createElement(ReactTable, { manual: true, loading: false, loadingText: '', className: "-striped -highlight", expanded: rowData.rowExpanded, data: candidatures, columns: defaultColumns, showPagination: false, pages: 0, pageSize: 0, SubComponent: ({ row }) => {
                    const onHover = () => {
                        actions.setExpandRow(row._index);
                    };
                    return (React.createElement(RmCandidatureSubComponent, { onHover: onHover, type: row._original.type, user: row._original.user }));
                } })))));
};
export default RmCandidatureTableNew;
