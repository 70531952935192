export const getMissiongFieldPreProd = [
    "id",
    "_id",
    "title",
    "job",
    "hiddenSociety",
    "description",
    "public",
    "startDate",
    "endDate",
    "duration",
    "startNow",
    "expertise",
    "expirationDate",
    "publicationDate",
    "comment",
    "activitySector{id, _id, name}",
    "nbModification",
    "canceledReason",
    "geometry{id _id latitude longitude}",
    "city",
    "tjm",
    "tags(first:20){edges{node{_id id status name}}}",
    "society{id _id name photo{_id id content} affiliate mainContact{firstName lastName}}",
    'candidatures(first:500){edges{node{_id, id, statusFl, type, indicationFlNew statusClientToValid, indicationFl, contactType, statusRm, statusClient, user{_id id firstName, email, civilityLabel, photo{_id content}, lastName, profile{_id id tjm, job profileDocuments{edges{node{ _id, type, document{_id, url, type, name} }}}}} }}}',
    "missionStatus{statusRm statusClient indicationClient indicationRm subStatusClient subStatusRm}",
    "helped",
    "updatedBy{id _id firstName lastName photo{_id id content}}"
];
export const getMissiongFieldProd = [
    "id",
    "_id",
    "title",
    "job",
    "hiddenSociety",
    "description",
    "public",
    "startDate",
    "endDate",
    "duration",
    "startNow",
    "expertise",
    "publicationDate",
    "comment",
    "activitySector{id, _id, name}",
    "nbModification",
    "canceledReason",
    "geometry{id _id latitude longitude}",
    "city",
    "tjm",
    "tags(first:20){edges{node{_id id status name}}}",
    "society{id _id name photo{_id id content} affiliate mainContact{firstName lastName}}",
    'candidatures(first:500){edges{node{_id, id, statusFl, type, statusClientToValid, indicationFlNew indicationFl, contactType, statusRm, statusClient, user{_id id firstName, email, photo{_id content}, lastName, profile{_id id tjm, job profileDocuments{edges{node{ _id, type, document{_id, url, type, name} }}}}} }}}',
    "missionStatus{statusRm statusClient indicationClient indicationRm subStatusClient subStatusRm}",
    "helped",
    "updatedBy{id _id firstName lastName photo{_id id content}}"
];
