import { useState, useEffect } from 'react';
import { defaultStatValue } from "../../../Interface/Stat";
import * as kpiService from "../../../Services/kpis";
import { useFilter } from "../../../Hooks/useFilter";
export const useStats = () => {
    const { headerFilters, filters, removeMultipleFilters, updateFilters, currentFilters: currentSelectedFilters, removeFilters, } = useFilter("statistique", "graphql");
    const [allStats, setAllStats] = useState(defaultStatValue);
    const [allStatsLoading, setAllStatsLoading] = useState(false);
    const [fireUseEffect, setFireUseEffect] = useState(null);
    const [onlyFromStats, setOnlyFromStats] = useState(defaultStatValue);
    const [onlyFromStatsLoading, setOnlyFromStatsLoading] = useState(false);
    const [currentFilters, setCurrentFilters] = useState(currentSelectedFilters);
    const [currentDateFilters, setCurrentDateFilters] = useState([]);
    const getKpisFrom = async (to) => {
        const newFilters = {
            from: new Date(2018, 0, 1),
            to: to.value.value
        };
        console.log("here ?");
        try {
            setOnlyFromStatsLoading(true);
            const res = await kpiService.get(newFilters);
            setOnlyFromStats(res);
            setOnlyFromStatsLoading(false);
        }
        catch (error) {
            console.error("Error::", error);
            setOnlyFromStatsLoading(false);
        }
        finally {
            setOnlyFromStatsLoading(false);
        }
    };
    const getKpis = async (filters) => {
        let formatedFiltersForApi = await formatFilterForApi(filters);
        try {
            setAllStatsLoading(true);
            const res = await kpiService.get(formatedFiltersForApi);
            setAllStats(res);
            setAllStatsLoading(false);
        }
        catch (error) {
            console.error("Error::", error);
            setAllStatsLoading(false);
        }
        finally {
            setAllStatsLoading(false);
        }
    };
    const formatFilterForApi = async (filters) => {
        let formatedFilters = {};
        const to = filters.find((s) => s.name === 'to');
        const from = filters.find((s) => s.name === 'from');
        formatedFilters = {
            to: to.value.value,
            from: from.value.value,
        };
        return formatedFilters;
    };
    useEffect(() => {
        const year = new Date().getFullYear();
        let newData = {
            from: new Date(year, 0, 1),
            to: new Date()
        };
        updateFilters(newData, null, true);
    }, []);
    useEffect(() => {
        setCurrentFilters(currentSelectedFilters);
    }, [currentSelectedFilters]);
    useEffect(() => {
        if (JSON.stringify(currentDateFilters) === JSON.stringify(currentFilters))
            return;
        setCurrentDateFilters(currentFilters);
    }, [currentSelectedFilters]);
    useEffect(() => {
        setFireUseEffect(Math.random());
    }, [currentDateFilters]);
    useEffect(() => {
        if (!fireUseEffect)
            return;
        getKpis(currentSelectedFilters);
    }, [fireUseEffect]);
    return ({
        allStats,
        onlyFromStats,
        onlyFromStatsLoading,
        allStatsLoading,
        setCurrentDateFilters,
        headerFilters,
        filters,
        removeMultipleFilters,
        updateFilters,
        currentFilters,
        removeFilters,
    });
};
