import React from "react";
import classnames from "classnames";
export const WhButton = (props) => {
    const { type, children, classNames, back, submit, cancel, onClick, color, small, outline, icon, } = props;
    const btnColor = color ? `wh-btn-color-${color}` : `wh-btn-color-default`;
    let btnType = type;
    let btnTypeClass = null;
    let btnSize = null;
    if (submit) {
        btnType = "submit";
        btnTypeClass = "wh-btn-submit";
    }
    if (cancel) {
        btnType = "reset";
        btnTypeClass = "wh-btn-cancel";
    }
    if (small) {
        btnSize = "wh-btn-small";
    }
    const btnOutline = outline ? "wh-btn-outline" : null;
    return (React.createElement("button", { className: classnames("wh-btn", { "wh-btn-type-back": back }, btnColor, btnTypeClass, btnOutline, btnSize, classNames), onClick: onClick, type: btnType ? btnType : "button" },
        children,
        " ",
        icon && React.createElement("span", { className: classnames("ico", icon) })));
};
export const WhButtonGroup = ({ children }) => {
    return React.createElement("div", { className: "wh-btn-group" }, children);
};
export default WhButton;
