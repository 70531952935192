import React, { useState, Fragment } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
const FileField = ({ onChange, name, disabled = false, value }) => {
    const [message, setMessage] = useState(value ? value : "Selectionner un fichier");
    const [inputKey, setInputKey] = useState(Date.now());
    const [greenClass, setGreenClass] = useState(false);
    const { t } = useTranslation("document");
    const getUploadedFileName = e => {
        if (e) {
            let files = e.target.files, value = e.target.value, message;
            if (files && files.length > 1)
                message = `${files.length} files selected`;
            else
                message = value.split("\\").pop();
            if (message) {
                setGreenClass(true);
                setMessage(value.split("\\").pop());
            }
        }
        else {
            setInputKey(Date.now());
            if (message) {
                setMessage("Selectionner un fichier");
                setGreenClass(false);
            }
            if (onChange)
                onChange(null);
        }
    };
    const cvDocClass = classNames("inputFileContainer", {
        active: message !== "Selectionner un fichier"
    });
    const onIputFileChange = event => {
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.onload = event => {
            onChange({
                content: event.target.result,
                name: file.name,
                file: file,
                type: file.type
            });
        };
        reader.readAsDataURL(file);
        getUploadedFileName(event);
    };
    return (React.createElement(Fragment, null,
        React.createElement("input", { id: name, type: "file", className: "hiddenFileInput", "data-multiple-caption": message, name: name, disabled: disabled, key: inputKey, onChange: () => onIputFileChange(event) }),
        React.createElement("label", { className: cvDocClass },
            React.createElement("span", { className: `flex flex-alignCenter  flex-justifyBetween  ${greenClass ? "fc-greenOk" : ""}` },
                message,
                React.createElement("i", { onClick: () => {
                        if (window.confirm("Voulez vous supprimer le fichier ?"))
                            getUploadedFileName(null);
                    }, className: `circle circle-tiny circle-transparent  ${greenClass ? "circle-border-green" : ""}  icon-close size-tiniest ml-10` }))),
        React.createElement("label", { className: "roundBlock-addBtn", htmlFor: name },
            t("selectFile"),
            React.createElement("span", { className: "circle circle-gray circle-sm circle-absolute" },
                React.createElement("i", { className: "icon-plus_1 size-small" })))));
};
const FFRenderCustomFileField = ({ input, label = "", disabled = false }) => {
    return (React.createElement(FileField, Object.assign({}, input, { label: label, disabled: disabled, value: input.value })));
};
const FileImgField = ({ onChange, name, disabled = false, imgAsInput = false, label, value, roundedImage = false }) => {
    const [message, setMessage] = useState(value ? value : "Selectionner un fichier");
    const [inputKey, setInputKey] = useState(Date.now());
    const getUploadedFileName = e => {
        if (e) {
            let files = e.target.files, value = e.target.value, message;
            if (files && files.length > 1)
                message = `${files.length} files selected`;
            else
                message = value.split("\\").pop();
            if (message)
                setMessage(value.split("\\").pop());
        }
        else {
            setInputKey(Date.now());
            if (message)
                setMessage("Selectionner un fichier");
            if (onChange)
                onChange(null);
        }
    };
    const onIputFileChange = event => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.onload = event => {
            onChange({
                content: event.target.result,
                name: file.name,
                file: file,
                type: file.type
            });
        };
        reader.readAsDataURL(file);
        getUploadedFileName(event);
    };
    const backgroundUrl = `url(${value.content})`;
    return (React.createElement("div", { className: "logo" }, !imgAsInput ? (React.createElement(Fragment, null,
        React.createElement("div", { className: "img" }, value && React.createElement("img", { src: value.content })),
        React.createElement("input", { id: name, type: "file", className: "hiddenFileInput", "data-multiple-caption": message, name: name, disabled: disabled, key: inputKey, onChange: () => onIputFileChange(event) }),
        React.createElement("label", { htmlFor: name, className: "littleTextButton" },
            React.createElement("span", null, label)))) : (React.createElement("div", { className: "img" },
        value && (React.createElement(Fragment, null, roundedImage ? (React.createElement("div", { className: "picture-container" },
            React.createElement("div", { className: "picture", style: {
                    backgroundImage: backgroundUrl
                } }))) : (React.createElement("img", { src: value.content })))),
        React.createElement("input", { id: name, type: "file", src: value.content, className: "hiddenFileInput", "data-multiple-caption": message, name: name, disabled: disabled, key: inputKey, onChange: () => onIputFileChange(event) }),
        React.createElement("label", { htmlFor: name, className: "littleTextButton icon-edit" },
            React.createElement("span", { className: "ml-10" }, label))))));
};
export const FFRenderImgFileUpload = ({ input, label = "", imgAsInput = false, disabled = false, roundedImage = false }) => {
    return (React.createElement(FileImgField, Object.assign({}, input, { label: label, imgAsInput: imgAsInput, roundedImage: roundedImage, disabled: disabled, value: input.value })));
};
export default FFRenderCustomFileField;
