import React, { useState, Fragment } from "react";
import FormWrapper from "../../Form/FormWrapper";
import ButtonBottomForm from "../../../Components/WHForm/ButtonBottomForm";
import { Field } from "redux-form";
import DisplayForm from "../../WHForm/DisplayForm";
import { UseDocument } from "../Documents/useDocument";
import renderCustomFileField from "../../../Components/WHForm/renderCustomFileField";
import ShowDocument from "../Documents/showDocument";
import { useTranslation } from "react-i18next";
import Loading from "../../Loading";
import shallowequal from 'shallowequal';
import { useSelector } from "react-redux";
const DocumentForm = ({ data, submitFile, disabled = false, changePrecalState, formName, fileType, dateText, title, secondTitle, secondText }) => {
    const { shouldDisplayForm, displayFormToggle } = DisplayForm();
    const [loaded, setLoaded] = useState(true);
    const { profileDocument } = UseDocument(data, fileType);
    const { t } = useTranslation(["notif", "document"]);
    const form = useSelector(state => state.form[formName]);
    const updateFile = async (fileData) => {
        setLoaded(false);
        if (!shallowequal(form.initial, form.values) && changePrecalState) {
            changePrecalState();
        }
        await submitFile(fileData, fileType, profileDocument);
        displayFormToggle();
        setLoaded(true);
    };
    return (React.createElement("div", { className: "mb-20 roundBlock documentItem" }, loaded ? (React.createElement(Fragment, null, !shouldDisplayForm ? (React.createElement("div", { className: "pt-30 pr-30 pb-30 pl-30" },
        React.createElement("span", { className: "size-big weight-bold text-left" },
            t(title),
            secondTitle &&
                React.createElement(Fragment, null,
                    React.createElement("br", null),
                    t(secondTitle))),
        React.createElement("p", { className: 'text-left mt-10' },
            t(dateText),
            secondText &&
                React.createElement(Fragment, null,
                    React.createElement("br", null),
                    t(secondText))),
        React.createElement(ShowDocument, { doc: profileDocument, disabled: disabled, toggle: () => displayFormToggle() }))) : (React.createElement("div", null,
        React.createElement("div", { className: "pt-30 pr-30 pl-30" },
            React.createElement("span", { className: "size-big weight-bold text-left" },
                t(title),
                secondTitle &&
                    React.createElement(Fragment, null,
                        React.createElement("br", null),
                        t(secondTitle))),
            React.createElement("p", { className: 'text-left mt-10' },
                t(dateText),
                secondText &&
                    React.createElement(Fragment, null,
                        React.createElement("br", null),
                        t(secondText)))),
        React.createElement(FormWrapper, { onSubmit: updateFile, form: formName, initialValues: { document: profileDocument ? profileDocument.document.url : null } },
            React.createElement(ButtonBottomForm, { toggle: () => displayFormToggle(), display: shouldDisplayForm },
                React.createElement("div", { className: "file-form-content" },
                    React.createElement(Field, { name: "document", component: renderCustomFileField, label: t("document:uploadFile") })))))))) : (React.createElement(Loading, null))));
};
export default DocumentForm;
