import React from 'react';
import { FieldSelect } from "../../../Components/WHForm/WhFields/FieldSelect";
import { useSelector } from "react-redux";
export const FilterSelect = ({ label, input, options, currentValue, placeholder, className = '', containerSize = 'mid', type = '' }) => {
    const state = useSelector(state => state);
    const get = (p, o) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);
    return (React.createElement("div", { className: `castorForm-inputContainer select-container ${containerSize} ${type} ${className ? className : ""}` },
        React.createElement(FieldSelect, Object.assign({}, input, { name: label, value: !currentValue ? '' : input.value, defaultValue: options[2], disabled: false, options: options.hasOwnProperty('getOptionsFromRedux') && options.getOptionsFromRedux ? get(options.params.split("."), state) : options, placeholder: placeholder })),
        React.createElement("label", { className: "selectLabel" }, label),
        type === "length" && React.createElement("span", { className: "icon-attente inputIcon" })));
};
