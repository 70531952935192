import React, { Fragment, useEffect, useState } from "react";
import Loading from "../../Components/Loading";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../Services/ApiService";
import { PROFILES, USERS } from "../../Services/contants";
import { freelancePriorityNotif, removeClientNotif } from "../../Services/notif/notif";
import { useTranslation } from "react-i18next";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useStoredNoFilters } from "../../Hooks/useStoredNoFilters";
import { fetchCounts } from "../../redux/count/action";
const LoadFreelance = ({ id, render, history }) => {
    const [storedQuery] = useState({
        api: process.env.REACT_APP_IDENTITY_GQL_URL
            ? process.env.REACT_APP_IDENTITY_GQL_URL
            : "",
        table: "user",
        entity: "User",
        list: false,
        query: {
            fields: [
                "id",
                "_id",
                "firstName",
                "lastName",
                "email",
                "civility",
                "nationality",
                "birthdate",
                "function",
                "type",
                "phone",
                "photo{_id id content}",
                "helpCreation",
                "newsletter{id, _id, email}",
                "profile{_id, id,  status commentaires{id _id}, qualified, address{id, _id, city, country, address, cpltAddress, zipCode, mobilityCity, mobilityRayon}, description, job, linkedin, tjm, availability, mobilityCity, mobilityRayon, prioritized,  mobilityZipCode, expertise,  profileDocuments(first:15){edges{node{_id,type,  document{_id, url, type, name} }}}}"
            ],
            filters: [
                {
                    value: `api/users/${id}`,
                    name: "id"
                }
            ]
        }
    });
    const { data, loadData, isCurrentlyLoading } = useStoredNoFilters(storedQuery);
    const [readOnly, setReadOnly] = useState(true);
    const [loaders, setLoaders] = useState({ delete: false });
    const user = useSelector((state) => state.user.data);
    const dispatch = useDispatch();
    const { t } = useTranslation(["notif", "freelance"]);
    useEffect(() => {
        if (user)
            setReadOnly(user.type === "CLIENT");
    }, [user]);
    const CastorPopUp = (title, confirm, cancel) => {
        const MySwal = withReactContent(Swal);
        return MySwal.fire({
            title: "",
            text: title,
            confirmButtonText: confirm,
            cancelButtonText: cancel,
            customClass: {
                popup: "swallModal-popup",
                content: "swallModal-container",
                actions: "swallModal-actions form-bottom-button",
                confirmButton: "swallModal-orangeButton secondButton btn btn-secondary",
                cancelButton: "swallModal-blueButton firstButton btn btn-secondary"
            },
            showCancelButton: true,
        });
    };
    const changePrecalState = async () => {
        const userService = new ApiService(USERS);
        await userService.update({ _id: id, precalState: 2 }).then(() => {
            loadData();
        });
    };
    const qualifFreelance = async () => {
        const profileService = new ApiService(PROFILES);
        if (!isCurrentlyLoading) {
            await profileService
                .update({ _id: data.profile._id, qualified: true })
                .then(() => {
                dispatch(fetchCounts());
                loadData();
            });
        }
    };
    const deleteAccount = () => {
        return CastorPopUp(t("notif:freelance.delete_confirm_title_other"), t("notif:freelance.delete_register"), t("notif:freelance.delete_cancel")).then((result) => {
            if (!result.value)
                return;
            setLoaders({ ...loaders, delete: true });
            const userService = new ApiService(USERS);
            userService
                .remove(data._id)
                .then(() => {
                dispatch(fetchCounts());
                removeClientNotif.success();
                setLoaders({ ...loaders, delete: false });
                history.push(data.profile.qualified ? "/rm/freelances/freelances_list" : "/rm/freelances/new_freelances_list");
            })
                .catch(e => {
                console.error(e);
                setLoaders({ ...loaders, delete: false });
                removeClientNotif.failed();
            });
        });
    };
    const changePriority = () => {
        return CastorPopUp(t("notif:freelance.priority.change_priority_title"), t("notif:freelance.delete_register"), t("notif:freelance.delete_cancel")).then((result) => {
            if (!result.value)
                return;
            const profileService = new ApiService(PROFILES);
            profileService
                .update({ _id: data.profile._id, prioritized: !data.profile.prioritized })
                .then(() => {
                freelancePriorityNotif.success();
                loadData();
            })
                .catch(e => {
                console.error(e);
                freelancePriorityNotif.failed();
            });
        });
    };
    return (React.createElement(Fragment, null,
        (isCurrentlyLoading) && React.createElement(Loading, { className: "load-zIndexMin" }),
        data && render({ data, loadData, user, loaders, qualifFreelance, changePriority, deleteAccount, changePrecalState, readOnly, isCurrentlyLoading })));
};
export default LoadFreelance;
