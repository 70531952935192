import React from "react";
import classnames from "classnames";
export const WhH1 = ({ weight, children, light, style }) => {
    let weightClass = weight ? `wh-${weight}` : null;
    weightClass = light ? `wh-light` : null;
    return (React.createElement("h1", { className: classnames("wh-H1", weightClass), style: style }, children));
};
export const WhH2 = ({ weight, children, light, style }) => {
    let weightClass = weight ? `wh-${weight}` : null;
    weightClass = light ? `wh-light` : null;
    return (React.createElement("h2", { className: classnames("wh-H1", weightClass), style: style }, children));
};
