import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
const SearchContainer = ({ title, withBtn, textBtn, iconBtn, onClick, children, addClassName, header, }) => {
    const { t } = useTranslation("searchcontainer");
    return (React.createElement("div", { className: addClassName ? `${addClassName} searchContainer` : "searchContainer" },
        (header) && (React.createElement("div", { className: "header" },
            React.createElement("p", { className: classnames("weight-bold", "size-title", "brightColor", "mb-0") }, t(title)),
            withBtn && (React.createElement("div", null,
                React.createElement("button", { className: classnames("button", { "button-icon": iconBtn }, "roundBlock-addBtn"), onClick: onClick }, textBtn && React.createElement("span", { className: "txt" },
                    t(textBtn),
                    " ")))))),
        children));
};
export default SearchContainer;
