import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import { NavItem } from "reactstrap";
import { useTranslation } from "react-i18next";
const MenuParent = ({ text, icon, children, linkPath, onClick, idx, isOpen, }) => {
    const { t } = useTranslation('menusidebar');
    return (React.createElement(Fragment, null,
        React.createElement(NavItem, { className: "menuSideBarElement", onClick: () => {
                if (isOpen) {
                    onClick(null, children ? true : false);
                }
                else {
                    onClick(idx, children ? true : false);
                }
            } },
            React.createElement(NavLink, { to: linkPath, activeClassName: "active", className: "flex-row" },
                icon && React.createElement("i", { className: icon }),
                React.createElement("span", null, t(text)),
                children &&
                    React.createElement(Fragment, null, !isOpen ? (React.createElement("div", { className: "navItem-arrow" },
                        React.createElement("i", { className: "icon-fleche-droite" }))) : (React.createElement("div", { className: "navItem-arrow" },
                        React.createElement("i", { className: "icon-fleche-droite rotate-90" })))))),
        isOpen && children));
};
export default withRouter(MenuParent);
