import React, { Fragment } from "react";
import { Row, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import { ROLES } from "../../../constants/roles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const HeaderMainContent = ({ user_role, toggleMenu }) => {
    const { t } = useTranslation("header");
    const params = useSelector((state) => state.params);
    const goToMission = () => {
        window.location.href = params.missionsUrl;
    };
    const goToPartners = () => {
        window.location.href = params.partnersUrl;
    };
    const getMenu = () => ({
        [ROLES.FREE]: (React.createElement(Fragment, null,
            React.createElement(NavItem, null,
                React.createElement("div", { className: "header-link", onClick: goToMission }, t("freelances.search"))),
            React.createElement(NavItem, null,
                React.createElement(NavLink, { onClick: toggleMenu, to: "missions" }, t("freelances.mine"))),
            React.createElement(NavItem, null,
                React.createElement("div", { className: "header-link", onClick: goToPartners }, t("freelances.partner"))),
            React.createElement(NavItem, { className: "vd-flex ml-auto" },
                React.createElement("a", { className: "contact-nav", target: "_blank", href: "mailto:contact@castor-network.com" }, "Contact")))),
        [ROLES.CLIENT]: (React.createElement(Fragment, null,
            React.createElement(NavLink, { onClick: toggleMenu, to: "/client/missions" }, t("clients.mine")),
            React.createElement(NavLink, { onClick: toggleMenu, to: "/client/dashboard" }, "Tableau de bord"),
            React.createElement(NavItem, { className: "vd-flex ml-auto" },
                React.createElement("a", { className: "contact-nav", target: "_blank", href: "mailto:contact@castor-network.com" }, "Contact")))),
        [ROLES.RM]: React.createElement(Row, null),
    });
    return React.createElement(Fragment, null, getMenu()[user_role]);
};
export default HeaderMainContent;
