import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import SearchContainer from "../../../../Components/SearchContainer/SearchContainer";
import { Container } from "reactstrap";
import { WhH2, WhButton, WhBlock, WhConfirm } from "../../../../Components/WHComponents";
import PermissionsForm from "./PermissionsForm";
import ApiService from "../../../../Services/ApiService";
import { USERS } from "../../../../Services/contants";
import Sidebar from "../../../../Components/Sidebar/View";
import FFCreateManager from "../CreateManagerForm";
import { deleteClientNotif, rightNotif } from "../../../../Services/notif/notif";
import { useUser } from "../../../../Services/useUser";
import { AccessEnum, checkAccess } from "../../../../Services/Access/access";
import Loading from "../../../../Components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { setUserDefault } from "../../../../redux/user/action";
import { getApiError } from "../../../../Shared/utils/getApiErrorTranslations";
import RoundedPhoto from "../../../../Components/BigPhoto/RoundedPhoto";
import FormRmHeader from "./FormRmHeader";
import DisplayForm from "../../../../Components/WHForm/DisplayForm";
const ManagerView = ({ history, match }) => {
    const [loaded, setLoaded] = useState(true);
    const connectedUser = useSelector((state) => state.user.data);
    const [modalOpen, setModalOpen] = useState(false);
    const { data: user, loaded: fetchLoaded, loadData } = useUser({
        name: "id",
        value: `api/users/${match.params.id}`
    });
    const [formLoaded, setFormLoaded] = useState(false);
    const dispatch = useDispatch();
    const create = submitData => {
        setFormLoaded(true);
        let permissions = [];
        for (let [key, value] of Object.entries(submitData)) {
            if (key !== "civility" &&
                key !== "lastName" &&
                key !== "firstName" &&
                key !== "email" &&
                key !== "phone" &&
                key !== "type" &&
                key !== "roles" &&
                key !== "function") {
                let permission = { permission: key, value: value === "1" };
                permissions.push(permission);
            }
        }
        let userService = new ApiService(USERS);
        userService
            .create({
            ...submitData,
            civility: submitData.civility.value,
            permissions: permissions
        })
            .then(() => {
            setFormLoaded(false);
            setModalOpen(false);
            rightNotif.success();
        })
            .catch(() => {
            setFormLoaded(false);
            rightNotif.failed();
        });
    };
    const deleteManager = () => {
        const userService = new ApiService(USERS);
        WhConfirm({
            title: connectedUser._id === parseInt(match.params.id)
                ? "Souhaitez-vous vraiment supprimer votre compte ?"
                : "Souhaitez-vous vraiment supprimer ce compte ?",
            ifConfirm: () => {
                userService
                    .remove(match.params.id)
                    .then(() => {
                    deleteClientNotif.success();
                    history.push("/rm/managers");
                })
                    .catch(_e => {
                    deleteClientNotif.failed();
                });
            },
            ifNotConfirm: () => false,
            labelYes: "Enregistrer",
            labelNo: "Annuler"
        });
    };
    const onSubmitPermission = (dataToSubmit) => {
        setLoaded(false);
        let permissions = [];
        for (let [key, value] of Object.entries(dataToSubmit)) {
            let permission = { permission: key, value: value === "1" };
            permissions.push(permission);
        }
        let userService = new ApiService(USERS);
        userService
            .update({ _id: match.params.id, permissions: permissions })
            .then(() => {
            setLoaded(true);
            if (connectedUser._id === parseInt(match.params.id)) {
                dispatch(setUserDefault());
            }
            rightNotif.success();
        })
            .catch(e => {
            setLoaded(true);
            rightNotif.failed(getApiError(e));
        });
    };
    const { t } = useTranslation("managers");
    const { shouldDisplayForm, displayFormToggle } = DisplayForm();
    if (!fetchLoaded)
        return React.createElement(Loading, { className: "load-zIndexMin" });
    return (React.createElement("div", { className: `managers_container rightContainer veryLittleHeader` },
        React.createElement(SearchContainer, { title: "managers.title", header: true, withBtn: checkAccess(AccessEnum.ADD_MEMBER, connectedUser.permissions), textBtn: "managers.btn", iconBtn: "icon-plus_1", onClick: () => setModalOpen(true), addClassName: "blueBackground" }),
        React.createElement(Container, null,
            !loaded && React.createElement(Loading, { className: "load-zIndexMin" }),
            formLoaded && React.createElement(Loading, { className: "load-zIndexMin" }),
            React.createElement(WhButton, { back: true, color: "blue", onClick: () => history.push("/rm/managers") }),
            user && (React.createElement(Fragment, null,
                React.createElement("div", { className: "roundBlock" },
                    React.createElement(Fragment, null,
                        React.createElement("div", { className: "headerFreeItem flex-end" }, !shouldDisplayForm && (React.createElement("span", { className: "littleTextButton editButton", "data-testid": "updateButton", onClick: () => displayFormToggle() }, "Modifier"))),
                        !shouldDisplayForm && (React.createElement("div", { className: "header card-body" },
                            React.createElement(RoundedPhoto, { imgUrl: user.photo && user.photo.content ? user.photo.content : undefined, photoClassNames: `medium ${user.photo && user.photo.content ? "" : "medium picture-container-withBorder"}` }),
                            React.createElement("div", { className: "rm-info-container content flex-directionRow flex-directionColumnMobile flex-justifyBetween flex-alignStart" },
                                React.createElement("div", { className: `castorForm-inputContainer small size-small pt-25 pb-5 pl-10 pr-20` },
                                    React.createElement("span", { className: "castorForm-fakeLabel weight-light" }, (t('freelance:account.firstname'))),
                                    React.createElement("span", { className: `castorForm-fakeInput weight-bold` }, user.firstName)),
                                React.createElement("div", { className: `castorForm-inputContainer mid size-small pt-25 pb-5 pl-10 pr-20` },
                                    React.createElement("span", { className: "castorForm-fakeLabel weight-light" }, (t('freelance:account.lastname'))),
                                    React.createElement("span", { className: `castorForm-fakeInput weight-bold` }, user.lastName)),
                                React.createElement("div", { className: `castorForm-inputContainer big size-small pt-25 pb-5 pl-10 pr-20` },
                                    React.createElement("span", { className: "castorForm-fakeLabel weight-light" }, (t('freelance:account.phone'))),
                                    React.createElement("span", { className: `castorForm-fakeInput weight-bold` }, user.phone))))),
                        shouldDisplayForm && (React.createElement("div", { "data-testid": "displayForm" },
                            React.createElement("div", { className: "rm-info-container flex-directionColumn flex-directionColumnMobile flex-justifyBetween flex-alignStart" },
                                React.createElement(FormRmHeader, { rm: user, loadData: loadData, toggle: () => displayFormToggle() })))))),
                React.createElement(WhH2, { weight: "light" }, "R\u00F4les"),
                React.createElement(PermissionsForm, { onSubmit: onSubmitPermission, userPermissions: user.permissions }),
                (connectedUser._id === parseInt(match.params.id) ||
                    checkAccess("rm_delete", connectedUser.permissions)) && (React.createElement(WhBlock, { pullRight: true, row: true },
                    React.createElement(WhButton, { outline: true, color: "danger", onClick: () => deleteManager() }, t("delete-account"))))))),
        React.createElement(Sidebar, { showSidebar: modalOpen, close: setModalOpen, title: t("btn-create") },
            React.createElement(FFCreateManager, { close: () => setModalOpen(false), onSubmit: create, isLoading: formLoaded }))));
};
export default ManagerView;
