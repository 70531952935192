import React, { Fragment } from "react";
import Loading from "../../../../Components/Loading";
import ApiService from "../../../../Services/ApiService";
import { MISSIONSEVENT } from "../../../../Services/contants";
import { missionCreateNotif, missionStatusUpdateNotif } from "../../../../Services/notif/notif";
import { getApiError } from "../../../../Shared/utils/getApiErrorTranslations";
import { createMission } from "../../../Missions/Functions/createMission";
import { useSelector } from "react-redux";
import { useStoredElasticSearchMission } from "../../../../Hooks/useStoresElasticSearch";
const LoadMissionListES = ({ render, defaultFilters = [], facetsConfig, sortConfig }) => {
    const { data, reloadData, facets, isLoading, loadData, setRequestFilters, setDefaultFiltersOverride, totalItems, hasNextPage, loadNextPage, isNextPageLoading } = useStoredElasticSearchMission(defaultFilters, facetsConfig, sortConfig);
    const user = useSelector((state) => state.user.data);
    const updateMissionsStatus = (mission_id, code) => {
        const missionService = new ApiService(MISSIONSEVENT);
        missionService
            .create({ mission: mission_id, code: code })
            .then(() => {
            reloadData();
            missionStatusUpdateNotif.success();
        })
            .catch(e => {
            missionStatusUpdateNotif.failed(getApiError(e));
        });
    };
    const onSubmitMission = data => {
        let missionTags = getTags(data);
        let formatedData = { ...data, tags: missionTags };
        return createMission(formatedData)
            .then(_data => {
            missionCreateNotif.success();
            updateMissionsStatus(_data["@id"], data.status);
        })
            .catch(e => {
            missionCreateNotif.failed(getApiError(e));
        });
    };
    const getTags = data => {
        let tags = data.tags ? data.tags.map(t => t.id) : [];
        let newTags = data.new_tags
            ? data.new_tags.map(t => t.__isNew__ ? { status: "published", name: t.label } : t.value)
            : [];
        let missionTags = tags.concat(newTags);
        return missionTags;
    };
    return (React.createElement(Fragment, null,
        isLoading && React.createElement(Loading, { className: "load-zIndexMin" }),
        data &&
            render({
                data,
                totalItems,
                facets,
                setRequestFilters,
                isLoading,
                loadData,
                loadNextPage,
                setDefaultFiltersOverride,
                isNextPageLoading,
                hasNextPage,
                user,
                updateMissionsStatus,
                onSubmitMission,
                getTags
            })));
};
export default LoadMissionListES;
