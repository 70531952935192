import { FILTER_ASYNC_SELECT, FILTER_TYPE_SELECT } from "../../../constants";
const headerFilters = [
    {
        name: 'name',
        label: 'search.client',
        className: 'label-input filter filter-with-marin-right-small',
        filterType: FILTER_ASYNC_SELECT,
        field: 'text',
        customComponentParameters: {
            customComponentName: 'default',
            isSearchable: true,
            isMulti: false
        },
        formatFilterForApi: (value) => { return ({ id: "id", value: value }); },
        formatCurrent: (obj) => { return obj.value.label; },
        asyncQueryParameters: {
            query: `${process.env.REACT_APP_IDENTITY_API_URL}/api/societies`,
            formatQueryResult: (obj) => obj,
            params: {
                filter_name: 'name',
                defaultFilters: [{ id: 'exists[mainContact.deletedAt]', value: false }, { id: "mainContact.type", value: "CLIENT" }, { value: true, id: "qualified" }],
                pageSize: 30,
                page: 1
            },
            formatResult: (obj) => obj["hydra:member"].map((o) => {
                return ({
                    label: `${o.name} (${o.mainContact.lastName} ${o.mainContact.firstName})`,
                    displayLabel: `${o.name} (${o.mainContact.lastName} ${o.mainContact.firstName})`,
                    value: o['@id'],
                    filterName: 'name'
                });
            })
        }
    },
    {
        name: "rm_lastName",
        label: 'rm',
        className: 'label-input filter filter-with-marin-right-small',
        filterType: FILTER_ASYNC_SELECT,
        field: 'text',
        customComponentParameters: {
            customComponentName: 'default',
            isSearchable: true,
            isMulti: false,
        },
        formatFilterForApi: (value) => { return ({ id: "rm.id", value: value }); },
        formatCurrent: (obj) => { return obj.value.label; },
        asyncQueryParameters: {
            query: `${process.env.REACT_APP_IDENTITY_API_URL}/api/users`,
            params: {
                filter_name: 'lastName',
                defaultFilters: [{ id: 'exists[deletedAt]', value: false }, { id: "type", value: "RM" }],
                pageSize: 30,
                page: 1
            },
            formatResult: (obj) => obj["hydra:member"].map((o) => ({
                label: o.name,
                value: o["@id"],
                filterName: 'rm_firstName'
            }))
        }
    }
];
const filters = [
    {
        name: "society_activitySector_name_list",
        formatFilterForApi: (values) => { return ({ id: "activitySector.name[]", value: values }); },
        label: 'activity_sector',
        className: 'label-input filter filter-with-marin-right-small',
        filterType: FILTER_ASYNC_SELECT,
        customComponentParameters: {
            isSearchable: true,
            isMulti: true,
            customComponentName: 'activity_sector',
        },
        formatCurrent: (obj) => obj,
        asyncQueryParameters: {
            query: `${process.env.REACT_APP_IDENTITY_API_URL}/api/activity_sectors`,
            params: {
                filter_name: 'name',
                defaultFilters: [],
                pageSize: 30,
                page: 1
            },
            formatResult: (obj) => obj["hydra:member"].map((o) => ({
                label: o.name,
                value: o.name,
                displayLabel: `${o.name}`,
                filterName: 'society_activitySector_list'
            }))
        }
    },
    {
        name: 'address_city_list',
        label: 'Ville',
        filterType: FILTER_ASYNC_SELECT,
        customComponentParameters: {
            isSearchable: true,
            isMulti: true,
            customComponentName: 'location'
        },
        formatFilterForApi: (values) => { return ({ id: "address.city[]", value: values }); },
        formatCurrent: (obj) => { return obj; },
        asyncQueryParameters: {
            query: `${process.env.REACT_APP_IDENTITY_API_URL}/api/societies`,
            params: {
                filter_name: 'city',
                defaultFilters: [{ id: 'exists[mainContact.deletedAt]', value: false }, { id: "mainContact.type", value: "CLIENT" }, { value: true, id: "qualified" }],
                pageSize: 30,
                page: 1
            },
            formatResult: (obj) => {
                return obj["hydra:member"].map((o) => ({
                    label: o.address.city,
                    value: o.address.city,
                    filterName: 'address_city_city_list'
                }));
            }
        }
    },
    {
        name: 'newsletter_exists',
        label: 'newsletter',
        defaultName: 'mainContact_newsletter',
        filterType: FILTER_TYPE_SELECT,
        placeholder: '',
        formatFilterForApi: (values) => { return ({ id: 'exists[mainContact.newsletter]', value: values.value }); },
        formatCurrent: (obj) => { return `Newsletter : ${obj.value.label}`; },
        options: [
            { value: 'toRemoveWhenFetch', label: "Vide" },
            { value: true, label: "Oui" },
            { value: false, label: "Non" },
        ],
    },
];
export default { headerFilters, filters };
