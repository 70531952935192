import React, { Fragment, useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Field } from "redux-form";
import { WhFieldTxt } from "../../WHForm/WhFields";
import { useTranslation } from "react-i18next";
const portfolioItem = ({ name, displayForm, onUpdate, disabled = false, isNewItem, cancelItem, fieldRemove, portfolio, }) => {
    const [displayItemForm, setDispayItemForm] = useState(false);
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    useEffect(() => {
        if (displayForm === false) {
            setDispayItemForm(false);
        }
        if (isNewItem) {
            onUpdate();
            setDisabledSubmit(true);
        }
    }, [displayForm]);
    const updateRef = () => {
        onUpdate();
        setDispayItemForm(true);
    };
    const cancel = () => {
        setDispayItemForm(false);
        cancelItem();
    };
    const seeProtfolioLink = url => {
        let prefix = url.startsWith("http") ? "" : "http://";
        window.open(prefix + url);
    };
    const textFieldChange = (value) => {
        (!value) ? setDisabledSubmit(true) : setDisabledSubmit(false);
    };
    const { t } = useTranslation("common");
    return (React.createElement(Fragment, null, (displayForm && displayItemForm) || isNewItem ? (React.createElement(Fragment, null,
        React.createElement("div", { className: "form-content withRemove" },
            React.createElement(Field, { className: "label-input", name: name ? `${name}.url` : "URL", label: "URL", type: "text", component: WhFieldTxt, onChange: (evt) => textFieldChange(evt.target.value) }),
            !disabled &&
                React.createElement("span", { className: "roundBlock-remove", onClick: () => {
                        if (window.confirm("Êtes-vous sur de vouloir supprimer cet item ?"))
                            fieldRemove();
                    } }, " ")),
        React.createElement("div", { className: "form-bottom-button" },
            React.createElement(Button, { type: "reset", onClick: () => cancel(), className: "blueButton firstButton" }, "Annuler"),
            React.createElement(Button, { type: "submit", className: "orangeButton secondButton", disabled: disabledSubmit },
                " ",
                "Valider",
                " ")))) : (React.createElement(Fragment, null,
        !disabled &&
            React.createElement("span", { style: { textAlign: "right" }, onClick: () => updateRef(), className: "littleTextButton editButton" }, t("modification")),
        React.createElement("span", { onClick: () => seeProtfolioLink(portfolio.url), className: "portfolioLink" },
            React.createElement("div", { className: "form-content disabled" },
                React.createElement(Field, { name: name ? `${name}.url` : "URL", type: "text", component: WhFieldTxt, disabled: false, onChange: (evt) => textFieldChange(evt.tagert.value) })))))));
};
export default portfolioItem;
