import React, { Fragment, useEffect } from "react";
import { Button } from "reactstrap";
import { buttonsText, statusParameters } from "./buttonText";
import { useTranslation } from "react-i18next";
const FreelanceButtonsList = ({ status, onSubmit, indication, setNumberButton }) => {
    const { t } = useTranslation("missions");
    useEffect(() => {
        if (status.label === statusParameters["ACTIVATION_WAIT"] && (!indication ||
            indication.value !== 100))
            setNumberButton(2);
        if (status.label === statusParameters["IN_PROGRESS"])
            setNumberButton(1);
    }, [status]);
    return (React.createElement(Fragment, null,
        status.label === statusParameters["ACTIVATION_WAIT"] && (!indication ||
            indication.value !== 100) && (React.createElement("div", { className: "container-flex container-flex-row" },
            React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-blue wh-btn", onClick: () => {
                    if (onSubmit)
                        onSubmit("DECLINE");
                } }, t(buttonsText["DECLINE_MISSION_FREELANCE"])),
            React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-primary wh-btn", onClick: () => {
                    if (onSubmit)
                        onSubmit("ACTIVE");
                } }, t(buttonsText["ACTIVE_MISSION"])))),
        status.label === statusParameters["IN_PROGRESS"] && (React.createElement("div", { className: "container-flex container-flex-row" },
            React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-blue wh-btn", onClick: () => {
                    if (onSubmit)
                        onSubmit("END_MISSION");
                } }, t(buttonsText["INFORM_END_MISSION"]))))));
};
export default FreelanceButtonsList;
