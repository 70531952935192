import React, { Fragment, useEffect, useState } from "react";
import SearchContainer from "../../../../Components/SearchContainer/SearchContainer";
import { GroupedFiltersList, NormalFilters, QueryFilters } from "../../../SearchFilter/FiltersList";
import GraphQl from "../../../../Shared/graphql";
import { useFilter } from "../../../../Hooks/useFilter";
import { Container, ModalBody, ModalHeader } from "reactstrap";
import exportCSV from "../../../../Shared/exportCsv";
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";
import InfiniteScroll from "../../../../Components/InfiniteScroll/InfiniteScroll";
import DisplaySelectedFilters from "../../../../Components/Filters/DisplaySelectedFilters";
import Result from "../../../../Components/FoundResult/Result";
import FreelanceListElementES from "./FreelanceListElementES";
import moment from "moment";
import "moment/locale/fr";
import Loading from "../../../../Components/Loading";
import { AccessEnum, checkAccess } from "../../../../Services/Access/access";
import { accessNotif } from "../../../../Services/notif/notif";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
const Search = ({ updateFilters, removeFilters, facets, headerFilters, currentFilters }) => {
    return (React.createElement(SearchContainer, { title: "freelances.list", header: true, withBtn: false, addClassName: "blueBackground centerContainer" }, headerFilters && (React.createElement("div", { className: "filterList castorForm-formRow" },
        React.createElement(QueryFilters, { filter: headerFilters.find(hf => hf.name === "query"), removeFilters: removeFilters, updateFilters: updateFilters, currentValues: currentFilters }),
        React.createElement(GroupedFiltersList, { isImmediate: true, filters: headerFilters.filter(hf => hf.name !== "query"), removeFilters: removeFilters, facets: facets, currentValues: currentFilters, updateFilters: updateFilters })))));
};
const MainBody = ({ children }) => {
    const { t } = useTranslation("rmfreelance");
    return (React.createElement("div", { className: "freelanceTable" },
        React.createElement(Container, { className: "infinite-scroll-container freelance-infinite-scroll-container freelanceListContainer" },
            React.createElement("div", { className: "orderFilterContainer" },
                React.createElement("span", { className: "exportButton littleTextButton", onClick: () => exportCSV("/api/export/freelance_qualified", "qualified-freelance") }, t("export.btn"))),
            children)));
};
const CommentItem = ({ note }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { style: { fontSize: 14, fontWeight: 300 } }, note.message),
        React.createElement("p", { style: { fontSize: 14, fontWeight: 300 } },
            React.createElement("strong", null,
                `Le ${moment(note.created).format("DD MMMM YYYY")}`,
                " par",
                " ",
                note.user.firstName,
                " ",
                note.user.lastName))));
};
const FreelanceClosed = ({ data, index, setSelectedIndex }) => {
    return (React.createElement(Fragment, null,
        React.createElement("div", null,
            React.createElement(FreelanceListElementES, { freelance: data[index], openModal: () => setSelectedIndex(index) }))));
};
export const FreelanceListBody = ({ data, facets, loadNextPage, hasNextPage, isNextPageLoading, totalItems, setRequestFilters }) => {
    const { headerFilters, filters, updateFilters, currentFilters, filterRequest, removeFilters } = useFilter("freelanceList", "graphql");
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [showNote, setShowNote] = useState(false);
    const [commentPending, setCommentPending] = useState(false);
    const [displayMoreContent, setDisplayMoreContent] = useState(false);
    const [seeProfile, setSeeProfile] = useState(false);
    const user = useSelector((state) => state.user.data);
    const history = useHistory();
    const [freelanceSelected, setFreelanceSelected] = useState({
        firstname: "",
        lastname: "",
        comments: [],
        ref: ""
    });
    const [comments, setComments] = useState([]);
    const [hiddenComments, setHiddenComments] = useState([]);
    const openNote = async () => {
        setCommentPending(true);
        setComments([]);
        if (!showNote)
            setFreelanceSelected(data[selectedIndex]);
        setShowNote(!showNote);
        try {
            const graphqlConfig = {
                api: process.env.REACT_APP_IDENTITY_GQL_URL
                    ? process.env.REACT_APP_IDENTITY_GQL_URL
                    : "",
                table: "commentaires",
                entity: "Commentaire",
                list: true,
                query: {
                    order: "DESC",
                    orderBy: { name: "created" },
                    fields: [
                        "id",
                        "_id",
                        "message",
                        "created",
                        "user{id, _id, firstName, lastName, photo{_id id content}}"
                    ],
                    maxResults: 20,
                    page: 0,
                    filters: [
                        {
                            value: `${`api/commentaire_collections/${data[selectedIndex].comments_collection}`}`,
                            name: "collection"
                        }
                    ]
                }
            };
            const res = await GraphQl(graphqlConfig);
            const [first = null, second = null, ...others] = res.datas;
            setComments([first, second].filter((el) => el !== null));
            console.log('others lenght', others.length);
            if (others.length > 8) {
                setSeeProfile(true);
            }
            setHiddenComments(others.slice(0, 8));
        }
        catch (error) {
            console.log({ error });
        }
        setCommentPending(false);
    };
    useEffect(() => {
        if (selectedIndex === -1)
            return;
        openNote();
    }, [selectedIndex]);
    useEffect(() => {
        if (!showNote) {
            setSelectedIndex(-1);
            setDisplayMoreContent(false);
        }
    }, [showNote]);
    useEffect(() => {
        if (!setRequestFilters)
            return;
        setRequestFilters(filterRequest);
        setSelectedIndex(-1);
    }, [filterRequest]);
    const { t } = useTranslation("form");
    const showMoreContent = () => {
        setDisplayMoreContent(true);
    };
    return (React.createElement(Fragment, null,
        React.createElement(Search, { updateFilters: updateFilters, removeFilters: removeFilters, facets: facets, headerFilters: headerFilters, currentFilters: currentFilters }),
        React.createElement("div", { className: "selectable-filter wrapFilters" }, filters && (React.createElement(NormalFilters, { currentValues: currentFilters, filters: filters, facets: facets, updateFilters: updateFilters }))),
        React.createElement(DisplaySelectedFilters, { filters: filters, headerFilters: headerFilters, selectedFilters: currentFilters, removeFilters: removeFilters }),
        React.createElement(Result, { size: totalItems }),
        React.createElement(Modal, { isOpen: showNote, toggle: openNote },
            React.createElement(ModalHeader, null,
                "Note :",
                " ",
                `${freelanceSelected.firstname} ${freelanceSelected.lastname}`),
            React.createElement(ModalBody, null,
                commentPending && React.createElement(Loading, null),
                !commentPending &&
                    React.createElement(React.Fragment, null,
                        comments.map((note, index) => (React.createElement("div", { key: index },
                            React.createElement(CommentItem, { note: note })))),
                        displayMoreContent && hiddenComments.length > 0 && hiddenComments.map((note, index) => (React.createElement("div", { key: index },
                            React.createElement(CommentItem, { note: note })))),
                        displayMoreContent && seeProfile &&
                            React.createElement("span", { className: "showMoreButton", onClick: () => {
                                    if (!checkAccess(AccessEnum.ACCESS_PROFIL, user.permissions)) {
                                        accessNotif.failed();
                                        return null;
                                    }
                                    return history.push(`/rm/freelances/freelance_edit/${freelanceSelected.ref}`);
                                } }, "Voir plus de notes sur le profil"),
                        !displayMoreContent && hiddenComments.length > 0 && (React.createElement("span", { className: "showMoreButton", onClick: () => showMoreContent() }, t("comment.more")))))),
        React.createElement(MainBody, null,
            React.createElement(InfiniteScroll, { data: data, rowHeight: 140, height: 1000, width: 930, hasNextPage: hasNextPage, loadNextPage: loadNextPage, isNextPageLoading: isNextPageLoading, component: ({ index }) => (React.createElement("div", { key: index, className: "freelance-element" },
                    React.createElement(FreelanceClosed, { data: data, index: index, setSelectedIndex: setSelectedIndex }))) }))));
};
