import React, { useState, useEffect, Fragment } from "react";
import DisplayForm from '../../WHForm/DisplayForm';
import FormWrapper from "../../Form/FormWrapper";
import InformationForm from './InformationForm';
import * as userService from '../../../Services/user';
import { SubmissionError } from 'redux-form';
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import Loading from "../../Loading";
import formatSelectvalue from "../../../Shared/formatSelectValue";
import { CIVILITY } from '../../../constants/civility';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import countryList from 'react-select-country-list';
import { updateNotif } from "../../../Services/notif/notif";
import { formatFirstName, formatLastName } from "../../../helper/formatUserName";
import { setQualifButtonEnable } from "../../../redux/reloader/action";
const FreelanceInformations = ({ userData, disabled = false, callback, changePrecalState }) => {
    const dispatch = useDispatch();
    const { shouldDisplayForm, displayFormToggle } = DisplayForm();
    const [loaded, setLoaded] = useState(false);
    const [formData, setFormData] = useState({});
    const [civility, setCivility] = useState();
    const [statusLabel, setStatusLabel] = useState(null);
    const [country, setCountry] = useState();
    const params = useSelector((state) => state.params);
    const form = useSelector((state) => state.form['freelance_informations_form']);
    useEffect(() => {
        let formatData = { ...userData, firstName: formatFirstName(userData.firstName), lastName: formatLastName(userData.lastName) };
        formatData.googleMapCity = formatData.profile.address.city;
        formatData.profile = { ...formatData.profile };
        formatData.newsletter = userData.newsletter ? true : false;
        formatData.civility = formatSelectvalue(CIVILITY, userData.civility);
        getCountry(formatData);
        formatData.profile.status = formatSelectvalue(params.flStatuses, parseInt(formatData.profile.status));
        formatData.profile.address.country = { label: country ? country.label : formatData.profile.address.country, value: formatData.profile.address.country };
        setFormData(formatData);
        let civility = CIVILITY.find((st) => st.value === userData.civility);
        setCivility(civility);
        if (formatData.profile.status) {
            setStatusLabel(formatData.profile.status.label);
        }
        setLoaded(true);
    }, [userData]);
    const getCountry = (user) => {
        let country = countryList().getData().find((c) => c.value === user.profile.address.country);
        setCountry(country);
    };
    const onSubmit = (data) => {
        dispatch(setQualifButtonEnable(false));
        if (!data.hasOwnProperty('googleMapCity') || (data.googleMapCity !== data.profile.address.city)) {
            alert("Tapez une ville puis sélectionnez la dans la liste proposée.");
            return;
        }
        if (data.profile.address.lat === 0 || data.profile.address.lng === 0) {
            alert("Tapez une ville puis sélectionnez la dans la liste proposée.");
            return;
        }
        const { newsletter, photo, googleMapCity, ...submitData } = data;
        const { profileDocuments, tjm, mobilityRayon, ...formatProfile } = data.profile;
        let initialValues = { ...form.initial, birthdate: moment(form.initial.birthdate).toJSON() };
        let values = { ...form.values, birthdate: moment(form.values.birthdate).toJSON() };
        if (JSON.stringify(initialValues) !== JSON.stringify(values) && changePrecalState) {
            changePrecalState();
        }
        setLoaded(false);
        let newsletterData = null;
        if (data.newsletter) {
            newsletterData = {
                user: userData,
                email: data.email,
                id: userData.neswletter ? userData.newsletter['@id'] ? userData.newsletter['@id'] : userData.newsletter.id : null,
            };
        }
        let formatData = {
            ...submitData,
            civility: data.civility.value,
            birthdate: moment(data.birthdate).format("YYYY-MM-DD"),
            _id: data._id ? data._id : data.id,
            nationality: data.nationality,
            newsletter: newsletterData,
            profile: {
                ...formatProfile,
                status: data.profile.status.value,
                address: {
                    ...data.profile.address,
                    country: data.profile.address.country.value
                }
            }
        };
        userService.update(formatData).then((_data) => {
            updateNotif.success();
            if (callback) {
                callback();
            }
            setLoaded(true);
        }).catch((error) => {
            setLoaded(true);
            updateNotif.failed();
            throw new SubmissionError(error);
        });
        displayFormToggle();
    };
    const { t } = useTranslation(['form', 'rmfreelance', 'common']);
    const addAsterix = (text) => {
        return `${text}*`;
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: 'roundBlock loadingContainer' },
            React.createElement("div", { className: 'headerFreeItem' },
                React.createElement("h2", { className: "thinTitle" }, t('rmfreelance:personalinformations')),
                !disabled && !shouldDisplayForm && (React.createElement("span", { className: 'littleTextButton editButton', "data-testid": 'updateButton', onClick: () => displayFormToggle() }, t('common:modification')))),
            !loaded && React.createElement(Loading, null),
            React.createElement(Fragment, null,
                !shouldDisplayForm && (React.createElement("div", { className: "content castorForm freelanceInfos" },
                    React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                        React.createElement("div", { className: `castorForm-inputContainer mid size-small pt-25 pb-5 pl-10 pr-20` },
                            React.createElement("span", { className: "castorForm-fakeLabel weight-light" }, addAsterix(t('form:contact.lastname'))),
                            React.createElement("span", { className: `castorForm-fakeInput weight-bold` }, formatLastName(userData.lastName))),
                        React.createElement("div", { className: `castorForm-inputContainer mid size-small pt-25 pb-5 pl-10 pr-20` },
                            React.createElement("span", { className: "castorForm-fakeLabel weight-light" }, addAsterix(t('form:contact.firstname'))),
                            React.createElement("span", { className: `castorForm-fakeInput weight-bold` }, formatFirstName(userData.firstName)))),
                    React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                        React.createElement("div", { className: `castorForm-inputContainer small size-small pt-25 pb-5 pl-10 pr-20` },
                            React.createElement("span", { className: "castorForm-fakeLabel weight-light" }, addAsterix(t('form:contact.civility'))),
                            React.createElement("span", { className: `castorForm-fakeInput weight-bold` }, civility ? civility.label : '')),
                        React.createElement("div", { className: `castorForm-inputContainer mid size-small pt-25 pb-5 pl-10 pr-20` },
                            React.createElement("span", { className: "castorForm-fakeLabel weight-light" }, addAsterix(t('form:contact.birthdate'))),
                            React.createElement("span", { className: `castorForm-fakeInput weight-bold` },
                                React.createElement(Moment, { format: "DD/MM/YYYY" }, userData.birthdate))),
                        React.createElement("div", { className: `castorForm-inputContainer big size-small pt-25 pb-5 pl-10 pr-20` },
                            React.createElement("span", { className: "castorForm-fakeLabel weight-light" }, addAsterix(t('form:contact.nationality'))),
                            React.createElement("span", { className: `castorForm-fakeInput weight-bold` }, userData.nationality))),
                    React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                        React.createElement("div", { className: `castorForm-inputContainer small size-small pt-25 pb-5 pl-10 pr-20` },
                            React.createElement("span", { className: "castorForm-fakeLabel weight-light" }, addAsterix(t('form:contact.zipcode'))),
                            React.createElement("span", { className: `castorForm-fakeInput weight-bold` }, userData.profile.address && userData.profile.address.zipCode ? userData.profile.address.zipCode : '')),
                        React.createElement("div", { className: `castorForm-inputContainer big size-small pt-25 pb-5 pl-10 pr-20` },
                            React.createElement("span", { className: "castorForm-fakeLabel weight-light" }, addAsterix(t('form:contact.city'))),
                            React.createElement("span", { className: `castorForm-fakeInput weight-bold` }, userData.profile.address && userData.profile.address.city ? userData.profile.address.city : '')),
                        false &&
                            React.createElement("div", { className: `castorForm-inputContainer big size-small pt-25 pb-5 pl-10 pr-20` },
                                React.createElement("span", { className: "castorForm-fakeLabel weight-light" }, addAsterix(t('form:contact.address'))),
                                React.createElement("span", { className: `castorForm-fakeInput weight-bold` }, userData.profile.address && userData.profile.address.address ? userData.profile.address.address : ''))),
                    React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                        React.createElement("div", { className: `castorForm-inputContainer big size-small pt-25 pb-5 pl-10 pr-20` },
                            React.createElement("span", { className: "castorForm-fakeLabel weight-light" }, addAsterix(t('form:contact.email'))),
                            React.createElement("span", { className: `castorForm-fakeInput weight-bold` }, userData.email)),
                        React.createElement("div", { className: `castorForm-inputContainer small size-small pt-25 pb-5 pl-10 pr-20` },
                            React.createElement("span", { className: "castorForm-fakeLabel weight-light" }, addAsterix(t('form:contact.phone'))),
                            React.createElement("span", { className: `castorForm-fakeInput weight-bold` }, userData.phone))),
                    React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                        React.createElement("div", { className: `castorForm-inputContainer mid size-small pt-25 pb-5 pl-10 pr-20` },
                            React.createElement("span", { className: "castorForm-fakeLabel weight-light" }, addAsterix(t('form:contact.country'))),
                            React.createElement("span", { className: `castorForm-fakeInput weight-bold` }, userData.profile.address.country.label)),
                        React.createElement("div", { className: `castorForm-inputContainer big size-small  pt-25 pb-5 pl-10 pr-20` },
                            React.createElement("span", { className: "castorForm-fakeLabel weight-light" }, addAsterix(t('form:contact.status'))),
                            React.createElement("span", { className: `castorForm-fakeInput weight-bold` }, statusLabel))),
                    !disabled &&
                        React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                            React.createElement("div", { className: `castorForm-inputContainer castorForm-inputContainer--noBorder mid size-small pt-25 pb-5 pl-10 pr-20` },
                                React.createElement("span", { className: "castorForm-fakeLabel weight-light size-tiny" }, t('form:contact.helpCreation')),
                                React.createElement("span", { className: `castorForm-fakeInput weight-bold` }, userData.helpCreation ? "Oui" : "Non"))),
                    !disabled &&
                        React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                            React.createElement("div", { className: `castorForm-inputContainer castorForm-inputContainer--noBorder mid size-small pt-25 pb-5 pl-10 pr-20` },
                                React.createElement("span", { className: "castorForm-fakeLabel weight-light size-tiny" }, t('form:contact.newsletter')),
                                React.createElement("span", { className: `castorForm-fakeInput weight-bold` }, userData.newsletter ? "Oui" : "Non"))))),
                shouldDisplayForm && (React.createElement("div", { "data-testid": "displayForm" },
                    React.createElement(FormWrapper, { form: 'freelance_informations_form', onSubmit: onSubmit, initialValues: formData },
                        React.createElement(InformationForm, { shouldDisplayForm: shouldDisplayForm, toggle: () => displayFormToggle(), statuses: params.flStatuses }))))))));
};
export default FreelanceInformations;
