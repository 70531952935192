export const formatLastName = (lastName) => {
    if (!lastName)
        return lastName;
    return lastName.toUpperCase();
};
export const formatFirstName = (firstName) => {
    if (!firstName)
        return firstName;
    let updatedFirstname = firstName.toLowerCase().replace(/(^\w{1})|(\s{1}\w{1})|(^\w|-\w)/g, match => match.toUpperCase());
    return updatedFirstname;
};
export const formatUserName = (firstName, lastName) => {
    return `${formatFirstName(firstName)} ${formatLastName(lastName)}`;
};
