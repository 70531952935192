import React from "react";
import { Field } from "redux-form";
import ButtonBottomForm from "../../Components/WHForm/ButtonBottomForm";
import { WhTextarea } from "../../Components/WHForm/WhFields";
const aboutForm = ({ toggle, shouldDisplayForm }) => {
    return (React.createElement("div", null,
        React.createElement(ButtonBottomForm, { toggle: toggle, display: shouldDisplayForm },
            React.createElement("div", { className: "form-content pt-0" },
                React.createElement(Field, { name: "user.profile.description", component: WhTextarea, placeholder: "Commentaire", maxLength: 2000 })))));
};
export default aboutForm;
