import { handleActions } from 'redux-actions';
import { SET_MISSION_COUNT_RELOAD, SET_UPDATE_NOTIF_DASHBOARD, SET_UPDATE_NOTIF_HEADER, QUALIF_BUTTON_ENABLE } from './constant';
const defaultState = { notifHeaderReload: false, notifDashboardReload: false, missionCountReload: false, qualifButtonEnable: true };
const reloaderReducer = handleActions({
    [SET_UPDATE_NOTIF_DASHBOARD]: (state, action) => ({ ...state, notifDashboardReload: action.payload.reload }),
    [SET_UPDATE_NOTIF_HEADER]: (state, action) => ({ ...state, notifHeaderReload: action.payload.reload }),
    [SET_MISSION_COUNT_RELOAD]: (state, action) => ({ ...state, missionCountReload: action.payload.reload }),
    [QUALIF_BUTTON_ENABLE]: (state, action) => ({ ...state, qualifButtonEnable: action.payload.reload })
}, defaultState);
export default reloaderReducer;
