import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../Freelance/DashBoard";
import Account from "../Freelance/Account";
import MyDocuments from "../Freelance/MyDocuments";
import Footer from "../Freelance/Footer";
import NewFreelanceMission from "../Missions/Freelance/NewFreelanceMission";
const FreelanceRoute = () => {
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: "mainContainer" },
            React.createElement("div", { className: "freelance-container" },
                React.createElement(Switch, null,
                    React.createElement(Route, { path: "/freelance/dashboard", name: "ressource_manager_main", component: Dashboard }),
                    React.createElement(Route, { path: "/freelance/missions/:id?", name: "freelance_missions", component: NewFreelanceMission }),
                    React.createElement(Route, { path: "/freelance/account", name: "ressource_manager_main", component: Account }),
                    React.createElement(Route, { path: "/freelance/my-documents", name: "ressource_manager_main", component: MyDocuments })),
                React.createElement(Footer, null)))));
};
export default FreelanceRoute;
