import React, { useState } from "react";
import DatePicker from "react-datepicker";
export const DateTimePickerField = ({ onChange }) => {
    const [startDate, setStartDate] = useState(null);
    const handleChange = date => {
        setStartDate(date);
        onChange(date);
    };
    return (React.createElement("div", { className: "date-picker" },
        React.createElement(DatePicker, { timeFormat: "HH:mm", className: "form__form-group-datepicker", selected: startDate, onChange: handleChange, showTimeSelect: true, dateFormat: "dd/MM/yyyy", locale: "fr" })));
};
const renderDateTimePickerField = props => {
    const { input } = props;
    return React.createElement(DateTimePickerField, Object.assign({}, input));
};
export default renderDateTimePickerField;
