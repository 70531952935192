import React, { useState, Fragment, useEffect } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);
export const DatePickerField = (props) => {
    const [startDate, setStartDate] = useState(props.value ? new Date(props.value) : null);
    const handleChange = date => {
        setStartDate(date);
        props.onChange(date);
    };
    useEffect(() => {
        if (props.value && props.value.hasOwnProperty('value') && !props.value.value) {
            setStartDate(null);
            return;
        }
        if (props.value) {
            const newDate = new Date(props.value);
            if (props.componentName && (props.componentName === "freelance_edit" || props.componentName === 'UpdateDate'))
                setStartDate(newDate);
            if (startDate && newDate.getTime() === startDate.getTime())
                return;
            props.onChange(newDate);
        }
        else
            setStartDate(null);
    }, [props.value]);
    return (React.createElement(Fragment, null,
        React.createElement(DatePicker, { className: `form__form-group-datepicker ${props.className}`, selected: startDate, disabledKeyboardNavigation: true, minDate: props.minDate ? props.minDate : null, placeholderText: "JJ/MM/AAAAA", onChange: handleChange, dateFormat: "dd/MM/yyyy", locale: "fr", readOnly: props.readOnly })));
};
const renderDatePickerField = ({ input, className = '', disabled, componentName = null, minDate = null, value = null }) => {
    return React.createElement(DatePickerField, Object.assign({}, input, { className: className, readOnly: disabled, componentName: componentName, minDate: minDate, value: componentName && (componentName === 'freelance_edit' || componentName === 'UpdateDate') ? input.value : value }));
};
export default renderDatePickerField;
