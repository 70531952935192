import { assign } from "xstate";
import { candidatureValidation, freelanceClientSelection } from "../candidatureStateMachine";
const getEvent = (events) => {
    let event = {};
    const existingEvent = {
        CREATE: {
            target: "create",
            actions: assign({ sidebarOpen: (_context, _event) => true, sidebarTitle: "missions:missions.create" })
        },
        CHANGE_END_DATE: {
            target: "change_date",
            actions: assign({ sidebarOpen: (_context, _event) => true, sidebarTitle: "missions:missions.editdate" })
        },
        SHALLOW_DELETE: {
            target: "shallow_delete",
            actions: assign({ sidebarOpen: (_context, _event) => true, sidebarTitle: "missions:missions.delete" })
        },
        REFUSE_DELETE: "refuse_delete",
        EDIT: { target: "edit", actions: assign({ sidebarOpen: (_context, _event) => true, sidebarTitle: "missions:missions.edit" }) },
        TRUE_DELETE: "true_delete",
        DECLINE_UPDATE: "decline_update",
        SEND_RM: "send_rm",
        VALIDATE_MISSION: "validate_mission",
        END_MISSION: "end_mission",
    };
    events.map((ev) => {
        event[ev] = existingEvent[ev];
    });
    return event;
};
const getEventStates = (events) => {
    let event = {};
    const existingState = {
        change_date: {
            on: { CANCEL: { target: 'idle' }, SUBMIT: "submit_change_date" },
        },
        submit_change_date: {
            invoke: {
                src: (context, event) => {
                    return context.missionService.submitChangeDate(event.data);
                },
                onDone: { target: "idle" },
                onError: { target: "idle" }
            }
        },
        refuse_delete: {
            invoke: {
                src: (context) => {
                    return context.missionService.clientDeleteRefuseMission();
                },
                onDone: { target: "idle" },
                onError: { target: "idle" }
            }
        },
        shallow_delete: { on: { SUBMIT: "submit_delete", CANCEL: { target: "idle" } } },
        submit_delete: {
            invoke: {
                src: (context, event) => {
                    return context.missionService.shallowDeleteMission(event.data, "client");
                },
                onDone: { target: "idle" },
                onError: { target: "idle" }
            }
        },
        end_mission: {
            invoke: {
                src: (context, _event) => {
                    return context.missionService.putEndMission("client");
                },
                onDone: { target: "idle" },
                onError: { target: "idle" }
            }
        },
        create: {
            on: { CANCEL: { target: 'idle' }, SUBMIT: "submit_create" }
        },
        true_delete: {
            invoke: {
                src: (context, _event) => {
                    return context.missionService.deleteMission();
                },
                onDone: { target: 'idle' },
                onError: { target: 'idle' }
            }
        },
        decline_update: {
            invoke: {
                src: (context, _event) => {
                    return context.missionService.updateMissionsStatus(203);
                },
                onDone: { target: 'idle' },
                onError: { target: 'idle' }
            },
        },
        validate_mission: {
            invoke: {
                src: (context, _event) => {
                    return context.missionService.updateMissionsStatus(204);
                },
                onDone: { target: 'idle' },
                onError: { target: 'idle' }
            },
        },
        send_rm: {
            invoke: {
                src: (context, _event) => {
                    return context.missionService.updateMissionsStatus(202);
                },
                onDone: { target: 'idle' },
                onError: { target: 'idle' }
            },
        },
        edit: { on: { CANCEL: { target: 'idle' }, SUBMIT: "submit_edit" } },
        submit_edit: {
            invoke: {
                src: (context, event) => {
                    return context.missionService.editMission(event.data);
                },
                onDone: {
                    target: 'idle'
                },
                onError: {
                    target: 'edit'
                }
            },
        },
        submit_create: {
            invoke: {
                src: (context, event) => {
                    return context.missionService.onSubmitMission(event.data);
                },
                onDone: {
                    target: 'idle'
                },
                onError: {}
            },
        },
    };
    events.map((ev) => {
        event[ev] = existingState[ev];
    });
    return event;
};
const missionCreate = {
    id: "mission_create",
    initial: 'idle',
    states: {
        idle: {
            on: {
                ...getEvent(["CREATE"])
            },
            entry: ['closeSideBar'],
        },
        ...getEventStates(["create", "submit_create"])
    }
};
const template = (eventArray, eventStates) => ({
    initial: "idle",
    states: {
        idle: {
            on: {
                ...getEvent(eventArray)
            },
            entry: ['closeSideBar'],
        },
        ...getEventStates(eventStates)
    }
});
const clientMissionsStatusState = {
    id: "clientMissionsStatus",
    initial: "none",
    on: { RETURN: "status", CHANGE_CONTEXT: { actions: ["updateMission", "setMission", "raiseStatus"], STATUS: "status" } },
    states: {
        none: {
            on: { "2100": "draft", "2200": "wait_validation", "2300": "updated", "2400": "refuse_updated", "2500": "open", "2600": "wait_activation", "2700": "in_progress", "2800": "canceled", "2900": "finish" },
            entry: ['closeSideBar'],
        },
        draft: { ...template(["TRUE_DELETE", "EDIT", "SEND_RM"], ["true_delete", "edit", "submit_edit", "send_rm"]) },
        wait_validation: { ...template(["TRUE_DELETE", "EDIT"], ["true_delete", "edit", "submit_edit"]) },
        updated: { ...template(["TRUE_DELETE", "EDIT", "DECLINE_UPDATE", "VALIDATE_MISSION"], ["true_delete", "edit", "submit_edit", "decline_update", "validate_mission"]) },
        refuse_updated: { ...template(["REFUSE_DELETE"], ["refuse_delete", "submit_delete"]) },
        open: { ...template(["SHALLOW_DELETE", "CHANGE_END_DATE"], ["shallow_delete", "submit_delete", "change_date", "submit_change_date"]) },
        wait_activation: {},
        in_progress: { ...template(["SHALLOW_DELETE", "CHANGE_END_DATE", "END_MISSION"], ["shallow_delete", "change_date", "submit_change_date", "submit_delete", "end_mission"]) },
        canceled: {},
        finish: {},
        refuse: {}
    }
};
export const clientMissionsState = {
    id: "rmMissions",
    type: "parallel",
    states: {
        status: {
            ...clientMissionsStatusState
        },
        client_candidatures: {
            ...freelanceClientSelection
        },
        candidature_display: {
            ...candidatureValidation
        },
        mission_create: { ...missionCreate },
    }
};
