import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FFAccountLoginContainer } from "../Client/ClientEdit/Component/FFIdentifiantContainer";
import { Field, Form } from "react-final-form";
import { WhFieldTxt, WhValidator } from "../../../Components/WHForm/WhFields";
import { addAsterix } from "../../../helper/addAsterix";
import { useTranslation } from "react-i18next";
import { Alert, Button, Card, CardBody, Container } from "reactstrap";
import { WhButton } from "../../../Components/WHForm/Button";
import * as documentService from "../../../Services/document";
import { deleteRmNotif, editClientNotif } from "../../../Services/notif/notif";
import ApiService from "../../../Services/ApiService";
import { USERS } from "../../../Services/contants";
import Loading from "../../../Components/Loading";
import { setUserDefault } from "../../../redux/user/action";
import { logout } from "../../../Shared/session";
import { RenderImgFileUpload } from "../../../Components/WHForm/renderCustomFileField";
import { FORM_ERROR } from 'final-form';
import { WhConfirm } from "../../../Components/WHComponents";
import { formatFirstName, formatLastName } from "../../../helper/formatUserName";
import * as MaintenanceService from "../../../Services/MaintenanceService";
const ProfileForm = () => {
    const { t } = useTranslation(["freelance", "form"]);
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: "picture-form" },
            React.createElement(Field, { name: "photoProfile" }, props => (React.createElement(RenderImgFileUpload, Object.assign({}, props, { disabled: false, label: t("colLeft.changeMyPicture"), imgAsInput: true, roundedImage: true }))))),
        React.createElement("div", { className: "header-form-containt" },
            React.createElement("h1", { className: "size-title weight-bold mt-25" }, t("account.title")),
            React.createElement("p", { className: "size-missionBig weight-thin" }, t("account.subTitle")),
            React.createElement("div", { className: "header-form" },
                React.createElement(Field, { name: "lastName", parse: (value) => formatLastName(value), format: (value) => formatLastName(value), validate: WhValidator.required }, props => (React.createElement(WhFieldTxt, Object.assign({ name: "lastName" }, props, { label: addAsterix(t("freelance:account.lastname")), className: "label-input", type: "text", disabled: false })))),
                React.createElement(Field, { name: "firstName", parse: (value) => formatFirstName(value), format: (value) => formatFirstName(value), validate: WhValidator.required }, props => (React.createElement(WhFieldTxt, Object.assign({ name: "firstName" }, props, { label: addAsterix(t("freelance:account.firstname")), className: "label-input", type: "text", disabled: false })))),
                React.createElement(Field, { name: "phone", validate: WhValidator.phone }, props => (React.createElement(WhFieldTxt, Object.assign({ name: "phone" }, props, { label: addAsterix(t("freelance:account.phone")), className: "label-input", type: "text", disabled: false }))))))));
};
const RmAccount = () => {
    const user = useSelector((state) => state.user.data);
    const [loaded, setLoaded] = useState(true);
    const dispatch = useDispatch();
    const { t } = useTranslation('rmclient');
    const userLogout = async () => {
        await logout();
        window.location.href = `${process.env.REACT_APP_WWW_HOST}/fr/logout`;
    };
    const [deleteLoading, setDeleteLoading] = useState(false);
    const deleteRm = () => {
        const userService = new ApiService(USERS);
        WhConfirm({
            title: "Souhaitez-vous vraiment supprimer votre compte ?",
            ifConfirm: () => {
                setDeleteLoading(true);
                userService
                    .remove(user._id)
                    .then(() => {
                    setDeleteLoading(false);
                    deleteRmNotif.mine_success();
                    userLogout();
                })
                    .catch(_e => {
                    setDeleteLoading(false);
                    deleteRmNotif.mine_failed();
                });
            },
            ifNotConfirm: () => false,
            labelYes: "Enregistrer",
            labelNo: "Annuler"
        });
    };
    const onSubmitProfile = (submitData) => {
        setLoaded(false);
        let shouldDeconnect = false;
        const { email, newPassword } = submitData;
        if (email !== user.email || newPassword)
            shouldDeconnect = true;
        const userService = new ApiService(USERS);
        if (submitData.photoProfile && submitData.photoProfile.content && submitData.photoProfile.hasOwnProperty('name')) {
            let submitPhoto = {
                file: submitData.photoProfile.file
            };
            return documentService.postFile(submitPhoto).then((doc) => {
                const { photoProfile, ...userData } = submitData;
                let profileData = {
                    _id: user._id,
                    photo: doc['@id'],
                    ...userData
                };
                return userService.update(profileData).then(() => {
                    editClientNotif.success();
                    if (shouldDeconnect)
                        userLogout();
                    dispatch(setUserDefault());
                    setLoaded(true);
                    return;
                }).catch((e) => {
                    console.error(e);
                    setLoaded(true);
                    editClientNotif.failed();
                    return { [FORM_ERROR]: e._error };
                });
            }).catch((e) => {
                console.error(e);
                setLoaded(true);
                editClientNotif.failed();
            });
        }
        else {
            const { photoProfile, ...userData } = submitData;
            return userService.update(userData).then(() => {
                if (shouldDeconnect)
                    userLogout();
                dispatch(setUserDefault());
                setLoaded(true);
                editClientNotif.success();
            }).catch((e) => {
                editClientNotif.failed();
                setLoaded(true);
                return { [FORM_ERROR]: e._error };
            });
        }
    };
    const setMaintenance = () => {
        try {
            MaintenanceService
                .setMaintenance({ env: "connect", deploy: true });
        }
        catch (error) {
            console.log("error", error);
        }
    };
    return (React.createElement("div", { className: "freelance-account" },
        (!loaded || deleteLoading) && React.createElement(Loading, null),
        React.createElement(Form, { onSubmit: onSubmitProfile, keepDirtyOnReinitialize: true, initialValues: { _id: user._id, firstName: formatFirstName(user.firstName), email: user.email, lastName: formatLastName(user.lastName), phone: user.phone, photoProfile: { content: user.photo ? user.photo.content : undefined } }, render: ({ handleSubmit, submitError, values }) => {
                return React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement("div", { className: "header header-rm" },
                        React.createElement(Container, { className: "little" },
                            React.createElement(ProfileForm, null))),
                    React.createElement(Container, { className: "little main" },
                        React.createElement(Card, null,
                            React.createElement(CardBody, null,
                                React.createElement(FFAccountLoginContainer, { disabled: false, error: null, values: values }),
                                submitError && React.createElement(Alert, { color: "danger" },
                                    React.createElement("div", { dangerouslySetInnerHTML: { __html: submitError } }))),
                            React.createElement(WhButton, { type: "submit", color: "primary", className: "size-medium weight-bold" }, "Enregistrer")),
                        React.createElement("div", { className: "container pt-0" },
                            React.createElement("div", { className: "listFooter flex-justifyEnd" },
                                user && (React.createElement("div", null,
                                    React.createElement(Button, { className: "btn-delete", onClick: deleteRm }, t("clientedit.suppress")))),
                                user && (React.createElement("div", null,
                                    React.createElement(Button, { className: "btn-delete", onClick: setMaintenance }, "Mettre connect en maintenance")))))));
            } })));
};
export default RmAccount;
