import React from "react";
import { Container, Row, Col } from "reactstrap";
import Anchors from "../../../../Components/Anchors/list";
import { WhH1 } from "../../../../Components/WHComponents";
import { useTranslation } from "react-i18next";
export const Menu = () => {
    const { t } = useTranslation('freelance');
    return (React.createElement("div", { className: "menuContainer" },
        React.createElement(Container, { className: "large" },
            React.createElement(Row, null,
                React.createElement(Col, { md: 4 },
                    React.createElement(WhH1, { light: true }, "Mon profil")),
                React.createElement(Col, { md: 8 },
                    React.createElement(Anchors, { sections: [
                            {
                                title: t('anchor.about'),
                                anchorName: "about",
                            },
                            {
                                title: t('anchor.studies'),
                                anchorName: "studies",
                            },
                            {
                                title: t('anchor.skills'),
                                anchorName: "skill",
                            },
                            {
                                title: t('anchor.references'),
                                anchorName: "references",
                            },
                            {
                                title: t('anchor.portfolio'),
                                anchorName: "portfolio",
                            },
                        ], offsetHeight: -200 }))))));
};
export default Menu;
