import React from "react";
import { Container } from "reactstrap";
export const Footer = () => {
    return (React.createElement(Container, { className: "large" },
        React.createElement("div", { className: "footer" },
            React.createElement("div", { className: "left" },
                React.createElement("a", { href: `${process.env.REACT_APP_WWW_HOST}` + '/fr/contact/', target: "_blank" }, "FAQ"),
                React.createElement("a", { href: `${process.env.REACT_APP_WWW_HOST}` + '/fr/cgu/', target: "_blank" }, "Mentions L\u00E9gales"),
                React.createElement("a", { href: `${process.env.REACT_APP_WWW_HOST}` + '/fr/cgu/', target: "_blank" }, "CGU/CGV")),
            React.createElement("div", { className: "right" },
                React.createElement("a", { href: "mailto:contact@castor-network.com", target: "_blank" },
                    React.createElement("span", { className: "icon-email" })),
                React.createElement("span", { className: "sep" }),
                React.createElement("a", { className: "size-big weight-bold", target: "_blank", href: "mailto:contact@castor-network.com" }, "Nous \u00E9crire")))));
};
export default Footer;
