import React from 'react';
import Loading from "../Loading";
import { InfiniteLoader, List } from 'react-virtualized';
const InfiniteScrollNotif = ({ data, loadNextPage, hasNextPage, isNextPageLoading, component, width = 900, height = 1000, rowHeight = 100 }) => {
    const isRowLoaded = ({ index }) => !!data[index];
    const loadMoreRows = () => {
        if (!hasNextPage)
            return;
        return loadNextPage();
    };
    const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => {
        let content;
        if (data.length === 0)
            return React.createElement(Loading, { key: key });
        if (data.length <= index && !isRowLoaded({ index })) {
            content = React.createElement(Loading, { key: key });
        }
        else
            content = component({ index });
        return (React.createElement("div", { key: key, style: style }, content));
    };
    return (React.createElement(InfiniteLoader, { isRowLoaded: isRowLoaded, loadMoreRows: loadMoreRows, rowCount: hasNextPage ? data.length + 1 : data.length }, ({ onRowsRendered, registerChild }) => {
        return (React.createElement(List, { width: width, className: 'infinite-loader-list', height: height, onRowsRendered: onRowsRendered, ref: registerChild, rowHeight: rowHeight, rowCount: data.length, rowRenderer: rowRenderer }));
    }));
};
export default InfiniteScrollNotif;
