import React from "react";
import { Container, Row, Col } from "reactstrap";
import { WhH1, WhH2, WhButton, CounterList, } from "../../../Components/WHComponents";
import { useSelector } from "react-redux";
import { StaticContainer } from "../../../Components/Statistique/StaticBar";
import { useTranslation } from "react-i18next";
import Notifications from "../../RessourceManager/Notifications/Notifications";
export const Dashboard = ({ history }) => {
    const user = useSelector((state) => state.user.data);
    const society = useSelector((state) => state.society.data);
    const { t } = useTranslation("client");
    const openAskForPacksMail = () => {
        let subject = t("mailpack.subject");
        let mainBody = ``;
        let strWindowFeatures = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
        location.href = `mailto:contact@castor-network.com?subject=${subject}&body=${encodeURIComponent(mainBody)}`;
    };
    return (React.createElement("div", { className: "costumer-dashboard" },
        React.createElement("div", { className: "header" },
            React.createElement(Container, { className: "large main p-20" },
                React.createElement(WhH1, null, "Dashboard"))),
        React.createElement(Container, { className: "large main" },
            React.createElement(Notifications, { type: "clientDashboard" }),
            React.createElement(Row, { style: { justifyContent: 'center' } },
                React.createElement(Col, { md: 8 },
                    React.createElement("div", { className: 'counters-header' },
                        React.createElement("div", null,
                            React.createElement(WhH2, { light: true }, "Gestion des Compteurs"),
                            React.createElement("p", null, "Castor Network est l\u00E0 pour vous accompagner dans la s\u00E9lection de vos freelances. D\u00E9couvrez nos packs !")),
                        React.createElement(WhButton, { onClick: openAskForPacksMail }, "Commander des packs")),
                    React.createElement("div", { className: "counters", style: { marginBottom: "10px" } },
                        React.createElement("div", null,
                            React.createElement("div", null, "Missions restantes"),
                            React.createElement("strong", null, "Sans accompagnement"),
                            React.createElement(CounterList, { number: society.nopackNoHelp, cssType: "big" })),
                        React.createElement("div", null,
                            React.createElement("div", null, "Missions restantes"),
                            React.createElement("strong", null, "Avec accompagnement"),
                            React.createElement(CounterList, { number: society.nbPackHelp, cssType: "big" })))),
                React.createElement(Col, { md: 4 },
                    React.createElement(StaticContainer, { clientId: society.id, withTooltip: true }))))));
};
export default Dashboard;
