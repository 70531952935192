import React from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
export const SideBarOnlyChild = ({ showSidebar, close, children, }) => {
    const childrenWithProps = children
        ? React.Children.map(children, child => React.cloneElement(child, { close: () => close(false) }))
        : null;
    return (React.createElement("aside", { className: showSidebar ? "sidebar sidebar-open" : "sidebar" }, showSidebar && (React.createElement("div", { "data-testid": "sidebarisOpen", className: "sidebar-content" }, childrenWithProps))));
};
const Sidebar = ({ showSidebar, close, title, children }) => {
    const { t } = useTranslation("sidebar");
    return (React.createElement("aside", { className: showSidebar ? "sidebar sidebar-open" : "sidebar" }, showSidebar && (React.createElement("div", { "data-testid": "sidebarisOpen", className: "sidebar-content" },
        React.createElement("div", { className: "header" },
            title && (React.createElement("span", { className: "size-title weight-bold" }, t(title))),
            React.createElement(Button, { className: "closeBtn", "data-testid": "closeButton", onClick: () => close(false) })),
        children))));
};
export default Sidebar;
