import { useState, useEffect } from "react";
import GraphQl from "../Shared/graphql";
import { formatGraphQlData } from "../Shared/rewriteArray";
import RestService from "../Services/Rest/RestService";
import { usePages } from "./usePages";
import { formatArrayObjFromKeys } from "../Services/Rest/getOnlyKeys";
import { useCursor } from "./useCursor";
export const useStoredNoFiltersWithConfig = (defaultConfig, objKeys) => {
    const [config] = useState(defaultConfig);
    const [data, setData] = useState(null);
    const [isCurrentlyLoading, setIsCurrentlyLoading] = useState(false);
    const [hasLoadAtLeastOnce, setHasLoadAtLeastOnce] = useState(false);
    const [isNextPageLoading, setIsNextPageLoading] = useState(false);
    const [requestFilters, setRequestFilters] = useState([]);
    const [defaultFiltersOverride, setDefaultFiltersOverride] = useState(null);
    const [defaultOrder, setOrders] = useState(null);
    const { pages, initValues, resetPage, totalItems, setCurrentDataLength, hasNextPage } = usePages({ page: 1, pageSize: 30 });
    const { hasNextPage: cursorHasNextPage, nextCursor, initCursor, setCursor, totalItems: cTotalItems, resetCursor } = useCursor();
    useEffect(() => {
        if (config.baseConfig.type !== 'rest' || isCurrentlyLoading)
            return;
        pages.page === 1 ? loadData() : loadNextPage();
    }, [pages]);
    useEffect(() => {
        if (config.baseConfig.type !== 'graphql' || isCurrentlyLoading)
            return;
        nextCursor === null && loadData();
    }, [nextCursor]);
    useEffect(() => {
        reloadData();
    }, [requestFilters]);
    useEffect(() => {
        if (!defaultFiltersOverride)
            return;
        reloadData();
    }, [defaultFiltersOverride]);
    const updateOrders = (order) => { setOrders(order); };
    useEffect(() => {
        if (defaultOrder)
            reloadData();
    }, [defaultOrder]);
    const reloadData = () => {
        setData([]);
        if (config.baseConfig.type === 'rest')
            resetPage();
        else
            loadData();
    };
    const constructExistsFilter = (selectedFilters) => {
        const existFilters = selectedFilters.filter(sf => (sf.name === "exists"));
        if (existFilters.length === 0)
            return [];
        const newFilter = existFilters.reduce((value, acc) => ({ name: value.name, value: { ...value.value, ...acc.value } }));
        return newFilter;
    };
    const getQueryFilters = () => {
        const { filters } = config;
        const defaultFilters = defaultFiltersOverride ? defaultFiltersOverride : filters;
        const allFilters = [...defaultFilters, ...requestFilters];
        const existFilters = constructExistsFilter(allFilters);
        const filtersWithoutExist = allFilters.filter((f) => f.name !== "exists");
        return [...filtersWithoutExist, existFilters];
    };
    const loadData = async () => {
        if (isCurrentlyLoading || isNextPageLoading)
            return;
        const { baseConfig: { type, api, endPoint, resultKey }, filters, sorted } = config;
        const defaultFilters = defaultFiltersOverride ? defaultFiltersOverride : filters;
        try {
            setIsCurrentlyLoading(true);
            if (type === 'graphql') {
                let order = defaultOrder ? { order: defaultOrder.value, orderBy: { name: defaultOrder.name } } : {};
                const graphqlConfig = { ...endPoint, api: api, query: { ...endPoint.query, filters: getQueryFilters(), ...order } };
                const res = await GraphQl(graphqlConfig);
                initCursor(res.pageInfo.endCursor, res.pageInfo.hasNextPage, res.totalItem);
                setData(formatGraphQlData(res[resultKey]));
            }
            if (type === 'rest') {
                const service = new RestService(endPoint);
                const res = await service.search({ sorted: sorted, filtered: [...defaultFilters, ...requestFilters], ...pages });
                initValues(res['hydra:totalItems'], res[resultKey].length);
                resultKey ? setData(formatArrayObjFromKeys(objKeys, res[resultKey])) : setData(res);
            }
        }
        catch (queryError) {
            console.error(queryError);
        }
        finally {
            setIsCurrentlyLoading(false);
            if (!hasLoadAtLeastOnce)
                setHasLoadAtLeastOnce(true);
        }
    };
    const loadNextPage = async () => {
        if (isCurrentlyLoading)
            return;
        if ((!hasNextPage && !cursorHasNextPage) && isNextPageLoading)
            return;
        const { baseConfig: { type, api, endPoint, resultKey }, filters } = config;
        const defaultFilters = defaultFiltersOverride ? defaultFiltersOverride : filters;
        setIsNextPageLoading(true);
        if (type === 'rest') {
            const service = new RestService(endPoint);
            const res = await service.search({ filtered: [...defaultFilters, ...requestFilters], ...pages });
            const oldDatas = [...data];
            const newData = [...oldDatas, ...formatArrayObjFromKeys(objKeys, res[resultKey])];
            setData(newData);
            setCurrentDataLength(newData.length);
            setIsNextPageLoading(false);
        }
        else if (type === 'graphql') {
            let order = defaultOrder ? { order: defaultOrder.value, orderBy: { name: defaultOrder.name } } : {};
            const graphqlConfig = { ...endPoint, api: api, query: { ...endPoint.query, after: nextCursor, filters: getQueryFilters(), ...order } };
            const res = await GraphQl(graphqlConfig);
            const oldDatas = [...data];
            const newDatas = formatGraphQlData(res[resultKey]);
            resultKey ? setData([...oldDatas, ...newDatas]) : setData(res.datas);
            setCursor(res.pageInfo.endCursor, res.pageInfo.hasNextPage);
            setIsNextPageLoading(false);
        }
        return;
    };
    return {
        data,
        isCurrentlyLoading,
        reloadData,
        totalItems: config.baseConfig.type === 'rest' ? totalItems : cTotalItems,
        setDefaultFiltersOverride,
        setRequestFilters,
        updateOrders,
        getQueryFilters,
        hasNextPage: config.baseConfig.type === 'rest' ? hasNextPage : cursorHasNextPage,
        isNextPageLoading,
        loadNextPage,
        loadData,
        hasLoadAtLeastOnce
    };
};
