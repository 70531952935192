import React, { useEffect, Fragment } from "react";
import { Field } from "react-final-form";
import { Alert } from "reactstrap";
import DisplayForm from "../../WHForm/DisplayForm";
import ButtonBottomForm from "../../WHForm/ButtonBottomForm";
import { WhFieldTxt, WhFieldSelect } from "../../../Components/WHForm/WhFields";
import { useTranslation } from "react-i18next";
import Loading from "../../Loading";
import { WhValidator } from "../../WHForm/WhFields";
import GoogleMapPlaces from "../../../Components/GoogleMap/GoogleMap";
export const FFEditableInformationSociety = ({ sectors, loaded, reset = undefined, initialValues, mutators, countries = [] }) => {
    const { shouldDisplayForm, displayFormToggle } = DisplayForm();
    const { t } = useTranslation(["form", "common"]);
    useEffect(() => {
        if (loaded)
            displayFormToggle();
    }, [loaded]);
    return (React.createElement("div", null,
        React.createElement("div", { className: "headerFreeItem" },
            React.createElement("h2", { className: "thinTitle" }, t("form:society.header")),
            !shouldDisplayForm && (React.createElement("span", { className: "littleTextButton absoluteEditButton editButton", "data-testid": "updateButton", onClick: () => displayFormToggle() }, t("common:modification")))),
        React.createElement(ButtonBottomForm, { toggle: displayFormToggle, display: shouldDisplayForm, reset: reset },
            React.createElement(InformationSociety, { error: null, disabled: !shouldDisplayForm, sectors: sectors, countries: countries, initialValues: initialValues, loaded: loaded, mutators: mutators }))));
};
const InformationSociety = ({ title, error, disabled, sectors, loaded, initialValues = null, mutators, countries }) => {
    const { t } = useTranslation("form");
    const addAsterix = text => `${text}*`;
    return (React.createElement(Fragment, null,
        title && (React.createElement("div", { className: "headerFreeItem mb-25" },
            React.createElement("span", { className: "thinTitle " }, t(title)))),
        React.createElement("div", { className: "content" },
            React.createElement("div", { className: "headerFreeItem mb-20" }),
            error && React.createElement(Alert, { color: "danger" }, error),
            React.createElement("div", { className: "field-row" },
                loaded && React.createElement(Loading, { className: "load-zIndexMin" }),
                React.createElement(Field, { name: "name", validate: WhValidator.required }, props => (React.createElement(WhFieldTxt, Object.assign({ name: "name" }, props, { label: addAsterix(t("society.name")), className: "label-input", type: "text", disabled: disabled })))),
                React.createElement(Field, { name: "siret", validate: WhValidator.composeValidators(WhValidator.required, WhValidator.siret) }, props => (React.createElement(WhFieldTxt, Object.assign({ name: "siret" }, props, { label: addAsterix(t("society.siret")), type: "text", className: "label-input", disabled: disabled })))),
                React.createElement(Field, { name: "affiliate" }, props => (React.createElement(WhFieldTxt, Object.assign({ name: "affiliate" }, props, { label: t("society.affiliate"), type: "text", className: "label-input", disabled: disabled }))))),
            React.createElement("div", { className: "field-row castorForm-googlemap-container" },
                React.createElement(GoogleMapPlaces, { input: null, label: t("society.address"), disabled: disabled, defaultCity: initialValues && initialValues.address
                        ? `${initialValues.address.address}, ${initialValues.address.city}, ${initialValues.address.country
                            ? initialValues.address.country.label
                            : ""}`
                        : undefined, inputClassName: "castorForm-inputContainer", onUpdate: mutators.setPlaces, updateAllAddressForm: true, type: "society" },
                    React.createElement(Fragment, null))),
            React.createElement("div", { className: "field-row" },
                React.createElement(Field, { name: "address.address", validate: WhValidator.required }, props => (React.createElement(WhFieldTxt, Object.assign({ name: "address.address" }, props, { label: addAsterix(t("society.number")), type: "text", className: "label-input", disabled: disabled })))),
                React.createElement(Field, { name: "address.cpltAddress" }, props => (React.createElement(WhFieldTxt, Object.assign({ name: "address.cpltAddress" }, props, { label: t("society.cplt"), type: "text", className: "label-input", disabled: disabled }))))),
            React.createElement("div", { className: "field-row" },
                React.createElement(Field, { name: "address.zipCode", validate: WhValidator.required }, props => (React.createElement(WhFieldTxt, Object.assign({ name: "address.zipCode" }, props, { label: addAsterix(t("society.zipCode")), type: "text", className: "label-input", disabled: disabled })))),
                React.createElement(Field, { name: "address.city", validate: WhValidator.required }, props => (React.createElement(WhFieldTxt, Object.assign({ name: "address.city" }, props, { label: addAsterix(t("society.city")), type: "text", className: "label-input", disabled: disabled })))),
                React.createElement(Field, { name: "address.country", validate: WhValidator.required }, props => (React.createElement(WhFieldSelect, Object.assign({ options: countries, name: "address.country" }, props, { label: addAsterix(t("form:society.country")), type: "text", className: "label-input", disabled: disabled }))))),
            React.createElement("div", { className: "field-row" },
                React.createElement(Field, { name: "activitySector", validate: WhValidator.required }, props => (React.createElement(WhFieldSelect, Object.assign({ options: sectors, isSearchable: true, multiple: false, name: "activitySector" }, props, { label: addAsterix(t("society.activity")), type: "text", disabled: disabled }))))))));
};
export default InformationSociety;
