import { ROLES } from "../constants/roles";
export const rmFreelance = [
    {
        id: 1,
        state: "Nouveau",
        created_at: new Date(),
        name: "Albert Lecoléoptère",
        firstName: "Albert",
        lastName: "Lecoléoptère",
        title: "Ux-UI Designer",
        about: "Text de description",
        commentaires: [
            {
                message: "text note",
                created: new Date("2019-01-16"),
                user: {
                    role: ROLES.RM,
                    firstname: "PrénomManager",
                    lastname: "NomManeger",
                    takenCare: false,
                    phone: "06 00 00 00 00",
                    email: "manager@castor.fr",
                    userName: "Manager",
                    picture: "photomangaer.png",
                },
            },
            {
                message: "text note deux",
                created: new Date("2019-01-16"),
                user: {
                    role: ROLES.RM,
                    firstname: "PrénomManager",
                    lastname: "NomManeger",
                    takenCare: false,
                    phone: "06 00 00 00 00",
                    email: "manager@castor.fr",
                    userName: "Manager",
                    picture: "photomangaer.png",
                },
            },
        ],
        profile: {
            linkedin: "monlinkedin.com",
            profileDocuments: [
                {
                    document: {
                        url: "urldemondoc/kBis.pdf",
                    },
                    type: "kBis",
                },
                {
                    document: {
                        url: "urldemondoc/cv.pdf",
                    },
                    type: "cv",
                },
            ],
            refs: [
                {
                    id: 1,
                    societyName: "ANKAMA",
                    job: "graphiste",
                    contactName: "Elise ENUTROF",
                    contactEmail: "elise@ankama.fr",
                    contactPhone: "03 20 00 00 00",
                },
                {
                    id: 2,
                    societyName: "SOCIETY",
                    job: "graphiste",
                    contactName: "Elise JBUVTF",
                    contactEmail: "elise@society.fr",
                    contactPhone: "03 20 00 00 00",
                },
                {
                    id: 3,
                    societyName: "RRDRDYRD",
                    job: "graphiste",
                    contactName: "Elise JBUVTF",
                    contactEmail: "elise@society.fr",
                    contactPhone: "03 20 00 00 00",
                },
            ],
            portfolios: [
                {
                    id: 1,
                    url: "www.mcdonald.com",
                },
                {
                    id: 2,
                    url: "https;//airbus-app.fr",
                },
            ],
        },
    },
    {
        id: 2,
        state: "En attente",
        created_at: new Date(),
        name: "Ledauphin",
        firstname: "Bobby",
        title: "Developpeur",
        about: "Text de description",
        commentaires: null,
        profile: {
            linkedin: "monlinkedin.com",
            profileDocuments: [
                {
                    document: {
                        url: "urldemondoc/cv.pdf",
                    },
                    type: "cv",
                },
                {
                    document: {
                        url: "urldemondoc/kBis.pdf",
                    },
                    type: "kBis",
                },
            ],
            refs: null,
            portfolios: null,
        },
    },
    {
        id: 3,
        state: "Nouveau",
        created_at: new Date(),
        name: "Ledauphin",
        firstname: "Sarah",
        title: "Product Owner",
        about: "Text de description",
        commentaires: null,
        profile: {
            linkedin: "monlinkedin.com",
            profileDocuments: [
                {
                    document: {
                        url: "urldemondoc/cv.pdf",
                    },
                    type: "cv",
                },
                {
                    document: {
                        url: "urldemondoc/kBis.pdf",
                    },
                    type: "kBis",
                },
            ],
            refs: null,
            portfolios: null,
        },
    },
    {
        id: 4,
        state: "Nouveau",
        created_at: new Date(),
        name: "Ledauphin",
        firstname: "Corentin",
        title: "Ux-UI Designer",
        about: "Text de description",
        commentaires: null,
        profile: {
            linkedin: "monlinkedin.com",
            profileDocuments: [
                {
                    document: {
                        url: "urldemondoc/cv.pdf",
                    },
                    type: "cv",
                },
                {
                    document: {
                        url: "urldemondoc/kBis.pdf",
                    },
                    type: "kBis",
                },
            ],
            refs: null,
            portfolios: null,
        },
    },
    {
        id: 5,
        state: "En attente",
        created_at: new Date(),
        name: "Ledauphin",
        firstname: "Bobby",
        title: "Ux-UI Designer",
        about: "Text de description",
        commentaires: null,
        profile: {
            linkedin: "monlinkedin.com",
            profileDocuments: [
                {
                    document: {
                        url: "urldemondoc/cv.pdf",
                    },
                    type: "cv",
                },
                {
                    document: {
                        url: "urldemondoc/kBis.pdf",
                    },
                    type: "kBis",
                },
            ],
            refs: null,
            portfolios: null,
        },
    },
];
