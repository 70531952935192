import { assign } from "xstate";
export const freelanceClientSelection = {
    id: "freelanceSelection",
    initial: "idle",
    states: {
        idle: {
            entry: ['closeSideBar'],
            on: {
                OPEN: {
                    target: "open",
                    actions: assign({
                        sidebarOpen: (_context, _event) => true,
                        sidebarData: (_context, event) => ({ candidatures: event.data.candidaturesData.candidatures, acceptedIndex: event.data.candidaturesData.acceptedIndex }),
                        sidebarTitle: "missions:missions.select_freelance"
                    })
                }
            }
        },
        open: { on: { CANCEL: { target: "idle" }, SUBMIT: "submit_freelance" } },
        submit_freelance: {
            invoke: {
                src: (context, event) => {
                    return context.missionService.AClientAcceptAFreelance(event.data, context.sidebarData, "client");
                },
                onDone: {
                    target: 'idle',
                },
                onError: {
                    target: 'open'
                }
            },
        }
    }
};
export const candidatureValidation = {
    id: "candidatureMission",
    type: "parallel",
    states: {
        validation: {
            id: "candidatureValidation",
            initial: "none",
            states: {
                validation: { on: { "SEND_VALIDATION": "send_validate", "NONE": "none" } },
                send_validate: {
                    invoke: {
                        src: (context, _event) => {
                            return context.missionService.validateCandidature();
                        },
                        onDone: {
                            target: 'validation'
                        },
                        onError: {
                            target: 'validation'
                        }
                    }
                },
                none: { on: { "VALIDATION": "validation" } }
            },
        },
        candidature: {
            id: "candidatureDisplay",
            initial: "nodisplay",
            states: {
                nodisplay: {
                    on: { EDITABLE: "editable", HISTORIC: "historic" }
                },
                historic: {},
                editable: {
                    id: "candidatureEditable",
                    initial: "idle",
                    states: {
                        idle: {
                            on: {
                                OPEN: {
                                    target: "open",
                                    actions: assign({
                                        sidebarOpen: (_context, _event) => true,
                                        sidebarData: (_context, _event) => ({ candidature_id: _event.data.candidature_id }),
                                        sidebarTitle: "missions:missions.select_freelance"
                                    })
                                },
                            },
                            entry: ['closeSideBar']
                        },
                        open: { on: { CANCEL: { target: "idle" }, SUBMIT: "submit_candidature" } },
                        submit_candidature: {
                            invoke: {
                                src: (context, event) => {
                                    return context.missionService.acceptFreelance(event.data, context.sidebarData.candidature_id, "admin");
                                },
                                onDone: {
                                    target: 'idle'
                                },
                                onError: {
                                    target: 'open'
                                }
                            },
                        },
                    }
                },
            }
        }
    }
};
