import React from "react";
import { Field } from "redux-form";
import { useTranslation } from "react-i18next";
import { WhFieldSelect } from "../../../../Components/WHComponents";
import FormWrapper from "../../../../Components/Form/FormWrapper";
import { Button } from "reactstrap";
import { WhFieldCheckbox } from "../../../../Components/WHForm/WhFields/index";
export const HeaderForm = ({ onSubmit, initialvalues, statuses }) => {
    const { t } = useTranslation("freelance");
    return (React.createElement(FormWrapper, { onSubmit: onSubmit, form: "document_header_form", initialValues: initialvalues },
        React.createElement("div", { className: "header-form myDocumentHeaderForm" },
            React.createElement(Field, { label: t("myDocument.form.status.label"), name: "profile.status", component: WhFieldSelect, options: statuses }),
            React.createElement(Button, { className: "btn-whiteButton btn btn-secondary mt-15Mobile p-15Mobile", type: "submit" }, "Enregistrer"),
            React.createElement("div", { className: "transparentCheckboxContainer" },
                React.createElement(Field, { component: WhFieldCheckbox, label: "En cochant cette case, j'accepte d'être contacté(e) pour m'accompagner dans la création de mon statut juridique", name: `helpCreation`, type: "checkbox" })))));
};
export default HeaderForm;
