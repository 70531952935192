import React, { Fragment, useState } from 'react';
import * as profileService from '../../../Services/profile';
import { SubmissionError } from 'redux-form';
import PortfolioForm from './portfolioForm';
import shallowequal from 'shallowequal';
import { useDispatch, useSelector } from "react-redux";
import { updateNotif } from "../../../Services/notif/notif";
import { setQualifButtonEnable } from "../../../redux/reloader/action";
const FreelancePortfolio = ({ user, callback, disabled = false, portfolios, changePrecalState }) => {
    const dispatch = useDispatch();
    const [displayForm, setDisplayForm] = useState(false);
    const form = useSelector(state => state.form['portfolio_form']);
    const onSubmit = (data) => {
        dispatch(setQualifButtonEnable(false));
        let toSubmitData = { ...data };
        let profilId = { _id: user.profile._id };
        setDisplayForm(false);
        if (!shallowequal(form.initial, form.values) && changePrecalState) {
            changePrecalState();
        }
        toSubmitData = Object.assign(toSubmitData, profilId);
        toSubmitData.portfolios = toSubmitData.portfolios.filter(el => el !== undefined);
        profileService.update(toSubmitData).then(() => {
            if (callback)
                callback();
        }).catch((error) => {
            updateNotif.failed();
            throw new SubmissionError(error);
        });
        updateNotif.success();
    };
    return (React.createElement(Fragment, null,
        React.createElement(PortfolioForm, { onSubmit: onSubmit, form: "portfolio_form", disabled: disabled, initialValues: { portfolios: portfolios }, displayForm: displayForm, onUpdate: () => setDisplayForm(true) })));
};
export default FreelancePortfolio;
