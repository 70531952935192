import { combineReducers } from 'redux';
import userReducer from './user/userReducer';
import tbReducer from './table/tbReducer';
import societyReducer from './society/societyReducer';
import missionReducer from './mission/reducer';
import sectorReducer from '/wh/redux/sector/sectorReducer';
import styleReducer from '/wh/redux/style/styleReducer';
import paramsReducer from './params/paramsReducer';
import navParamsReducer from './nav/navPramsReducer';
import langReducer from './lang/reducer';
import { reducer as formReducer } from 'redux-form';
import countsReducer from "/wh/redux/count/countReducer";
import reloaderReducer from './reloader/reloaderReducer';
export default (history) => combineReducers({
    user: userReducer,
    reloader: reloaderReducer,
    society: societyReducer,
    counts: countsReducer,
    sectors: sectorReducer,
    table: tbReducer,
    lang: langReducer,
    form: formReducer,
    mission: missionReducer,
    style: styleReducer,
    params: paramsReducer,
    navNumbers: navParamsReducer,
});
