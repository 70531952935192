import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePermission } from "./usePermission";
import { Field, Form } from "react-final-form";
import { WhButton } from "../../../../Components/WHForm/Button";
const RadioComponent = props => {
    return React.createElement("input", Object.assign({ id: props.id, type: "radio" }, props.input));
};
const RadioField = ({ name, label, value, id }) => {
    return (React.createElement("div", { className: `castorRadio-container` },
        React.createElement(Field, { name: name, type: "radio", value: value, id: id, component: RadioComponent, className: `castorRadio-input` }),
        React.createElement("label", { htmlFor: id, className: `castorRadio-label` }, label)));
};
const PermissionClient = ({ title, category, permissions, parent = "" }) => {
    return (React.createElement(Fragment, null,
        React.createElement("h1", { className: `mt-50` }, title),
        permissions
            .filter(p => p.category === category)
            .map((fp, idx) => {
            return (React.createElement("div", { className: "castorRadio", key: idx },
                React.createElement("div", { className: `castorRadio-title` }, fp.name),
                React.createElement("div", { className: `castorRadio-checkboxes` },
                    React.createElement(RadioField, { key: `${category}_${idx}_1`, name: `${fp.id}`, id: `${parent}${category}-${idx}-radio-0`, label: "Autoriser", value: "1" }),
                    React.createElement(RadioField, { key: `${category}_${idx}_0`, name: `${fp.id}`, id: `${parent}${category}-${idx}-radio-1`, label: "Refuser", value: "0" }))));
        })));
};
export const PermissionsField = ({ permissions, onCheckAll, onUncheckAll, parent = "" }) => {
    const [authoriseCheck, setAuthoriseCheck] = useState(false);
    const [unAuthoriseCheck, setUnAuthoriseCheck] = useState(false);
    const onUpdateAuthorise = () => {
        const pcheck = authoriseCheck;
        setAuthoriseCheck(!authoriseCheck);
        setUnAuthoriseCheck(false);
        if (!pcheck)
            onCheckAll();
    };
    const onUpdateUnAuthorise = () => {
        const pcheck = unAuthoriseCheck;
        setUnAuthoriseCheck(!unAuthoriseCheck);
        setAuthoriseCheck(false);
        if (!pcheck)
            onUncheckAll();
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: "castorRadio noBorder" },
            React.createElement("div", { className: `castorRadio-checkboxes` },
                React.createElement("div", { className: `castorRadio-container` },
                    React.createElement("input", { type: "radio", name: "authorize", id: `${parent}authorize`, onChange: onUpdateAuthorise, checked: authoriseCheck }),
                    React.createElement("label", { htmlFor: `${parent}authorize`, className: `castorRadio-label` }, "Tout s\u00E9lectionner"))),
            React.createElement("div", { className: `castorRadio-container` },
                React.createElement("input", { type: "radio", name: "unauthorize", id: `${parent}unauthorize`, onChange: onUpdateUnAuthorise, checked: unAuthoriseCheck }),
                React.createElement("label", { htmlFor: `${parent}unauthorize`, className: `castorRadio-label` }, "Tout d\u00E9s\u00E9lectionner"))),
        React.createElement(PermissionClient, { title: "Clients", category: "client", parent: parent, permissions: permissions ? permissions : [] }),
        React.createElement(PermissionClient, { title: "Missions", category: "mission", parent: parent, permissions: permissions ? permissions : [] }),
        React.createElement(PermissionClient, { title: "Freelances", category: "freelance", parent: parent, permissions: permissions ? permissions : [] }),
        React.createElement(PermissionClient, { title: "Resources Manager", category: "rm", permissions: permissions ? permissions : [] })));
};
const PermissionsForm = ({ onSubmit, userPermissions }) => {
    const { t } = useTranslation("managers");
    const { data: allPermissions } = usePermission();
    const [initialValues, setInitialValues] = useState({});
    useEffect(() => {
        if (allPermissions.length > 0)
            getInitialValue();
    }, [allPermissions, userPermissions]);
    const getInitialValue = () => {
        let _initialValues = {};
        allPermissions.map(p => {
            const permission = userPermissions.find(up => up.permission.slug === p.slug);
            _initialValues = {
                ..._initialValues,
                [p.id]: permission ? (permission.value ? "1" : "0") : "0"
            };
        });
        setInitialValues(_initialValues);
    };
    const mutators = {
        checkEverything: (_args, state, utils) => {
            for (let [key] of Object.entries(state.fields)) {
                utils.changeValue(state, key, () => "1");
            }
        },
        unCheckEverything: (_args, state, utils) => {
            for (let [key] of Object.entries(state.fields)) {
                utils.changeValue(state, key, () => "0");
            }
        }
    };
    return (React.createElement(Fragment, null, allPermissions && allPermissions.length > 0 && (React.createElement(Form, { onSubmit: onSubmit, initialValues: initialValues, mutators: { ...mutators }, render: ({ handleSubmit, form: { mutators } }) => {
            return (React.createElement("form", { onSubmit: handleSubmit, className: `castorRadio-form` },
                React.createElement(PermissionsField, { permissions: allPermissions, onCheckAll: mutators.checkEverything, onUncheckAll: mutators.unCheckEverything }),
                React.createElement("div", { className: `btn-container flex-row dualBtn mt-10 mb-25 size-big` },
                    React.createElement(WhButton, { cancel: true }, t("btn-cancel")),
                    React.createElement(WhButton, { submit: true }, t("btn-save")))));
        } }))));
};
export default PermissionsForm;
