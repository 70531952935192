import React from "react";
export const DisplayDateFilter = ({ selectedFilters, headerFilters, onRemove, onRemoveMultiple }) => {
    const removeFilters = (e, currentFilter) => {
        const currentHeaderFilterConfig = headerFilters.find((hs) => hs.name === currentFilter.name);
        if (currentHeaderFilterConfig && currentHeaderFilterConfig.hasOwnProperty('fusionWith')) {
            const nextFilter = selectedFilters.find((s) => s.name === currentHeaderFilterConfig.fusionWith.name);
            if (nextFilter) {
                onRemoveMultiple([{ name: currentFilter.name, value: currentFilter.value }, { name: nextFilter.name, value: nextFilter.value }]);
                return;
            }
            onRemove(currentFilter.name, currentFilter.value);
            return;
        }
        onRemove(currentFilter.name, currentFilter.value);
        return;
    };
    const formatLabel = (currentFilter) => {
        const currentHeaderFilterConfig = headerFilters.find((hs) => hs.name === currentFilter.name);
        if (currentHeaderFilterConfig && currentHeaderFilterConfig.hasOwnProperty('fusionWith')) {
            const nextFilter = selectedFilters.find((s) => s.name === currentHeaderFilterConfig.fusionWith.name);
            if (nextFilter) {
                return currentHeaderFilterConfig.fusionWith.formatLabel(currentFilter.value, nextFilter.value);
            }
            return currentHeaderFilterConfig.formatCurrent(currentFilter.value);
        }
        return currentHeaderFilterConfig.formatCurrent(currentFilter.value);
    };
    return (React.createElement("div", { className: "selected_filters_wrapper" },
        React.createElement("div", { className: "selected_filter_container flex-row" }, selectedFilters.map((s, idx) => {
            return (React.createElement("div", { key: `selected_${idx}`, "data-testid": "selected-filter", className: "selectedFilter" },
                React.createElement("i", { onClick: e => removeFilters(e, s), className: "fa fa-times-circle" }),
                React.createElement("span", null, formatLabel(s)),
                React.createElement("i", { className: `fa icon-calendrier ${s.name === 'from' ? "dateBadge-orangeColor" : "dateBadge-greenColor"}` })));
        }))));
};
