import { useState } from "react";
const DisplayForm = () => {
    const [shouldDisplayForm, setDisplayForm] = useState(false);
    function displayFormToggle() {
        setDisplayForm(!shouldDisplayForm);
    }
    return {
        shouldDisplayForm,
        displayFormToggle,
        setDisplayForm
    };
};
export default DisplayForm;
