import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "react-final-form";
import { ButtonDropdown, DropdownToggle, DropdownMenu, Button } from 'reactstrap';
import Range from 'rc-slider/lib/Range';
export const FFRangeContainer = props => {
    const onChange = (value) => {
        props.updateRange(value);
        props.input.onChange(value);
    };
    return (React.createElement(Range, Object.assign({}, props, { min: props.rangeConfig.min, max: props.rangeConfig.max, value: props.rangeConfig.value, onChange: onChange })));
};
const FFRangeFilter = ({ filter, values, submit }) => {
    const [dropdownOpen, setOpen] = useState(false);
    const [range, setRange] = useState({
        min: filter.minRange,
        max: filter.maxRange,
        value: [filter.minRange, filter.maxRange],
        defaultValue: [filter.minRange, filter.maxRange]
    });
    const toggle = () => setOpen(!dropdownOpen);
    const updateRange = (value) => {
        setRange({
            ...range,
            value: value,
            defaultValue: [filter.minRange, filter.maxRange]
        });
    };
    const { t } = useTranslation('filter');
    const onSubmit = (data) => {
        submit(data);
        toggle();
    };
    return (React.createElement(Fragment, null,
        React.createElement(ButtonDropdown, { isOpen: dropdownOpen, toggle: toggle, className: "rangeDropdownFilter FFrangeDropdownFilter" },
            React.createElement(DropdownToggle, { caret: true }, t(filter.label)),
            React.createElement(DropdownMenu, null,
                React.createElement(Field, { name: name, label: t(filter.label) }, props => {
                    return (React.createElement(FFRangeContainer, Object.assign({}, props, { rangeConfig: range, updateRange: updateRange })));
                }),
                React.createElement("div", { className: "rangeValues" },
                    React.createElement("span", null, `${range.value[0]} ${filter.unity}`),
                    React.createElement("span", null, `${range.value[1]} ${filter.unity}`)),
                React.createElement("div", { className: "rangeButtons" },
                    React.createElement(Button, { className: "btn blueButton", onClick: toggle }, "Annuler"),
                    React.createElement(Button, { className: "btn orangeButton", onClick: onSubmit }, "Valider"))))));
};
export default FFRangeFilter;
