import React from "react";
import { Container, Row, Col, CardBody } from "reactstrap";
import { Field, change } from "redux-form";
import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import { WhH2, WhButton, WhFieldTxt, WhFieldSelect, WhBlock, WhValidator } from "../../../../Components/WHComponents";
import FormWrapper from "../../../../Components/Form/FormWrapper";
import { RenderImgFileUpload } from "../../../../Components/WHForm/renderCustomFileField";
import { WhFieldCheckbox } from "../../../../Components/WHForm/WhFields";
import { useDispatch, useSelector } from "react-redux";
import { addAsterix } from "../../../../helper/addAsterix";
import { formatFirstName, formatLastName } from "../../../../helper/formatUserName";
import GoogleMapPlaces from "../../../../Components/GoogleMap/GoogleMap";
export let FormAccount = ({ deleteMyAccount, onSubmit, initialvalues, countries }) => {
    const { t } = useTranslation("freelance");
    const dispatch = useDispatch();
    const nationalities = useSelector((state) => state.params.nationalities);
    const form = useSelector((state) => state.form["freelanceAccount"]);
    const onUpdateGoogleMap = (cityName, lat, long, zipcode) => {
        dispatch(change("freelanceAccount", "profile.address.city", cityName));
        dispatch(change("freelanceAccount", "googleMapCity", cityName));
        dispatch(change("freelanceAccount", "profile.address.lat", lat));
        dispatch(change("freelanceAccount", "profile.address.lng", long));
        dispatch(change("freelanceAccount", "profile.address.zipCode", zipcode));
    };
    return (React.createElement(FormWrapper, { onSubmit: onSubmit, form: "freelanceAccount", initialValues: initialvalues },
        React.createElement("div", { className: "header" },
            React.createElement(Container, { className: "little" },
                React.createElement("div", { className: "picture-form" },
                    React.createElement(Field, { name: "photoProfile", imgAsInput: true, position: "bottom", component: RenderImgFileUpload, roundedImage: true, label: t("colLeft.changeMyPicture") })),
                React.createElement("div", { className: "header-form-containt" },
                    React.createElement("h1", { className: "size-title weight-bold mt-25" }, t("account.title")),
                    React.createElement("p", { className: "size-missionBig weight-thin" }, t("account.subTitle")),
                    React.createElement("div", { className: "header-form" },
                        React.createElement(Field, { label: addAsterix(t("account.civility")), name: "civility", validate: WhValidator.required, component: WhFieldSelect, containerSize: "none", options: [
                                { label: "Mr", value: 0 },
                                { label: "Mme", value: 1 }
                            ] }),
                        React.createElement(Field, { label: addAsterix(t("account.lastname")), validate: WhValidator.required, parse: value => formatLastName(value), format: value => formatLastName(value), name: "lastName", type: "text", component: WhFieldTxt }),
                        React.createElement(Field, { label: addAsterix(t("account.firstname")), validate: WhValidator.required, parse: value => formatFirstName(value), format: value => formatFirstName(value), name: "firstName", type: "text", component: WhFieldTxt }))))),
        React.createElement(Container, { className: "little main" },
            React.createElement(Card, null,
                React.createElement(CardBody, null,
                    React.createElement(WhH2, { light: true }, t("account.personnalInformation")),
                    React.createElement(Row, { className: "mt-30" },
                        React.createElement(Col, { md: 4 },
                            React.createElement(Field, { label: addAsterix(`${t("account.nationality")}`), name: "nationality", validate: WhValidator.required, component: WhFieldSelect, format: value => nationalities.find(n => n.value === value), parse: value => value.value, "data-testid": "nationality", className: "label-input", options: nationalities })),
                        React.createElement(Col, { md: 4 },
                            React.createElement(Field, { label: addAsterix(t("account.birthday")), validate: WhValidator.required, name: "birthdate", dateValue: form ? form.birthdate : null, componentName: "UpdateDate", component: WhFieldTxt, type: "date" }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { md: 3 },
                            React.createElement(Field, { label: addAsterix(t("account.zipCode")), validate: WhValidator.required, name: "profile.address.zipCode", component: WhFieldTxt, type: "text" })),
                        React.createElement(Col, { md: 4 },
                            React.createElement(Field, { label: addAsterix(t("account.city")), validate: WhValidator.required, name: "profile.address.city", component: GoogleMapPlaces, inputClassName: "castorForm-inputContainer big googlemap-input", defaultCity: form ? form.values.profile.address.city : null, onUpdate: onUpdateGoogleMap, type: "mission" })),
                        React.createElement(Col, { md: 5 },
                            React.createElement(Field, { label: addAsterix(t("account.country")), format: value => {
                                    return countries.find(n => n.value === value);
                                }, validate: WhValidator.required, name: "profile.address.country", component: WhFieldSelect, containerSize: "none", options: countries }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { md: 3 },
                            React.createElement(Field, { label: addAsterix(t("account.phone")), validate: [WhValidator.required, WhValidator.phone], name: "phone", component: WhFieldTxt, type: "text" })),
                        React.createElement(Col, { md: 9 },
                            React.createElement(Field, { component: WhFieldCheckbox, label: t("account.newsletter"), name: "newsletter", type: "checkbox", containerSize: "mid" }))),
                    React.createElement("hr", null),
                    React.createElement(WhH2, { light: true }, t("account.myIdentity")),
                    React.createElement(Row, { className: "mt-30" },
                        React.createElement(Col, { md: 6 },
                            React.createElement(Field, { label: addAsterix(t("account.email")), validate: WhValidator.required, name: "email", component: WhFieldTxt, type: "text" }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { md: 6 },
                            React.createElement(Field, { label: addAsterix(t("account.newPassword")), name: "newPassword", component: WhFieldTxt, type: "password" })),
                        React.createElement(Col, { md: 12 },
                            React.createElement("p", { className: "mb-0 mb-0Mobile mt-5 mt-5Mobile ml-5 ml-5Mobile size-tiny weight-light" }, t("claccount.validPassword")))),
                    React.createElement(Row, null,
                        React.createElement(Col, { md: 6 },
                            React.createElement(Field, { label: addAsterix(t("account.confirmPassword")), name: "newPasswordConfirm", component: WhFieldTxt, type: "password" })))),
                React.createElement(WhButton, { type: "submit", color: "primary", className: "size-medium weight-bold" }, "Enregistrer")),
            React.createElement(WhBlock, { pullRight: true, row: true },
                React.createElement(WhButton, { outline: true, color: "danger", onClick: () => deleteMyAccount() }, t("account.deleteAccount"))))));
};
export default FormAccount;
