import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { Field, Form } from "react-final-form";
import { PermissionsField } from "./View/PermissionsForm";
import { usePermission } from "./View/usePermission";
import { WhFieldSelect, WhFieldTxt, WhValidator } from "../../../Components/WHForm/WhFields";
import { useTranslation } from "react-i18next";
import Loading from "../../../Components/Loading";
const FFCreateManager = ({ onSubmit, close, isLoading }) => {
    const { data: allPermissions } = usePermission();
    const [initialValues, setInitialValues] = useState({});
    useEffect(() => {
        if (allPermissions.length > 0)
            getInitialValue();
    }, [allPermissions]);
    const mutators = {
        checkEverything: (_args, state, utils) => {
            for (let [key] of Object.entries(state.fields)) {
                if (key !== "civility" &&
                    key !== "lastName" &&
                    key !== "firstName" &&
                    key !== "email" &&
                    key !== "phone")
                    utils.changeValue(state, key, () => "1");
            }
        },
        unCheckEverything: (_args, state, utils) => {
            for (let [key] of Object.entries(state.fields)) {
                if (key !== "civility" &&
                    key !== "lastName" &&
                    key !== "firstName" &&
                    key !== "email" &&
                    key !== "phone")
                    utils.changeValue(state, key, () => "0");
            }
        }
    };
    const { t } = useTranslation("form");
    const addAsterix = text => `${text}*`;
    const getInitialValue = () => {
        let _initialValues = {
            type: "RM",
            roles: ["ROLE_ADMIN"],
        };
        allPermissions.map(p => {
            _initialValues = { ..._initialValues, [p.id]: "0" };
        });
        setInitialValues(_initialValues);
    };
    return (React.createElement(Container, { className: "rm-client-create-form" },
        React.createElement(Form, { onSubmit: onSubmit, mutators: { ...mutators }, initialValues: initialValues, render: ({ handleSubmit, form: { mutators } }) => {
                return (React.createElement("form", { onSubmit: handleSubmit },
                    isLoading && React.createElement(Loading, null),
                    React.createElement("div", null,
                        React.createElement("div", { className: "content" },
                            React.createElement("p", { className: `size-missionBig weight-medium` }, t("contact.rminfos")),
                            React.createElement("div", { className: "field-row mb-5" },
                                React.createElement(Field, { name: name ? `${name}.civility` : "civility", validate: WhValidator.required }, props => (React.createElement(WhFieldSelect, Object.assign({ options: [
                                        { label: "Mr", value: 0 },
                                        { label: "Mme", value: 1 }
                                    ], name: name ? `${name}.civility` : "civility" }, props, { label: addAsterix(t("form:contact.civility")), type: "text", className: "label-input", disabled: false })))),
                                React.createElement(Field, { name: name ? `${name}.lastName` : "lastName", validate: WhValidator.required }, props => (React.createElement(WhFieldTxt, Object.assign({ name: name ? `${name}.lastName` : "lastName" }, props, { label: addAsterix(t("form:contact.lastname")), type: "text", className: "label-input mb-5", disabled: false }))))),
                            React.createElement("div", { className: "field-row mb-5" },
                                React.createElement(Field, { name: name ? `${name}.firstName` : "firstName", validate: WhValidator.required }, props => (React.createElement(WhFieldTxt, Object.assign({ name: name ? `${name}.firstName` : "firstName" }, props, { label: addAsterix(t("form:contact.firstname")), type: "text", className: "label-input", disabled: false }))))),
                            React.createElement("div", { className: "field-row mb-5" },
                                React.createElement(Field, { name: name ? `${name}.email` : "email", validate: WhValidator.composeValidators(WhValidator.required, WhValidator.email) }, props => (React.createElement(WhFieldTxt, Object.assign({ name: name ? `${name}.email` : "email" }, props, { label: addAsterix(t("form:contact.email")), type: "text", className: "label-input mb-5", containerSize: "big", disabled: false })))),
                                React.createElement(Field, { name: name ? `${name}.phone` : "phone", validate: WhValidator.composeValidators(WhValidator.required, WhValidator.phone) }, props => (React.createElement(WhFieldTxt, Object.assign({ name: name ? `${name}.phone` : "phone" }, props, { label: addAsterix(t("form:contact.phone")), type: "text", className: "label-input", containerSize: "small", disabled: false }))))),
                            React.createElement(PermissionsField, { permissions: allPermissions, onCheckAll: mutators.checkEverything, parent: "create", onUncheckAll: mutators.unCheckEverything }),
                            React.createElement("div", { className: "btn-container flex-row" },
                                React.createElement("button", { onClick: () => close(false) }, "Annuler"),
                                React.createElement("button", { type: "submit" }, "Envoyer la demande d'inscription"))))));
            } })));
};
export default FFCreateManager;
