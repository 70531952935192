import { FILTER_ASYNC_SELECT, FILTER_TYPE_TEXT } from "../../../../../Components/Filters/constants";
import moment from "moment";
const headerFilters = [
    {
        name: "society_name",
        label: 'search.client',
        className: 'label-input filter filter-with-marin-right-small',
        filterType: FILTER_ASYNC_SELECT,
        field: 'text',
        customComponentParameters: {
            customComponentName: 'default',
            isSearchable: true,
            isMulti: false,
        },
        formatFilterForApi: (value) => { return ({ id: "society", value: value }); },
        formatCurrent: (obj) => { return obj.value.label; },
        asyncQueryParameters: {
            query: `${process.env.REACT_APP_IDENTITY_API_URL}/api/societies`,
            formatQueryResult: (obj) => obj,
            params: {
                filter_name: 'name',
                defaultFilters: [{ id: 'exists[mainContact.deletedAt]', value: false }],
                pageSize: 30,
                page: 1
            },
            formatResult: (obj) => obj["hydra:member"].map((o) => ({
                label: o.name,
                displayLabel: `${o.name} (${o.mainContact.lastName} ${o.mainContact.firstName})`,
                value: o['@id'],
                filterName: 'society_name'
            }))
        }
    },
    {
        name: "society_rm_firstName",
        label: 'rm',
        className: 'label-input filter filter-with-marin-right-small',
        filterType: FILTER_ASYNC_SELECT,
        field: 'text',
        customComponentParameters: {
            isSearchable: true,
            isMulti: false,
            customComponentName: 'default',
        },
        formatFilterForApi: (value) => { return ({ id: "society.rm.firstName", value: value }); },
        formatCurrent: (obj) => { return obj.value.label; },
        asyncQueryParameters: {
            query: `${process.env.REACT_APP_IDENTITY_API_URL}/api/users`,
            params: {
                filter_name: 'firstName',
                defaultFilters: [{ id: 'exists[deletedAt]', value: false }],
                pageSize: 30,
                page: 1
            },
            formatResult: (obj) => obj["hydra:member"].map((o) => ({
                label: o.name,
                value: o['@id'],
                filterName: 'society_rm_firstName'
            }))
        }
    }
];
const filters = [
    {
        name: "society_activitySector_name_list",
        formatFilterForApi: (values) => { return ({ id: "society.activitySector.name[]", value: values }); },
        label: 'activity_sector',
        className: 'label-input filter filter-with-marin-right-small',
        filterType: FILTER_ASYNC_SELECT,
        customComponentParameters: {
            isSearchable: true,
            isMulti: true,
            customComponentName: 'activity_sector',
        },
        formatCurrent: (obj) => obj,
        asyncQueryParameters: {
            query: `${process.env.REACT_APP_IDENTITY_API_URL}/api/activity_sectors`,
            params: {
                filter_name: 'name',
                defaultFilters: [],
                pageSize: 30,
                page: 1
            },
            formatResult: (obj) => obj["hydra:member"].map((o) => ({
                label: o.name,
                value: o.name,
                filterName: 'society_activitySector_list'
            }))
        }
    },
    {
        name: 'city_list',
        label: 'Ville',
        filterType: FILTER_ASYNC_SELECT,
        customComponentParameters: {
            isSearchable: true,
            isMulti: true,
            customComponentName: 'location'
        },
        formatFilterForApi: (values) => { return ({ id: "city[]", value: values }); },
        formatCurrent: (obj) => { return obj; },
        asyncQueryParameters: {
            query: `${process.env.REACT_APP_IDENTITY_API_URL}/api/missions`,
            params: {
                filter_name: 'city',
                defaultFilters: [],
                pageSize: 30,
                page: 1
            },
            formatResult: (obj) => {
                return obj["hydra:member"].map((o) => ({
                    label: o.city,
                    value: o.city,
                    filterName: 'city_list'
                }));
            }
        }
    },
    {
        name: 'startDate',
        label: 'search.date',
        filterType: FILTER_TYPE_TEXT,
        type: 'date',
        formatFilterForApi: (value) => { return ({ id: `startDate[before]`, value: moment(value).format('YYYY-MM-DDTHH:mm:ss.SSSZ') }); },
        formatCurrent: (obj) => { return moment(obj.value).format('DD-MM-YYYY'); },
    }
];
export default { headerFilters, filters };
