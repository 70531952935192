import React from "react";
import NotificationSystem from "rc-notification";
import { useTranslation } from "react-i18next";
export const FullWideNotification = ({ color = "", message }) => {
    const { t } = useTranslation('notif');
    return (React.createElement("div", { className: `notification notification--full-wide notification--${color}` },
        React.createElement("p", { className: "notification__message" }, t(message))));
};
export default attr => {
    const defaultValue = {
        duration: 5,
        message: "",
        title: "",
        closable: true,
        color: "info",
    };
    const data = { ...defaultValue, ...attr };
    NotificationSystem.newInstance({}, notification => {
        notification.notice({
            content: (React.createElement(FullWideNotification, { color: attr.color, message: attr.message })),
            duration: data.duration,
            closable: data.closable,
        });
    });
};
