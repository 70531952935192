import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ROLES } from "../../../constants/roles";
import { ImageLogo, ImagePlaceholder } from "../../../Components/Image/Image";
import { addHTTPS } from "../../../Shared/utils/utils";
const MissionHeader = ({ logo, title, affiliate, jobTitle, clientDescription, status, statusPosition = null, sideBarDescription = false, indication = null, onClick, mainContact, showIcon = false, showCandidatureIcon = false, candidatures = [], userRole = "ROLE_ADMIN", }) => {
    const connectedUser = useSelector((state) => state.user);
    const { t } = useTranslation("missions");
    const getColor = (indication) => {
        return indication.color;
        if (indication && indication.label === "Attente activation FL")
            return "green";
        if (indication && indication.label === "Attente validation dossier")
            return "green";
        if (indication && indication.label === "FL proposés")
            return "green";
        if (indication && indication.label === "Pris en charge")
            return "green";
        if (indication && indication.label === "FL refusé")
            return "red";
        if (indication && indication.label === "Refusé par un FL")
            return "red";
        return;
    };
    return (React.createElement("div", { className: "header-container mb-15" },
        !statusPosition && status && (React.createElement(Fragment, null,
            React.createElement("div", { className: "badgesContainer" },
                React.createElement("div", { className: `status-badge status-badge-${status.color}` },
                    React.createElement("span", { "data-testid": "missionHeaderStatus", className: "weight-medium" }, status.label)),
                indication && status && status.value && (status.value !== 2800 && status.value !== 1400) && (React.createElement(Fragment, null,
                    React.createElement("div", { className: `indication-badge ${getColor(indication)}` },
                        React.createElement("i", { className: "icon-attente size-small mr-5" }),
                        React.createElement("span", null, indication.label))))))),
        showIcon && (React.createElement(Fragment, null, logo && logo.content ? (React.createElement("div", { className: "image" },
            React.createElement(ImageLogo, { img: addHTTPS(logo.content) }))) : (React.createElement("div", { className: "image image-empty" },
            React.createElement(ImagePlaceholder, { size: "big" }))))),
        React.createElement("div", { className: "text mt-15 mt-15Mobile w-50 w-100Mobile" },
            React.createElement("div", { className: "container-flex container-flex-align-center" },
                React.createElement("span", { className: `${userRole !== "ROLE_ADMIN"
                        ? "weight-bold size-subtitle missionTitle"
                        : "weight-bold size-missionBig missionTitle"}` }, title),
                mainContact &&
                    [ROLES.RM].some((r) => connectedUser.data.roles.indexOf(r) >= 0) && (React.createElement("span", { className: "weight-light size-medium missionTitle" },
                    " ",
                    "\u00A0",
                    `- ${affiliate ? ` ${affiliate}` : ''} (${mainContact.lastName} - ${mainContact.firstName})`))),
            React.createElement("span", { className: `size-missionBig text-normalcolor weight-light jobTitle` }, jobTitle),
            clientDescription && (React.createElement("span", { className: "weight-light size-small mt-5 mt-5Mobile" }, clientDescription))),
        sideBarDescription && (React.createElement("div", { className: "text container-flex container-flex-end" },
            React.createElement("span", { onClick: onClick, className: "cursor weight-bold size-small" }, t("missions.description")))),
        statusPosition === "end" && status && (React.createElement(Fragment, null,
            React.createElement("div", { className: "badgesContainer" },
                React.createElement("div", { className: `status-badge status-badge-${status.color}` },
                    React.createElement("span", { "data-testid": "missionHeaderStatus", className: "weight-medium" }, status.label)),
                indication && (React.createElement("div", { className: `indication-badge ${getColor(indication)}` },
                    React.createElement("i", { className: "icon-attente size-small mr-5" }),
                    React.createElement("span", null, indication.label))),
                showCandidatureIcon && candidatures.length > 0 && (React.createElement("i", { className: "icon-communuaute size-bigSubtitle" })))))));
};
export default MissionHeader;
