import React from 'react';
import FormWrapper from "../../../Components/Form/FormWrapper";
import { Field, change } from 'redux-form';
import { WhFieldSelect, WhValidator } from "../../../Components/WHForm/WhFields/index";
import DateRangeInputComponent from "../../../Components/RangeDatePicker/RangeDatePicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { durationTable } from "../../../Shared/constants";
const ChangeDateClient = ({ onSubmit, close, initialValues }) => {
    const { t } = useTranslation('form');
    const dispatch = useDispatch();
    const endDate = useSelector((state) => state.form ? (state.form.changedate ? state.form.changedate.values.endDate : null) : null);
    const startDate = useSelector((state) => state.form ? (state.form.changedate ? state.form.changedate.values.startDate : null) : null);
    const duration = useSelector((state) => state.form ? (state.form.changedate ? state.form.changedate.values.duration : null) : null);
    const onDateUpdate = (data) => {
        dispatch(change('changedate', 'startDate', moment(data.startDate).format('YYYY-MM-DD')));
        dispatch(change('changedate', 'endDate', moment(data.endDate).format('YYYY-MM-DD')));
    };
    return (React.createElement("div", { className: "rm-mission-edit-form sidebarBody delete-mission-client" },
        React.createElement(FormWrapper, { onSubmit: onSubmit, form: "changedate", initialValues: initialValues },
            React.createElement("p", { className: "size-big weight-light mt-5 mb-20" }, t('changedate.title')),
            React.createElement("div", { className: 'form-content' },
                React.createElement("div", null,
                    React.createElement(Field, { name: "endDate", values: { endDate: endDate, startDate: startDate, duration: duration }, component: DateRangeInputComponent, onUpdate: onDateUpdate, validate: [WhValidator.required] })),
                React.createElement(Field, { name: "duration", component: WhFieldSelect, validate: [WhValidator.required], options: durationTable(), required: true, label: `${t("createmission.duration")} *`, containerSize: "big changeDate-select" })),
            React.createElement("div", { className: 'btn-container flex-row' },
                React.createElement("button", { onClick: () => close(false) }, t('changedate.cancel')),
                React.createElement("button", { type: "submit" }, t('changedate.confirm'))))));
};
export default ChangeDateClient;
