import { FILTER_ASYNC_SELECT, FILTER_TYPE_TEXT, } from "../../../../../Components/Filters/constants";
import moment from "moment";
import { MAX } from "../../../../../Shared/maxResults";
import { uniqueArray } from "../../../../../Shared/utils/utils";
const headerFilters = [
    {
        name: "society_name",
        label: "Rechercher une mission",
        placeholder: "Entrez les termes de recherche",
        className: "label-input filter filter-with-marin-right-small",
        filterType: FILTER_ASYNC_SELECT,
        field: "text",
        customComponentParameters: {
            customComponentName: "default",
            isSearchable: true,
            isMulti: false,
        },
        formatFilterForApi: (value) => {
            return { name: "society_name", value: value };
        },
        formatCurrent: (obj) => {
            return obj.value.label;
        },
        asyncQueryParameters: {
            query: {
                api: process.env.REACT_APP_IDENTITY_GQL_URL
                    ? process.env.REACT_APP_IDENTITY_GQL_URL
                    : "",
                table: "societies",
                entity: "Society",
                list: true,
                query: {
                    fields: [
                        "id",
                        "_id",
                        "name",
                        "mainContact{firstName lastName}",
                        "affiliate",
                    ],
                    maxResults: 30,
                    page: 0,
                    orderBy: { name: "name" },
                    order: "ASC",
                },
            },
            formatQueryResult: (obj) => obj,
            params: {
                type: "graphql",
                filter_name: "name",
                defaultFilters: [
                    { value: { mainContact_deletedAt: false }, name: "exists" },
                    {
                        name: "missions_missionStatus_statusRm_list",
                        value: [100, 200, 250, 300, 400, 500, 600, 700, 800],
                    },
                    {
                        name: "mainContact_type",
                        value: "CLIENT",
                    },
                ],
            },
            formatResult: (obj) => obj.map((o) => {
                return {
                    label: o.name,
                    displayLabel: `${o.name}${o.affiliate ? ` - ${o.affiliate}` : ""} (${o.mainContact.lastName} ${o.mainContact.firstName})`,
                    value: o.name,
                    filterName: "name",
                };
            }),
        },
    },
    {
        name: "society_rm_lastName",
        label: "rm",
        placeholder: "Entrez un Resource Manager",
        className: "label-input filter filter-with-marin-right-small",
        filterType: FILTER_ASYNC_SELECT,
        field: "text",
        customComponentParameters: {
            customComponentName: "default",
            isSearchable: true,
            isMulti: false,
        },
        formatFilterForApi: (value) => {
            return { name: "society_rm", value: value };
        },
        formatCurrent: (obj) => {
            return obj.value.label;
        },
        asyncQueryParameters: {
            query: {
                api: process.env.REACT_APP_IDENTITY_GQL_URL
                    ? process.env.REACT_APP_IDENTITY_GQL_URL
                    : "",
                table: "societies",
                entity: "Society",
                list: true,
                query: {
                    fields: ["id", "_id", "rm{firstName lastName id _id}"],
                    maxResults: MAX,
                    page: 0,
                    orderBy: { name: "rm_lastName" },
                    order: "ASC",
                },
            },
            formatQueryResult: (obj) => obj,
            params: {
                type: "graphql",
                filter_name: "rm_lastName",
                defaultFilters: [
                    {
                        value: { rm_deletedAt: false, mainContact_deletedAt: false },
                        name: "exists",
                    },
                    {
                        name: "missions_missionStatus_statusRm_list",
                        value: [100, 200, 250, 300, 400, 500, 600, 700, 800],
                    },
                    {
                        name: "mainContact_type",
                        value: "CLIENT",
                    },
                ],
            },
            formatResult: (obj) => {
                const results = obj.map((o) => ({
                    label: `${o.rm.firstName} ${o.rm.lastName}`,
                    value: o.rm.id,
                    filterName: "rm_lastName",
                }));
                return uniqueArray(results);
            },
        },
    },
];
const filters = [
    {
        name: "society_activitySector_name_list",
        formatFilterForApi: (values) => {
            return { name: "society_activitySector_name_list", value: values };
        },
        label: "activity_sector",
        className: "label-input filter filter-with-marin-right-small",
        filterType: FILTER_ASYNC_SELECT,
        customComponentParameters: {
            isSearchable: true,
            isMulti: true,
            customComponentName: "activity_sector",
        },
        formatCurrent: (obj) => obj,
        asyncQueryParameters: {
            query: {
                api: process.env.REACT_APP_IDENTITY_GQL_URL
                    ? process.env.REACT_APP_IDENTITY_GQL_URL
                    : "",
                table: "activitySectors",
                entity: "ActivitySector",
                list: true,
                query: {
                    fields: ["id", "_id", "name"],
                    maxResults: MAX,
                    page: 0,
                },
            },
            params: {
                type: "graphql",
                filter_name: "name",
                defaultFilters: [],
            },
            formatResult: (obj) => obj.map((o) => ({
                label: o.name,
                value: o.name,
                displayLabel: `${o.name}`,
                filterName: "society_activitySector_list",
            })),
        },
    },
    {
        name: "city_list",
        label: "Ville",
        placeholder: "Entrez votre ville",
        filterType: FILTER_ASYNC_SELECT,
        customComponentParameters: {
            isSearchable: true,
            isMulti: true,
            customComponentName: "location",
        },
        formatFilterForApi: (values) => {
            return { name: "city_list", value: values };
        },
        formatCurrent: (obj) => {
            return obj;
        },
        asyncQueryParameters: {
            query: {
                api: process.env.REACT_APP_IDENTITY_GQL_URL
                    ? process.env.REACT_APP_IDENTITY_GQL_URL
                    : "",
                table: "missions",
                entity: "Mission",
                list: true,
                query: {
                    fields: ["city"],
                    maxResults: 30,
                    page: 0,
                    orderBy: { name: "city" },
                    order: "ASC",
                },
            },
            params: {
                type: "graphql",
                filter_name: "missioncity",
                defaultFilters: [
                    {
                        name: "missionStatus_statusRm_list",
                        value: [100, 200, 250, 300, 400, 500, 600, 700, 800],
                    },
                ],
                pageSize: 30,
                page: 1,
            },
            formatResult: (obj) => {
                const results = obj.map((o) => ({
                    label: `${o.city}`,
                    value: o.city,
                    filterName: "city",
                }));
                return uniqueArray(results);
            },
        },
    },
    {
        name: "startDate",
        defaultName: "search.date",
        formatFilterForApi: (value) => {
            return {
                name: `startDate`,
                value: { after: moment(value).format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
            };
        },
        formatCurrent: (obj) => {
            return moment(obj.value.hasOwnProperty("value") ? obj.value.value : obj.value).format("DD-MM-YYYY");
        },
        label: "search.date",
        filterType: FILTER_TYPE_TEXT,
        className: "label-input filter filter-with-marin-right-small",
        field: "text",
        type: "date",
        dateType: "after",
    },
];
export default { headerFilters, filters };
