import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import ApiService from "../../../Services/ApiService";
import { CANDIDATURE } from "../../../Services/contants";
import { candidatureUpdateNotif } from "../../../Services/notif/notif";
const ContactTypeSelect = ({ row, missionTitle = "", rowData, actions }) => {
    const { t } = useTranslation("candidatures");
    const options = [
        { value: "NONE", label: t("candidatures:mailtype.choice") },
        { value: "MANU", label: t("candidatures:mailtype.tel") },
        { value: "AUTO", label: t("candidatures:mailtype.mailauto") },
        { value: "AUTO_REFUSE", label: t("candidatures:mailtype.mailautorefuse") },
        { value: "PERSO", label: t("candidatures:mailtype.mailperso") }
    ];
    const currentValue = rowData.rows.hasOwnProperty(row.index)
        ? rowData.rows[row.index].contactType
        : null;
    const updateCandidature = value => {
        const candidatureService = new ApiService(CANDIDATURE);
        if (value === "PERSO") {
            console.log("roxw original", row.original);
            let subject = t("mail.subject");
            let body_before = `${t("mail.body_before")}  ${row.original.user.civilityLabel}, `;
            let body = t("mail.body_main");
            let body_after = t("mail.body_after");
            let mainBody = `${body_before} ${body} ${missionTitle} ${body_after}`;
            let strWindowFeatures = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
            location.href = `mailto:${row.original.user.email}?subject=${subject}&body=${encodeURIComponent(mainBody)}`;
        }
        candidatureService
            .update({ _id: row.original._id, contactType: value })
            .then(() => {
            candidatureUpdateNotif.success();
        });
    };
    const setSelectedMailType = option => {
        actions.setMailType(row.index, option.value);
    };
    const onChange = value => {
        setSelectedMailType(value);
    };
    const isDisabled = row.original.contactType !== 'NONE';
    return (React.createElement("div", { className: "rmChoice" },
        React.createElement(Select, { isDisabled: isDisabled ? isDisabled : false, className: "rmChoice-select weight-light size-small", classNamePrefix: "rmChoice", value: currentValue
                ? options.find(opt => opt.value === currentValue)
                : options.find(opt => opt.value === row.value), onChange: onChange, options: options })));
};
export default ContactTypeSelect;
