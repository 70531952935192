import moment from "moment";
export const formatNotifDate = (date) => {
    const today = moment(new Date(), 'YYYY-MM-DD');
    const notifDate = moment(new Date(date), 'YYYY-MM-DD');
    const diffDays = today.diff(notifDate, 'days');
    const diffHours = today.diff(notifDate, 'hours');
    const diffMinutes = today.diff(notifDate, 'minutes');
    if (diffDays > 4) {
        return notifDate.format('DD/MM/YYYY hh:ss');
    }
    if (diffMinutes < 60)
        return `Il y a ${diffMinutes} minutes`;
    if (diffHours < 7) {
        return `Il y a ${diffHours} heures`;
    }
    if (diffHours > 7) {
        if (diffHours < 24)
            return `Il y a 1 jours`;
        return `Il y a ${diffDays} jours`;
    }
    return date;
};
