import { useState, useEffect } from "react";
import GraphQl from "../../Shared/graphql";
import { formatGraphQlData } from "../../Shared/rewriteArray";
import { MAX } from "../../Shared/maxResults";
const useGetAll = (entities_config, userRole = 'ROLE_RM') => {
    const [entities, setEntities] = useState({});
    const [entitiesLoaded, setEntitiesLoaded] = useState(false);
    const default_config = {
        api: process.env.REACT_APP_IDENTITY_GQL_URL
            ? process.env.REACT_APP_IDENTITY_GQL_URL
            : "",
        list: true,
        query: {
            maxResults: MAX,
            page: 0,
        },
    };
    useEffect(() => {
        if (userRole === "ROLE_RM" || userRole === 'ROLE_ADMIN')
            setRequest();
    }, []);
    const setRequest = async () => {
        setEntitiesLoaded(true);
        const promises = entities_config.map(async (e) => {
            const config = {
                ...default_config,
                table: e.table,
                entitiy: e.entity,
                query: { ...default_config.query, fields: [...e.fields], filters: [...e.filters] },
                format: e.format
            };
            return getRequest(config);
        });
        Promise.all(promises).then((values) => {
            let updatedEntities = {};
            values.map((ent) => {
                updatedEntities[ent.type] = ent.data;
            });
            setEntities(updatedEntities);
            setEntitiesLoaded(false);
        });
    };
    const getRequest = async (config) => {
        return new Promise(async (resolve, _reject) => {
            const res = await GraphQl(config);
            const d = formatGraphQlData(res.datas);
            if (!d)
                return;
            let updatedData = d;
            if (config.format) {
                updatedData = d.map((d) => config.format(d));
            }
            resolve({ data: updatedData, type: `${config.table}` });
        });
    };
    return {
        entities,
        entitiesLoaded
    };
};
export default useGetAll;
