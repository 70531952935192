import { useTranslation } from "react-i18next";
import { Alert } from "reactstrap";
import { Field } from "react-final-form";
import { WhFieldTxt, WhValidator } from "../../../../../Components/WHForm/WhFields";
import React from "react";
import { addAsterix } from "../../../../../helper/addAsterix";
export const FFAccountLoginContainer = ({ error, disabled, values }) => {
    const { t } = useTranslation("form");
    return (React.createElement("div", { className: "content" },
        error && React.createElement(Alert, { color: "danger" }, error),
        React.createElement("div", { className: "mt-30 field-row" },
            React.createElement(Field, { name: "email", validate: WhValidator.required }, props => (React.createElement(WhFieldTxt, Object.assign({ name: "email" }, props, { label: addAsterix(t("claccount.login")), className: "label-input", type: "text", disabled: disabled }))))),
        React.createElement("div", { className: "mt-30 field-row password-clientaccount" },
            React.createElement(Field, { name: "newPassword" }, props => (React.createElement(WhFieldTxt, Object.assign({ name: "newPassword" }, props, { label: addAsterix(t("claccount.password")), className: "label-input", type: "password", disabled: disabled })))),
            React.createElement("p", { className: "mb-0 mb-0Mobile mt-5 mt-5Mobile ml-5 ml-5Mobile size-tiny weight-light" }, t("claccount.validPassword"))),
        React.createElement("div", { className: "mt-30 field-row password-clientaccount" },
            React.createElement(Field, { name: "newPasswordConfirm", validate: values.newPassword ? WhValidator.required : undefined }, props => (React.createElement(WhFieldTxt, Object.assign({ name: "newPassword" }, props, { label: addAsterix(t("claccount.confirmPassword")), className: "label-input", type: "password", disabled: disabled })))))));
};
