import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
const HeaderMissionList = ({ onClick, missions }) => {
    const { t } = useTranslation('missions');
    const [current, setCurrent] = useState(0);
    let name = `missionStatus_statusRm_list`;
    const sendStatus = (type, current) => {
        setCurrent(current);
        if (type === "toutes")
            onClick([{ name: name, value: [100, 200, 250, 300, 400, 500, 600, 700, 800] }]);
        if (type === "validation")
            onClick([{ name: name, value: [100, 200, 250] }]);
        if (type === "traiter")
            onClick([{ name: name, value: [300, 400] }]);
        if (type === "termine")
            onClick([{ name: name, value: [500, 600, 700, 800] }]);
    };
    return (React.createElement("div", { className: 'header-mission-bar' },
        React.createElement("div", { className: 'header-mission-bar-container' },
            React.createElement("div", { className: current === 0 ? 'selected' : '', onClick: () => sendStatus('toutes', 0) },
                React.createElement("span", null, t('filters.all'))),
            React.createElement("div", { className: current === 1 ? 'selected' : '', onClick: () => sendStatus('validation', 1) },
                React.createElement("span", null, t('filters.validate'))),
            React.createElement("div", { className: current === 2 ? 'selected' : '', onClick: () => sendStatus('traiter', 2) },
                React.createElement("span", null, t('filters.handle'))),
            React.createElement("div", { className: current === 3 ? 'selected' : '', onClick: () => sendStatus('termine', 3) },
                React.createElement("span", null, t('filters.over'))))));
};
export default HeaderMissionList;
