import React from "react";
import { Container, Row, Col } from "reactstrap";
import Menu from "./components/Menu";
import ColLeft from "./components/ColLeft";
import Main from "./components/Main";
import { useSelector, useDispatch } from 'react-redux';
import { setUserDefault } from '../../../redux/user/action';
export const Dashboard = () => {
    const user = useSelector(state => state.user.data);
    const params = useSelector((state) => state.params);
    const goToMission = () => {
        window.location.href = params.missionsUrl;
    };
    const dispatch = useDispatch();
    const loadData = () => {
        dispatch(setUserDefault());
    };
    return (React.createElement("div", { className: "freelance-dashboard" },
        React.createElement(Menu, null),
        React.createElement(Container, { className: "large main" },
            React.createElement(Row, null,
                React.createElement(Col, { md: 4 },
                    React.createElement(ColLeft, { data: user, loadData: loadData, goToMission: goToMission })),
                React.createElement(Col, { md: 8 },
                    React.createElement(Main, { data: user, loadData: loadData }))))));
};
export default Dashboard;
