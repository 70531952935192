import React from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import NewClientListBody from "./NewClientListBody";
import LoadClientListES from "../ClientListES/LoadClientListES";
export const NewClientList = ({ history }) => {
    const user = useSelector((state) => state.user.data);
    return (React.createElement(LoadClientListES, { defaultFilters: [{ name: "qualified", value: 'false' }, { name: 'main_contact_enabled', value: 'true' }], facetsConfig: [
            { name: "activity_sector_name", size: 100, sort: { value: "asc" } },
            { name: "rm_fullname", size: 100, sort: { value: "asc" } },
            { name: "city", size: 100, sort: { value: "asc" } },
        ], render: ({ totalItems, updateSort, clientData, setRequestFilters, facets, getQueryFilters, hasNextPage, isNextPageLoading, loadNextPage, onSubmitClient }) => {
            return (React.createElement(NewClientListBody, { filterConfig: 'graphql', facets: facets, updateSort: updateSort, user: user, history: history, hasNextPage: hasNextPage, totalItems: totalItems, isNextPageLoading: isNextPageLoading, loadNextPage: loadNextPage, data: clientData, onSubmitClient: onSubmitClient, setRequestFilters: setRequestFilters }));
        } }));
};
export default withRouter(NewClientList);
