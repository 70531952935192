import React from "react";
import Moment from "react-moment";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { formatFirstName, formatLastName } from "../../helper/formatUserName";
import PopOver from "../PopOver/PopOver";
import moment from "moment";
export const Body = () => {
    return (React.createElement("div", { className: "patch-tbody" },
        React.createElement("div", { className: "patch-tr" },
            React.createElement("div", { className: "patch-td" },
                React.createElement("div", null,
                    React.createElement("span", null, "Value"))))));
};
export const NewClientBody = ({ d }) => {
    const { t } = useTranslation("rmfreelance");
    return (React.createElement("div", { className: "patch-tbody" },
        React.createElement("div", { className: `patch-tr` },
            React.createElement("div", { className: "patch-td" },
                React.createElement("div", { className: `${d.precal_state === 1
                        ? "round round-orange"
                        : "round"}` },
                    React.createElement("div", { className: `${d.precal_state === 1
                            ? "text-orange precalState"
                            : "text-normal precalState"}` },
                        React.createElement("span", null, d.precal_state === 1 ? t("table.new") : t("table.waiting"))))),
            React.createElement("div", { className: "patch-td" },
                React.createElement(Moment, { format: "DD/MM/YYYY" }, d.created_at)),
            React.createElement("div", { className: "patch-td" },
                React.createElement(PopOver, { text: `${d.name} - ${d.affiliate ? ` ${d.affiliate}` : ''} (${formatLastName(d.main_contact_lastname)} - ${formatFirstName(d.main_contact_firstname)})`, className: 'toolTip-popover toolTip-popover-right', condition: (_text) => true, render: ({ setIsHover, formattedText }) => {
                        return (React.createElement("span", { onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false) }, formattedText));
                    } })),
            React.createElement("div", { className: "patch-td" },
                React.createElement(PopOver, { text: d.activity_sector_name, className: 'toolTip-popover toolTip-popover-right', condition: (_text) => true, render: ({ setIsHover, formattedText }) => {
                        return (React.createElement("span", { onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false) }, formattedText));
                    } })),
            React.createElement("div", { className: "patch-td" },
                React.createElement("div", null,
                    React.createElement("span", { className: "arrowRight" }))))));
};
export const NewFreelanceBody = ({ d }) => {
    const { t } = useTranslation("rmfreelance");
    return (React.createElement("div", { className: "patch-tbody" },
        React.createElement("div", { className: `patch-tr` },
            React.createElement("div", { className: "patch-td" },
                React.createElement("div", { className: `prioritizeIcon ${classNames({
                        inactive: !d.prioritized,
                    })}` },
                    React.createElement("span", { className: "priorityButton littleIcon" },
                        React.createElement("i", { className: "lnr lnr-diamond" })))),
            React.createElement("div", { className: "patch-td" },
                React.createElement("div", { className: `${d.precalstate === 1
                        ? "text-orange precalState"
                        : "text-normal precalState"}` },
                    React.createElement("span", null, d.precalstate === 1 ? t("table.new") : t("table.waiting")))),
            React.createElement("div", { className: "patch-td" },
                React.createElement(PopOver, { text: moment(d.created_at).format('DD/MM/YYYY'), className: 'toolTip-popover toolTip-popover-right', condition: (_text) => true, render: ({ setIsHover, formattedText }) => {
                        return (React.createElement("span", { onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false) }, formattedText));
                    } })),
            React.createElement("div", { className: "patch-td" },
                React.createElement(PopOver, { text: formatLastName(d.lastname), className: 'toolTip-popover toolTip-popover-right', condition: (_text) => true, render: ({ setIsHover, formattedText }) => {
                        return (React.createElement("span", { onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false) }, formattedText));
                    } })),
            React.createElement("div", { className: "patch-td" },
                React.createElement(PopOver, { text: formatFirstName(d.firstname), className: 'toolTip-popover toolTip-popover-right', condition: (_text) => true, render: ({ setIsHover, formattedText }) => {
                        return (React.createElement("span", { onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false) }, formattedText));
                    } })),
            React.createElement("div", { className: "patch-td" },
                React.createElement(PopOver, { text: d.job, className: 'toolTip-popover toolTip-popover-right', condition: (_text) => true, render: ({ setIsHover, formattedText }) => {
                        return (React.createElement("div", { onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false) },
                            React.createElement("div", null,
                                React.createElement("span", null, formattedText))));
                    } })),
            React.createElement("div", { className: "patch-td" },
                React.createElement("div", null,
                    React.createElement("span", { className: "arrowRight" }))))));
};
export const BodyProposition = ({ d }) => {
    return (React.createElement("div", { className: "patch-tbody" },
        React.createElement("div", { className: `patch-tr` },
            React.createElement("div", { className: "patch-td" },
                React.createElement(PopOver, { text: `${d.customer_name} - ${d.customer_filiale ? ` ${d.customer_filiale}` : ""} (${d.customer_lastname_name} - ${d.customer_first_name})`, className: 'toolTip-popover toolTip-popover-propositionMission', condition: (_text) => true, render: ({ setIsHover, formattedText }) => {
                        return (React.createElement("div", null,
                            React.createElement("span", { className: "visibleMobileLabel" }, "Client: "),
                            React.createElement("span", { onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false) }, formattedText)));
                    } })),
            React.createElement("div", { className: "patch-td" },
                React.createElement(PopOver, { text: `${d.object}`, className: 'toolTip-popover toolTip-popover-propositionMission', condition: (_text) => true, render: ({ setIsHover, formattedText }) => {
                        return (React.createElement("div", null,
                            React.createElement("span", { className: "visibleMobileLabel" }, "Mission: "),
                            React.createElement("span", { onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false) }, formattedText)));
                    } })),
            React.createElement("div", { className: "patch-td" },
                React.createElement("div", null,
                    React.createElement("span", { className: "visibleMobileLabel" }, "Date de d\u00E9but: "),
                    React.createElement(Moment, { format: "DD/MM/YYYY" }, d.start_date))),
            React.createElement("div", { className: "patch-td" },
                React.createElement("div", null,
                    React.createElement("span", { className: "visibleMobileLabel" }, "Nombre de r\u00E9ponses: "),
                    React.createElement("span", null,
                        React.createElement("strong", null, d.response_nbr),
                        " "))),
            React.createElement("div", { className: "patch-td" },
                React.createElement("div", null,
                    React.createElement("span", { className: "visibleMobileLabel" }, "Nombre de freelances propos\u00E9s: "),
                    React.createElement("span", null,
                        React.createElement("strong", null, d.proposition_nbr)))))));
};
export const BodyValidate = ({ d }) => {
    const { t } = useTranslation(["notif", "missions"]);
    return (React.createElement("div", { className: "patch-tbody" },
        React.createElement("div", { className: `patch-tr` },
            React.createElement("div", { className: "patch-td" },
                React.createElement(PopOver, { text: formatLastName(d.fl_lastname), className: 'toolTip-popover toolTip-popover-valideMission', condition: (_text) => true, render: ({ setIsHover, formattedText }) => {
                        return (React.createElement("div", null,
                            React.createElement("span", { className: "visibleMobileLabel" },
                                t("missions:columns.name"),
                                ": "),
                            React.createElement("span", { onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false), className: "" }, formattedText)));
                    } })),
            React.createElement("div", { className: "patch-td" },
                React.createElement(PopOver, { text: formatFirstName(d.fl_firstname), className: 'toolTip-popover toolTip-popover-valideMission', condition: (_text) => true, render: ({ setIsHover, formattedText }) => {
                        return (React.createElement("div", null,
                            React.createElement("span", { className: "visibleMobileLabel" },
                                t("missions:columns.firstname"),
                                ": "),
                            React.createElement("span", { onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false), className: "" }, formattedText)));
                    } })),
            React.createElement("div", { className: "patch-td" },
                React.createElement(PopOver, { text: formatFirstName(d.jobs), className: 'toolTip-popover toolTip-popover-valideMission', condition: (_text) => true, render: ({ setIsHover, formattedText }) => {
                        return (React.createElement("div", null,
                            React.createElement("span", { className: "visibleMobileLabel" },
                                t("missions:columns.jobname"),
                                ": "),
                            React.createElement("span", { onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false), className: "" }, formattedText)));
                    } })),
            React.createElement("div", { className: "patch-td" },
                React.createElement(PopOver, { text: `${d.customer_name} - ${d.customer_filiale ? ` ${d.customer_filiale}` : ""} (${d.customer_lastname_name} - ${d.customer_first_name})`, className: 'toolTip-popover toolTip-popover-valideMission', condition: (_text) => true, render: ({ setIsHover, formattedText }) => {
                        return (React.createElement("div", null,
                            React.createElement("span", { className: "visibleMobileLabel" },
                                t("missions:columns.client"),
                                ": "),
                            React.createElement("span", { onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false), className: "" }, formattedText)));
                    } })),
            React.createElement("div", { className: "patch-td" },
                React.createElement(PopOver, { text: `${d.object}`, className: 'toolTip-popover toolTip-popover-valideMission', condition: (_text) => true, render: ({ setIsHover, formattedText }) => {
                        return (React.createElement("div", null,
                            React.createElement("span", { className: "visibleMobileLabel" },
                                t("missions:columns.mission"),
                                ": "),
                            React.createElement("span", { onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false), className: "" }, formattedText)));
                    } })),
            React.createElement("div", { className: "patch-td" },
                React.createElement("div", null,
                    React.createElement("span", { className: "visibleMobileLabel" },
                        t("missions:columns.startDate"),
                        ": "),
                    React.createElement(Moment, { format: "DD/MM/YYYY" }, d.start_date))),
            React.createElement("div", { className: "patch-td" },
                React.createElement("div", null,
                    React.createElement("span", { className: "arrowRight" }))))));
};
