import { withRouter } from "react-router";
import React from "react";
import PropositionBody from "./PropositionBody";
import LoadMissionListES from "../LoadMissionListES";
const MissionListProposition = ({ history }) => {
    return (React.createElement(LoadMissionListES, { facetsConfig: [
            { name: "activities_name", size: 100, sort: { value: "asc" } },
            { name: "city", size: 100, sort: { value: "asc" } },
            { name: "rm_fullname", size: 100, sort: { value: "asc" } },
        ], sortConfig: { created_at: "desc" }, defaultFilters: [{ name: 'main_contact_enabled', value: 'true' }, { name: "rm_status_value", value: "300" }], render: ({ data, user, setRequestFilters, facets, onSubmitMission, isLoading, hasNextPage, isNextPageLoading, totalItems, loadNextPage, setDefaultFiltersOverride, }) => {
            return (React.createElement("div", { className: "littleHeader width-selectable-filters rightContainer rightContainer--withHeadFilters missionListContainer" },
                React.createElement(PropositionBody, { history: history, user: user, facets: facets, hasNextPage: hasNextPage, totalItems: totalItems, data: data, isCurrentlyLoading: isLoading, loadNextPage: loadNextPage, setDefaultFiltersOverride: setDefaultFiltersOverride, isNextPageLoading: isNextPageLoading, setRequestFilters: setRequestFilters, onSubmitMission: onSubmitMission })));
        } }));
};
export default withRouter(MissionListProposition);
