export const required = value => {
    if (!value) {
        return "Obligatoire";
    }
    return undefined;
};
export const requiredLatitudeLongitude = value => {
    if (!value) {
        alert("Vous n'avez pas séléctionné une ville valide");
        return "Obligatoire";
    }
    return undefined;
};
export const requiredCity = value => {
    if (!value) {
        return "Obligatoire";
    }
    return undefined;
};
export const requiredCkEditor = value => {
    if (!value) {
        return "Obligatoire";
    }
    if (value === '<p></p>\n')
        return "Obligatoire";
    return undefined;
};
export const requiredSelect = value => {
    if (!value) {
        return "Obligatoire";
    }
    return undefined;
};
export const email = value => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return "Adresse email non valide";
    }
    return undefined;
};
export const numeric = value => {
    if (value && !/^[0-9]*$/i.test(value)) {
        return "Ce champ ne peut contenir que des chiffres";
    }
    return undefined;
};
export const siret = value => {
    if (value && value.length !== 14) {
        return "Ce champs est limité a 14 charactère";
    }
    return undefined;
};
export const phone = value => {
    if (value.length < 10)
        return "Ce n’est pas pas un numéro valide";
    if (value &&
        !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\.0-9]*$/i.test(value)) {
        return "Ce n’est pas pas un numéro valide";
    }
    return undefined;
};
export const password = value => {
    if (value && !/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/.test(value)) {
        return "Mot de passe non valide";
    }
    return undefined;
};
export const validateStartDate = (value, allValues) => {
    if (allValues.startNow)
        return undefined;
    if (!allValues.startNow && value)
        return undefined;
    return "Obligatoire";
};
export const validateGoogleMapCity = (_value, allValue) => {
    if (!allValue.hasOwnProperty('googleMapCity'))
        return 'Tapez une ville puis sélectionnez la dans la liste proposée.';
    return undefined;
};
export const validateCity = (value, allValue) => {
    if (!allValue.hasOwnProperty('falseCity'))
        return 'Obligatoire';
    if (!value || (allValue.hasOwnProperty('falseCity') && value !== allValue.falseCity)) {
        return "Obligatoire";
    }
    return undefined;
};
export const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);
export default { required, validateCity, validateGoogleMapCity, requiredCkEditor, requiredLatitudeLongitude, requiredSelect, requiredCity, email, phone, password, validateStartDate, siret, composeValidators };
