export const getApiError = (e) => {
    if (!e) {
        return null;
    }
    if (e && !e.hasOwnProperty("violations") || e.violations.length === 0)
        return null;
    const message = e.violations[0].message;
    if (message === "email existe deja") {
        return "notif:error.email";
    }
    if (message === "Le freelance a déjà postulé à cette mission.")
        return "notif:already_has_candidature";
    if (message === "Vous ne disposez plus de crédits sur votre pack, veuillez contacter votre Resource Manager") {
        return "notif:error.nopackleft";
    }
    if (message === "Vous êtes déjà en mission. Vous ne pouvez pas valider une autre mission")
        return "notif:error.alreadyvalidate";
    if (message)
        return message;
    return null;
};
