import React, { Fragment } from 'react';
import { NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import NumberCounter from '../../../Components/NumberCounter/NumberCounter';
import { useTranslation } from "react-i18next";
;
const MenuChild = ({ text, icon, children, linkPath, location, countNumber, toggleRmMenu }) => {
    const { t } = useTranslation('menusidebar');
    const isActive = location.pathname === linkPath;
    return (React.createElement(Fragment, null,
        React.createElement(NavItem, { onClick: () => { toggleRmMenu ? toggleRmMenu() : ""; }, className: isActive ? "menuSideBarElementChild col-2 selected-submenu" : 'menuSideBarElementChild col-2' },
            React.createElement(NavLink, { to: linkPath, activeClassName: "active", className: "flex-row " },
                icon &&
                    React.createElement("i", { className: icon }),
                countNumber > -1 &&
                    React.createElement(NumberCounter, { countNumber: countNumber }),
                React.createElement("span", null, t(text))))));
};
export default withRouter(MenuChild);
