import { FILTER_TYPE_SELECT_AUTOC, FILTER_TYPE_SELECT } from "../constants";
import { MAX } from "../../../Shared/maxResults";
const SOCIETYSTORED = {
    api: process.env.REACT_APP_IDENTITY_GQL_URL
        ? process.env.REACT_APP_IDENTITY_GQL_URL
        : "",
    table: "societies",
    entity: "Society",
    list: true,
    query: { fields: ["id", "_id", "name", "mainContact{lastName firstName}"],
        maxResults: MAX,
        page: 0,
        filtersToUpdate: {
            field: 'name',
            objectField: ['name', 'mainContact.firstName', 'mainContact.lastName']
        },
        filters: [{ value: { mainContact_deletedAt: false }, name: "exists" }, { name: "mainContact_type", value: "CLIENT" }]
    }
};
const RMSTORED = {
    api: process.env.REACT_APP_IDENTITY_GQL_URL
        ? process.env.REACT_APP_IDENTITY_GQL_URL
        : "",
    table: "users",
    entity: "User",
    list: true,
    query: {
        fields: ["id", "_id", "firstName", "lastName"],
        maxResults: MAX,
        page: 0,
        filtersToUpdate: {
            field: 'firstName',
            objectField: ["^(", 'firstName', "lastName"]
        },
        filters: [{ name: "type", value: "RM", }]
    },
};
const headerFilters = [
    {
        name: 'name',
        defaultName: '',
        inputAsDefault: true,
        canBeRemove: true,
        isHeader: true,
        isModal: false,
        isSearchable: true,
        asyncParameters: SOCIETYSTORED,
        formatFilterValue: (obj) => { return obj ? obj : obj; },
        formatCurrent: (obj) => { return obj ? (obj.value.hasOwnProperty('label') ? obj.value.label : obj.value) : obj; },
        label: 'search.client',
        className: 'label-input filter filter-with-marin-right-small',
        filterType: FILTER_TYPE_SELECT_AUTOC,
        field: 'text',
    },
    {
        defaultName: "",
        inputAsDefault: true,
        canBeRemove: true,
        isHeader: true,
        asyncParameters: RMSTORED,
        isModal: false,
        isSearchable: true,
        formatFilterValue: (obj) => { return obj ? obj : obj; },
        formatCurrent: (obj) => { return obj ? (obj.value.hasOwnProperty('label') ? obj.value.label : obj.value) : obj; },
        name: 'rm_firstName',
        label: 'rm',
        className: 'select-label-input filter filter-with-marin-right-small',
        filterType: FILTER_TYPE_SELECT_AUTOC,
        field: 'text',
    }
];
const SectorStored = {
    api: process.env.REACT_APP_IDENTITY_GQL_URL ? process.env.REACT_APP_IDENTITY_GQL_URL : "",
    table: 'activitySectors',
    entity: 'ActivitySector',
    list: true,
    query: {
        fields: ['id', '_id', 'name'],
        maxResults: MAX,
        page: 0,
        filtersToUpdate: {
            field: 'activitySector_name',
            objectField: 'name'
        },
        filters: [],
    },
};
const AdressStored = {
    api: process.env.REACT_APP_IDENTITY_GQL_URL ? process.env.REACT_APP_IDENTITY_GQL_URL : "",
    table: 'addresses',
    entity: 'Address',
    list: true,
    query: {
        fields: ['id', '_id', 'city', "zipCode"],
        maxResults: MAX,
        page: 0,
        filtersToUpdate: {
            field: 'city',
            objectField: ['city', 'zipCode'],
        },
        filters: [],
    },
};
const filters = [
    {
        name: 'activitySector_name_list',
        label: 'activity_sector',
        defaultName: 'activity_sector',
        isModal: true,
        multiple: true,
        inputAsDefault: true,
        asyncParameters: SectorStored,
        formatFilterValue: (obj) => { return obj.map((o) => o.label); },
        formatCurrent: (obj) => { return obj; },
        filterType: FILTER_TYPE_SELECT_AUTOC,
        isSearchable: false,
        options: [],
        customComponent: 'activity_sector'
    },
    {
        name: 'address_city_list',
        label: 'Ville',
        defaultName: 'ville',
        isModal: true,
        multiple: true,
        inputAsDefault: true,
        asyncParameters: AdressStored,
        formatFilterValue: (obj) => { return obj.map((o) => o.value); },
        formatCurrent: (obj) => { return obj; },
        filterType: FILTER_TYPE_SELECT_AUTOC,
        isSearchable: true,
        options: [],
        customComponent: 'location'
    },
    {
        name: 'exists',
        label: 'newsletter',
        defaultName: 'mainContact_newsletter',
        isModal: true,
        filterType: FILTER_TYPE_SELECT,
        isSearchable: false,
        inputAsDefault: true,
        formatFilterValue: (obj) => { return obj; },
        formatCurrent: (obj) => obj.value.label,
        options: [
            { value: { mainContact_newsletter: true }, label: "Oui" },
            { value: { mainContact_newsletter: false }, label: "Non" },
            { value: "donotfetch", label: "Vide" }
        ],
    },
    {
        name: 'mainContact_precalState',
        label: 'profile.precalState',
        defaultName: 'precalState',
        isModal: true,
        filterType: FILTER_TYPE_SELECT,
        isSearchable: false,
        inputAsDefault: true,
        formatFilterValue: (obj) => { return obj; },
        formatCurrent: (obj) => obj.value.label,
        options: [
            { value: 1, label: "Nouveau" },
            { value: 2, label: "En Attente" },
        ],
    },
];
export default { headerFilters, filters };
