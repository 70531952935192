import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RmCandidatureWrapper from "./RmCandidatureWrapper";
import { AccessEnum, checkAccess } from "../../../Services/Access/access";
const Statistique = ({ candidatures }) => {
    const getNbCandidature = () => candidatures.length;
    const getNbRmFreelance = () => candidatures.filter((c) => c.statusRm === "transfert").length;
    const getNbRefusCl = () => candidatures.filter((c) => c.statusClient === "refused").length;
    const getNbManualAdd = () => candidatures.filter((c) => c.type === "par le rm").length;
    const { t } = useTranslation('candidatures');
    return React.createElement("div", { className: 'statistique' },
        React.createElement("div", null,
            React.createElement("div", null,
                React.createElement("span", null, t('statistique.candidature'))),
            React.createElement("div", null,
                React.createElement("span", null,
                    React.createElement("strong", null, getNbCandidature())))),
        React.createElement("div", null,
            React.createElement("div", null,
                React.createElement("span", null, t('statistique.rm'))),
            React.createElement("div", null,
                React.createElement("span", null,
                    React.createElement("strong", null, getNbRmFreelance())))),
        React.createElement("div", null,
            React.createElement("div", null,
                React.createElement("span", null, t('statistique.client'))),
            React.createElement("div", null,
                React.createElement("span", null,
                    React.createElement("strong", null, getNbRefusCl())))),
        React.createElement("div", null,
            React.createElement("div", null,
                React.createElement("span", null, t('statistique.addrm'))),
            React.createElement("div", null,
                React.createElement("span", null,
                    React.createElement("strong", null, getNbManualAdd())))));
};
const Historic = ({ missionId, candidatures, callback, permissions }) => {
    const [tab, setTab] = useState(1);
    const { t } = useTranslation('candidatures');
    return (React.createElement("div", { className: 'historic' },
        React.createElement("div", { className: 'historic-header-container' },
            checkAccess(AccessEnum.STATISTIQUES, permissions) && React.createElement("div", { className: tab === 0 ? 'tab tab-selected' : 'tab', onClick: () => setTab(0) },
                React.createElement("span", null, t('statistique.title'))),
            React.createElement("div", { className: tab === 1 ? 'tab tab-selected' : 'tab', onClick: () => setTab(1) },
                React.createElement("span", null, t('historique.historicmission')))),
        tab === 0 &&
            React.createElement(Statistique, { candidatures: candidatures }),
        tab === 1 &&
            React.createElement(RmCandidatureWrapper, { missionId: missionId, candidatures: candidatures, callback: callback, readonly: true, onAcceptFreelance: null })));
};
export default Historic;
