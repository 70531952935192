import { useState } from 'react';
export const useCursor = () => {
    const [nextCursor, setNextCursor] = useState(null);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const initCursor = (nextCursor, hasNextPage, totalItems) => {
        setHasNextPage(hasNextPage);
        setNextCursor(nextCursor);
        setTotalItems(totalItems);
    };
    const setCursor = (nextCursor, hasNextPage) => {
        setHasNextPage(hasNextPage);
        setNextCursor(nextCursor);
    };
    const resetCursor = () => {
        setHasNextPage(false);
        setNextCursor(null);
        setTotalItems(0);
    };
    return {
        initCursor,
        hasNextPage,
        setCursor,
        nextCursor,
        resetCursor,
        totalItems,
    };
};
