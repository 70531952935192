import React from 'react';
import Moment from 'react-moment';
import { useTranslation } from "react-i18next";
import { addHTTPS } from "../../Shared/utils/utils";
const PackManElement = ({ picture, firstName, lastName, created, help, value, comment }) => {
    const { t } = useTranslation('counter');
    return (React.createElement("div", { className: 'packman-elements' },
        React.createElement("div", { className: 'content-container flex-row flex-justifyBetween' },
            React.createElement("div", { className: 'presentation' },
                React.createElement("div", { className: 'picture mr-15 centering' }, picture && picture.content ?
                    React.createElement("img", { id: 'image', src: picture ? addHTTPS(picture.content) : '' })
                    :
                        React.createElement("div", { className: 'logo-empty' },
                            React.createElement("i", { className: `icon-image size-big` }))),
                React.createElement("div", null,
                    React.createElement("span", null, `${firstName} ${lastName}`))),
            React.createElement("div", null,
                React.createElement("span", null,
                    React.createElement(Moment, { format: "DD/MM/YYYY" }, created),
                    React.createElement(Moment, { format: "HH:mm" }, ` ${created}`))),
            React.createElement("div", null,
                React.createElement("span", null,
                    React.createElement("strong", null, help ? t('counter.pack_with') : t('counter.pack_without')))),
            React.createElement("div", { className: 'credit-container' },
                React.createElement("div", { className: 'flex-row' },
                    React.createElement("div", { className: value < 0 ? 'credit-block debit debit-selected' : 'credit-block debit' },
                        React.createElement("div", null, "D\u00E9bit"),
                        React.createElement("div", null, value < 0 ? `${value}` : '-')),
                    React.createElement("div", { className: value > 0 ? 'credit-block credit credit-selected' : 'credit-block credit' },
                        React.createElement("div", null, "Cr\u00E9dit"),
                        React.createElement("div", null, value > 0 ? `+ ${value}` : '+'))))),
        React.createElement("div", { className: 'comment' },
            React.createElement("span", null, comment))));
};
export default PackManElement;
