import React from "react";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { WhFieldTxt } from "../../../Components/WHForm/WhFields";
import { useTranslation } from "react-i18next";
const FormFilter = (props) => {
    const { t } = useTranslation("statistique");
    return (React.createElement("div", { className: "filterList castorForm-formRow" },
        React.createElement(Field, { component: WhFieldTxt, label: "Du", name: "from", type: "date", dateValue: props.initialValues && props.initialValues.from ? props.initialValues.from : null }),
        React.createElement(Field, { component: WhFieldTxt, label: "Au", name: "to", type: "date", minDate: props.from, dateValue: props.initialValues && props.initialValues.to ? props.initialValues.to : null, withMinDate: true }),
        React.createElement("button", { type: "submit", className: "filterList-save castorBtn-primary" }, t("btn-search"))));
};
let FormFilterRedux = reduxForm({
    form: "StatisticFormFilter",
})(FormFilter);
const selector = formValueSelector('StatisticFormFilter');
FormFilterRedux = connect(state => {
    const from = selector(state, 'from');
    const to = selector(state, 'to');
    return {
        from,
        to
    };
})(FormFilterRedux);
export default FormFilterRedux;
