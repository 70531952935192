import React from 'react';
import { RenderImgFileUpload } from "../../WHForm/renderCustomFileField";
import { Field } from 'redux-form';
import { useTranslation } from "react-i18next";
const LogoCreate = ({ label, position, imgAsInput, isLoading = false }) => {
    const { t } = useTranslation('document');
    return React.createElement("div", { className: 'logo-create' },
        React.createElement(Field, { name: 'photo', imgAsInput: imgAsInput, position: position, isLoading: isLoading, component: RenderImgFileUpload, label: label ? t(label) : '' }));
};
export default LogoCreate;
