import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import Footer from "../Freelance/Footer";
import Dashboard from "../Client/DashBoard/ClientDashboard";
import ClientMissions from "../Missions/Client/NewClientMission";
import ClientAccount from "../Client/Account/ClientAccount";
import FreelanceEdit from "../RessourceManager/Freelance/FreelanceEdit";
const ClientRoute = () => {
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: "mainContainer" },
            React.createElement("div", { className: "costumer-container" },
                React.createElement(Switch, null,
                    React.createElement(Route, { path: "/client/missions/:id?", name: "client_missions", component: ClientMissions }),
                    React.createElement(Route, { path: "/client/freelance_view/:id", name: "client_freelance_edit", component: FreelanceEdit }),
                    React.createElement(Route, { path: "/client/account", name: "client_account", component: ClientAccount }),
                    React.createElement(Route, { path: "/client/dashboard/:id?", name: "client_missions", component: Dashboard })),
                React.createElement(Footer, null)))));
};
export default ClientRoute;
