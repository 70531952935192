import React, { useState, Fragment } from "react";
import { CounterWithBtn, CounterWithoutBtn } from "../../../../../Components/Counter/Counter";
import { useTranslation } from "react-i18next";
import ApiService from "../../../../../Services/ApiService";
import { PACKMEN } from "../../../../../Services/contants";
import Loading from "../../../../../Components/Loading";
import DisplayForm from "../../../../../Components/WHForm/DisplayForm";
import ButtonBottomForm from "../../../../../Components/WHForm/ButtonBottomForm";
import { useSelector } from "react-redux";
import { Form } from "react-final-form";
import FFTextArea from "../../../../../Components/FinalForm/FFTextArea";
import { editClientNotif } from "../../../../../Services/notif/notif";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
export const UnupdatableCounterContainer = ({ nbPackHelp, nbpackNoHelp, }) => {
    const { t } = useTranslation(["form", "counter"]);
    const [nbHelp] = useState(nbPackHelp);
    const [nbNoHelp] = useState(nbpackNoHelp);
    return (React.createElement("div", { className: "counters-container" },
        React.createElement("span", { className: "header thinTitle" }, t("counter:counter.main")),
        React.createElement("div", { className: "counters-wrapper counters-wrapper-row  br-10 " },
            React.createElement(CounterWithoutBtn, { subtitle: "counter:counter.with", number: nbHelp, cssType: "big", type: 1 }),
            React.createElement("span", { className: "separator separator-vertical m-0 separator-lightGray absolute" }),
            React.createElement(CounterWithoutBtn, { subtitle: "counter:counter.without", number: nbNoHelp, cssType: "big", type: 1 }))));
};
const CounterContainer = ({ nbPackHelp, nbpackNoHelp, data, callback }) => {
    const { t } = useTranslation(["form", "counter"]);
    const [loaded, setLoaded] = useState(true);
    const [nbHelp, setNbHelp] = useState(nbPackHelp);
    const [nbNoHelp, setNbNoHelp] = useState(nbpackNoHelp);
    const [nbNoHelpFooter, setNoHelpFooter] = useState({ value: '00' });
    const [nbHelpFooter, setHelpNbFooter] = useState({ value: '00' });
    const [currentIncH, setCurrentIncH] = useState(0);
    const [currentInNH, setCurrentInNH] = useState(0);
    const { shouldDisplayForm, displayFormToggle } = DisplayForm();
    const connectedUser = useSelector((state) => state.user);
    const onSubmit = (formdata) => {
        const message = formdata.message;
        const counterService = new ApiService(PACKMEN);
        if (currentIncH !== 0) {
            counterService
                .create({ society: data.id, author: connectedUser.data.id, value: currentIncH, comment: message, help: true })
                .then(() => {
                editClientNotif.success();
                setCurrentIncH(0);
                setLoaded(true);
                displayFormToggle();
                if (callback)
                    callback();
            })
                .catch(e => {
                console.error(e);
                setLoaded(true);
                editClientNotif.failed();
            });
        }
        if (currentInNH !== 0) {
            counterService
                .create({ society: data.id, author: connectedUser.data.id, value: currentInNH, comment: message, help: false })
                .then(() => {
                editClientNotif.success();
                setCurrentInNH(0);
                setLoaded(true);
                displayFormToggle();
                if (callback)
                    callback();
            })
                .catch(e => {
                console.error(e);
                setLoaded(true);
                editClientNotif.failed();
            });
        }
    };
    const counterSup999Alert = () => {
        const MySwal = withReactContent(Swal);
        return MySwal.fire({
            title: "",
            text: t("counter:counter.overflow"),
            customClass: {
                popup: "swallModal-popup",
                content: "swallModal-container",
                actions: "swallModal-actions form-bottom-button",
                confirmButton: "swallModal-orangeButton secondButton btn btn-secondary",
                cancelButton: "swallModal-blueButton firstButton btn btn-secondary",
            },
            showCancelButton: false,
        });
    };
    const addValue = (value, type) => {
        if (!shouldDisplayForm)
            displayFormToggle();
        if (isNaN(value))
            return;
        if (type === 1) {
            if (nbHelp + value > 999) {
                counterSup999Alert();
                return;
            }
            setCurrentIncH(currentIncH + value);
            setNbHelp(nbHelp + value);
        }
        else {
            if (nbHelp + value > 999) {
                counterSup999Alert();
                return;
            }
            setCurrentInNH(currentInNH + value);
            setNbNoHelp(nbNoHelp + value);
        }
        return;
    };
    const cancelCounter = () => {
        setNbNoHelp(nbpackNoHelp);
        setNbHelp(nbPackHelp);
        setHelpNbFooter(prevalue => {
            return { ...prevalue, value: "00" };
        });
        setNoHelpFooter(prevalue => {
            return { ...prevalue, value: "00" };
        });
        displayFormToggle();
    };
    const validateComment = (value) => {
        if (value)
            return undefined;
        else {
            return t("counter:counter.note_validation");
        }
    };
    return (React.createElement("div", { className: "counters-container" },
        React.createElement("span", { className: "header thinTitle" }, t("counter:counter.main")),
        !loaded && React.createElement(Loading, { className: 'load-zIndexMin' }),
        React.createElement(Form, { onSubmit: onSubmit, render: ({ handleSubmit }) => {
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(ButtonBottomForm, { toggle: cancelCounter, display: shouldDisplayForm },
                        React.createElement(Fragment, null,
                            React.createElement("div", { className: "counters-wrapper counters-wrapper-row  br-10 " },
                                React.createElement(CounterWithBtn, { subtitle: "counter:counter.with", number: nbHelp, footerNumber: nbHelpFooter, type: 1, onUpdate: addValue, cssType: "big" }),
                                React.createElement("span", { className: "separator separator-vertical m-0 separator-lightGray absolute" }),
                                React.createElement(CounterWithBtn, { subtitle: "counter:counter.without", number: nbNoHelp, footerNumber: nbNoHelpFooter, type: 0, onUpdate: addValue, cssType: "big" })),
                            shouldDisplayForm && (React.createElement("div", { className: "form-content counter-field" },
                                React.createElement(FFTextArea, { name: "message", validate: validateComment, label: t("form:comment.comment") })))))));
            } })));
};
export default CounterContainer;
