import React, { Fragment } from "react";
import { ImagePlaceholder } from "../Image/Image";
import { addHTTPS } from "../../Shared/utils/utils";
const RoundedPhoto = ({ imgUrl, photoClassNames = "big" }) => {
    return (React.createElement("div", { className: "img roundedPhoto" },
        React.createElement(Fragment, null,
            React.createElement("div", { className: `freelancePicture picture-container picture-container-${photoClassNames} ${imgUrl ? "picture-container-img" : ""}`, style: {
                    backgroundImage: `url(${addHTTPS(imgUrl)})`,
                } }, !imgUrl && React.createElement(ImagePlaceholder, { size: "bigSubtitle" })))));
};
export default RoundedPhoto;
