import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setNotifDashboardPanelReload, setNotifHeaderPanelReload } from "../../../redux/reloader/action";
import { formatUserName } from "../../../helper/formatUserName";
import app from './feathers';
export const useLastThreeNotifications = () => {
    const user = useSelector((state) => state.user.data);
    const notifHeaderReload = useSelector((state) => state.reloader.notifHeaderReload);
    const [notifications, setNotifications] = useState([]);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const findAndUpdateNotif = (updatedNotification) => {
        const updatedNotifications = [...notifications];
        let idx = notifications.findIndex((n) => n._id === updatedNotification._id);
        if (idx > -1) {
            updatedNotifications.splice(idx, 1);
            setNotifications(updatedNotifications);
        }
    };
    const markNotificationAsRead = (notification) => {
        const updatedNotification = { ...notification };
        const updatedTos = notification.tos.map((t) => {
            if (t.sub === `${user._id}`) {
                t.status = t.status === 'toread' ? 'readed' : 'toread';
            }
            return t;
        });
        updatedNotification.tos = updatedTos;
        app.io.emit('patch', 'messages', notification._id, { tos: updatedTos }, (error, results) => {
            findAndUpdateNotif(results);
        });
    };
    const fetchNotifications = async () => {
        setIsLoading(true);
        app.io.emit('find', 'messages', { $limit: 3, "tos.sub": user._id, "tos.status": 'toread', canalPush: true, $sort: { date: -1 } }, (error, results) => {
            if (error) {
                console.log("Error while fetching notification::", error);
                setIsLoading(false);
                return;
            }
            const data = results.data.filter((d) => d.hasOwnProperty('canalPush') && d.canalPush);
            setNotifications(data);
            setIsLoading(false);
        });
    };
    useEffect(() => {
        fetchNotifications();
    }, []);
    const updatedMessage = (_message) => {
        fetchNotifications();
    };
    useEffect(() => {
        app.service('messages')
            .on('created', updatedMessage);
        return () => app.service('messages').removeListener('created', updatedMessage);
    }, []);
    useEffect(() => {
        if (!notifHeaderReload)
            return;
        fetchNotifications();
        dispatch(setNotifHeaderPanelReload(false));
    }, [notifHeaderReload]);
    return {
        notifications,
        fetchNotifications,
        markNotificationAsRead,
        isLoading
    };
};
export const useNotifications = (type) => {
    const user = useSelector((state) => state.user.data);
    const [resetNotif, setResetNotif] = useState(false);
    const reload = useSelector((state) => state.reloader.notifDashboardReload);
    const [filters, setFilters] = useState({ label: formatUserName(user.firstName, user.lastName), photo: user.photo, value: user._id });
    const [mode, setMode] = useState('toread');
    const [notifications, setNotifications] = useState([]);
    const [readedNotifications, setReadedNotifications] = useState([]);
    const [skip, setSkip] = useState(0);
    const [fireUseEffect, setFireUseEffect] = useState(Math.random());
    const [hasNextPage, setHasNextPage] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const formatFilters = () => {
        let defaultFilter = !type ? { "from.role": "RM" } : (type === 'client' ? { "from.role": "CLIENT" } : type === 'clientDashboard' ? { "from.role": ["RM", "FREELANCE"] } : { "from.role": "FREELANCE" });
        if (!filters)
            return { ...defaultFilter, "tos.sub": user._id };
        return {
            "tos.sub": filters.value,
            ...defaultFilter,
        };
    };
    const getOneNotification = async (id) => {
        app.io.emit('find', 'messages', { _id: id }, (error, results) => {
            if (error) {
                console.log("Error while fetching one notification", error);
            }
            if (results && results.data.length > 0) {
                if (results.data[0].hasOwnProperty('canalPush'))
                    setSelectedNotification(results.data[0]);
            }
        });
    };
    const findAndUpdateNotif = (updatedNotification) => {
        const updatedNotifications = [...notifications];
        const updatedReadedNotifications = [...readedNotifications];
        let idx = notifications.findIndex((n) => n._id === updatedNotification._id);
        if (idx > -1) {
            updatedNotifications[idx] = updatedNotification;
            setNotifications(updatedNotifications);
            return;
        }
        idx = updatedReadedNotifications.findIndex((n) => n._id === updatedNotification._id);
        if (idx > -1) {
            updatedNotifications[idx] = updatedNotification;
            setReadedNotifications(updatedNotifications);
            return;
        }
    };
    const switchNotifToReadNotifInList = (notification) => {
        const updatedNotifications = [...notifications];
        const updatedReadedNotifications = [...readedNotifications];
        let idx = notifications.findIndex((n) => n._id === notification._id);
        if (idx === -1)
            return;
        const newNotif = { ...notifications[idx] };
        updatedNotifications.splice(idx, 1);
        updatedReadedNotifications.push(newNotif);
        setNotifications(updatedNotifications);
        setReadedNotifications(updatedReadedNotifications);
    };
    const removeNotif = (notification) => {
        const updatedNotifications = [...notifications];
        const updatedReadedNotifications = [...readedNotifications];
        let idx = updatedReadedNotifications.findIndex((n) => n._id === notification._id);
        if (idx > -1) {
            updatedReadedNotifications.splice(idx, 1);
            setReadedNotifications(updatedReadedNotifications);
            return;
        }
        let notifIdx = updatedNotifications.findIndex((n) => { return n._id === notification._id; });
        if (notifIdx > -1) {
            updatedNotifications.splice(notifIdx, 1);
            setNotifications(updatedNotifications);
            return;
        }
    };
    const switchReadToNotifInList = (notification) => {
        const updatedNotifications = [...notifications];
        const updatedReadedNotifications = [...readedNotifications];
        let idx = updatedReadedNotifications.findIndex((n) => n._id === notification._id);
        if (idx === -1)
            return;
        const newNotif = { ...updatedReadedNotifications[idx] };
        updatedReadedNotifications.splice(idx, 1);
        updatedNotifications.push(newNotif);
        setNotifications(updatedNotifications);
        setReadedNotifications(updatedReadedNotifications);
    };
    const switchNotificationAsRead = (notification) => {
        let hasChanged = false;
        let typeChange = 'toread';
        const updatedNotification = { ...notification };
        const updatedTos = notification.tos.map((t) => {
            if (t.sub === `${user._id}`) {
                hasChanged = true;
                t.status = t.status === 'toread' ? 'readed' : 'toread';
                typeChange = t.status === 'toread' ? 'readed' : 'toread';
            }
            return t;
        });
        if (!hasChanged)
            return;
        updatedNotification.tos = updatedTos;
        typeChange === 'toread' ? switchNotifToReadNotifInList(updatedNotification) : switchReadToNotifInList(updatedNotification);
        app.io.emit('patch', 'messages', notification._id, { tos: updatedTos }, (_error, _results) => {
            dispatch(setNotifHeaderPanelReload(true));
        });
    };
    const markNotificationAsRead = (notification) => {
        let hasChanged = false;
        let typeChange = 'toread';
        const updatedNotification = { ...notification };
        const updatedTos = notification.tos.map((t) => {
            if (t.sub === `${user._id}` && t.status === 'toread') {
                hasChanged = true;
                typeChange = t.status === 'toread' ? 'readed' : 'toread';
                t.status = 'readed';
            }
            return t;
        });
        if (!hasChanged)
            return;
        updatedNotification.tos = updatedTos;
        typeChange === 'toread' ? switchNotifToReadNotifInList(updatedNotification) : switchReadToNotifInList(updatedNotification);
        app.io.emit('patch', 'messages', notification._id, { tos: updatedTos }, (_error, _results) => {
            dispatch(setNotifHeaderPanelReload(true));
        });
    };
    const onRemoveNotification = (e, notification) => {
        e.stopPropagation();
        const updatedNotification = { ...notification };
        const updatedTos = notification.tos.map((t) => {
            if (t.sub === `${user._id}`) {
                t.status = 'archived';
            }
            return t;
        });
        updatedNotification.tos = updatedTos;
        app.io.emit('patch', 'messages', notification._id, { tos: updatedTos }, (_error, _results) => {
            removeNotif(notification);
            dispatch(setNotifHeaderPanelReload(true));
        });
    };
    const fetchNotifications = async () => {
        setIsLoading(true);
        app.io.emit('find', 'messages', { $skip: skip, ...formatFilters(), "tos.status": mode, canalPush: true, $sort: { date: -1 } }, (error, results) => {
            if (error) {
                console.log("Echec de la récupération des notifications::", error);
                setIsLoading(false);
                return;
            }
            const totalItems = results.total;
            const data = results.data.filter((d) => d.hasOwnProperty('canalPush') && d.canalPush);
            let newData = [];
            if (mode === 'toread') {
                newData = skip > 0 && !resetNotif ? [...notifications, ...data] : data;
                setSkip(newData.length);
                setHasNextPage(newData.length < totalItems);
                if (newData.length >= totalItems) {
                    setMode('readed');
                    setSkip(0);
                    if (newData.length === 0) {
                        setNotifications([]);
                        setFireUseEffect(Math.random());
                        return;
                    }
                    setHasNextPage(true);
                }
                setNotifications(newData);
            }
            else {
                newData = skip > 0 && !resetNotif ? [...readedNotifications, ...data] : data;
                setSkip(newData.length);
                setHasNextPage(newData.length < totalItems);
                setReadedNotifications(newData);
                setResetNotif(false);
            }
            setIsLoading(false);
        });
    };
    const updatedMessage = (_message) => {
        dispatch(setNotifDashboardPanelReload(true));
        dispatch(setNotifHeaderPanelReload(true));
    };
    useEffect(() => {
        app.service('messages')
            .on('created', updatedMessage);
        return () => app.service('messages').removeListener('created', updatedMessage);
    }, []);
    const reloadData = () => {
        setNotifications([]);
        setReadedNotifications([]);
        if (selectedNotification) {
            getOneNotification(selectedNotification.id);
        }
        setMode('toread');
        setSkip(0);
        setFireUseEffect(Math.random());
    };
    const loadNextPage = () => {
        setFireUseEffect(Math.random());
    };
    const updateFilters = (value) => {
        setFilters(value.lastName_notif);
    };
    useEffect(() => {
        if (filters !== null) {
            setNotifications([]);
            setReadedNotifications([]);
            setMode('toread');
            setSkip(0);
            setFireUseEffect(Math.random());
        }
    }, [filters]);
    useEffect(() => {
        if (!reload)
            return;
        reloadData();
        dispatch(setNotifDashboardPanelReload(false));
    }, [reload]);
    useEffect(() => {
        if (!resetNotif)
            return;
        setMode('toread');
        setSkip(0);
        setFireUseEffect(Math.random());
    }, [resetNotif]);
    useEffect(() => {
        if (!app)
            return;
        fetchNotifications();
    }, [fireUseEffect]);
    return {
        notifications,
        markNotificationAsRead,
        switchNotificationAsRead,
        updateFilters,
        onRemoveNotification,
        readedNotifications,
        isLoading,
        filters,
        hasNextPage,
        getOneNotification,
        loadNextPage,
        reloadData,
        setFilters,
        setSelectedNotification,
        selectedNotification,
    };
};
