import React, { Fragment } from "react";
import { Field } from "react-final-form";
import { WhFieldTxt, WhValidator, } from "../../../../Components/WHForm/WhFields";
import { useTranslation } from "react-i18next";
import { WhButton, WhH2 } from "../../../../Components/WHComponents";
import GoogleMapPlaces from "../../../../Components/GoogleMap/GoogleMap";
import FFSelect from "../../../../Components/FinalForm/FFSelect";
import { formatUserName } from "../../../../helper/formatUserName";
const ColLeftForm = ({ expertises, data, mutators }) => {
    const { profile: { address, job, tjm, availability, expertise }, } = data;
    const { t } = useTranslation("freelance");
    return (React.createElement("div", { className: "freelanceLeftForm" },
        React.createElement("div", { className: "boldInputText" },
            React.createElement(Field, { name: "job", validate: WhValidator.required }, (props) => (React.createElement(WhFieldTxt, Object.assign({ name: "job" }, props, { label: false, type: "text", maxLength: 120, className: `${job
                    ? "text-label-input text-label-input-disabled"
                    : "text-label-input"}` }))))),
        React.createElement(WhH2, { light: true },
            " ",
            formatUserName(data.firstName, data.lastName),
            " "),
        React.createElement("hr", null),
        React.createElement("div", { className: "select-row" },
            React.createElement("span", { className: "icon-date size-subtitle flex-directionColumn flex-justifyCenter mr-15" }),
            React.createElement("div", { className: "select-body freelance_dashboard_input" },
                React.createElement(Field, { name: "availability", validate: WhValidator.required }, (props) => {
                    return (React.createElement(WhFieldTxt, Object.assign({ name: "availability" }, props, { label: `${t("colLeft.myAvailability")}*`, className: `${availability
                            ? "text-label-input text-label-input-disabled"
                            : "text-label-input"}`, type: "date", componentName: 'UpdateDate', iconDate: false })));
                }))),
        React.createElement("div", { className: "select-row" },
            React.createElement("span", { className: "icon-tjm size-subtitle flex-directionColumn flex-justifyCenter mr-15" }),
            React.createElement("div", { className: "select-body freelance_dashboard_input" },
                React.createElement(Field, { name: "tjm", type: "text", validate: WhValidator.required }, (props) => (React.createElement(WhFieldTxt, Object.assign({ name: "tjm" }, props, { className: `${tjm
                        ? "text-label-input text-label-input-disabled"
                        : "text-label-input"}`, label: `${t("colLeft.myTdm")}*`, type: "text" })))))),
        React.createElement("div", { className: "select-row" },
            React.createElement("span", { className: "icon-lieu size-subtitle flex-directionColumn flex-justifyCenter mr-15" }),
            React.createElement("div", { className: "select-body freelance_dashboard_input" },
                React.createElement(Field, { name: "address.mobilityCity", validate: WhValidator.required }, (props) => {
                    return (React.createElement(GoogleMapPlaces, { input: props.input, type: "mission", label: `${t("colLeft.move")}*`, defaultCity: address.mobilityCity, inputClassName: `${address.mobilityCity
                            ? "castorForm-inputContainer mid text-label-input text-label-input-disabled googleField"
                            : "castorForm-inputContainer mid text-label-input googleField"}`, onUpdate: mutators.setPlaces },
                        React.createElement(Fragment, null, props.meta.touched && props.meta.error && (React.createElement("span", { className: "form__form-group-error formError" }, props.meta.error)))));
                }))),
        React.createElement("div", { className: "select-row" },
            React.createElement("img", { src: "/img/cible.svg", className: `mr-15`, style: { position: "relative", width: "25px", height: "auto" } }),
            React.createElement("div", { className: "select-body freelance_dashboard_input" },
                React.createElement(Field, { name: "address.mobilityRayon", validate: WhValidator.required }, (props) => (React.createElement(WhFieldTxt, Object.assign({ name: "mobilityRayon" }, props, { className: `${address.mobilityRayon
                        ? "text-label-input text-label-input-disabled"
                        : "text-label-input"}`, type: "text", label: `${t("colLeft.radius")}*` })))))),
        React.createElement("div", { className: "select-row" },
            React.createElement("span", { className: "icon-xp size-subtitle flex-directionColumn flex-justifyCenter mr-15" }),
            React.createElement("div", { className: "select-body freelance_dashboard_input--select" },
                React.createElement(FFSelect, { name: "expertise", validate: WhValidator.required, isSearchable: true, format: (value) => expertises.find((n) => n.value === value), parse: (value) => value.value, className: `${expertise ? "select-label-input-disabled" : ""}`, label: `${t("colLeft.myExperience")}*`, options: expertises }))),
        React.createElement("div", { className: `form-bottom-button` },
            React.createElement(WhButton, { classNames: "size-small", submit: true, color: "default" }, t("colLeft.save")))));
};
export default ColLeftForm;
