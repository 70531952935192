import React, { useState, useEffect, Fragment } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { addHTTPS } from "../../Shared/utils/utils";
import { ImagePlaceholder } from "../Image/Image";
import Loading from "../Loading";
const FileField = ({ onChange, iconType, name, disabled = false, value, subLabel, className, }) => {
    const [message, setMessage] = useState(value ? value : "Selectionner un fichier");
    const [inputKey, setInputKey] = useState(Date.now());
    const { t } = useTranslation("document");
    const getUploadedFileName = e => {
        if (e) {
            let files = e.target.files, value = e.target.value, message;
            if (files && files.length > 1)
                message = `${files.length} files selected`;
            else
                message = value.split("\\").pop();
            if (message) {
                setMessage(value.split("\\").pop());
            }
        }
        else {
            setInputKey(Date.now());
            if (message) {
                setMessage("Selectionner un fichier");
            }
            if (onChange)
                onChange(null);
        }
    };
    const cvDocClass = classNames("inputFileContainer", {
        active: message !== "Selectionner un fichier"
    });
    const onIputFileChange = event => {
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.onload = event => {
            onChange({
                content: event.target.result,
                name: file.name,
                file: file,
                type: file.type
            });
        };
        reader.readAsDataURL(file);
        getUploadedFileName(event);
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: className ? className : "" },
            React.createElement("input", { id: name, type: "file", className: "hiddenFileInput", "data-multiple-caption": message, name: name, disabled: disabled, key: inputKey, onChange: () => onIputFileChange(event) }),
            React.createElement("label", { className: `flex-directionRow flex-directionRowMobile ${cvDocClass}` },
                React.createElement("i", { className: `mr-10 size-subtitle icon-${iconType}` }),
                React.createElement("span", { className: `flex flex-alignCenter flex-justifyBetween` },
                    React.createElement("p", { className: `weight-bold flex-selfCenter mb-0` }, message),
                    React.createElement("i", { onClick: () => {
                            if (window.confirm("Voulez vous supprimer le fichier ?"))
                                getUploadedFileName(null);
                        }, className: `circle circle-tiny circle-transparent icon-close size-tiniest ml-10 circle-border-gray` }))),
            React.createElement("label", { className: "roundBlock-addBtn", htmlFor: name },
                t("selectFile"),
                subLabel ? (' ' + subLabel) : null,
                React.createElement("span", { className: "circle circle-gray circle-sm circle-absolute" },
                    React.createElement("i", { className: "icon-plus_1 size-small" }))))));
};
const renderCustomFileField = ({ input, label = "", subLabel = "", iconType = "profil", disabled = false, className }) => {
    return (React.createElement(FileField, Object.assign({}, input, { label: label, iconType: iconType, disabled: disabled, value: input.value, className: className, subLabel: subLabel })));
};
const FileImgField = ({ onChange, name, disabled = false, imgAsInput = false, isLoading = false, disablePositionRelative = false, label, value, roundedImage = false }) => {
    const [message, setMessage] = useState(value ? value : "Selectionner un fichier");
    const [inputKey, setInputKey] = useState(Date.now());
    const [backgroundUrl, setBackgroundUrl] = useState(undefined);
    useEffect(() => {
        if (value.content) {
            setBackgroundUrl(addHTTPS(value.content));
        }
    }, [value.content]);
    const getUploadedFileName = e => {
        if (e) {
            let files = e.target.files, value = e.target.value, message;
            if (files && files.length > 1)
                message = `${files.length} files selected`;
            else
                message = value.split("\\").pop();
            if (message)
                setMessage(value.split("\\").pop());
        }
        else {
            setInputKey(Date.now());
            if (message)
                setMessage("Selectionner un fichier");
            if (onChange)
                onChange(null);
        }
    };
    const onIputFileChange = event => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.onload = event => {
            onChange({
                content: event.target.result,
                name: file.name,
                file: file,
                type: file.type
            });
        };
        reader.readAsDataURL(file);
        getUploadedFileName(event);
    };
    return (React.createElement("div", { className: "logo", style: !disablePositionRelative ? { "position": "relative" } : {} },
        isLoading && React.createElement(Loading, { className: "load-zIndexMin" }),
        !imgAsInput ? (React.createElement(Fragment, null,
            React.createElement("div", { className: "img" },
                value && backgroundUrl && React.createElement("img", { src: backgroundUrl }),
                !backgroundUrl && React.createElement(ImagePlaceholder, { size: "big" })),
            React.createElement("input", { id: name, type: "file", className: "hiddenFileInput", "data-multiple-caption": message, name: name, disabled: disabled, key: inputKey, onChange: () => onIputFileChange(event) }),
            React.createElement("label", { htmlFor: name, className: "littleTextButton" },
                React.createElement("span", null, label)))) : (React.createElement("div", { className: "img" },
            value && (React.createElement(Fragment, null, roundedImage ? (React.createElement("div", { className: "picture-container" },
                React.createElement("div", { className: "picture", style: {
                        backgroundImage: `url(${backgroundUrl})`
                    } }, !backgroundUrl &&
                    React.createElement(ImagePlaceholder, { size: "bigSubtitle" })))) : (React.createElement("img", { src: backgroundUrl })))),
            React.createElement("input", { id: name, type: "file", src: value.content, className: "hiddenFileInput", "data-multiple-caption": message, name: name, disabled: disabled, key: inputKey, onChange: () => onIputFileChange(event) }),
            React.createElement("label", { htmlFor: name, className: "littleTextButton icon-edit" },
                React.createElement("span", { className: "ml-10" }, label))))));
};
export const RenderImgFileUpload = ({ input, label = "", imgAsInput = false, disabled = false, disablePositionRelative = false, isLoading = false, roundedImage = false }) => {
    return (React.createElement(FileImgField, Object.assign({}, input, { label: label, disablePositionRelative: disablePositionRelative, isLoading: isLoading, imgAsInput: imgAsInput, roundedImage: roundedImage, disabled: disabled, value: input.value })));
};
export default renderCustomFileField;
