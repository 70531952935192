import React, { useEffect } from 'react';
import { Container } from "reactstrap";
import ReactTable from "react-table-6";
import { useStored } from "../../Hooks/useStored";
import { columns } from "./packManColumns";
import { MAX } from "../../Shared/maxResults";
const PackManList = ({ id, nbPackHelp, nbpackNoHelp }) => {
    const PackSTORED = {
        api: process.env.REACT_APP_IDENTITY_GQL_URL
            ? process.env.REACT_APP_IDENTITY_GQL_URL
            : "",
        table: "packMen",
        entity: "PackMan",
        list: true,
        query: {
            order: "DESC",
            orderBy: { name: "created" },
            fields: [
                "comment",
                "value",
                "help",
                "created",
                "author{id _id firstName lastName photo{id _id content}}"
            ],
            maxResults: MAX,
            page: 0,
            filters: [
                {
                    name: "society",
                    value: `${id}`,
                }
            ],
        },
    };
    const { data, loaded, pageSize, pages, loadData } = useStored(PackSTORED, "clientList");
    useEffect(() => {
        loadData();
    }, [nbpackNoHelp, nbPackHelp]);
    return (React.createElement(Container, { className: 'packmanList' },
        React.createElement(ReactTable, { manual: true, data: data, TheadComponent: () => null, columns: columns, showPagination: false, loading: !loaded, pageSize: pageSize, pages: pages })));
};
export default PackManList;
