import React, { Fragment, useEffect, useState } from "react";
import * as session from "../../Shared/session/index";
import { SubmissionError } from "redux-form";
import * as api from "../../Shared/session/api";
import { setUser, singleUserKey } from "./../../redux/user/action";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router";
import Loading from "../../Components/Loading";
import { withRouter } from "react-router-dom";
import { ROLES } from "../../constants/roles";
import { authFeathers } from "../RessourceManager/Notifications/feathers";
import { setSectorsThunk } from "../../redux/sector/action";
import { fetchParameters } from "../../redux/params/action";
import { getSingleObjectOnlyKeys } from "../../Services/Rest/getOnlyKeys";
import { setSocietyThunk } from "../../redux/society/action";
import { setLang } from "../../redux/lang/actions";
import * as MaintenanceService from "../../Services/MaintenanceService";
const Login = ({ history, match }) => {
    const dispatch = useDispatch();
    const [userError, setUserError] = useState(false);
    const [userRole, setUserRole] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [fAuthenticated, setFAuthenticated] = useState(false);
    useEffect(() => {
        loginUser();
    }, []);
    const loginUser = async () => {
        try {
            await session.login(match.params.token);
            connectUser();
        }
        catch (error) {
            setUserError(true);
            throw new SubmissionError({ _error: error });
        }
    };
    const connectUser = async () => {
        try {
            await api.me().then((data) => {
                dispatch(setSectorsThunk());
                dispatch(fetchParameters());
                const newObj = getSingleObjectOnlyKeys(singleUserKey, data);
                dispatch(setUser(newObj));
                authFeathers().then(() => {
                    setFAuthenticated(true);
                }).catch((e) => {
                    console.log("Erreur lors de l'authentification feathers", e);
                    setFAuthenticated(true);
                });
                dispatch(setSocietyThunk(newObj._id));
                dispatch(setLang("fr"));
                authFeathers();
                setUserRole(data.roles);
                setLoaded(true);
            });
        }
        catch (error) {
            setUserError(true);
        }
    };
    const goToLoginPage = () => {
        const urlParams = new URLSearchParams(history.location.search);
        const myParam = urlParams.get("redirectUrl");
        try {
            MaintenanceService
                .check()
                .then((data) => {
                if (data.api || data.connect) {
                    window.location.href =
                        `${process.env.REACT_APP_MAINTENANCE_SERVICE}`;
                    return;
                }
                else {
                    var redirectTo = `${process.env.REACT_APP_WWW_HOST}/fr/me-connecter/`;
                    if (myParam) {
                        redirectTo += "?redirectUrl=" + myParam;
                    }
                    window.location.href = redirectTo;
                    return;
                }
            })
                .catch(async () => {
            });
        }
        catch (error) {
            console.log("maintenance error", error);
        }
    };
    const getDashBoard = () => {
        const urlParams = new URLSearchParams(history.location.search);
        const myParam = urlParams.get("redirectUrl");
        if (myParam) {
            window.location.href = myParam;
            return;
        }
        if ([ROLES.RM].some((r) => userRole.indexOf(r) >= 0)) {
            return React.createElement(Redirect, { to: "/rm/dashboard/freelance" });
        }
        else if ([ROLES.CLIENT].some((r) => userRole.indexOf(r) >= 0))
            return React.createElement(Redirect, { to: "/client/dashboard" });
        else if ([ROLES.FREE].some((r) => userRole.indexOf(r) >= 0)) {
            const urlParams = new URLSearchParams(history.location.search);
            const myParam = urlParams.get("redirectUrl");
            var redirectTo = `${process.env.REACT_APP_WWW_HOST}/fr/me-connecter/`;
            if (myParam) {
                redirectTo += "?redirectUrl=" + myParam;
            }
            window.location.href = redirectTo;
            return;
        }
        else {
            return React.createElement(Redirect, { to: "/auth_error" });
        }
    };
    return (React.createElement(Fragment, null,
        userError && goToLoginPage(),
        React.createElement(Fragment, null, !userError && fAuthenticated && loaded && getDashBoard()),
        !loaded && React.createElement(Loading, null)));
};
export default withRouter(Login);
