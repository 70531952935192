import { useState, useEffect } from 'react';
import { MAX } from "../../../Shared/maxResults";
import GraphQl from "../../../Shared/graphql";
import { formatGraphQlData } from "../../../Shared/rewriteArray";
const useGetMission = (id) => {
    const [isLoading, setIsLoading] = useState(false);
    const [mission, setMission] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const [reload, setReload] = useState(false);
    useEffect(() => {
        if (!id)
            return;
        if (isLoading)
            return;
        loadSingleMission(id);
    }, [id]);
    useEffect(() => {
        if (!reload)
            return;
        if (isLoading)
            return;
        loadSingleMission(id);
        setReload(false);
    }, [reload]);
    const loadSingleMission = async (missionId) => {
        let config = {
            api: process.env.REACT_APP_IDENTITY_GQL_URL
                ? process.env.REACT_APP_IDENTITY_GQL_URL
                : "",
            table: "mission",
            entity: "Mission",
            list: false,
            query: {
                maxResults: MAX,
                fields: [
                    "id",
                    "_id",
                    "title",
                    "job",
                    "hiddenSociety",
                    "description",
                    "public",
                    "startDate",
                    "endDate",
                    "duration",
                    "startNow",
                    "expertise",
                    "publicationDate",
                    "comment",
                    "activitySector{id, _id, name}",
                    "nbModification",
                    "geometry{id _id latitude longitude}",
                    "canceledReason",
                    "geometry{id _id}",
                    "city",
                    "tjm",
                    "tags(first:20){edges{node{_id id status name}}}",
                    "society{id _id name photo{_id id content} affiliate mainContact{firstName lastName}}",
                    "candidatures(first:500){edges{node{id, _id, statusRm  statusClientToValid indicationFl indicationFlNew statusClient, statusFl, user{id, _id, firstName lastName civilityLabel photo{_id content} profile{job}}}}}",
                    "missionStatus{statusRm statusClient indicationClient indicationRm subStatusClient subStatusRm}",
                    "helped",
                    "updatedBy{id _id firstName lastName photo{_id id content}}"
                ],
                filters: [
                    {
                        value: `api/missions/${missionId}`,
                        name: "id",
                    },
                ],
            }
        };
        setIsLoading(true);
        const res = await GraphQl(config);
        if (res.datas) {
            setMission(formatGraphQlData(res.datas));
        }
        else {
            setNotFound(true);
        }
        setIsLoading(false);
    };
    return {
        mission,
        isLoading,
        notFound,
        setReload
    };
};
export default useGetMission;
