import React, { useEffect } from "react";
import { useMachine } from "@xstate/react";
import { Machine } from "xstate";
import { useStored } from "../../Hooks/useStored";
import { withRouter } from "react-router-dom";
import Loading from "../../Components/Loading";
import Mission from "./Mission";
import { MAX } from "../../Shared/maxResults";
import { getMissiongFieldPreProd, getMissiongFieldProd } from "./getMissionFieldsConfig";
const MissionApiMachine = Machine({
    id: "getData",
    initial: "pending",
    states: {
        pending: {
            on: { LOADED: "loaded", ERROR: "error" },
        },
        loaded: {
            type: "final",
        },
        error: {
            type: "final",
        },
    },
});
const GetMissionData = ({ match, history }) => {
    const [current, send] = useMachine(MissionApiMachine);
    const MISSIONSTORED = {
        api: process.env.REACT_APP_IDENTITY_GQL_URL
            ? process.env.REACT_APP_IDENTITY_GQL_URL
            : "",
        table: "mission",
        entity: "Mission",
        list: false,
        query: {
            maxResults: MAX,
            fields: process.env.NODE_ENV === 'production' ? getMissiongFieldProd : getMissiongFieldPreProd,
            filters: [
                {
                    value: `api/missions/${match.params.missionId}`,
                    name: "id",
                },
            ],
        },
    };
    const { data, loadData, firstLoaded, error, isLoading } = useStored(MISSIONSTORED, "");
    useEffect(() => {
        if (!firstLoaded)
            return;
        send("LOADED");
    }, [firstLoaded]);
    useEffect(() => {
        if (!error)
            return;
        send("ERROR");
    }, [error]);
    const redirect = () => {
        history.goBack();
    };
    const goToProfile = (id) => {
        history.push(`/rm/freelances/freelance_edit/${id}`);
    };
    return (React.createElement("div", { className: `rightContainerWithoutTable rightContainer--withHeadFilters mission-view` },
        current.matches("pending") && React.createElement(Loading, null),
        isLoading && React.createElement(Loading, { className: "load-zIndexMin" }),
        current.matches("loaded") && (React.createElement(Mission, { mission: data, goToProfile: goToProfile, userRole: "ROLE_ADMIN", callback: loadData, redirect: redirect })),
        current.matches("error") && React.createElement("div", null, "LOAD ERREUR")));
};
export default withRouter(GetMissionData);
