import React, { Fragment, useState, useEffect } from 'react';
import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-xhr-backend';
export const I18Load = ({ render }) => {
    const [hasBeenInit, setHasBeenInit] = useState(false);
    useEffect(() => {
        i18n.use(Backend).use(initReactI18next).init({
            fallbackLng: 'en',
            initImmediate: false,
            preload: ["en", "fr"],
            lng: 'fr',
            react: {
                useSuspense: false
            },
            debug: false,
            interpolation: {
                escapeValue: false
            }
        }).then(() => {
            console.log("i18 has been load");
            setHasBeenInit(true);
        }).catch((e) => {
            console.log("Failed loading i18", e);
            setHasBeenInit(true);
        });
    }, []);
    return (React.createElement(Fragment, null, hasBeenInit && render()));
};
