import React, { useEffect } from "react";
import { Container } from "reactstrap";
import { Form } from "react-final-form";
import FFInformationSociety from "./FFInformationSociety";
import FFLogoCreate from "./FFLogoCreate";
import { useSelector } from "react-redux";
import FFContact from "./Edit/FFContact";
const FFCreateClient = ({ onSubmit, close }) => {
    const sectors = useSelector((state) => state.sectors.sectors);
    const lang = useSelector((state) => state.lang);
    useEffect(() => {
    }, [sectors]);
    const mutators = {
        setPlaces: (_args, state, utils) => {
            const countries = lang.countries;
            const country = countries.find((c) => c.label === _args[4]);
            utils.changeValue(state, 'address.address', () => _args[0] + ' ' + _args[2]);
            utils.changeValue(state, 'address.zipCode', () => _args[3]);
            utils.changeValue(state, 'address.city', () => _args[1]);
            if (country)
                utils.changeValue(state, 'address.country', () => country);
        }
    };
    return (React.createElement(Container, { className: "rm-client-create-form" },
        React.createElement(Form, { onSubmit: onSubmit, mutators: { ...mutators }, render: ({ handleSubmit, form: { mutators } }) => {
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(FFLogoCreate, { label: "logo", position: "bottom", imgAsInput: false }),
                    React.createElement(FFInformationSociety, { error: null, loaded: false, title: "society.header", disabled: false, mutators: mutators, countries: lang.countries, sectors: sectors.map(s => ({ label: s.name, value: s.id })) }),
                    React.createElement("hr", { className: `mt-20 mb-20` }),
                    React.createElement(FFContact, { error: null, disabled: false, isCreate: true, title: "Informations du contact principal" }),
                    React.createElement("div", { className: "btn-container flex-row" },
                        React.createElement("button", { onClick: () => close(false) }, "Annuler"),
                        React.createElement("button", { type: "submit" }, "Envoyer la demande d'inscription"))));
            } })));
};
export default FFCreateClient;
