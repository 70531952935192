import React, { Fragment } from "react";
import NewClientList from "../RessourceManager/Client/ClientList/NewClientList";
import ClientList from "../RessourceManager/Client/ClientList/ClientList";
import ClientEdit from "../RessourceManager/Client/ClientEdit";
import FreelanceEdit from "../RessourceManager/Freelance/FreelanceEdit";
import { Route, Switch } from "react-router-dom";
import MenuSideBar from "../Layout/MenuSideBar/MenuSideBar";
import Statistics from "../RessourceManager/Statistics";
import ManagerView from "../RessourceManager/Managers/View/index";
import MissionListValidate from "../RessourceManager/Mission/MissionLists/MissionListComponents/MissionListValidate";
import MissionListProposition from "../RessourceManager/Mission/MissionLists/MissionListComponents/MissionListProposition";
import GetMissionData from "../Missions/GetMissionData";
import RmAccount from "../RessourceManager/Account/RmAccount";
import RmList from "../RessourceManager/Managers/RmList";
import MissionListES from "../RessourceManager/Mission/MissionLists/MissionListComponents/MissionListES";
import FreelanceListES from "../RessourceManager/Freelance/FreelanceListES/FreelanceListES";
import { NewFreelanceListES } from "../RessourceManager/Freelance/NewFreelanceListES/NewFreelanceListES";
import NotificationClient from "../RessourceManager/Notifications/Notifications";
const RMRoute = (props) => {
    const { user, isRmMenuOpen, toggleRmMenu } = props;
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: "mainContainer" },
            React.createElement(MenuSideBar, { permissions: user.permissions, menuOpen: isRmMenuOpen, toggleRmMenu: toggleRmMenu }),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "/rm/account", name: "account", component: RmAccount }),
                React.createElement(Route, { path: "/rm/dashboard/:type/:id?", name: "ressource_manager_main", component: NotificationClient }),
                React.createElement(Route, { path: "/rm/freelances/freelance_edit/:id", name: "ressource_manager_freelance_edit", component: FreelanceEdit }),
                React.createElement(Route, { path: "/rm/freelances/new_freelances_list", name: "ressource_manager_new_freelance_list", component: NewFreelanceListES }),
                React.createElement(Route, { path: "/rm/freelances/freelances_list", name: "ressource_manager_freelance_list", component: FreelanceListES }),
                React.createElement(Route, { path: "/rm/clients/new_clients_list", name: "ressource_manager_new_client_list", component: NewClientList }),
                React.createElement(Route, { path: "/rm/clients/new_clients_edit/:id", name: "ressource_manager_new_client_edit", component: ClientEdit }),
                React.createElement(Route, { path: "/rm/clients/clients_list", name: "ressource_manager_freelance_list", component: ClientList }),
                React.createElement(Route, { path: "/rm/clients/client_edit/:id", name: "ressource_manager_new_client_edit", component: ClientEdit }),
                React.createElement(Route, { path: "/rm/missions/validate_missions_list", name: "ressource_manager_mission_list", component: MissionListValidate }),
                React.createElement(Route, { path: "/rm/missions/missions_list", name: "ressource_manager_mission_list", component: MissionListES }),
                React.createElement(Route, { path: "/rm/missions/pending_missions_list", name: "ressource_manager_mission_list", component: MissionListProposition }),
                React.createElement(Route, { path: "/rm/missions/:missionId", name: "ressource_manager_mission_list", component: GetMissionData }),
                React.createElement(Route, { path: "/rm/managers/:id", name: "ressource_manager_mission_list", component: ManagerView }),
                React.createElement(Route, { path: "/rm/managers", name: "ressource_manager_mission_list", component: RmList }),
                React.createElement(Route, { path: "/rm/statistics", name: "statistics", component: Statistics })))));
};
export default RMRoute;
