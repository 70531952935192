import { useEffect, useState } from "react";
import ElasticService from "../Services/ElasticService";
import { MISSIONSES } from "../Services/contants";
import { EsFormatV2 } from "../Shared/utils/esformat";
const maxPageResult = 20;
export const useStoredElasticSearchMission = (defaultFilters = [], facetsConfig, sortConfig = null) => {
    const [baseFilters] = useState(defaultFilters);
    const [facets, setFacets] = useState(null);
    const [fireUseEffect, setFireUseEffect] = useState(Math.random());
    const [data, setData] = useState(null);
    const [sort, setSort] = useState(sortConfig);
    const [isLoading, setIsLoading] = useState(false);
    const [isNextPageLoading, setIsNextPageLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [requestFilters, setRequestFilters] = useState([]);
    const [defaultFiltersOverride, setDefaultFiltersOverride] = useState(null);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const getFacets = () => {
        let newFacets = {};
        facetsConfig.map((f) => {
            newFacets = {
                ...newFacets,
                [`${f.name}`]: { type: "value", size: f.size, sort: f.sort }
            };
        });
        return newFacets;
    };
    const getFilters = () => {
        const allFilters = defaultFiltersOverride
            ? [
                ...requestFilters.filter(f => f.name !== "query"),
                ...defaultFiltersOverride
            ]
            : [...requestFilters.filter(f => f.name !== "query"), ...baseFilters];
        let addedFilters = [];
        let anyAddedFilters = [];
        let Allfilters = allFilters
            .filter(f => !f.hasOwnProperty("type"))
            .map((f) => {
            if (f.hasOwnProperty("addedFilters")) {
                f.addedFilters.map(af => {
                    addedFilters.push({ [`${af.name}`]: af.value });
                });
            }
            return { [`${f.name}`]: f.value };
        });
        const NoneFilters = allFilters
            .filter(f => f.hasOwnProperty("type") && f.type === "none")
            .map((f) => ({
            [`${f.name}`]: f.value
        }));
        const AnyFilters = allFilters
            .filter(f => f.hasOwnProperty("type") && f.type === "any")
            .map((f) => {
            if (f.hasOwnProperty("addedFilters")) {
                f.addedFilters.map(af => {
                    anyAddedFilters.push({ [`${af.name}`]: af.value });
                });
            }
            return { [`${f.name}`]: f.value };
        });
        return {
            all: Allfilters.concat(addedFilters),
            none: NoneFilters,
            any: AnyFilters.concat(anyAddedFilters),
        };
    };
    const getQuery = () => {
        const idx = requestFilters.findIndex(f => f.name === "query");
        if (idx === -1)
            return "";
        return requestFilters[idx].value;
    };
    const loadData = async () => {
        setIsLoading(true);
        if (page > 1)
            setIsNextPageLoading(true);
        const missionService = new ElasticService(MISSIONSES);
        let query = getQuery();
        let req = {
            query: query,
            filters: getFilters(),
            page: { current: page, size: maxPageResult }
        };
        if (facetsConfig && facetsConfig.length > 0) {
            req = { ...req, facets: getFacets() };
        }
        if (requestFilters.length === 0 && sort) {
            req = {
                ...req,
                facets: getFacets(),
                sort: { ...sort }
            };
        }
        try {
            const result = await missionService.search(req);
            const newResult = result.hits.hits.map(r => EsFormatV2(r));
            const current = page;
            const total_results = result.hits.total.value;
            const mod = total_results % maxPageResult > 0 ? 1 : 0;
            const total_pages = Math.ceil(total_results / maxPageResult) + mod;
            let updatedFacets = {};
            result.hasOwnProperty("aggregations") &&
                Object.keys(result.aggregations).map(k => {
                    updatedFacets = {
                        ...updatedFacets,
                        [`${k}`]: [
                            {
                                type: "value",
                                data: result.aggregations[k].buckets.map(b => ({
                                    value: b.key
                                }))
                            }
                        ]
                    };
                });
            setFacets(Object.keys(updatedFacets).length > 0 ? updatedFacets : null);
            if (page === 2 && newResult.length === 0) {
                setIsLoading(false);
                setHasNextPage(false);
                return;
            }
            const newData = data && page > 1 ? [...data, ...newResult] : newResult;
            setData(newData);
            setTotalItems(total_results);
            setHasNextPage(current < total_pages);
            setIsLoading(false);
            setIsNextPageLoading(false);
        }
        catch (e) {
            console.log("Error with Req::", req);
            console.log("Error is::", e);
            setIsLoading(false);
            setHasNextPage(false);
            setIsNextPageLoading(false);
        }
    };
    const updateSort = sort => {
        setSort(sort);
    };
    const loadNextPage = () => {
        if (hasNextPage) {
            setPage(page + 1);
            setFireUseEffect(Math.random());
        }
    };
    const reloadData = () => {
        setPage(1);
        setFireUseEffect(Math.random());
    };
    useEffect(() => {
        setPage(1);
        setFireUseEffect(Math.random());
    }, [requestFilters]);
    useEffect(() => {
        setPage(1);
        setFireUseEffect(Math.random());
    }, [defaultFiltersOverride]);
    useEffect(() => {
        (async function () {
            await loadData();
        })();
    }, [fireUseEffect]);
    return {
        data,
        isLoading,
        totalItems,
        setDefaultFiltersOverride,
        hasNextPage,
        facets,
        updateSort,
        isNextPageLoading,
        setRequestFilters,
        loadNextPage,
        loadData,
        reloadData
    };
};
