import { handleActions } from 'redux-actions';
import { SET_NATIONALITIES, SET_USER_STATUSES, SET_CIVILITIES, SET_EXPERTISES, SET_MISSIONS_STATUS, SET_FL_STATUS, SET_CANDIDATURE_CL_STATUS, SET_MISSION_URL, SET_PARTNERS_URL } from "./constant";
const defaultState = {
    userStatuses: [],
    civilities: [],
    nationalities: [],
    expertises: [],
    flStatuses: [],
    candidatureClStatuses: [],
    missionsUrl: '',
    partnersUrl: ''
};
const paramsReducer = handleActions({
    [SET_USER_STATUSES]: (state, action) => ({ ...state, userStatuses: action.payload }),
    [SET_CIVILITIES]: (state, action) => ({ ...state, civilities: action.payload }),
    [SET_EXPERTISES]: (state, action) => ({ ...state, expertises: action.payload }),
    [SET_MISSIONS_STATUS]: (state, action) => ({ ...state, missionsStatus: action.payload }),
    [SET_FL_STATUS]: (state, action) => ({ ...state, flStatuses: action.payload }),
    [SET_CANDIDATURE_CL_STATUS]: (state, action) => ({ ...state, candidatureClStatuses: action.payload }),
    [SET_NATIONALITIES]: (state, action) => ({ ...state, nationalities: action.payload }),
    [SET_MISSION_URL]: (state, action) => ({ ...state, missionsUrl: action.payload }),
    [SET_PARTNERS_URL]: (state, action) => ({ ...state, partnersUrl: action.payload })
}, defaultState);
export default paramsReducer;
