import { FILTER_TYPE_SELECT_AUTOC, FILTER_TYPE_TEXT, FILTER_TYPE_SELECT, RANGE_FILTER } from "../constants";
import moment from "moment";
import { MAX } from "../../../Shared/maxResults";
const USERSTORED = {
    api: process.env.REACT_APP_IDENTITY_GQL_URL
        ? process.env.REACT_APP_IDENTITY_GQL_URL
        : "",
    table: "users",
    entity: "User",
    list: true,
    query: {
        filtersToUpdate: {
            redux: true,
            field: 'lastName',
            objectField: ['^(', 'firstName', 'lastName']
        },
        fields: [
            "id",
            "_id",
            "lastName",
            "firstName",
            "precalState",
            "profile{job, availability, tjm, expertise, qualified}",
            "type",
        ],
        maxResults: MAX,
        page: 0,
        filters: [
            {
                value: true,
                name: "profile_qualified",
            },
            {
                value: true,
                name: "enabled"
            },
            {
                name: "type",
                value: "FREELANCE",
            },
            {
                name: "isMissionProgress",
                value: 'false'
            }
        ],
    },
};
const headerFilters = [
    {
        name: 'lastName',
        defaultName: '',
        inputAsDefault: true,
        canBeRemove: true,
        isHeader: true,
        isModal: false,
        isSearchable: true,
        formatFilterValue: (obj) => { return obj ? obj : obj; },
        formatCurrent: (obj) => { return obj ? (obj.value.hasOwnProperty('label') ? obj.value.label : obj.value) : obj; },
        asyncParameters: USERSTORED,
        label: 'search.lastname',
        className: 'label-input filter filter-with-marin-right-small',
        filterType: FILTER_TYPE_SELECT_AUTOC,
        field: 'text'
    },
    {
        name: 'profile_availability',
        defaultName: '',
        inputAsDefault: true,
        canBeRemove: true,
        isHeader: true,
        isModal: false,
        formatFilterValue: (value) => { if (!value)
            return null; return ({ before: moment(value).format('YYYY-MM-DDTHH:mm:ss.SSSZ') }); },
        formatCurrent: (obj) => { if (obj && !obj.value.before)
            return; return moment(obj.value.before).format('DD-MM-YYYY'); },
        isSearchable: true,
        label: 'search.date',
        filterType: FILTER_TYPE_TEXT,
        options: [],
        className: 'label-input filter filter-with-marin-right-small',
        field: 'text',
        type: 'date',
        dateType: 'before'
    },
];
const MOBILITYSTORED = {
    api: process.env.REACT_APP_IDENTITY_GQL_URL
        ? process.env.REACT_APP_IDENTITY_GQL_URL
        : "",
    table: "users",
    entity: "User",
    list: true,
    query: {
        filtersToUpdate: {
            field: 'mobilityCity',
            objectField: 'profile.mobilityCity'
        },
        fields: [
            "id",
            "_id",
            "profile{mobilityCity}",
        ],
        maxResults: MAX,
        page: 0,
        filters: [
            {
                value: true,
                name: "profile_qualified",
            },
            {
                value: true,
                name: "enabled",
            },
            {
                name: "type",
                value: "FREELANCE",
            },
            {
                value: { profile_mobilityCity: true },
                name: "exists",
            },
        ],
    },
};
const filters = [
    {
        name: 'profile_tjm',
        label: 'profile.tjm',
        defaultName: 'profile_tjm',
        isModal: true,
        multiple: false,
        inputAsDefault: true,
        filterType: FILTER_TYPE_SELECT,
        isSearchable: false,
        formatFilterValue: (obj) => { return obj; },
        formatCurrent: (obj) => obj.value.label,
        options: [
            { value: { between: "200..250" }, label: "200€ - 250€" },
            { value: { between: "350..500" }, label: "350€ - 500€" },
            { value: { between: "500..700" }, label: "500€ - 700€" },
            { value: { between: "700..1000" }, label: "700€ - 1 000€" },
            { value: { after: "1000" }, label: " > 1 000€" },
        ],
    },
    {
        name: 'profile_expertise',
        label: 'profile.expertise',
        defaultName: 'profile_expertise',
        isModal: true,
        multiple: false,
        inputAsDefault: true,
        formatFilterValue: (value) => value,
        formatCurrent: (value) => value.value.label,
        filterType: FILTER_TYPE_SELECT,
        isSearchable: false,
        options: { redux: true, params: "params.expertises" },
    },
    {
        name: 'profile_mobilityCity_list',
        label: 'profile.mobilityCity',
        defaultName: 'profile_mobilityCity',
        isModal: true,
        multiple: true,
        inputAsDefault: true,
        asyncParameters: MOBILITYSTORED,
        filterType: FILTER_TYPE_SELECT_AUTOC,
        isSearchable: true,
        formatFilterValue: (obj) => { return obj.map((o) => o.label); },
        formatCurrent: (obj) => { return obj; },
        customComponent: 'location',
    },
    {
        name: 'profile_mobilityRayon',
        label: 'profile.rayon',
        defaultName: 'rayon',
        isModal: true,
        filterType: RANGE_FILTER,
        formatFilterValue: (obj) => { return ({ value: { between: `${obj[0]}..${obj[1]}` }, label: `Dans un rayon de ${obj[0]} km à ${obj[1]} Km` }); },
        formatCurrent: (obj) => { return obj.value.label; },
        asyncParameters: "",
        minRange: 0,
        maxRange: 500,
        unity: 'km'
    },
    {
        name: 'helpCreation',
        label: 'profile.helpCreation',
        defaultName: 'helpCreation',
        isModal: true,
        filterType: FILTER_TYPE_SELECT,
        isSearchable: false,
        inputAsDefault: true,
        formatFilterValue: (obj) => { return obj; },
        formatCurrent: (obj) => obj.value.label,
        options: [
            { value: true, label: "Oui" },
            { value: false, label: "Non" },
            { value: "donotfetch", label: "Vide" },
        ],
    },
    {
        name: 'exists',
        label: 'newsletter',
        defaultName: 'newsletter',
        isModal: true,
        filterType: FILTER_TYPE_SELECT,
        isSearchable: false,
        inputAsDefault: true,
        formatFilterValue: (obj) => { return obj; },
        formatCurrent: (obj) => obj.value.label,
        options: [
            { value: { newsletter: true }, label: "Oui" },
            { value: { newsletter: false }, label: "Non" },
            { value: "donotfetch", label: "Vide" }
        ],
    },
];
export default { headerFilters, filters };
