import React from "react";
import { useTranslation } from "react-i18next";
import { UseDocument } from "../../../Components/Freelance/Documents/useDocument";
import getFile from "../../../Shared/getFile";
import { ImagePlaceholder } from "../../../Components/Image/Image";
import { addHTTPS } from "../../../Shared/utils/utils";
import { formatUserName } from "../../../helper/formatUserName";
import PopOver from "../../../Components/PopOver/PopOver";
export const RmCandidatureSubComponent = ({ onHover, user, type }) => {
    const { profileDocument } = UseDocument(user.profile, "parcours");
    const { t } = useTranslation("candidatures");
    return (React.createElement("div", { onMouseLeave: onHover, className: "candidature-subcomponent", style: { display: "flex", flexDirection: "row" } },
        React.createElement("div", { style: { display: "flex", flexDirection: "row" } },
            React.createElement("div", { className: "candidature-subcomponentInfos" },
                React.createElement("span", { className: "size-small weight-bold" },
                    t("subcomponent.candidature"),
                    " :"),
                React.createElement("span", { className: "size-small weight-light" }, type === "candidature"
                    ? t("subcomponent.candidaturefree")
                    : t("subcomponent.rm")))),
        React.createElement("div", { className: "candidature-subcomponentActions" },
            React.createElement("a", { href: `/rm/freelances/freelance_edit/${user._id}`, className: "size-small weight-bold" }, t("subcomponent.profil")),
            profileDocument && (React.createElement("span", { onClick: () => getFile(profileDocument.document._id), className: "size-small weight-bold mt-10" }, t("subcomponent.cv"))))));
};
const RmCandidatureSnapshot = ({ onHover, user }) => {
    const getName = () => {
        if ((user.firstName.toLowerCase() === 'none' && user.lastName.toLowerCase() === 'none'))
            return 'Freelance supprimé';
        return formatUserName(user.firstName, user.lastName);
    };
    return (React.createElement("div", { className: "candidature-freelance", style: { display: "flex", flexDirection: "row" } },
        React.createElement("div", { className: "picture-container" },
            React.createElement("div", { className: "picture", onMouseEnter: e => {
                    onHover(e);
                } }, user.photo && user.photo.content ? (React.createElement("img", { style: { width: "100%" }, src: addHTTPS(user.photo.content) })) : (React.createElement("div", { className: "image image-empty" },
                React.createElement(ImagePlaceholder, { size: "big" }))))),
        React.createElement(PopOver, { text: getName(), className: 'toolTip-popover toolTip-popover-candidature_name', condition: (_text) => true, render: ({ setIsHover, formattedText }) => {
                return (React.createElement("div", { className: "candidature-freelanceInfos", onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false) },
                    React.createElement("span", { className: "size-small weight-bold" }, formattedText),
                    React.createElement("span", { className: "size-small weight-light" }, user.job)));
            } })));
};
const RmCandidatureSnapshotContainer = ({ user, onHover }) => {
    return (React.createElement("div", { className: "flex-selfStart" },
        React.createElement(RmCandidatureSnapshot, { user: user, onHover: onHover })));
};
export default RmCandidatureSnapshotContainer;
