import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
const DisplayTags = ({ tags, removeText = false }) => {
    const { t } = useTranslation("missions");
    return (React.createElement(Fragment, null,
        !removeText &&
            React.createElement(Fragment, null,
                React.createElement("p", { className: "size-big weight-light mt-35 mb-20" }, t("missions.keyword")),
                React.createElement("p", { className: "draftText" }, t("missions.tags_draft_text"))),
        React.createElement("ul", { className: "missionKeyWords castorForm-formRow mt-5 mb-5" }, tags &&
            tags.filter(tag => tag.status === "published").map((tag, index) => {
                return (React.createElement("li", { key: index, className: "missionKeyWords-badge" }, tag.name));
            }))));
};
export default DisplayTags;
