import { fetch } from '../Shared/fetch';
class ApiService {
    constructor(endpoint) {
        this.create = (data) => fetch(`${process.env.REACT_APP_IDENTITY_API_URL}${this.endPoint}`, 'post', data);
        this.getAll = () => fetch(`${process.env.REACT_APP_IDENTITY_API_URL}${this.endPoint}`, 'get');
        this.update = (data) => fetch(`${process.env.REACT_APP_IDENTITY_API_URL}${this.endPoint}/${data._id}`, 'put', data);
        this.remove = (id) => fetch(`${process.env.REACT_APP_IDENTITY_API_URL}${this.endPoint}/${id}`, 'delete');
        this.removeWithData = (data) => fetch(`${process.env.REACT_APP_IDENTITY_API_URL}${this.endPoint}/${data.id}`, 'delete', data);
        this.endPoint = endpoint;
    }
}
export default ApiService;
