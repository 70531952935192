import { handleActions } from 'redux-actions';
import { SET_SECTORS, SET_LOADED } from './constant';
const defaultState = {
    loaded: false,
    sectors: []
};
const sectorReducer = handleActions({
    [SET_SECTORS]: (state, action) => ({ ...state, sectors: action.payload.sectors }),
    [SET_LOADED]: (state, action) => ({ ...state, loaded: action.payload.loaded })
}, defaultState);
export default sectorReducer;
