import React, { useEffect, useState } from "react";
import LogoCreate from './LogoCreate';
import FormWrapper from "../../Form/FormWrapper";
import { useSelector } from "react-redux";
import * as documentService from '../../../Services/document';
import * as societyService from '../../../Services/society';
import { editNotif } from "../../../Services/notif/notif";
const LogoEdition = ({ directUpdate, id, imgAsInput = false, callback, imgUrl = '', changePrecalState }) => {
    const logoform = useSelector((state) => state.form.logo_assign);
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState(undefined);
    useEffect(() => {
        if (directUpdate && logoform && logoform.hasOwnProperty('values') && logoform.values.hasOwnProperty('photo')) {
            if (logoform.initial && logoform.values.photo.content !== logoform.initial.photo.content && logoform.values.photo.content !== "") {
                submitLogo(logoform.values.photo);
                if (changePrecalState)
                    changePrecalState();
            }
            if (!logoform.initial) {
                submitLogo(logoform.values.photo);
                if (changePrecalState)
                    changePrecalState();
            }
        }
    }, [logoform]);
    useEffect(() => {
        if (imgUrl) {
            setInitialValues({ photo: { content: imgUrl } });
        }
    }, [imgUrl]);
    const submitLogo = (photo) => {
        setIsLoading(true);
        let submitPhoto = {
            file: photo.file
        };
        return documentService.postFile(submitPhoto).then((data) => {
            let societySubmitData = {
                _id: id,
                photo: data["@id"]
            };
            return societyService.update(societySubmitData).then(() => {
                editNotif.success();
                setIsLoading(false);
                if (callback)
                    callback();
            }).catch((e) => {
                console.error("Error while updating society", e);
                editNotif.failed();
                setIsLoading(false);
            });
        }).catch((e) => {
            setIsLoading(false);
            console.error(e);
            editNotif.failed();
        });
    };
    return (React.createElement(FormWrapper, { onSubmit: submitLogo, form: "logo_assign", initialValues: initialValues },
        React.createElement(LogoCreate, { label: 'logo', position: 'bottom', imgAsInput: imgAsInput, isLoading: isLoading })));
};
export default LogoEdition;
