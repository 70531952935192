import { useStored } from "../Hooks/useStored";
export const useUser = (id) => {
    const USERTORED = {
        api: process.env.REACT_APP_IDENTITY_GQL_URL
            ? process.env.REACT_APP_IDENTITY_GQL_URL
            : "",
        table: "user",
        entity: "User",
        list: false,
        query: {
            fields: [
                "id",
                "_id",
                "firstName",
                "lastName",
                "email",
                "birthdate",
                "phone",
                "nationality",
                "civility",
                "roles",
                "photo{ id _id content}",
                "newsletter{id _id}",
                "helpCreation",
                "permissions(first:40){edges{node{id _id permission{id _id slug name} value}}}",
                "society{_id, id photo{id _id} address{city country address cpltAddress zipCode} rm{firstName lastName phone email}}",
                "profile{_id, id, status expertise address{city country address cpltAddress zipCode mobilityCity mobilityRayon } prioritized,  description, job, linkedin, tjm, availability,  profileDocuments(first:15){edges{node{ _id, type, document{_id, url, type, name} }}}}",
            ],
            filters: [id],
        },
    };
    const { data, loaded, loadData } = useStored(USERTORED, "");
    return {
        data,
        loadData,
        loaded
    };
};
