import React, { Fragment, useEffect, useState } from "react";
import Loading from "../../Components/Loading";
import ApiService from "../../Services/ApiService";
import { SOCIETY, USERS } from "../../Services/contants";
import { deleteClientNotif, editClientNotif, wrongPageNotif } from "../../Services/notif/notif";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useStoredNoFilters } from "../../Hooks/useStoredNoFilters";
import { fetchCounts } from "../../redux/count/action";
const LoadSociety = ({ id, render, history }) => {
    const [storedQuery] = useState({
        api: process.env.REACT_APP_IDENTITY_GQL_URL
            ? process.env.REACT_APP_IDENTITY_GQL_URL
            : "",
        table: "society",
        entity: "Society",
        list: false,
        query: {
            fields: [
                "id",
                "_id",
                "name",
                "siret",
                "affiliate",
                "nbPackHelp",
                "qualified",
                "nopackNoHelp",
                "rm{lastName firstName _id id}",
                "photo{_id, id content}",
                "contacts{edges{node{id _id civility lastName firstName email phone function newsletter{id _id} }}}",
                "mainContact{id _id newsletter{id _id} civility firstName lastName email phone function profile{_id id}}",
                "address{_id id address country zipCode city cpltAddress }",
                "activitySector{name _id id}",
                "commentaires{id _id}"
            ],
            filters: [
                {
                    value: `api/societies/${id}`,
                    name: "id"
                }
            ]
        }
    });
    const user = useSelector((state) => state.user.data);
    const { data, loadData, hasLoadAtLeastOnce, isLoadingForTheFirstTime } = useStoredNoFilters(storedQuery);
    const [loaders, setLoaders] = useState({ contact: false, delete: false });
    const { t } = useTranslation(["notif", "freelance"]);
    const dispatch = useDispatch();
    const CastorPopUp = (title, confirm, cancel) => {
        const MySwal = withReactContent(Swal);
        return MySwal.fire({
            title: "",
            text: title,
            confirmButtonText: confirm,
            cancelButtonText: cancel,
            customClass: {
                popup: "swallModal-popup",
                content: "swallModal-container",
                actions: "swallModal-actions form-bottom-button",
                confirmButton: "swallModal-orangeButton secondButton btn btn-secondary",
                cancelButton: "swallModal-blueButton firstButton btn btn-secondary"
            },
            showCancelButton: true,
        });
    };
    const onAddContact = contactData => {
        const c = contactData;
        const societyService = new ApiService(SOCIETY);
        const updatedContacts = [...data.contacts, { ...c, roles: ["ROLE_CLIENT"], type: 'CLIENT', civility: parseInt(c.civility.value), newsletter: c.newsletter ? { user: c, email: c.email, id: c.newsletter._id } : null }];
        setLoaders({ ...loaders, contact: true });
        return societyService
            .update({
            _id: data._id,
            mainContact: { ...data.mainContact },
            contacts: updatedContacts
        })
            .then(() => {
            changePrecalState();
            editClientNotif.success();
            loadData().then(() => {
                setLoaders({ ...loaders, contact: false });
            });
        })
            .catch(() => {
            setLoaders({ ...loaders, contact: false });
            editClientNotif.failed();
        });
    };
    const deleteSociety = () => {
        const userService = new ApiService(USERS);
        return CastorPopUp(t("notif:freelance.delete_confirm_title_other"), t("notif:freelance.delete_register"), t("notif:freelance.delete_cancel")).then((result) => {
            if (!result.value)
                return;
            setLoaders({ ...loaders, delete: true });
            userService
                .remove(data.mainContact._id)
                .then(() => {
                setLoaders({ ...loaders, delete: false });
                dispatch(fetchCounts());
                deleteClientNotif.success();
                if (!data.qualified)
                    history.push("/rm/clients/new_clients_list");
                else {
                    history.push("/rm/clients/clients_list");
                }
            }).catch(_e => {
                setLoaders({ ...loaders, delete: false });
                deleteClientNotif.failed();
            });
        });
    };
    const qualifSociety = () => {
        const societyService = new ApiService(SOCIETY);
        societyService
            .update({ ["@id"]: data._id, _id: data._id, qualified: true })
            .then(() => {
            changePrecalState();
            loadData();
            dispatch(fetchCounts());
            editClientNotif.success();
        })
            .catch(_e => {
            editClientNotif.failed();
        });
    };
    const changePrecalState = () => {
        const userService = new ApiService(USERS);
        userService
            .update({
            ["@id"]: data.mainContact._id,
            _id: data.mainContact._id,
            precalState: 2
        })
            .then(() => {
            editClientNotif.success();
            loadData();
        }).catch(() => {
            editClientNotif.failed();
        });
    };
    useEffect(() => {
        if (!hasLoadAtLeastOnce)
            return;
        if (!data) {
            wrongPageNotif.failed();
        }
        ;
    }, [hasLoadAtLeastOnce]);
    return (React.createElement(Fragment, null,
        (isLoadingForTheFirstTime) && React.createElement(Loading, null),
        data && render({ data, loadData, user, loaders, qualifSociety, onAddContact, deleteSociety, changePrecalState })));
};
export default LoadSociety;
