import { Field, Form } from "react-final-form";
import React, { useState } from "react";
import ApiService from "../../../../Services/ApiService";
import { USERS } from "../../../../Services/contants";
import { setUserDefault } from "../../../../redux/user/action";
import { rmEditNotif } from "../../../../Services/notif/notif";
import { getApiError } from "../../../../Shared/utils/getApiErrorTranslations";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../Components/Loading";
import { formatFirstName, formatLastName } from "../../../../helper/formatUserName";
import { WhFieldTxt, WhValidator } from "../../../../Components/WHForm/WhFields";
import { useTranslation } from "react-i18next";
import { addAsterix } from "../../../../helper/addAsterix";
import { Button } from "reactstrap";
const FormRmHeader = ({ rm, toggle, loadData }) => {
    const [loaded, setLoaded] = useState(true);
    const connectedUser = useSelector((state) => state.user.data);
    const { t } = useTranslation(["freelance", "form"]);
    const dispatch = useDispatch();
    const onSubmitRm = (data) => {
        setLoaded(false);
        let userService = new ApiService(USERS);
        return userService
            .update({ _id: rm._id, ...data }).then(() => {
            setLoaded(true);
            if (connectedUser._id === rm._id) {
                dispatch(setUserDefault());
            }
            loadData();
            toggle();
            rmEditNotif.success();
        })
            .catch(e => {
            setLoaded(true);
            rmEditNotif.failed(getApiError(e));
        });
    };
    return (React.createElement(Form, { onSubmit: onSubmitRm, keepDirtyOnReinitialize: true, initialValues: {
            firstName: rm.firstName,
            lastName: rm.lastName,
            phone: rm.phone
        }, render: ({ handleSubmit, values }) => {
            return React.createElement("form", { onSubmit: handleSubmit },
                React.createElement("div", { className: "form-content" },
                    !loaded && React.createElement(Loading, null),
                    React.createElement("div", { className: "field-row" },
                        React.createElement(Field, { name: "firstName", parse: (value) => formatFirstName(value), format: (value) => formatFirstName(value), validate: WhValidator.required }, props => (React.createElement(WhFieldTxt, Object.assign({ name: "firstName" }, props, { label: addAsterix(t("freelance:account.firstname")), className: "label-input weight-bold size-medium", type: "text" })))),
                        React.createElement(Field, { name: "lastName", parse: (value) => formatLastName(value), format: (value) => formatLastName(value), validate: WhValidator.required }, props => (React.createElement(WhFieldTxt, Object.assign({ name: "lastName" }, props, { label: addAsterix(t("freelance:account.lastname")), className: "label-input", type: "text" }))))),
                    React.createElement("div", { className: 'mt-3 mb-3' },
                        React.createElement("span", { className: `weight-light size-small ml-1 mt-5 mt-5Mobile` }, rm.email)),
                    React.createElement("div", null,
                        React.createElement(Field, { name: "phone", validate: WhValidator.composeValidators(WhValidator.required, WhValidator.phone) }, props => (React.createElement(WhFieldTxt, Object.assign({ name: "phone" }, props, { label: addAsterix(t("freelance:account.phone")), className: "label-input weight-light size-small mt-5 mt-5Mobile", type: "text" })))))),
                React.createElement("div", { className: "form-bottom-button" },
                    React.createElement(Button, { type: "reset", onClick: e => {
                            e.preventDefault();
                            toggle();
                        }, className: "blueButton firstButton" }, t("form:btn.cancel")),
                    React.createElement(Button, { type: "submit", className: "orangeButton secondButton" }, t("form:btn.register"))));
        } }));
};
export default FormRmHeader;
