import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import RMRoute from "./RMRoute";
import FreelanceRoute from "./FreelanceRoute";
import { useSelector } from "react-redux";
import ClientRoute from "./ClientRoute";
import { ROLES } from "../../constants/roles";
import FetchAppData from "../../FetchAppData";
import FirebaseContext from "../../Components/Firebase/FirebaseContext";
import ApiService from "../../Services/ApiService";
import { USERS } from "../../Services/contants";
const ConnectedMain = () => {
    const mainStyle = useSelector((state) => state.style.fullContainer);
    const WINDOW_VAR_NAME = 'jiraIssueCollector';
    const setCollector = () => {
        const appElement = document.querySelector('body');
        if (appElement) {
            const snippet = document.createElement('script');
            snippet.type = 'text/javascript';
            snippet.src = 'https://castor-network.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/vd1cif/b/24/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=fr-FR&collectorId=bd2f7a84';
            appElement.appendChild(snippet);
        }
    };
    if (!window[WINDOW_VAR_NAME]) {
        setCollector();
        window[WINDOW_VAR_NAME] = this;
    }
    const connectedSociety = useSelector((state) => state.society);
    return (React.createElement("div", { className: `${mainStyle.join(" ")}` },
        React.createElement(FetchAppData, { render: ({ user, toggleRmMenu, isRmMenuOpen }) => {
                return (React.createElement(Fragment, null,
                    React.createElement(FirebaseContext.Consumer, null, ({ messaging }) => {
                        if (!messaging)
                            return;
                        messaging.firebaseDependencies.installations.getId().then((_v) => {
                            messaging.getToken({ vapidKey: `BGT1H6a67vxFkprSt-lsQK7d9ZT7OlJZ-ANZZhBuCixhseHmNgTC0v0XpM4EOvVL9ekkRrYUk6W8zeEMzpWYmjA` }).then((t) => {
                                const userService = new ApiService(USERS);
                                userService.update({ _id: user._id, firebaseId: t });
                            });
                        });
                        return React.createElement("div", null);
                    }),
                    React.createElement(Switch, null,
                        [ROLES.RM].some((r) => user.roles.indexOf(r) >= 0) && (React.createElement(Route, { path: "/rm", name: "Ressource manager", render: () => (React.createElement(RMRoute, { user: user, isRmMenuOpen: isRmMenuOpen, toggleRmMenu: toggleRmMenu })) })),
                        [ROLES.FREE].some((r) => user.roles.indexOf(r) >= 0) && (React.createElement(Route, { path: "/freelance", name: "Freelance", component: FreelanceRoute })),
                        connectedSociety.data &&
                            [ROLES.CLIENT].some((r) => user.roles.indexOf(r) >= 0) && React.createElement(Route, { path: "/client", name: "Client", component: ClientRoute }))));
            } })));
};
export default ConnectedMain;
