import React, { Fragment } from "react";
import CurrentFilter from "./CurrentFilter";
const DisplaySelectedFilters = ({ filters, headerFilters, selectedFilters, removeFilters }) => {
    const onRemove = (e, filterName, value) => {
        e.stopPropagation();
        removeFilters(filterName, value);
    };
    return (React.createElement("div", { className: "selected_filters_wrapper" },
        filters.map((filter, idx) => {
            const selectedFilter = selectedFilters.find(s => s.name === filter.name);
            if (selectedFilter)
                return (React.createElement(CurrentFilter, { key: idx, filter: filter, selectedFilters: selectedFilter, onRemove: onRemove }));
            else
                return React.createElement(Fragment, { key: idx });
        }),
        headerFilters.map((filter, idx) => {
            const selectedFilter = selectedFilters.find(s => s.name === filter.name);
            if (selectedFilter)
                return (React.createElement(CurrentFilter, { key: idx, filter: filter, selectedFilters: selectedFilter, onRemove: onRemove }));
            else
                return React.createElement(Fragment, { key: idx });
        })));
};
export default DisplaySelectedFilters;
