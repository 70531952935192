import React from "react";
import MissionHeader from './MissionHeader';
import MissionDate from './MissionDate';
import { useSelector } from "react-redux";
import { useMission } from "../useMission";
const Mission = ({ mission, userRole, selected = false }) => {
    const connectedUser = useSelector((state) => state.user);
    const { status, getIndication } = useMission(mission, userRole, connectedUser.data.id);
    const displayEndDate = () => {
        if (status.value === 2900 || status.value === 1500)
            return true;
        return (((status.value >= 1200 && status.value <= 1500) || (status.value >= 2600 && status.value <= 2900)));
    };
    return (React.createElement("div", { className: "mission-item" },
        selected && (React.createElement("span", { className: "select-border", "data-testid": "selectedMission" })),
        React.createElement(MissionHeader, { logo: mission.society.photo, title: mission.title, jobTitle: mission.job, clientDescription: '', status: status, indication: getIndication() }),
        React.createElement(MissionDate, { startNow: mission.startNow, startDate: mission.startDate, endDate: displayEndDate() ? mission.endDate : null, duration: mission.duration, isFinish: status.value === 2900 || status.value === 1500 })));
};
export default Mission;
