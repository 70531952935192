import React, { useEffect, useState } from "react";
import { GroupedFiltersList, NormalFilters, QueryFilters } from "../../../SearchFilter/FiltersList";
import DisplaySelectedFilters from "../../../../Components/Filters/DisplaySelectedFilters";
import Result from "../../../../Components/FoundResult/Result";
import { Container } from "reactstrap";
import InfiniteScroll from "../../../../Components/InfiniteScroll/InfiniteScroll";
import ClientElement from "../ClientElement";
import { useTranslation } from "react-i18next";
import SearchContainer from "../../../../Components/SearchContainer/SearchContainer";
import { AccessEnum, checkAccess } from "../../../../Services/Access/access";
import { accessNotif } from "../../../../Services/notif/notif";
import { useFilter } from "../../../../Hooks/useFilter";
import FFCreateClient from "../../../../Components/Clients/Form/FFCreateClient";
import Sidebar from "../../../../Components/Sidebar/View";
import exportCSV from "../../../../Shared/exportCsv";
import Loading from "../../../../Components/Loading";
import { formatFirstName, formatLastName } from "../../../../helper/formatUserName";
import useWindowDimensions from '../../../../Hooks/useWindowDimensions';
const Search = ({ setIsOpen, facets, removeFilters, userPermissions, updateFilters, headerFilters, currentFilters }) => {
    const { t } = useTranslation('rmclient');
    return (React.createElement(SearchContainer, { addClassName: "blueBackground", title: t("clients.list"), header: true, withBtn: checkAccess(AccessEnum.CREATE_CLIENT, userPermissions), textBtn: t("clients.btn"), onClick: e => {
            e.stopPropagation();
            setIsOpen(true);
        } }, headerFilters && (React.createElement("div", { className: "filterList castorForm-formRow" }, headerFilters && (React.createElement("div", { className: "filterList castorForm-formRow" },
        React.createElement(QueryFilters, { filter: headerFilters.find((hf) => hf.name === "query"), removeFilters: removeFilters, updateFilters: updateFilters, currentValues: currentFilters }),
        React.createElement(GroupedFiltersList, { isImmediate: true, updateFilters: updateFilters, filters: headerFilters.filter((hf) => hf.name !== "query"), removeFilters: removeFilters, facets: facets, currentValues: currentFilters })))))));
};
const ClientListBody = ({ user, facets, filterConfig, history, totalItems, hasNextPage, loadNextPage, isNextPageLoading, data, setRequestFilters, onSubmitClient }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loaded, setLoaded] = useState(true);
    const { t } = useTranslation("rmclient");
    const { headerFilters, filters, updateFilters, currentFilters, filterRequest, removeFilters } = useFilter('clientList', filterConfig);
    useEffect(() => {
        if (!setRequestFilters)
            return;
        setRequestFilters(filterRequest);
    }, [filterRequest]);
    const goToClient = (id) => {
        if (!checkAccess(AccessEnum.VIEW_CLIENT, user.permissions)) {
            accessNotif.failed();
            return null;
        }
        return history.push(`/rm/clients/client_edit/${id}`);
    };
    const onSubmit = (data) => {
        setIsLoading(true);
        return onSubmitClient(data).then(() => {
            setIsOpen(false);
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    };
    const exportFile = () => {
        setLoaded(false);
        const exportFile = exportCSV('/api/export/client_qualified', 'client_qualified');
        exportFile.then(() => {
            setLoaded(true);
        }).catch((e) => {
            console.log(e);
            setLoaded(true);
        });
    };
    const { width } = useWindowDimensions();
    return (React.createElement("div", { className: `client_list_container rightContainer rightContainer--withHeadFilters width-selectable-filters littleHeader ` },
        !loaded && React.createElement(Loading, null),
        React.createElement(Search, { userPermissions: user.permissions, removeFilters: removeFilters, facets: facets, updateFilters: updateFilters, currentFilters: currentFilters, headerFilters: headerFilters, setIsOpen: setIsOpen }),
        React.createElement("div", { className: "selectable-filter" }, filters && (React.createElement(NormalFilters, { facets: facets, currentValues: currentFilters, filters: filters, updateFilters: updateFilters }))),
        React.createElement(DisplaySelectedFilters, { filters: filters, headerFilters: headerFilters, selectedFilters: currentFilters, removeFilters: removeFilters }),
        React.createElement(Result, { size: totalItems }),
        React.createElement(Container, { className: 'infinite-scroll-container' },
            React.createElement("div", { className: "orderFilterContainer" },
                React.createElement("span", { className: "exportButton littleTextButton ml-auto", onClick: () => exportFile() }, t("export.btn"))),
            React.createElement("div", { className: "overloadGrid" },
                React.createElement(InfiniteScroll, { data: data, hasNextPage: hasNextPage, loadNextPage: loadNextPage, isNextPageLoading: isNextPageLoading, rowHeight: width > 820 ? 100 : 190, component: ({ index }) => {
                        return (React.createElement("div", { key: `client-element-${index}`, className: "client-element flex flex-alignCenter w-100", onClick: () => {
                                goToClient(data[index].ref);
                            } },
                            React.createElement(ClientElement, { img: data[index].logo, name: data[index].affiliate ? `${data[index].name} - ${data[index].affiliate}` : `${data[index].name}`, user: `${formatFirstName(data[index].main_contact_firstname)}-${formatLastName(data[index].main_contact_lastname)}`, activitySector: data[index].activity_sector_name, city: data[index].city, zipCode: data[index].zip_code }),
                            React.createElement("span", { className: 'ml-auto arrowRight' })));
                    } }))),
        React.createElement(Sidebar, { showSidebar: isOpen, close: setIsOpen, title: "client.title" },
            isLoading && React.createElement(Loading, null),
            React.createElement(FFCreateClient, { close: setIsOpen, onSubmit: onSubmit }))));
};
export default ClientListBody;
