import React from 'react';
import { CounterHeader, CounterFooter } from "./CounterElement";
import { CounterList, CounterListWithBtn } from "./CounterList";
export const CounterWithBtn = ({ subtitle, number, type, cssType, onUpdate, footerNumber = { value: '00' } }) => {
    return (React.createElement("div", { className: 'counter-container' },
        React.createElement(CounterHeader, { subtitle: subtitle }),
        React.createElement(CounterListWithBtn, { number: number, cssType: cssType, type: type, onUpdate: onUpdate }),
        React.createElement(CounterFooter, { onUpdate: onUpdate, type: type, footerNumber: footerNumber })));
};
export const CounterWithoutBtn = ({ subtitle, number, cssType }) => {
    return (React.createElement("div", { className: 'counter-container' },
        React.createElement(CounterHeader, { subtitle: subtitle }),
        React.createElement(CounterList, { number: number, cssType: cssType })));
};
