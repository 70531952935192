import { useState, useEffect } from 'react';
import { missionCandidatureAllUpdateNotif, missionCandidatureNotif } from "../Services/notif/notif";
import { CANDIDATURE, MISSIONSEVENT } from "../Services/contants";
import ApiService from "../Services/ApiService";
export const useValidateCandidature = (mission, candidatures, onAccept, reload) => {
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const isAllStatusTheSame = () => {
        return candidatures.filter(c => c.user.firstName !== 'none' && c.user.lastName !== 'none').some((c) => c.statusClientToValid !== c.statusClient);
    };
    useEffect(() => {
        const acceptedCandidature = candidatures.filter((c) => c.statusClientToValid === "accepted" && c.statusFl !== '1400');
        if (!isAllStatusTheSame()) {
            setDisabled(true);
            return;
        }
        if (acceptedCandidature.length === 0 || acceptedCandidature.length > 1)
            setDisabled(true);
        else
            setDisabled(false);
    }, [candidatures]);
    const updateMissionsStatus = (code, candidatureId) => {
        setLoading(true);
        const missionService = new ApiService(MISSIONSEVENT);
        return missionService
            .create({
            mission: "/api/missions/" + mission._id,
            code: code,
            candidature: "/api/candidatures/" + candidatureId
        });
    };
    const cancelCandidature = () => {
        setLoading(true);
        const candidatureService = new ApiService(CANDIDATURE);
        const candidaturesPromises = candidatures.filter((c) => c.statusClientToValid !== 'waiting' && c.statusFl !== '1400').map((c) => {
            return candidatureService
                .update({
                _id: c._id,
                statusClientToValid: "waiting",
            });
        });
        Promise.all(candidaturesPromises).then(() => {
            setLoading(false);
            missionCandidatureNotif.success();
            reload(false);
        }).catch(() => {
            setLoading(false);
            missionCandidatureNotif.failed();
        });
    };
    const validateSelectedCandidature = () => {
        const candidaturesToUpdate = candidatures.filter((c) => c.statusFl !== '1400' && c.statusClientToValid !== c.statusClients);
        const idx = candidaturesToUpdate.findIndex((c) => c.statusClientToValid === "accepted");
        if (idx > -1) {
            onAccept({ candidatures: candidaturesToUpdate, acceptedIndex: idx });
            return;
        }
        setLoading(true);
        const candidaturesPromises = candidatures.filter((c) => c.statusClientToValid !== c.statusClients).map((c) => {
            return updateMissionsStatus(c.statusClientToValid === "refused" ? 208 : 209, c._id);
        });
        Promise.all(candidaturesPromises).then(() => {
            setLoading(false);
            missionCandidatureAllUpdateNotif.success();
            reload();
        }).catch(() => {
            setLoading(false);
            missionCandidatureAllUpdateNotif.failed();
        });
    };
    return { disabled, validateSelectedCandidature, loading, cancelCandidature };
};
