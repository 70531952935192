import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SearchContainer from "../../../../../Components/SearchContainer/SearchContainer";
import { AccessEnum, checkAccess } from "../../../../../Services/Access/access";
import { GroupedFiltersList, NormalFilters, QueryFilters, } from "../../../../SearchFilter/FiltersList";
import { useFilter } from "../../../../../Hooks/useFilter";
import DisplaySelectedFilters from "../../../../../Components/Filters/DisplaySelectedFilters";
import Result from "../../../../../Components/FoundResult/Result";
import { Container } from "reactstrap";
import Sidebar from "../../../../../Components/Sidebar/View";
import FFCreateMission from "../../../../Missions/SideBarComponents/FFCreateMission";
import { FalseReactTableHeader } from "../../../../../Components/FalseReactTable/PatchHeader";
import InfiniteScroll from "../../../../../Components/InfiniteScroll/InfiniteScroll";
import { BodyValidate } from "../../../../../Components/FalseReactTable/PatchBody";
import useWindowDimensions from '../../../../../Hooks/useWindowDimensions';
const Search = ({ setIsOpen, removeFilters, facets, userPermissions, updateFilters, headerFilters, currentFilters, }) => {
    const { t } = useTranslation("missions");
    return (React.createElement(SearchContainer, { title: t("missions:missions.mine"), header: true, withBtn: checkAccess(AccessEnum.CREATE_EDIT_MISSION, userPermissions), textBtn: t("missions:missions.new"), onClick: (e) => {
            e.stopPropagation();
            setIsOpen(true);
        } }, headerFilters && (React.createElement("div", { className: "filterList castorForm-formRow" },
        React.createElement(QueryFilters, { filter: headerFilters.find((hf) => hf.name === "query"), removeFilters: removeFilters, updateFilters: updateFilters, currentValues: currentFilters }),
        React.createElement(GroupedFiltersList, { isImmediate: true, filters: headerFilters.filter((hf) => hf.name !== "query"), removeFilters: removeFilters, facets: facets, currentValues: currentFilters, updateFilters: updateFilters })))));
};
const ValidateBody = ({ history, user, setRequestFilters, facets, isCurrentlyLoading, onSubmitMission, totalItems, data, hasNextPage, isNextPageLoading, loadNextPage, setDefaultFiltersOverride, }) => {
    const { t } = useTranslation(["notif", "missions"]);
    const { headerFilters, filters, updateFilters, currentFilters, filterRequest, removeFilters, } = useFilter("missionListValidate", "graphql");
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('responsiveCreateMissionopen');
        }
        else {
            document.body.classList.remove('responsiveCreateMissionopen');
        }
    }, [isOpen]);
    useEffect(() => {
        if (!setRequestFilters)
            return;
        setRequestFilters(filterRequest);
    }, [filterRequest]);
    const submitMission = (data) => {
        return onSubmitMission(data)
            .then(() => {
            setIsOpen(false);
        })
            .catch(() => {
            setIsOpen(true);
        });
    };
    const setClose = () => setIsOpen(false);
    const { width } = useWindowDimensions();
    return (React.createElement(Fragment, null,
        React.createElement(Search, { userPermissions: user.permissions, facets: facets, removeFilters: removeFilters, updateFilters: updateFilters, currentFilters: currentFilters, headerFilters: headerFilters, setIsOpen: setIsOpen }),
        React.createElement("div", { className: "selectable-filter" }, filters && (React.createElement(NormalFilters, { currentValues: currentFilters, facets: facets, filters: filters, updateFilters: updateFilters }))),
        React.createElement(DisplaySelectedFilters, { filters: filters, headerFilters: headerFilters, selectedFilters: currentFilters, removeFilters: removeFilters }),
        React.createElement(Result, { size: totalItems }),
        React.createElement("div", { className: "missionTable" },
            React.createElement(Container, { className: "infinite-scroll-container mission-list-container" },
                React.createElement("div", { className: "patch-wh-table " },
                    React.createElement("div", { className: "overloadGrid" },
                        React.createElement(FalseReactTableHeader, { headers: [
                                t("missions:columns.name"),
                                t("missions:columns.firstname"),
                                t("missions:columns.jobname"),
                                t("missions:columns.client"),
                                t("missions:columns.mission"),
                                t("missions:columns.startDate"),
                                "",
                            ] }),
                        React.createElement(InfiniteScroll, { data: data, rowHeight: width > 820 ? 100 : 190, hasNextPage: hasNextPage, loadNextPage: loadNextPage, isNextPageLoading: isNextPageLoading, component: ({ index }) => {
                                return (React.createElement("div", { onClick: () => {
                                        history.push(`/rm/missions/${data[index].ref}`);
                                    } },
                                    React.createElement(BodyValidate, { key: `mission-${index}`, d: data[index], index: index })));
                            } }))))),
        React.createElement(Sidebar, { showSidebar: isOpen, close: setIsOpen, title: t("missions:missions.new") },
            React.createElement(FFCreateMission, { close: setClose, onSubmit: submitMission, permissions: user.permissions, role: "ROLE_ADMIN", entities: {}, entitiesLoaded: false }))));
};
export default ValidateBody;
