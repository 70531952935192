import Notif from "../../Components/Notif";
const NOTIF_DURATION = 2;
export const editClientNotif = {
    success: () => Notif({ color: "success", message: "notif:success_edit", duration: NOTIF_DURATION }),
    failed: () => Notif({ color: "warning", message: 'notif:failed_edit', duration: NOTIF_DURATION })
};
export const freelanceNotif = {
    success: () => Notif({ color: "success", message: "notif.freelance.success", duration: NOTIF_DURATION }),
    failed: () => Notif({ color: "warning", message: 'notif.freelance.failed', duration: NOTIF_DURATION })
};
export const removeClientNotif = {
    success: () => Notif({ color: "success", message: "notif:user.success_remove", duration: NOTIF_DURATION }),
    failed: (message = null) => Notif({ color: "warning", message: message ? message : 'notif:user.failed_remove', duration: NOTIF_DURATION })
};
export const deleteClientNotif = {
    success: () => Notif({ color: "success", message: 'notif:user.success_remove', duration: NOTIF_DURATION }),
    failed: () => Notif({ color: "warning", message: 'notif:user.failed_remove', duration: NOTIF_DURATION }),
    mine_success: () => Notif({ color: "success", message: 'notif:user.success_remove_mine', duration: NOTIF_DURATION }),
    mine_failed: () => Notif({ color: "warning", message: 'notif:user.failed_remove_mine', duration: NOTIF_DURATION })
};
export const deleteRmNotif = {
    mine_success: () => Notif({ color: "success", message: 'notif:mine_account.success_remove', duration: NOTIF_DURATION }),
    mine_failed: () => Notif({ color: "warning", message: 'notif:mine_account.failed_remove', duration: NOTIF_DURATION }),
};
export const societyUpdateNotif = {
    success: () => Notif({ color: "success", message: 'society.update_success', duration: NOTIF_DURATION }),
    failed: () => Notif({ color: "warning", message: 'society.update_failed', duration: NOTIF_DURATION })
};
export const societyCreateNotif = {
    success: () => Notif({ color: "success", message: 'society.success_create', duration: NOTIF_DURATION }),
    failed: () => Notif({ color: "warning", message: 'society.failed_create', duration: NOTIF_DURATION })
};
export const photoCreateNotif = {
    success: () => Notif({ color: "success", message: 'notif:photo.success', duration: NOTIF_DURATION }),
    failed: () => Notif({ color: "warning", message: 'notif:photo.failed', duration: NOTIF_DURATION })
};
export const missionStatusUpdateNotif = {
    success: () => Notif({ color: "success", message: 'notif:mission.status.success', duration: NOTIF_DURATION }),
    failed: (message = null) => Notif({ color: "warning", message: message ? message : 'notif:mission.status.failed', duration: NOTIF_DURATION })
};
export const missionCreateNotif = {
    success: () => Notif({ color: "success", message: 'notif:mission.success', duration: NOTIF_DURATION }),
    failed: (message = null) => Notif({ color: "warning", message: message ? message : 'notif:mission.failed_create', duration: NOTIF_DURATION })
};
export const editNotif = {
    success: () => Notif({ color: "success", message: 'success_edit', duration: NOTIF_DURATION }),
    failed: (message = null) => Notif({ color: "warning", message: message ? message : 'failed_edit', duration: NOTIF_DURATION })
};
export const rmEditNotif = {
    success: () => Notif({ color: "success", message: 'notif:user.success_edit', duration: NOTIF_DURATION }),
    failed: (message = null) => Notif({ color: "warning", message: message ? message : 'notif:user.failed_edit', duration: NOTIF_DURATION })
};
export const updateNotif = {
    success: () => Notif({ color: "success", message: 'update_success', duration: NOTIF_DURATION }),
    failed: () => Notif({ color: "warning", message: 'update_failed', duration: NOTIF_DURATION })
};
export const missionEditNotif = {
    success: () => Notif({ color: "success", message: 'notif:mission.edit.success', duration: NOTIF_DURATION }),
    failed: (message = null) => Notif({ color: "warning", message: message ? message : 'notif:mission.edit.failed', duration: NOTIF_DURATION })
};
export const missionRemoveNotif = {
    success: () => Notif({ color: "success", message: 'notif:mission.remove.success', duration: NOTIF_DURATION }),
    failed: () => Notif({ color: "warning", message: 'mission.remove.failed', duration: NOTIF_DURATION })
};
export const missionCandidatureNotif = {
    success: () => Notif({ color: "success", message: 'notif:mission.candidatures.success', duration: NOTIF_DURATION }),
    failed: (message = null) => Notif({ color: "warning", message: message ? message : 'notif:mission.candidatures.failed', duration: NOTIF_DURATION })
};
export const missionCandidatureAllUpdateNotif = {
    success: () => Notif({ color: "success", message: 'notif:mission.candidatures.all_update_success', duration: NOTIF_DURATION }),
    failed: (message = null) => Notif({ color: "warning", message: message ? message : 'notif:mission.candidatures.all_update_failed', duration: NOTIF_DURATION })
};
export const rightNotif = {
    success: () => Notif({ color: "success", message: 'notif:right.success', duration: NOTIF_DURATION }),
    failed: (message = null) => Notif({ color: "warning", message: message ? message : 'notif:right.failed', duration: NOTIF_DURATION })
};
export const accessNotif = {
    failed: () => Notif({ color: "warning", message: 'notif:access.failed', duration: NOTIF_DURATION })
};
export const addCandidature = {
    success: () => Notif({ color: "success", message: 'notif:freelance.add_success', duration: NOTIF_DURATION }),
    failed: (message = null) => Notif({ color: "warning", message: message ? message : 'notif:freelance.add_failed', duration: NOTIF_DURATION })
};
export const candidatureDeleteNotif = {
    success: () => Notif({ color: "success", message: 'candidature.delete.success', duration: NOTIF_DURATION }),
    failed: () => Notif({ color: "warning", message: 'candidature.delete.failed', duration: NOTIF_DURATION })
};
export const candidatureUpdateNotif = {
    success: () => Notif({ color: "success", message: 'candidatures_update.success', duration: NOTIF_DURATION }),
    failed: () => Notif({ color: "warning", message: 'candidatures_update.failed', duration: NOTIF_DURATION })
};
export const wrongPageNotif = {
    failed: () => Notif({ color: "warning", message: 'failed_page.failed', duration: NOTIF_DURATION })
};
export const notifRead = {
    success: () => () => Notif({ color: "success", message: 'read.success', duration: NOTIF_DURATION })
};
export const freelancePriorityNotif = {
    success: () => Notif({ color: "success", message: 'freelance.priority.change_priority_success', duration: NOTIF_DURATION }),
    failed: () => Notif({ color: "warning", message: 'freelance.priority.change_priority_failed', duration: NOTIF_DURATION })
};
export const notesNotif = {
    success_delete: () => Notif({ color: "success", message: 'notif:notes.success_delete', duration: NOTIF_DURATION }),
    failed_delete: (message = null) => Notif({ color: "warning", message: message ? message : 'notif:notes.failed_delete', duration: NOTIF_DURATION }),
    success_edit: () => Notif({ color: "success", message: 'notif:notes.success_edit', duration: NOTIF_DURATION }),
    failed_edit: (message = null) => Notif({ color: "warning", message: message ? message : 'notif:notes.failed_edit', duration: NOTIF_DURATION }),
    success_add: () => Notif({ color: "success", message: 'notif:notes.success_add', duration: NOTIF_DURATION }),
    failed_add: (message = null) => Notif({ color: "warning", message: message ? message : 'notif:notes.failed_add', duration: NOTIF_DURATION })
};
