import { fetch } from './fetch';
import QueryBuilder from './queryBuilder';
class RestService {
    constructor(endpoint) {
        this.create = data => fetch(`${this.endPoint}`, 'post', data);
        this.update = data => fetch(`${this.endPoint}/${data._id}`, 'put', data);
        this.patch = data => fetch(`${this.endPoint}/${data._id}`, 'patch', data);
        this.remove = id => fetch(`${this.endPoint}/${id}`, 'delete');
        this.getAll = () => fetch(`${this.endPoint}`, 'get');
        this.get = (id) => fetch(`${this.endPoint}${id}`, 'get');
        this.search = (data = null) => {
            let query = {};
            if (data)
                query = QueryBuilder(data);
            return fetch(`${this.endPoint}`, 'get', query);
        };
        this.endPoint = endpoint;
    }
}
export default RestService;
