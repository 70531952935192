import React from 'react';
import { ImageLogo, ImagePlaceholder } from "../../../Components/Image/Image";
import { addHTTPS } from "../../../Shared/utils/utils";
const ClientElement = ({ img, name, activitySector, city, zipCode, user = '' }) => {
    return (React.createElement("div", { className: 'clientElement' },
        img ?
            React.createElement(ImageLogo, { img: addHTTPS(`${process.env.REACT_APP_IDENTITY_API_URL}/documents/file${img}`) }) : React.createElement(ImagePlaceholder, { size: 'big' }),
        React.createElement("div", { className: 'content' },
            React.createElement("div", { className: 'header' },
                React.createElement("span", null, `${name} (${user})`)),
            React.createElement("div", { className: 'description' },
                React.createElement("span", null, activitySector)),
            React.createElement("div", { className: ' mt-5' },
                React.createElement("span", null, `${zipCode} ${city}`)))));
};
export default ClientElement;
