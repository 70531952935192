import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatGraphQlData } from "../../../Shared/rewriteArray";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import HeaderMissionBar from "../Components/HeaderMissionBar";
import Mission from "../Mission";
import CardMission from "../Components/Mission";
import InfiniteScrollWithAutoSizer from "../../../Components/InfiniteScroll/InfiniteScrollWithAutoSizer";
import Loading from "../../../Components/Loading";
import { useRouteMatch, withRouter } from "react-router-dom";
import useGetMission from "../Client/useGetMission";
import { useStoredWithPagination } from "../../../Hooks/useStoredWithPagination";
const NewFreelanceMission = ({ history, match }) => {
    const connectedUser = useSelector((state) => state.user.data);
    const [config] = useState({
        baseConfig: {
            type: "graphql",
            api: process.env.REACT_APP_IDENTITY_GQL_URL
                ? process.env.REACT_APP_IDENTITY_GQL_URL
                : "",
            endPoint: {
                table: "missions",
                entity: "Mission",
                list: true,
                query: {
                    order: "DESC",
                    orderBy: { name: "createdAt" },
                    fields: [
                        "id",
                        "_id",
                        "title",
                        "job",
                        "hiddenSociety",
                        "description",
                        "public",
                        "startDate",
                        "tjm",
                        "endDate",
                        "duration",
                        "startNow",
                        "expertise",
                        "publicationDate",
                        "tags(first:20){edges{node{_id id status name}}}",
                        "activitySector{id, _id, name}",
                        "comment",
                        "nbModification",
                        "canceledReason",
                        "city",
                        "society{id _id name photo{id _id content}}",
                        "candidatures{edges{node{id _id statusFl indicationFl indicationFlNew user{id _id}}}}",
                        "missionStatus{statusRm statusClient}",
                        "updatedBy{id _id firstName lastName photo{_id id content}}",
                        "helped",
                    ],
                    maxResults: 15,
                    page: 0,
                },
            },
            resultKey: "datas",
        },
        pagination: {
            type: "pages",
            pages: {
                page: 1,
                pageSize: 20,
            },
        },
        filters: [
            { name: "candidatures_user", value: connectedUser._id.toString() },
            {
                name: "candidatures_statusFl_list",
                value: ["1100", "1200", "1300", "1400"],
            },
        ],
        uniqueFilters: [],
        formatResult: (res) => formatGraphQlData(res),
    });
    const [isShown, setIsShown] = useState(false);
    const { path } = useRouteMatch();
    const [selectedMission] = useState();
    const { data, isLoading, reloadData, hasNextPage, loadNextPage, isNextPageLoading, setDefaultFiltersOverride, } = useStoredWithPagination(config);
    const { mission, isLoading: isMissionLoading, setReload } = useGetMission(match.params.id);
    useEffect(() => {
        if (!data || data.length === 0 || isNextPageLoading || isLoading)
            return;
        if (match.params.id)
            return;
        history.push({
            pathname: "/freelance/missions" + "/" + data[0]._id,
        });
    }, [data, isLoading]);
    const onUpdate = () => {
        reloadData();
        setReload(true);
    };
    const { t } = useTranslation(["notif", "missions"]);
    const updateDefaultFilters = (filters) => {
        const { value } = filters[0];
        setDefaultFiltersOverride([
            { name: "candidatures_user", value: connectedUser._id.toString() },
            { name: "candidatures_statusFl_list", value: value },
        ]);
    };
    return (React.createElement("div", { className: "client-layout-mission" },
        React.createElement(Container, { className: "large" },
            React.createElement("div", { className: "title mb-25" }, t("missions:missions.mine")),
            React.createElement("div", { className: "client-layout-header-container" },
                React.createElement(HeaderMissionBar, { role: "ROLE_FREE", onClick: updateDefaultFilters })),
            React.createElement("div", { className: "mission-view" },
                React.createElement("div", { className: "sidebar-left", style: { minHeight: "800px" } },
                    data && data.length === 0 && (React.createElement("div", null, t("missions:missions.nomission"))),
                    isLoading && React.createElement(Loading, { className: "load-zIndexMin" }),
                    data && (React.createElement(InfiniteScrollWithAutoSizer, { key: JSON.stringify({ value: isNextPageLoading }), data: [...data], loadNextPage: loadNextPage, hasNextPage: hasNextPage, defaultCache: {
                            fixedWidth: true,
                            minHeight: data.length === 1 ? 220 : 193,
                            defaultHeight: 300,
                        }, selectedIndex: -1, className: "infinite-loader-list mission-list", isNextPageLoading: isNextPageLoading, openComponent: () => React.createElement("div", null), closedComponent: ({ index }) => {
                            const { _id } = data[index];
                            return (React.createElement("div", { key: index, className: `mission ${selectedMission && selectedMission._id === _id
                                    ? "select-border"
                                    : ""}` },
                                React.createElement("div", { className: "item", onClick: () => {
                                        setIsShown(true);
                                        history.push({
                                            pathname: "/freelance/missions" + "/" + data[index]._id,
                                        });
                                    } },
                                    React.createElement(CardMission, { mission: data[index], userRole: "ROLE_USER" }))));
                        } }))),
                isMissionLoading && React.createElement(Loading, { className: "load-zIndexMin" }),
                mission && (React.createElement(Mission, { key: JSON.stringify(data), mission: mission, isShown: isShown, setIsShown: setIsShown, callback: () => onUpdate(), userRole: "ROLE_USER", redirect: () => onUpdate() }))))));
};
export default withRouter(NewFreelanceMission);
