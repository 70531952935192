import { handleActions } from 'redux-actions';
import { SET_COUNTS } from "/wh/redux/count/constant";
const defaultState = {
    new_freelances: 0,
    new_clients: 0,
    mission_validates: 0
};
const countsReducer = handleActions({
    [SET_COUNTS]: (state, action) => ({ ...state, new_freelances: action.payload.new_freelances, new_clients: action.payload.new_clients, mission_validates: action.payload.mission_validates }),
}, defaultState);
export default countsReducer;
