export const doesThisArrayIncludeThoseString = (arr, arrInclude) => {
    for (const element of arrInclude) {
        if (arr.some((el) => el.includes(element)))
            return true;
    }
    return false;
};
export const arrayCompare = (a, b) => {
    if (a.toLowerCase() > b.toLowerCase()) {
        return -1;
    }
    if (b.toLowerCase() > a.toLowerCase()) {
        return 1;
    }
    return 0;
};
