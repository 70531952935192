import React, { useEffect, useState } from "react";
import echarts from "echarts";
import { useTranslation } from "react-i18next";
const Donut = ({ data }) => {
    const { t } = useTranslation('missionsStatus');
    const [chartInstance, setChartInstance] = useState(null);
    const [legends, setLegends] = useState(null);
    useEffect(() => {
        const myChart = echarts.init(document.getElementById(`stat-donut`));
        setChartInstance(myChart);
    }, []);
    useEffect(() => {
        let colors = ['#449046', '#70bc70', '#68687d', '#d8d8d8'];
        const l = data.statusMissions.map((el, index) => ({
            value: el.value,
            name: t(`${el.name}`),
            itemStyle: { color: colors[index],
            }
        }));
        setLegends(l ? [...l] : null);
    }, [data]);
    useEffect(() => {
        if (chartInstance === null || legends === null)
            return;
        const option = {
            tooltip: {
                trigger: "item",
                formatter: "{a} <br/>{b}: {c}",
            },
            legend: {
                show: false,
            },
            series: [
                {
                    name: "Missions",
                    type: "pie",
                    radius: ["60%", "90%"],
                    avoidLabelOverlap: false,
                    label: {
                        normal: {
                            formatter: '{d}%',
                            show: true,
                            position: 'inside',
                            textStyle: {
                                fontSize: "15",
                                fontWeight: "bold",
                            },
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                fontSize: "15",
                                fontWeight: "bold",
                            },
                        },
                    },
                    labelLine: {
                        normal: {
                            show: true,
                        },
                    },
                    data: legends
                }
            ]
        };
        if (chartInstance !== null) {
            chartInstance.setOption(option, true);
        }
    }, [legends]);
    const values = data.statusMissions.map((el) => { return el.value; });
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const changeLabel = (e) => {
        e.target.childNodes[0].classList.toggle('active');
        e.target.childNodes[1].classList.toggle('active');
        e.target.childNodes[2].classList.toggle('active');
        chartInstance.dispatchAction({
            type: 'legendToggleSelect',
            name: e.target.childNodes[1].innerText
        });
    };
    const showLegend = (legends) => {
        let colors = ['#449046', '#70bc70', '#68687d', '#d8d8d8'];
        return legends.map((item, i) => {
            return (React.createElement("li", { onClick: (e) => changeLabel(e), key: i, className: "bottom-list-item" },
                React.createElement("span", { className: "bottom-list-item__color", style: { backgroundColor: colors[i] } }),
                React.createElement("span", { className: "bottom-list-item__name noselect" }, item.name),
                React.createElement("span", { className: "bottom-list-item__number noselect" }, item.value)));
        });
    };
    return (React.createElement("div", { className: "donut-container" },
        React.createElement("div", { id: "stat-donut", style: { width: "350px", height: "450px" } }),
        React.createElement("div", { className: "center-total" },
            React.createElement("span", { className: "center-total__number noselect" }, values.reduce(reducer)),
            React.createElement("span", { className: "center-total__mission noselect" }, "Missions")),
        React.createElement("ul", { className: "bottom-list" }, legends && showLegend(legends))));
};
export default Donut;
