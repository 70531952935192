import { FILTER_TYPE_SELECT_AUTOC } from "../constants";
import { MAX } from "../../../Shared/maxResults";
const USERSTORED = {
    api: process.env.REACT_APP_IDENTITY_GQL_URL
        ? process.env.REACT_APP_IDENTITY_GQL_URL
        : "",
    table: "users",
    entity: "User",
    list: true,
    query: {
        fields: ["id", "_id", "", 'firstName', "lastName"],
        maxResults: MAX,
        page: 0,
        filtersToUpdate: {
            field: 'lastName',
            objectField: ["^(", 'firstName', "lastName"]
        },
        filters: [
            { name: "type", value: "RM" },
        ],
    },
};
const headerFilters = [
    {
        name: 'lastName',
        defaultName: '',
        inputAsDefault: true,
        canBeRemove: true,
        isHeader: true,
        isModal: false,
        isSearchable: true,
        formatFilterValue: (obj) => { return obj ? obj : obj; },
        formatCurrent: (obj) => { return obj ? (obj.value.hasOwnProperty('label') ? obj.value.label : obj.value) : obj; },
        asyncParameters: USERSTORED,
        label: 'search.manager',
        className: 'label-input filter filter-with-marin-right-small',
        filterType: FILTER_TYPE_SELECT_AUTOC,
        field: 'text'
    },
];
const AdressStored = {
    api: process.env.REACT_APP_IDENTITY_GQL_URL
        ? process.env.REACT_APP_IDENTITY_GQL_URL
        : "",
    table: "addresses",
    entity: "Address",
    list: true,
    query: {
        fields: ["id", "_id", "city"],
        maxResults: MAX,
        page: 0,
        filtersToUpdate: {
            field: "city",
            objectField: "city",
        },
        filters: [],
    },
};
const filters = [
    {
        name: "society_address_city",
        label: "location",
        defaultName: "location",
        isModal: true,
        multiple: false,
        inputAsDefault: true,
        asyncParameters: AdressStored,
        filterType: FILTER_TYPE_SELECT_AUTOC,
        isSearchable: true,
        options: [],
        customComponent: "location",
    },
];
export default { headerFilters, filters };
