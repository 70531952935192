import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import Header from "./Views/Layout/Header/Header";
import * as api from "./Shared/session/api";
import { setSectors, setSectorsThunk } from "./redux/sector/action";
import { fetchParameters, setCandidatureClStatus, setCivilities, setExpertises, setFlStatus, setMissionsStatus, setMissionUrl, setNationalities, setPartnerUrl, setUserStatus } from "./redux/params/action";
import { getSingleObjectOnlyKeys } from "./Services/Rest/getOnlyKeys";
import { setUser, singleUserKey } from "./redux/user/action";
import { setSociety, setSocietyThunk } from "./redux/society/action";
import { setLang } from "./redux/lang/actions";
import { ROLES } from "./constants/roles";
import { authFeathers } from "./Views/RessourceManager/Notifications/feathers";
import { logout } from './Shared/session';
const FetchAppData = ({ render }) => {
    const dispatch = useDispatch();
    const [userError, setUserError] = useState(false);
    const [fAuthenticated, setFAuthenticated] = useState(false);
    const user = useSelector((state) => state.user);
    const society = useSelector((state) => state.society.data);
    useEffect(() => {
        fetchData();
    }, []);
    const getFromLocalStorage = () => {
        const LSsociety = localStorage.getItem('society');
        const LSsectors = localStorage.getItem('activity_sectors');
        const LSparameters = localStorage.getItem('parameters');
        if (LSsociety) {
            dispatch(setSociety(JSON.parse(LSsociety)));
        }
        if (LSsectors) {
            dispatch(setSectors(JSON.parse(LSsectors)));
        }
        if (LSparameters) {
            const parameters = JSON.parse(LSparameters);
            dispatch(setUserStatus(parameters.profile_status));
            dispatch(setCivilities(parameters.civilities));
            dispatch(setExpertises(parameters.expertise));
            dispatch(setMissionsStatus(parameters.missionsStatus));
            dispatch(setFlStatus(parameters.fl_status));
            dispatch(setNationalities(parameters.nationalities));
            dispatch(setCandidatureClStatus(parameters.candidature_cl_status));
            dispatch(setMissionUrl(parameters.missionsUrl));
            dispatch(setPartnerUrl(parameters.partnersUrl));
        }
    };
    const fetchData = async () => {
        if (user && user.loaded) {
            setFAuthenticated(true);
            return;
        }
        try {
            await api
                .me()
                .then((data) => {
                dispatch(setSectorsThunk());
                dispatch(fetchParameters());
                const newObj = getSingleObjectOnlyKeys(singleUserKey, data);
                dispatch(setUser(newObj));
                authFeathers().then(() => {
                    setFAuthenticated(true);
                }).catch((e) => {
                    console.log("Erreur lors de l'authentification feathers", e);
                    setFAuthenticated(true);
                });
                dispatch(setSocietyThunk(newObj._id));
                dispatch(setLang("fr"));
            })
                .catch(async () => {
                dispatch(setUser(null));
                await logout().then(() => {
                    window.location.href =
                        `${process.env.REACT_APP_WWW_HOST}/fr/me-connecter/?logout=true&redirectUrl=` +
                            window.location;
                });
            });
        }
        catch (error) {
            setUserError(true);
        }
    };
    const [isRmMenuOpen, setRmMenuIsOpen] = useState(false);
    const toggleRmMenu = () => {
        setRmMenuIsOpen(!isRmMenuOpen);
    };
    return (React.createElement(Fragment, null,
        userError && React.createElement(Redirect, { to: "/" }),
        fAuthenticated && user.loaded && user.data ?
            React.createElement(Header, { user_role: user.data.roles, picture: [ROLES.CLIENT].some((r) => user.data.roles.indexOf(r) >= 0) ? (society ? society.photo : undefined) : user.data.photo, firstName: user.data.firstName, lastName: user.data.lastName, toggleRmMenu: toggleRmMenu }) : React.createElement("div", null),
        fAuthenticated && user.loaded && user.data && render({ user: user.data, isRmMenuOpen, toggleRmMenu })));
};
export default FetchAppData;
