import { createAction } from 'redux-actions';
import { SET_SECTORS, SET_LOADED } from './constant.js';
import RestService from "../../Services/Rest/RestService";
import { formatArrayObjFromKeys } from "../../Services/Rest/getOnlyKeys";
import * as storage from "../../Shared/storage";
export const setSectors = createAction(SET_SECTORS, (sectors) => ({ sectors }));
export const setLoaded = createAction(SET_LOADED, (loaded) => ({ loaded }));
export const setSectorsThunk = () => (dispatch) => {
    setLoaded(false);
    const service = new RestService(`${process.env.REACT_APP_IDENTITY_API_URL}/api/activity_sectors`);
    service.getAll().then((res) => {
        const sectors = formatArrayObjFromKeys(["id", "_id", "name"], res["hydra:member"]);
        storage.save('activity_sectors', sectors);
        dispatch(setSectors(sectors));
        dispatch(setLoaded(true));
    }).catch(() => {
        console.error("Activity sectors can't load");
        dispatch(setSectors([]));
        dispatch(setLoaded(true));
    });
};
