import React, { Fragment } from "react";
import ButtonBottomForm from "../../Components/WHForm/ButtonBottomForm";
import { Field } from "redux-form";
import renderCustomFileField from "../../Components/WHForm/renderCustomFileField";
import { WhFieldTxt } from "../../Components/WHForm/WhFields";
import { useTranslation } from "react-i18next";
const studiesForm = ({ toggle, shouldDisplayForm }) => {
    const { t } = useTranslation(["notif", "document"]);
    return (React.createElement(Fragment, null,
        React.createElement(ButtonBottomForm, { toggle: toggle, display: shouldDisplayForm },
            React.createElement("div", { className: "form-content" },
                React.createElement(Field, { name: "document", className: "col-two-input-file", component: renderCustomFileField, iconType: "cv", label: t("document:uploadCvFile"), subLabel: t("document:noEncryptPdf") }),
                React.createElement(Field, { className: "label-input flex-directionRow", label: "Linkedin", component: WhFieldTxt, name: "linkedin", type: "text", containerSize: "big flex-directionRow", iconbefore: React.createElement("i", { className: "fa fa-linkedin-square size-title mt-5 ml-5" }) })))));
};
export default studiesForm;
