export const addHTTPS = (photo) => {
    if (!photo)
        return photo;
    if (photo.search("https") > -1)
        return photo;
    if (photo) {
        return photo.replace('http', 'https');
    }
    return photo;
};
export const uniqueArray = (arr) => arr.filter((v, i, a) => a.findIndex((t) => t.label.trim() === v.label.trim()) === i);
