import React, { Fragment, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
const DropDownOrderFilter = ({ title, changeOrder, name, DescText, AscText }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    return (React.createElement(Fragment, null,
        React.createElement(Dropdown, { isOpen: dropdownOpen, toggle: toggle },
            React.createElement(DropdownToggle, { caret: true }, title),
            React.createElement(DropdownMenu, null,
                React.createElement(DropdownItem, { onClick: () => changeOrder(name, "DESC") }, DescText),
                React.createElement(DropdownItem, { onClick: () => changeOrder(name, "ASC") }, AscText)))));
};
export default DropDownOrderFilter;
