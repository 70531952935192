import { assign, Machine, send } from "xstate";
import { rmMissionsState } from "./rmMachine/rmStateMachine";
import MissionService from "../Functions/MissionService";
import { clientMissionsState } from "./rmMachine/clientStateMachine";
import { freelanceMissionsState } from "./rmMachine/freelanceStateMachine";
import { raise } from "xstate/lib/actions";
export const createDefaultMissionMachine = (callback, mission, redirect, user_id) => {
    return Machine({
        id: "missionMachine",
        initial: "none",
        context: { mission_id: mission.id, sidebarOpen: false, sidebarTitle: '', callback: callback, sidebarData: null, missionService: new MissionService(mission, user_id, callback, redirect) },
        states: {
            none: {
                on: {
                    ROLE_CLIENT: "role_client",
                    ROLE_ADMIN: "role_admin",
                    ROLE_USER: "role_user",
                }
            },
            role_client: {
                ...clientMissionsState
            },
            role_admin: {
                ...rmMissionsState
            },
            role_user: {
                ...freelanceMissionsState
            },
        }
    }, {
        actions: {
            updateMission: assign({ mission_id: (_context, event) => event.mission.id }),
            setMission: (context, event) => {
                context.missionService.setMission(event.mission);
            },
            raiseStatut: raise("STATUT"),
            switchSideBar: assign({ sidebarOpen: (_context) => true }),
            closeSideBar: assign({ sidebarOpen: (_context) => false, sidebarData: (_context) => null }),
            goBack: send("300")
        }
    });
};
