import { useStored } from "../../../../Hooks/useStored";
import { MAX } from "../../../../Shared/maxResults";
export const useUserPermission = (id) => {
    const USERPERMISSIONSTORED = {
        api: process.env.REACT_APP_IDENTITY_GQL_URL
            ? process.env.REACT_APP_IDENTITY_GQL_URL
            : "",
        table: "users",
        entity: "User",
        list: true,
        query: {
            fields: ["id", "_id", "permission{id _id name slug category} "],
            filters: [id],
        },
    };
    const { data, loaded } = useStored(USERPERMISSIONSTORED, "");
    return {
        data,
        loaded
    };
};
export const usePermission = () => {
    const PERMISSIONSTORED = {
        api: process.env.REACT_APP_IDENTITY_GQL_URL
            ? process.env.REACT_APP_IDENTITY_GQL_URL
            : "",
        table: "permissions",
        entity: "Permission",
        list: true,
        query: {
            fields: ["name", "id", "_id", "slug", "category"],
            maxResults: MAX,
            page: 0,
            filters: [],
        },
    };
    const { data, loaded } = useStored(PERMISSIONSTORED, "");
    return {
        data,
        loaded
    };
};
