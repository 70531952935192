import { useEffect, useState } from "react";
export const usePages = (defaultPageConfig) => {
    const [pages, setPages] = useState(defaultPageConfig);
    const [totalItems, setTotalItems] = useState(0);
    const [currentDataLength, setCurrentDataLength] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(false);
    useEffect(() => {
        if (totalItems === 0) {
            setHasNextPage(false);
            return;
        }
        if (currentDataLength < totalItems) {
            setHasNextPage(true);
            setNextPage();
            return;
        }
        setHasNextPage(false);
    }, [currentDataLength, totalItems]);
    const resetPage = () => {
        setPages({ page: 1, pageSize: 30 });
        setTotalItems(0);
        setCurrentDataLength(0);
        setHasNextPage(false);
    };
    const setNextPage = () => {
        const { page, pageSize } = pages;
        setPages({ page: page + 1, pageSize });
    };
    const initValues = (totalItems, dataLength) => {
        setCurrentDataLength(dataLength);
        setTotalItems(totalItems);
    };
    return {
        totalItems,
        setNextPage,
        initValues,
        resetPage,
        pages,
        hasNextPage,
        setCurrentDataLength,
        currentDataLength
    };
};
