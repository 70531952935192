import React, { useEffect, Fragment } from "react";
import FormWrapper from "../../../../Components/Form/FormWrapper";
import { Field } from 'redux-form';
import { useSelector } from "react-redux";
import * as documentService from '../../../../Services/document';
import { RenderImgFileUpload } from "../../../../Components/WHForm/renderCustomFileField";
import { useTranslation } from "react-i18next";
import * as userService from '../../../../Services/user';
import { editNotif } from "../../../../Services/notif/notif";
const PhotoEdition = ({ directUpdate, userId, photoProfile, callback, disablePositionRelative = false }) => {
    const photoform = useSelector((state) => state.form.photo);
    const { t } = useTranslation("freelance");
    useEffect(() => {
        if (directUpdate && photoform && photoform.hasOwnProperty('values') && photoform.values.hasOwnProperty('photo')) {
            if (photoform.values.photo.content !== photoform.initial.photo.content && photoform.values.photo.content !== "")
                submitLogo(photoform.values.photo);
        }
    }, [photoform]);
    const submitLogo = (photo) => {
        let submitPhoto = {
            file: photo.file
        };
        documentService.postFile(submitPhoto).then((data) => {
            let profileData = {
                _id: userId,
                photo: data['@id']
            };
            userService.update(profileData).then(() => {
                editNotif.success();
            });
            callback();
        }).catch((e) => {
            console.error(e);
            editNotif.failed();
        });
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", null,
            React.createElement(FormWrapper, { form: "photo", initialValues: { photo: { content: photoProfile ? photoProfile.content : undefined } } },
                React.createElement(Field, { name: 'photo', imgAsInput: true, position: "bottom", disablePositionRelative: disablePositionRelative, component: RenderImgFileUpload, roundedImage: true, label: t("colLeft.changeMyPicture") })))));
};
export default PhotoEdition;
