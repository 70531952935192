import missionList from './rest/mission';
import clientList from './rest/clientlist';
import { default as GClientList } from './graphql/clientlist';
import { default as GNewClientList } from './graphql/newclientlist';
import { default as GManagerList } from './graphql/managerlist';
import { default as GFreelanceList } from './graphql/freelancelist';
import { default as GNewFreelanceList } from './graphql/newfreelancelist';
import { default as GMissionList } from './graphql/missionlist';
import { default as MissionListES } from './graphql/missionlistes';
import { default as GMissionListProposition } from './graphql/missionlistproposition';
import { default as GMissionListValidate } from './graphql/missionlistvalidate';
import { default as GAssignRm } from './graphql/assignRm';
import statistique from "./graphql/statistique";
export const filtersConfig = {
    missionList: {
        headerFilters: missionList.headerFilters,
        filters: missionList.filters,
    },
    clientList: {
        headerFilters: clientList.headerFilters,
        filters: clientList.filters
    }
};
export const filtersGraphQlConfig = {
    clientList: {
        headerFilters: GClientList.headerFilters,
        filters: GClientList.filters
    },
    newClientList: {
        headerFilters: GNewClientList.headerFilters,
        filters: GNewClientList.filters
    },
    statistique: {
        headerFilters: statistique.headerFilters,
        filters: statistique.filters
    },
    managerList: {
        headerFilters: GManagerList.headerFilters,
        filters: GManagerList.filters
    },
    freelanceList: {
        headerFilters: GFreelanceList.headerFilters,
        filters: GFreelanceList.filters
    },
    newfreelanceList: {
        headerFilters: GNewFreelanceList.headerFilters,
        filters: GNewFreelanceList.filters
    },
    missionListES: {
        headerFilters: MissionListES.headerFilters,
        filters: MissionListES.filters
    },
    missionList: {
        headerFilters: GMissionList.headerFilters,
        filters: GMissionList.filters
    },
    missionListProposition: {
        headerFilters: GMissionListProposition.headerFilters,
        filters: GMissionListProposition.filters
    },
    missionListValidate: {
        headerFilters: GMissionListValidate.headerFilters,
        filters: GMissionListValidate.filters
    },
    assignRm: {
        headerFilters: GAssignRm.headerFilters,
        filters: GAssignRm.filters
    }
};
