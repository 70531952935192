import React, { useState, Fragment } from 'react';
import { Redirect } from "react-router";
import { WhFieldTxt, WhValidator } from "../../Components/WHForm/WhFields";
import { Field, Form } from "react-final-form";
import { Alert } from "reactstrap";
import { WhButton } from "../../Components/WHForm/Button";
import ApiService from "../../Services/ApiService";
import { LOGIN } from "../../Services/contants";
import Loading from "../../Components/Loading";
import * as Session from "./../../Shared/session/index";
import { authFeathers } from "../RessourceManager/Notifications/feathers";
const AccountTempLoginForm = () => {
    const [loaded, setLoaded] = useState(true);
    const [token, setToken] = useState(null);
    const [client, setClient] = useState(false);
    const [rm, setRm] = useState(false);
    const [freelance, setFreelance] = useState(false);
    const onSubmitLogin = async (formData) => {
        setLoaded(false);
        const userService = new ApiService(LOGIN);
        userService.create(formData).then(async (r) => {
            await Session.setJwtToken(r.token);
            if (r.data.roles.includes("ROLE_CLIENT"))
                setClient(true);
            if (r.data.roles.includes("ROLE_ADMIN"))
                setRm(true);
            if (r.data.roles.includes("ROLE_FREE"))
                setFreelance(true);
            authFeathers();
            setLoaded(true);
            setToken(null);
        }).catch((_e) => {
            setLoaded(true);
            alert("Echec de la connexion");
        });
    };
    return (React.createElement(Fragment, null,
        React.createElement(Form, { onSubmit: onSubmitLogin, render: ({ handleSubmit, error }) => {
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement("div", { className: "content" },
                        error && React.createElement(Alert, { color: "danger" }, error),
                        React.createElement("div", { className: "mt-30 field-row" },
                            React.createElement(Field, { name: "email", validate: WhValidator.required }, props => (React.createElement(WhFieldTxt, Object.assign({ name: "email" }, props, { label: "Email", className: "label-input", type: "text", disabled: false }))))),
                        React.createElement("div", { className: "mt-30 field-row password-clientaccount" },
                            React.createElement(Field, { name: "password" }, props => (React.createElement(WhFieldTxt, Object.assign({ name: "password" }, props, { label: "Password", className: "label-input", type: "password", disabled: false }))))),
                        React.createElement(WhButton, { type: "submit", color: "primary", className: "size-medium weight-bold" }, "Se connecter"))));
            } }),
        !loaded && React.createElement(Loading, null),
        token && React.createElement(Redirect, { to: `/app/login/${token}` }),
        rm && React.createElement(Redirect, { to: `/rm/dashboard` }),
        client && React.createElement(Redirect, { to: `/client/dashboard` }),
        freelance && React.createElement(Redirect, { to: `/freelance/dashboard` })));
};
export default AccountTempLoginForm;
