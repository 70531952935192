import React, { useEffect, useState, Fragment } from 'react';
import { useNotifications } from "./useNotifications";
import NotifClientCard from "./NotifClientCard";
import useGetMission from "./useGetMissionRm";
import { withRouter } from 'react-router';
import Mission from "../../Missions/Mission";
import Loading from "../../../Components/Loading";
import SearchContainer from "../../../Components/SearchContainer/SearchContainer";
import { useSelector } from "react-redux";
import { NormalFilters } from "../../SearchFilter/FiltersList";
import notiflist from "../../SearchFilter/FilterConfig/new/graphql/notiflist";
import { Route } from "react-router-dom";
import NotifCard from "./NotifCard";
import { ROLES } from "../../../constants/roles";
import { Modal, Button } from 'reactstrap';
import InfiniteScrollNotif from "../../../Components/InfiniteScroll/InfiniteScrollNotif";
const Notifications = ({ history, match, type }) => {
    const user = useSelector((state) => state.user.data);
    const [isShown, setIsShown] = useState(false);
    const [device, setDevice] = useState('computer');
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const { notifications, readedNotifications, isLoading, loadNextPage, hasNextPage, selectedNotification, getOneNotification, markNotificationAsRead, switchNotificationAsRead, updateFilters, filters, reloadData, onRemoveNotification } = useNotifications(type ? type : match.params.type);
    const [notificationIF, setNotificationIf] = useState([]);
    const { mission, isLoading: isMissionLoading, setReload, goToProfile } = useGetMission(selectedNotification ? selectedNotification.params.MISSION_ID : null, history);
    useEffect(() => {
        setNotificationIf([...notifications, ...readedNotifications]);
        setDevice(!!navigator.maxTouchPoints ? 'mobile' : 'computer');
    }, [notifications, readedNotifications]);
    const onUpdate = (_reloadAll = true) => {
        setReload(true);
    };
    const onClickNotif = (notification) => {
        setModal(true);
        markNotificationAsRead(notification);
        let pathname = user.roles.includes(ROLES.RM) ? '/rm/dashboard/' + match.params.type + '/' + notification._id : '/client/dashboard/' + notification._id;
        history.push({
            pathname: pathname,
        });
    };
    useEffect(() => {
        reloadData();
    }, [match.params.type]);
    useEffect(() => {
        getOneNotification(match.params.id);
    }, [match.params.id]);
    const getInfiniteScrollHeight = () => {
    };
    console.log("device", device);
    return (React.createElement("div", { className: ` ${user.roles.includes(ROLES.RM) ? "dashboard_bm_freelance rightContainer rightContainer--withHeadFilters littleHeader" : ""}` },
        user.roles.includes(ROLES.RM) &&
            React.createElement(SearchContainer, { title: "dashboard.main", withBtn: false, addClassName: 'blueBackground' },
                React.createElement("div", { className: 'rm_selector_container' },
                    React.createElement("div", null,
                        React.createElement("p", { className: 'size-title weight-bold brightColor' }, "Tableau de bord"),
                        notiflist.headerFilters && (React.createElement("div", { className: "filterList castorForm-formRow" },
                            React.createElement(NormalFilters, { currentValues: [{ name: 'lastName_notif', getValue: true, value: filters, filterName: 'lastName_notif' }], filters: notiflist.headerFilters, updateFilters: updateFilters })))))),
        React.createElement("div", { className: "notificationListView mission-view" },
            React.createElement("div", { className: "sidebar-left" },
                React.createElement("div", { className: "overloadGrid" },
                    !isMissionLoading && isLoading && React.createElement(Loading, null),
                    isMissionLoading && isLoading && React.createElement(Loading, { className: "load-zIndexMin" }),
                    notificationIF.length === 0 && React.createElement("div", { className: "p-10" }, "Aucune notification"),
                    React.createElement(InfiniteScrollNotif, { data: notificationIF, hasNextPage: hasNextPage, loadNextPage: loadNextPage, width: 450, rowHeight: 200, isNextPageLoading: isLoading, component: ({ index }) => {
                            if (!match.params.type) {
                                return (React.createElement(NotifCard, { notification: notificationIF[index], isSelect: selectedNotification ? notificationIF[index]._id === selectedNotification._id : false, onClick: onClickNotif, onClickPuce: switchNotificationAsRead, removeNotif: onRemoveNotification, clientView: true }));
                            }
                            if (match.params.type === 'client') {
                                return (React.createElement(NotifClientCard, { notification: notificationIF[index], onClick: onClickNotif, isSelect: selectedNotification ? notificationIF[index]._id === selectedNotification._id : false, onClickPuce: switchNotificationAsRead, removeNotif: onRemoveNotification }));
                            }
                            return (React.createElement(NotifCard, { notification: notificationIF[index], onClick: onClickNotif, isSelect: selectedNotification ? notificationIF[index]._id === selectedNotification._id : false, onClickPuce: switchNotificationAsRead, removeNotif: onRemoveNotification }));
                        } }))),
            React.createElement(Route, { path: `${user.roles.includes(ROLES.RM) ? "/rm/dashboard/:type/:id" : "/client/dashboard/:id"}` },
                !isLoading && isMissionLoading && React.createElement(Loading, { className: 'load-zIndexMin' }),
                mission &&
                    React.createElement(Fragment, null, device === "mobile" ?
                        React.createElement(Modal, { isOpen: modal, toggle: toggle, className: "modalNotifications" },
                            React.createElement(Button, { className: "closeBtn", "data-testid": 'closeButton', onClick: toggle }),
                            React.createElement(Mission, { key: JSON.stringify(mission), mission: mission, withoutHeader: true, goToProfile: goToProfile, userRole: user.roles.includes(ROLES.RM) ? ROLES.RM : ROLES.CLIENT, callback: (value) => onUpdate(value), redirect: () => onUpdate(), isShown: isShown, setIsShown: setIsShown }))
                        :
                            React.createElement(Mission, { key: JSON.stringify(mission), mission: mission, withoutHeader: true, goToProfile: goToProfile, userRole: user.roles.includes(ROLES.RM) ? ROLES.RM : ROLES.CLIENT, callback: (value) => onUpdate(value), redirect: () => onUpdate(), isShown: isShown, setIsShown: setIsShown }))))));
};
export default withRouter(Notifications);
