export const defaultStatValue = ({
    total: 0,
    totalOnDate: 0,
    costumer: {
        new: 0,
        total: 0,
        deleted: 0
    },
    freelance: {
        new: 0,
        total: 0,
        deleted: 0
    },
    canceledMissions: [{ nbr: 0, label: "test" }],
    waitingMission: [],
    statusMissions: [
        { name: "En brouillon", value: 0 },
        { name: "En cours", value: 0 },
        { name: "Ouverte", value: 0 },
        { name: "En attente", value: 0 }
    ]
});
