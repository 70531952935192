import React, { useEffect, useState } from "react";
import SearchContainer from "../../../../Components/SearchContainer/SearchContainer";
import { AccessEnum, checkAccess } from "../../../../Services/Access/access";
import { GroupedFiltersList, NormalFilters, QueryFilters } from "../../../SearchFilter/FiltersList";
import { useFilter } from "../../../../Hooks/useFilter";
import DisplaySelectedFilters from "../../../../Components/Filters/DisplaySelectedFilters";
import Result from "../../../../Components/FoundResult/Result";
import DropDownOrderFilter from "../../../../Components/Filters/DropDownOrderFilter";
import { Container } from "reactstrap";
import exportCSV from "../../../../Shared/exportCsv";
import { useTranslation } from "react-i18next";
import FFCreateClient from "../../../../Components/Clients/Form/FFCreateClient";
import Sidebar from "../../../../Components/Sidebar/View";
import Loading from "../../../../Components/Loading";
import { FalseReactTableHeader } from "../../../../Components/FalseReactTable/PatchHeader";
import InfiniteScroll from "../../../../Components/InfiniteScroll/InfiniteScroll";
import { NewClientBody } from "../../../../Components/FalseReactTable/PatchBody";
const Search = ({ setIsOpen, removeFilters, facets, userPermissions, updateFilters, headerFilters, currentFilters }) => {
    return (React.createElement(SearchContainer, { title: "clients.new", header: true, iconBtn: "circle circle-gray circle-sm icon-plus_1 size-tiny", withBtn: checkAccess(AccessEnum.CREATE_CLIENT, userPermissions), textBtn: "clients.btn", onClick: e => {
            e.stopPropagation();
            setIsOpen(true);
        } }, headerFilters && (React.createElement("div", { className: "filterList castorForm-formRow" },
        React.createElement(QueryFilters, { filter: headerFilters.find(hf => hf.name === "query"), removeFilters: removeFilters, updateFilters: updateFilters, currentValues: currentFilters }),
        React.createElement(GroupedFiltersList, { isImmediate: true, updateFilters: updateFilters, filters: headerFilters.filter(hf => hf.name !== "query"), removeFilters: removeFilters, facets: facets, currentValues: currentFilters })))));
};
const NewClientListBody = ({ user, filterConfig, facets, hasNextPage, loadNextPage, isNextPageLoading, history, data, totalItems, setRequestFilters, updateSort, onSubmitClient }) => {
    const { headerFilters, filters, updateFilters, currentFilters, filterRequest, removeFilters } = useFilter("newClientList", filterConfig);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (!setRequestFilters)
            return;
        setRequestFilters(filterRequest);
    }, [filterRequest]);
    const { t } = useTranslation(["rmclient", "notif"]);
    const changeOrder = (name, value) => {
        updateSort({ [`${name}`]: value.toLowerCase() });
    };
    const exportFile = () => exportCSV("/api/export/client_unqualified", "unqualified-client");
    const onSubmit = data => {
        setIsLoading(true);
        return onSubmitClient(data)
            .then(() => {
            setIsOpen(false);
            setIsLoading(false);
        })
            .catch(() => {
            setIsLoading(false);
        });
    };
    return (React.createElement("div", { className: `freelance_list_container rightContainer rightContainer--withHeadFilters width-selectable-filters littleHeader ` },
        React.createElement(Search, { removeFilters: removeFilters, facets: facets, userPermissions: user.permissions, updateFilters: updateFilters, currentFilters: currentFilters, headerFilters: headerFilters, setIsOpen: setIsOpen }),
        React.createElement("div", { className: "selectable-filter" }, filters && (React.createElement(NormalFilters, { facets: facets, currentValues: currentFilters, filters: filters, updateFilters: updateFilters }))),
        React.createElement(DisplaySelectedFilters, { filters: filters, headerFilters: headerFilters, selectedFilters: currentFilters, removeFilters: removeFilters }),
        React.createElement(Result, { size: totalItems }),
        React.createElement(Container, null,
            React.createElement("div", { className: "orderFilterContainer" },
                React.createElement("div", null,
                    React.createElement(DropDownOrderFilter, { title: "Trier par statut", changeOrder: changeOrder, name: "precal_state", DescText: "En Attente", AscText: "Nouveau" }),
                    React.createElement(DropDownOrderFilter, { title: "Trier par création", changeOrder: changeOrder, name: "created_at", DescText: "Du plus récent au plus ancien", AscText: "Du plus ancien au plus récent" })),
                React.createElement("span", { className: "exportButton littleTextButton", onClick: () => exportFile() }, t("export.btn"))),
            React.createElement("div", { className: "patch-wh-table " },
                React.createElement("div", { className: "overloadGrid" },
                    React.createElement(FalseReactTableHeader, { headers: [
                            t("table.state"),
                            t("table.inscription"),
                            t("table.society"),
                            t("table.activity_sector"),
                            ""
                        ] }),
                    React.createElement(InfiniteScroll, { data: data, rowHeight: 100, height: 1000, hasNextPage: hasNextPage, loadNextPage: loadNextPage, isNextPageLoading: isNextPageLoading, component: ({ index }) => {
                            return (React.createElement("div", { onClick: () => {
                                    history.push(`/rm/clients/client_edit/${data[index].ref}`);
                                } },
                                React.createElement(NewClientBody, { key: `freelance-${index}`, d: data[index], index: index })));
                        } })))),
        React.createElement(Sidebar, { showSidebar: isOpen, close: setIsOpen, title: "sidebar:client.title" },
            isLoading && React.createElement(Loading, null),
            React.createElement(FFCreateClient, { close: setIsOpen, onSubmit: onSubmit }))));
};
export default NewClientListBody;
