import { useState, useEffect } from "react";
import GraphQl from "../Shared/graphql";
import { formatGraphQlData } from "../Shared/rewriteArray";
import { setQualifButtonEnable } from "../redux/reloader/action";
import { useDispatch } from "react-redux";
export const useStoredNoFilters = (defaultStored) => {
    const [stored, setStored] = useState(defaultStored);
    const [data, setData] = useState(defaultStored.list ? [] : null);
    const [isCurrentlyLoading, setIsCurrentlyLoading] = useState(false);
    const [isLoadingForTheFirstTime, setIsLoadingForTheFirstTime] = useState(false);
    const [hasLoadAtLeastOnce, setHasLoadAtLeastOnce] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        setStored(defaultStored);
    }, [defaultStored]);
    useEffect(() => {
        if (isCurrentlyLoading)
            return;
        loadData();
    }, [stored]);
    const loadData = async () => {
        try {
            if (!isLoadingForTheFirstTime && !hasLoadAtLeastOnce)
                setIsLoadingForTheFirstTime(true);
            setIsCurrentlyLoading(true);
            const res = await GraphQl(stored);
            setData(formatGraphQlData(res.datas));
        }
        catch (queryError) {
            console.error(queryError);
        }
        finally {
            setIsCurrentlyLoading(false);
            setIsLoadingForTheFirstTime(false);
            dispatch(setQualifButtonEnable(true));
            if (!hasLoadAtLeastOnce)
                setHasLoadAtLeastOnce(true);
        }
    };
    return {
        data,
        isCurrentlyLoading,
        loadData,
        isLoadingForTheFirstTime,
        hasLoadAtLeastOnce
    };
};
