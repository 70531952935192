import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
const CurrentFilter = ({ filter, selectedFilters, onRemove }) => {
    const { t } = useTranslation("filter");
    return (React.createElement(Fragment, null,
        (!selectedFilters || selectedFilters.length === 0) && (React.createElement("div", { "data-testid": "default-filter", className: "defaultFilter" },
            React.createElement("span", null, t(filter.label)))),
        selectedFilters && selectedFilters.value && (React.createElement("div", { className: "selected_filter_container flex-row" }, Array.isArray(selectedFilters.value) ? (selectedFilters.value.map((selected, index) => {
            return (React.createElement("div", { key: `selected_${index}`, "data-testid": "selected-filter", className: "selectedFilter" },
                React.createElement("i", { onClick: e => onRemove(e, selectedFilters.name, selected), className: "fa fa-times-circle" }),
                React.createElement("span", null, filter.formatCurrent(selected))));
        })) : (React.createElement("div", { "data-testid": "selected-filter", className: "selectedFilter" },
            React.createElement("i", { onClick: e => onRemove(e, selectedFilters.name, selectedFilters.value), className: "fa fa-times-circle" }),
            React.createElement("span", null, filter.formatCurrent
                ? filter.formatCurrent(selectedFilters)
                : selectedFilters.value)))))));
};
export default CurrentFilter;
