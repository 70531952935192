import { useState, useEffect } from 'react';
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
export const useScroll = () => {
    const [scrollClass, setScrollClass] = useState("");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useScrollPosition(({ prevPos, currPos }) => {
        if (currPos.y < 50) {
            setScrollClass("scrolled");
        }
        else {
            setScrollClass("");
        }
    });
    return {
        scrollClass
    };
};
