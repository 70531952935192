import React, { useState, useEffect, Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useStored } from "../../Hooks/useStored";
import { MAX } from "../../Shared/maxResults";
import FormFilter from "../../Views/RessourceManager/Statistics/FormFilter";
import FormWrapper from "../Form/FormWrapper";
import moment from "moment";
export const StaticBar = ({ stats, totalNumber }) => {
    const getWidth = (size, totalNumber) => {
        if (totalNumber === 0)
            return `${25}%`;
        return `${size * 100 / totalNumber}%`;
    };
    return (React.createElement("div", { className: 'static-bar flex-row' },
        React.createElement("div", { style: { width: getWidth(stats.waiting, totalNumber), backgroundColor: '#40404b' } },
            React.createElement("span", null, stats.waiting)),
        React.createElement("div", { style: { width: getWidth(stats.open, totalNumber), backgroundColor: '#7e7e93' } },
            React.createElement("span", null, stats.open)),
        React.createElement("div", { style: { width: getWidth(stats.current, totalNumber), backgroundColor: '#cacad1' } },
            React.createElement("span", null, stats.current)),
        React.createElement("div", { style: { width: getWidth(stats.done, totalNumber), backgroundColor: '#e8e8e8' } },
            React.createElement("span", null, stats.done))));
};
export const StaticTable = ({ stats, totalNumber }) => {
    const getPercent = (size, totalNumber) => {
        if (totalNumber === 0)
            return '0%';
        return `${Math.round(size / totalNumber * 100)}%`;
    };
    const { t } = useTranslation('statistique');
    return (React.createElement("div", { className: 'static-bar-table' },
        React.createElement("div", { className: 'element' },
            React.createElement("span", { className: 'number flex-spacer-1 bar-color-darkblue' }, stats.waiting),
            React.createElement("span", { className: 'flex-spacer-10' }, t('waiting')),
            React.createElement("span", null,
                React.createElement("strong", { className: "size-tiny" }, getPercent(stats.waiting, totalNumber)))),
        React.createElement("div", { className: 'element' },
            React.createElement("span", { className: 'number flex-spacer-1 bar-color-lightblue' }, stats.open),
            React.createElement("span", { className: 'flex-spacer-10' }, t('open')),
            React.createElement("span", null,
                React.createElement("strong", { className: "size-tiny" }, getPercent(stats.open, totalNumber)))),
        React.createElement("div", { className: 'element' },
            React.createElement("span", { className: 'number flex-spacer-1 bar-color-grayblue' }, stats.current),
            React.createElement("span", { className: 'flex-spacer-10' }, t('current')),
            React.createElement("span", null,
                React.createElement("strong", { className: "size-tiny" }, getPercent(stats.current, totalNumber)))),
        React.createElement("div", { className: 'element' },
            React.createElement("span", { className: 'number flex-spacer-1 bar-color-gray' }, stats.done),
            React.createElement("span", { className: 'flex-spacer-10' }, t('over')),
            React.createElement("span", null,
                React.createElement("strong", { className: "size-tiny" }, getPercent(stats.done, totalNumber))))));
};
export const StaticContainer = ({ clientId, withTooltip = false }) => {
    const { t } = useTranslation('statistique');
    const [stats, setStats] = useState({ waiting: 0, open: 0, current: 0, done: 0 });
    const [statsLoaded, setStatsLoaded] = useState(false);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const iconRef = useRef(null);
    const [filters, setFilters] = useState({
        from: moment(),
        to: moment()
    });
    const MISSIONCLIENT = {
        api: process.env.REACT_APP_IDENTITY_GQL_URL
            ? process.env.REACT_APP_IDENTITY_GQL_URL
            : "",
        table: "missions",
        entity: "Mission",
        list: true,
        query: {
            fields: [
                "id",
                "_id",
                "missionStatus{statusClient}"
            ],
            maxResults: MAX,
            page: 0,
            filters: [
                {
                    value: clientId,
                    name: "society"
                }
            ]
        }
    };
    const { data, loaded, loadData } = useStored(MISSIONCLIENT, "");
    useEffect(() => {
        let stats = {
            waiting: 0,
            open: 0,
            current: 0,
            done: 0
        };
        data.map((mission) => {
            if (mission.missionStatus && mission.missionStatus.statusClient === 2200) {
                stats.waiting = stats.waiting + 1;
            }
            if (mission.missionStatus && mission.missionStatus.statusClient === 2500) {
                stats.open = stats.open + 1;
            }
            if (mission.missionStatus && mission.missionStatus.statusClient === 2700) {
                stats.current = stats.current + 1;
            }
            if (mission.missionStatus && mission.missionStatus.statusClient === 2900) {
                stats.done = stats.done + 1;
            }
        });
        setStats(stats);
        setStatsLoaded(true);
    }, [data]);
    const onFilter = dataSubmited => {
        let newDate = {
            to: dataSubmited.to.hasOwnProperty("_isAMomentObject")
                ? moment(dataSubmited.to)
                : dataSubmited.to,
            from: dataSubmited.from.hasOwnProperty("_isAMomentObject")
                ? moment(dataSubmited.from)
                : dataSubmited.from
        };
        setFilters(newDate);
    };
    return React.createElement("div", { className: 'static-container roundBlock' },
        React.createElement("div", { className: 'thinTitle border-with-bottom border-color-light pb-10', ref: iconRef },
            React.createElement("span", null, t('title')),
            withTooltip &&
                React.createElement("div", { className: "toolTip-container", onMouseEnter: () => setIsTooltipOpen(!isTooltipOpen), onMouseLeave: () => setIsTooltipOpen(false) },
                    React.createElement("i", { className: "icon-info" }),
                    isTooltipOpen && (React.createElement("div", { className: "toolTip-content", style: {
                            top: iconRef.current.getBoundingClientRect().top - 73,
                        } },
                        React.createElement("p", { className: "size-medium" }, t("popup")))))),
        React.createElement("div", null),
        loaded && statsLoaded &&
            React.createElement(Fragment, null,
                React.createElement("div", { style: { display: 'none', opacity: 0 } },
                    React.createElement(FormWrapper, { onSubmit: onFilter, form: "StatisticFormFilter", initialValues: filters },
                        React.createElement(FormFilter, null))),
                React.createElement(StaticBar, { stats: stats, totalNumber: data.filter((d) => {
                        if (!d.missionStatus)
                            return false;
                        const s = d.missionStatus.statusClient;
                        return s === 2200 || s === 2500 || s === 2700 || s === 2900;
                    }).length }),
                React.createElement(StaticTable, { stats: stats, totalNumber: data.filter((d) => {
                        if (!d.missionStatus)
                            return false;
                        const s = d.missionStatus.statusClient;
                        return s === 2200 || s === 2500 || s === 2700 || s === 2900;
                    }).length })));
};
