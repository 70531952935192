import React, { Fragment, useEffect } from "react";
import { Button } from "reactstrap";
import { buttonsText, statusParameters } from "./buttonText";
import { useTranslation } from "react-i18next";
const ClientButtonsList = ({ status, userTakenCare, indication, onSubmit, setNumberButton }) => {
    const { t } = useTranslation("missions");
    useEffect(() => {
        if (status.label === statusParameters["DRAFT"] ||
            status.label === statusParameters["IN_PROGRESS"] ||
            status.label === statusParameters["UPDATED"])
            setNumberButton(3);
        if ((status.label === statusParameters["VALIDATION_WAIT"] && userTakenCare) ||
            status.label === statusParameters["REFUSED"])
            setNumberButton(1);
        if ((status.label === statusParameters["VALIDATION_WAIT"] &&
            !userTakenCare) ||
            (status.label === statusParameters["OPEN"] && indication &&
                indication.value === 100) ||
            (status.label === statusParameters["OPEN"] && (!indication || (indication && indication.value === 200))))
            setNumberButton(2);
    }, [status]);
    return (React.createElement(Fragment, null,
        status.label === statusParameters["DRAFT"] && (React.createElement("div", { className: "container-flex container-flex-row" },
            React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-blue wh-btn", onClick: () => {
                    if (onSubmit)
                        onSubmit("TRUE_DELETE");
                } }, t(buttonsText["DELETE"])),
            React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-edit wh-btn", onClick: () => {
                    if (onSubmit)
                        onSubmit("EDIT");
                } }, t(buttonsText["EDIT"])),
            React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-primary wh-btn", onClick: () => {
                    if (onSubmit)
                        onSubmit("SEND_RM");
                } }, t(buttonsText["SEND_RM"])))),
        status.label === statusParameters["VALIDATION_WAIT"] && userTakenCare && (React.createElement("div", { className: "container-flex container-flex-row" },
            React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-blue wh-btn", onClick: () => {
                    if (onSubmit)
                        onSubmit("TRUE_DELETE");
                } }, t(buttonsText["DELETE"])))),
        status.label === statusParameters["VALIDATION_WAIT"] && !userTakenCare && (React.createElement("div", { className: "container-flex container-flex-row" },
            React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-blue wh-btn", onClick: () => {
                    if (onSubmit)
                        onSubmit("TRUE_DELETE");
                } }, t(buttonsText["DELETE"])),
            React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-edit wh-btn", onClick: () => {
                    if (onSubmit)
                        onSubmit("EDIT");
                } }, t(buttonsText["EDIT"])))),
        status.label === statusParameters["UPDATED"] && (React.createElement("div", { className: "container-flex container-flex-row" },
            React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-blue wh-btn", onClick: () => {
                    if (onSubmit)
                        onSubmit("TRUE_DELETE");
                } }, t(buttonsText["DELETE"])),
            React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-send wh-btn", onClick: () => {
                    if (onSubmit)
                        onSubmit("DECLINE_UPDATE");
                } }, t(buttonsText["DECLINE_UPDATE"])),
            React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-primary wh-btn", onClick: () => {
                    if (onSubmit)
                        onSubmit("VALIDATE_MISSION");
                } }, t(buttonsText["VALIDATE_MISSION"])))),
        status.label === statusParameters["REFUSED"] && (React.createElement("div", { className: "container-flex container-flex-row" },
            React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-blue wh-btn", onClick: () => {
                    if (onSubmit)
                        onSubmit("REFUSE_DELETE");
                } }, t(buttonsText["DELETE"])))),
        status.label === statusParameters["OPEN"] && indication &&
            indication.value === 100 && (React.createElement("div", { className: "container-flex container-flex-row" },
            React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-blue wh-btn", onClick: () => {
                    if (onSubmit)
                        onSubmit("SHALLOW_DELETE");
                } }, t(buttonsText["DELETE"])),
            React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-edit wh-btn", onClick: () => {
                    if (onSubmit)
                        onSubmit("CHANGE_END_DATE");
                } }, t(buttonsText["CHANGE_END_DATE_MISSION"])))),
        status.label === statusParameters["OPEN"] &&
            (!indication || (indication && indication.value === 200)) && (React.createElement("div", { className: "container-flex container-flex-row" },
            React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-blue wh-btn", onClick: () => {
                    if (onSubmit)
                        onSubmit("SHALLOW_DELETE");
                } }, t(buttonsText["DELETE"])),
            React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-edit wh-btn", onClick: () => {
                    if (onSubmit)
                        onSubmit("CHANGE_END_DATE");
                } }, t(buttonsText["CHANGE_END_DATE_MISSION"])))),
        status.label === statusParameters["IN_PROGRESS"] && (React.createElement("div", { className: "container-flex container-flex-row" },
            React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-blue wh-btn", onClick: () => {
                    if (onSubmit)
                        onSubmit("SHALLOW_DELETE");
                } }, t(buttonsText["DELETE"])),
            React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-primary wh-btn", onClick: () => {
                    if (onSubmit)
                        onSubmit("CHANGE_END_DATE");
                } }, t(buttonsText["CHANGE_END_DATE_MISSION"])),
            React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-edit wh-btn", onClick: () => {
                    if (onSubmit)
                        onSubmit("END_MISSION");
                } }, t(buttonsText["PUT_END_TO_MISSION"]))))));
};
export default ClientButtonsList;
