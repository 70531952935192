import React, { Fragment, useState } from "react";
import ImageOverlay from "../ImageOverlay/ImageOverlay";
import { NavLink } from "react-router-dom";
import { ROLES } from "../../constants/roles";
import UserMenu from "./UserMenu";
import { addHTTPS } from "../../Shared/utils/utils";
import { formatFirstName, formatLastName } from "../../helper/formatUserName";
const UserSnapshot = ({ firstname, lastname, onClickNotif, notifications = [], user_role, picture }) => {
    const [open, setOpen] = useState(false);
    const toggleMenu = () => {
        setOpen(!open);
    };
    return (React.createElement(Fragment, null,
        (user_role === ROLES.FREE || user_role === ROLES.CLIENT) && (React.createElement(NavLink, { className: "contact-nav d-none d-sm-flex", to: "www.google.com" }, "Contact")),
        React.createElement("div", { className: "userSnapshot" },
            React.createElement("button", { className: "user-btn", onClick: toggleMenu },
                picture &&
                    React.createElement(ImageOverlay, { icon: notifications.length > 0 ? "icon-notif" : '', media_src: picture ? addHTTPS(picture.content) : undefined }),
                React.createElement("span", { className: "icon-fleche rotate-90 d-sm-none" }),
                React.createElement("div", { className: "user-content d-none d-sm-flex" },
                    React.createElement("div", null,
                        React.createElement("span", { className: "text-color-gray" }, "Hey salut!")),
                    React.createElement("div", null,
                        React.createElement("span", { className: "user-name" }, `${formatFirstName(firstname).trim()} ${formatLastName(lastname).trim()}`),
                        React.createElement("span", { className: "icon-fleche rotate-90" })))),
            React.createElement(UserMenu, { open: open, toggleMenu: toggleMenu, user_role: user_role, notifications: notifications, onClickNotif: onClickNotif }))));
};
export default UserSnapshot;
