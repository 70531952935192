import React from "react";
import { formatFirstName, formatLastName } from "../../../helper/formatUserName";
import PopOver from "../../../Components/PopOver/PopOver";
export const columns = [
    {
        Header: "Nom",
        sortable: false,
        accessor: "lastName",
        default: true,
        filterable: false,
        Cell: (row) => {
            const text = formatLastName(row.value);
            return (React.createElement("div", null,
                React.createElement(PopOver, { text: text, className: 'toolTip-popover toolTip-popover-managerlist', condition: (_text) => true, render: ({ setIsHover, formattedText }) => {
                        return (React.createElement("span", { onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false), className: "" }, formattedText));
                    } })));
        }
    },
    {
        Header: "Prénom",
        sortable: false,
        accessor: "firstName",
        default: true,
        filterable: false,
        Cell: (row) => {
            const text = formatFirstName(row.value);
            return (React.createElement("div", null,
                React.createElement(PopOver, { text: text, className: 'toolTip-popover toolTip-popover-managerlist', condition: (_text) => true, render: ({ setIsHover, formattedText }) => {
                        return (React.createElement("span", { onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false), className: "" }, formattedText));
                    } })));
        }
    },
    {
        Header: "Email",
        sortable: false,
        accessor: "email",
        default: true,
        filterable: false,
        Cell: (row) => {
            const text = row.value;
            return (React.createElement("div", null,
                React.createElement(PopOver, { text: text, className: 'toolTip-popover toolTip-popover-managerlist', condition: (_text) => true, render: ({ setIsHover, formattedText }) => {
                        return (React.createElement("span", { onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false), className: "" }, formattedText));
                    } })));
        }
    },
    {
        Header: "Téléphone",
        sortable: false,
        accessor: "phone",
        default: true,
        filterable: false
    },
    {
        sortable: false,
        accessor: "id",
        default: true,
        filterable: false,
        maxWidth: 70,
        Cell: () => React.createElement("span", { className: "arrowRight" })
    }
];
