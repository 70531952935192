const traverseObj = (data, f, debug = false) => {
    let obj = {};
    for (let prop in data) {
        if (data[prop] && data[prop].hasOwnProperty('edges')) {
            obj = {
                ...obj,
                [`${prop}`]: f(data[prop], debug)
            };
        }
        else if (!Array.isArray(data[prop]) && typeof (data[prop]) === 'object') {
            obj = {
                ...obj,
                [`${prop}`]: traverseObj(data[prop], f, debug)
            };
        }
        else {
            obj = { ...obj, [`${prop}`]: data[prop] };
        }
    }
    return Object.keys(obj).length > 0 ? obj : null;
};
export const formatGraphQlData = (data, debug = false) => {
    if (data === null) {
        return null;
    }
    if (Array.isArray(data)) {
        if (data.length === 0)
            return [];
        return data.map((d) => traverseObj(d, rewriteArray, debug));
    }
    return traverseObj(data, rewriteArray, debug);
};
const rewriteArray = (array, debug = false) => {
    if (debug)
        console.log("rewriteArray::", array);
    let newArr = [];
    if (array.edges) {
        newArr = array.edges.map((item) => traverseObj(item.node, rewriteArray));
    }
    else {
        newArr = Object.values(array);
    }
    return newArr;
};
export default rewriteArray;
