import { createAction } from 'redux-actions';
import { SET_USER } from './constant.js';
import { formatGraphQlData } from "../../Shared/rewriteArray";
import GraphQl from '../../Shared/graphql';
import * as api from '../../Shared/session/api';
import { USERTORED } from '../../Shared/userStored';
import { setSocietyThunk } from "/wh/redux/society/action";
import RestService from "/wh/Services/Rest/RestService";
import { getSingleObjectOnlyKeys } from "/wh/Services/Rest/getOnlyKeys";
export const setUser = createAction(SET_USER, (user) => ({ user }));
const getDataUser = async (graphQlConfig) => {
    const res = await GraphQl(graphQlConfig);
    return formatGraphQlData(res.datas);
};
export const setUserThunk = (storeConfig) => {
    return (dispatch) => getDataUser(storeConfig).then(async (res) => {
        dispatch(setUser(res));
        dispatch(setSocietyThunk(res._id));
    });
};
const address = { name: "address", type: "obj", value: ["city", "country", "address", "cpltAddress", "zipCode", "mobilityCity", "mobilityRayon"] };
const addressWithId = { name: "address", type: "obj", value: ["id", "_id", "city", "country", "address", "cpltAddress", "zipCode", "mobilityCity", "mobilityRayon"] };
export const singleUserKey = [
    "id",
    "_id",
    "firstName",
    "lastName",
    "email",
    "birthdate",
    "phone",
    "type",
    "nationality",
    "civility",
    "roles",
    { name: "photo", type: "obj", value: ["id", "_id", "content"] },
    { name: "newsletter", type: "obj", value: ["id", "_id"] },
    "helpCreation",
    { name: "permissions", type: "array", value: ["id", "_id", { name: "permission", type: "obj", value: ["id", "_id", "slug", "name"] }, "value"] },
    {
        name: "society",
        type: "obj",
        value: [
            { name: "photo", type: "obj", value: ["id", "_id"] },
            "_id",
            "id",
            { ...address },
            { name: "rm", type: "obj", value: ["firstName", "lastName", "phone", "email"] },
        ]
    },
    {
        name: "profile",
        type: "obj",
        value: [
            "_id",
            "id",
            { name: "tags", type: "obj", value: ["_id", "id", "status", "name"] },
            "status",
            "expertise",
            { ...addressWithId },
            "prioritized",
            "description",
            "job",
            "linkedin",
            "tjm",
            "availability",
            { name: "profileDocuments", type: "array", value: ["_id", "type", { name: "document", type: "obj", value: ["_id", "id", "url", "type", "name"] }] },
        ]
    }
];
export const setUserFromRestThunk = (id) => {
    const userEndPoint = `${process.env.REACT_APP_IDENTITY_API_URL ? process.env.REACT_APP_IDENTITY_API_URL : ""}`;
    const service = new RestService(userEndPoint);
    return (dispatch) => service.get(id).then((async (res) => {
        const newObj = getSingleObjectOnlyKeys(singleUserKey, res);
        dispatch(setUser(newObj));
    }));
};
export const setUserDefault = () => (dispatch) => {
    api.me().then((data) => {
        dispatch(setUserThunk({ ...USERTORED, query: { ...USERTORED.query, filters: [{ value: data['@id'], name: 'id' }] } }));
    });
};
