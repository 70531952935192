export const AccessEnum = {
    QUALIF_CLIENT: "qualif_costumer",
    DELETE_CLIENT: "delete_client",
    ACT_COUNTER: "counter",
    CREATE_CLIENT: "create_account_costumer",
    VIEW_CLIENT: "view_costumer",
    QUALIF_FREELANCE: "qualif_freelance",
    ACCESS_PROFIL: "access_profil",
    DELETE_FREELANCE: "delete_account",
    VALIDATE_FOLDER: "validate_folder_freelance",
    CREATE_EDIT_MISSION: "create_edit_mission",
    PUBLISH_MISSION: "publish_mission",
    ACT_CANDIDATURE: "put_in_relation",
    CLOSE_MISSION: "close_mission",
    DELETE_MISSION: "delete_mission",
    ADD_MEMBER: "add_member",
    HANDLING_RIGHT: "handle_right_members",
    STATISTIQUES: "access_stat",
    RM_DELETE: "rm_delete"
};
export const checkAccess = (permissionSlug, userPermission) => {
    if (!userPermission)
        return false;
    const permission = userPermission.find((p) => p.permission.slug === permissionSlug);
    if (permission)
        return permission.value;
    return false;
};
