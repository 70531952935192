import { createAction } from 'redux-actions';
import { fetch } from '../../Shared/fetch';
import { SET_NATIONALITIES, SET_USER_STATUSES, SET_CIVILITIES, SET_EXPERTISES, SET_MISSIONS_STATUS, SET_FL_STATUS, SET_CANDIDATURE_CL_STATUS, SET_MISSION_URL, SET_PARTNERS_URL } from "./constant";
import * as storage from "../../Shared/storage";
export const setUserStatus = createAction(SET_USER_STATUSES, (data) => (data));
export const setCivilities = createAction(SET_CIVILITIES, (data) => (data));
export const setExpertises = createAction(SET_EXPERTISES, (data) => (data));
export const setMissionsStatus = createAction(SET_MISSIONS_STATUS, (data) => (data));
export const setFlStatus = createAction(SET_FL_STATUS, (data) => (data));
export const setCandidatureClStatus = createAction(SET_CANDIDATURE_CL_STATUS, (data) => (data));
export const setNationalities = createAction(SET_NATIONALITIES, (data) => (data));
export const setMissionUrl = createAction(SET_MISSION_URL, (data) => (data));
export const setPartnerUrl = createAction(SET_PARTNERS_URL, (data) => (data));
const endPoints = '/parameters';
export const fetchParameters = () => async (dispatch) => {
    try {
        const parameters = await fetch(`${process.env.REACT_APP_IDENTITY_API_URL}${endPoints}`, 'get');
        storage.save('parameters', parameters);
        dispatch(setUserStatus(parameters.profile_status));
        dispatch(setCivilities(parameters.civilities));
        dispatch(setExpertises(parameters.expertise));
        dispatch(setMissionsStatus(parameters.missionsStatus));
        dispatch(setFlStatus(parameters.fl_status));
        dispatch(setNationalities(parameters.nationalities));
        dispatch(setCandidatureClStatus(parameters.candidature_cl_status));
        dispatch(setMissionUrl(parameters.missionsUrl));
        dispatch(setPartnerUrl(parameters.partnersUrl));
    }
    catch (error) {
        console.error("Error while fetching parameters::", error);
    }
};
