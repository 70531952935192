import React, { Fragment } from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { WhFieldTxt } from "../WHForm/WhFields";
import FFSelect from "../../Components/FinalForm/FFSelect";
import { MAX } from "../../Shared/maxResults";
import { useTranslation } from "react-i18next";
const TagList = ({ fields, disabled, tags = [] }) => {
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: "flex-row flex-row-space-between flex-wrap" }, fields.map((_field, idx) => {
            return (React.createElement("div", { key: `tags-edit-${idx}`, className: `tag-item mb-10 flex-directionRow flex-justifyStart flex-alignCenter  ${tags[idx] && tags[idx].status === "draft" ? "draftTag" : ""}` },
                React.createElement("i", { className: "icon-incomplet-copy size-tiny ml-3 mr-3 cursor", onClick: () => {
                        fields.remove(idx);
                    } }),
                React.createElement(Field, { name: `${_field}.name`, required: true, disabled: true }, props => (React.createElement(WhFieldTxt, Object.assign({}, props, { name: _field, type: "text", className: "label-input m-0", size: tags[idx].name.length }))))));
        }))));
};
export const TagsFormArray = ({ tags = [] }) => {
    return (React.createElement("div", { className: "tagsForm mb-3" },
        React.createElement(FieldArray, { name: "tags" }, ({ fields }) => (React.createElement(TagList, { fields: fields, disabled: false, tags: tags })))));
};
export const NewTagsForm = ({ tags = [], mutator, maxValue, format = null, values = [] }) => {
    let TAGSTORED = {
        api: process.env.REACT_APP_IDENTITY_GQL_URL
            ? process.env.REACT_APP_IDENTITY_GQL_URL
            : "",
        table: "tags",
        entity: "Tag",
        list: true,
        query: {
            fields: ["id", "_id", "name", "status"],
            filtersToUpdate: {
                field: "name",
                objectField: "name",
                orderBy: { name: 'name' }, order: 'ASC',
            },
            maxResults: MAX,
            page: 0,
            filters: [{ name: "status", value: "published" }]
        }
    };
    const { t } = useTranslation("common");
    return (React.createElement("div", { className: "flex-directionRow flex-justifyStart flex-alignCenter fullWidth" },
        React.createElement(FFSelect, { name: "new_tags", multiple: true, isSearchable: true, creatable: true, onChange: mutator, maxValue: maxValue, asyncParameters: TAGSTORED, format: format, options: tags, values: values, placeholder: t("tags_select") })));
};
export default TagsFormArray;
