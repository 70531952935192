import React from "react";
import { rmEvent } from "./rmEventConstant";
import Select from "react-select";
const RmStatusSelect = ({ row, readonly, rowData, actions }) => {
    const options = rowData.options;
    const currentValue = rowData.rows.hasOwnProperty(row.index)
        ? rowData.rows[row.index].statusRm
        : null;
    const addRmSelected = value => {
        actions.updateStatusRm(value, row.index);
    };
    const onChange = value => {
        addRmSelected(value);
    };
    const isDisabled = row.original.statusFl === "1400" ||
        row.value === rmEvent["TRANSFERT"] ||
        row.value === rmEvent["REFUSER"] ||
        row.value === rmEvent["ACCEPT"];
    return (React.createElement("div", { className: "rmChoice" },
        React.createElement(Select, { isDisabled: isDisabled ? isDisabled : false, className: "rmChoice-select weight-light size-small", classNamePrefix: "rmChoice", value: currentValue
                ? options.find(opt => opt.value === currentValue)
                : options.find(opt => opt.value === row.value), onChange: onChange, options: options })));
};
export default RmStatusSelect;
