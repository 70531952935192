import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import configureStore from "./redux/store";
import "bootstrap/dist/css/bootstrap.css";
import 'react-quill/dist/quill.snow.css';
import "./styles";
import App from "./App";
import { I18Load } from "./i18/i18load";
const history = createBrowserHistory();
const { store } = configureStore(history, {});
import * as Sentry from "@sentry/browser";
if (process.env.REACT_APP_SENTRY_URL) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
    });
}
ReactDOM.render(React.createElement(Provider, { store: store },
    React.createElement(Fragment, null,
        React.createElement(I18Load, { render: () => (React.createElement(BrowserRouter, null,
                React.createElement(Switch, null,
                    React.createElement(Route, { path: "/", name: "main", component: App })))) }))), document.getElementById("root"));
