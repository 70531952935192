export const sections = [
    {
        title: 'about',
        anchorName: 'about'
    },
    {
        title: 'notes',
        anchorName: 'notes'
    },
    {
        title: 'studies',
        anchorName: 'studies'
    },
    {
        title: 'skill',
        anchorName: 'skill'
    },
    {
        title: 'reference',
        anchorName: 'reference'
    },
    {
        title: 'portfolio',
        anchorName: 'portfolio'
    },
    {
        title: 'documents',
        anchorName: 'documents'
    },
    {
        title: 'informations',
        anchorName: 'informations'
    },
];
export const AccountClientSections = [
    {
        title: 'Société',
        anchorName: 'society'
    },
    {
        title: 'Contact(s)',
        anchorName: 'contacts'
    },
    {
        title: 'Identifiants',
        anchorName: 'identifiant'
    }
];
export const EditClientSections = [
    {
        title: 'Société',
        anchorName: 'society'
    },
    {
        title: 'Contact(s)',
        anchorName: 'contacts'
    },
    {
        title: 'Note(s)',
        anchorName: 'notes'
    },
    {
        title: 'Statistiques',
        anchorName: 'statistique'
    },
    {
        title: 'Missions restantes',
        anchorName: 'missionsrestantes'
    },
];
