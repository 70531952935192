import { handleActions } from "redux-actions";
import { SET_FULL_CONTAINER_CLASS, ADD_FULL_CONTAINER_CLASS, REMOVE_FULL_CONTAINER_CLASS, } from "./constant";
const defaultState = { fullContainer: ["full"] };
const styleReducer = handleActions({
    [SET_FULL_CONTAINER_CLASS]: (state, action) => ({
        ...state,
        fullContainer: action.payload.classes,
    }),
    [ADD_FULL_CONTAINER_CLASS]: (state, action) => ({
        ...state,
        fullContainer: [...state.fullContainer].concat(action.payload.newClass),
    }),
    [REMOVE_FULL_CONTAINER_CLASS]: (state, action) => {
        const newState = [...state.fullContainer];
        const index = newState.indexOf(action.payload.removedClass);
        if (index > -1) {
            newState.splice(index, 1);
        }
        return { ...state, fullContainer: newState };
    },
}, defaultState);
export default styleReducer;
