import React, { useEffect, useRef, useState } from "react";
import AsyncSelect from "react-select/async";
import RestService from "../../../Services/Rest/RestService";
import GraphQl from "../../../Shared/graphql";
import { formatGraphQlData } from "../../../Shared/rewriteArray";
import ElasticService from "../../../Services/ElasticService";
import { CLIENTS, FREELANCEES } from "../../../Services/contants";
import { EsFormat, EsFormatV2, EsGetFilters, } from "../../../Shared/utils/esformat";
const FormatLabelMultiple = (props) => {
    const { currentValue } = props.selectProps;
    if (!currentValue || (currentValue && !currentValue.value))
        return (React.createElement("div", { className: `displaySelected` },
            props.selectProps.inputValue ? (React.createElement("div", { className: "selectName" }, props.selectProps.inputValue)) : (React.createElement("div", { className: "selectName" }, props.selectProps.placeholder)),
            React.createElement("div", { className: "select-dropdown noShowDropdown" }, props.children)));
    return (React.createElement("div", { className: `displaySelected` },
        React.createElement("div", { className: "selectName" }, props.selectProps.inputValue
            ? props.selectProps.inputValue
            : `${props.selectProps.label}-${currentValue.value.length}`),
        React.createElement("div", { className: "select-dropdown noShowDropdown" }, props.children)));
};
const FormatOptionLocation = (props) => {
    const { currentValue } = props.selectProps;
    const isChecked = currentValue && currentValue.value
        ? currentValue.value.findIndex((v) => v === props.value) > -1
        : false;
    const handleChange = () => {
        props.selectProps.onChange(props.value, isChecked);
    };
    return (React.createElement("div", { style: { display: "flex" }, className: "formatoption-sector-container formatoption-sector-container--location", onClick: handleChange },
        React.createElement("div", { className: isChecked
                ? "formatoption-checkbox formatoption-checkbox-selected"
                : "formatoption-checkbox" }),
        React.createElement("div", { className: `formatoption-label` }, props.label)));
};
const FormatOptionSector = (props) => {
    const { currentValue } = props.selectProps;
    const isChecked = currentValue && currentValue.value
        ? currentValue.value.findIndex((v) => v === props.value) > -1
        : false;
    const handleChange = () => {
        props.selectProps.onChange(props.value, isChecked);
    };
    return (React.createElement("div", { style: { display: "flex" }, className: "formatoption-sector-container", onClick: handleChange },
        React.createElement("div", { className: isChecked
                ? "formatoption-checkbox formatoption-checkbox-selected"
                : "formatoption-checkbox" }),
        React.createElement("div", { className: `formatoption-label` }, props.label)));
};
const FormatOptionSimple = (props) => {
    const handleChange = () => {
        props.setValue(props.data);
    };
    return (React.createElement("div", { style: { display: "flex" }, className: "cursorPointer formatoption-sector-container", onClick: handleChange },
        React.createElement("div", { className: `formatoption-label` }, `${props.data && props.data.hasOwnProperty("displayLabel")
            ? props.data.displayLabel
            : props.label}`)));
};
export const FilterAsyncSelect = ({ filter, facets = null, currentValue, props, containerSize = "mid", }) => {
    const [isOpened, setIsOpened] = useState(false);
    const [isLoading, setIsLoading] = useState([]);
    const [message, setMessage] = useState(null);
    const filterAsyncRef = useRef();
    const { asyncQueryParameters, customComponentParameters } = filter;
    useEffect(() => {
        if (!message)
            return;
        if (message.value === "PUSH") {
            setIsLoading([...isLoading, { id: message.id, isLoading: true }]);
        }
        if (message.value === "POP") {
            const idx = isLoading.findIndex((l) => l.id === message.id);
            const updatedLoading = [...isLoading];
            updatedLoading.splice(idx, 1);
            setIsLoading(updatedLoading);
        }
    }, [message]);
    const getListFromFacets = (format, input) => {
        if (facets && facets.hasOwnProperty(filter.name)) {
            let arr = facets[filter.name];
            if (arr.length > 0) {
                const result = format(arr[0].data);
                const reg = new RegExp(input.toLowerCase(), "i");
                return result.filter((v) => reg.test(v.label.toLowerCase()));
            }
        }
        return [];
    };
    const getEsQuery = (filters, value, orderBy) => {
        let req = {
            query: value
                ? value
                : process.env.REACT_APP_ELASTIC_VER === "V1"
                    ? ""
                    : "*",
            filters: EsGetFilters(filters),
            page: { current: 1, size: 100 },
        };
        if (orderBy) {
            req = {
                ...req,
            };
        }
        return req;
    };
    const asyncLoad = async (value, queryParams, facets = null) => {
        const { query, params } = queryParams;
        const { pageSize, page, filter_name, type, defaultFilters } = params;
        let filtered;
        let res;
        if (!type || type === "rest") {
            const service = new RestService(query);
            filtered = value
                ? [
                    {
                        id: filter_name,
                        value: value,
                    },
                    ...defaultFilters,
                ]
                : [...defaultFilters];
            res = await service.search({ pageSize, page, filtered });
            setIsLoading([...isLoading, { value: value, isLoading: true }]);
            setIsLoading(false);
            return queryParams.formatResult(res["hydra:member"]);
        }
        else if (type && type === "elasticsearch") {
            const freelanceService = new ElasticService(FREELANCEES);
            const result = await freelanceService.search(getEsQuery(defaultFilters, value, query.query.orderBy));
            const newResult = process.env.REACT_APP_ELASTIC_VER === "V1"
                ? result.results.map((r) => EsFormat(r))
                : result.hits.hits.map((r) => EsFormatV2(r));
            return queryParams.formatResult(newResult);
        }
        else if (type && type === "elasticsearchcustomer") {
            const customerService = new ElasticService(CLIENTS);
            const result = await customerService.search(getEsQuery(defaultFilters, value, query.query.orderBy));
            const newResult = process.env.REACT_APP_ELASTIC_VER === "V1"
                ? result.results.map((r) => EsFormat(r))
                : result.hits.hits.map((r) => EsFormatV2(r));
            return queryParams.formatResult(newResult);
        }
        else {
            if (facets) {
                return getListFromFacets(queryParams.formatResult, value);
            }
            filtered = value
                ? [
                    {
                        name: filter_name,
                        value: value,
                    },
                    ...defaultFilters,
                ]
                : [...defaultFilters];
            const id = Math.random();
            setMessage({ id: id, value: "PUSH" });
            res = await GraphQl({
                ...query,
                query: { ...query.query, filters: filtered },
            });
            setMessage({ id: id, value: "POP" });
            return queryParams.formatResult(formatGraphQlData(res.datas));
        }
    };
    const handleChange = (value, toRemove) => {
        if (customComponentParameters.isMulti) {
            const updatedValues = currentValue && currentValue.value ? [...currentValue.value] : [];
            if (toRemove) {
                props.input.onChange([]);
                return;
            }
            else
                updatedValues.push(value);
            props.input.onChange(updatedValues);
        }
        else {
            props.input.onChange(value);
        }
    };
    const SwitchComponent = (props) => {
        return {
            location: React.createElement(FormatOptionLocation, Object.assign({}, props)),
            activity_sector: React.createElement(FormatOptionSector, Object.assign({}, props)),
            default: React.createElement(FormatOptionSimple, Object.assign({}, props, { isLoading: isLoading })),
        }[props.selectProps.customComponent];
    };
    const loadOptionOnOpen = () => {
        setIsOpened(true);
        if (filterAsyncRef && filterAsyncRef.current) {
            const res = filterAsyncRef.current.props.loadOptions("", (value) => asyncLoad(value, asyncQueryParameters));
            filterAsyncRef.current.setState({ isLoading: true });
            res.then((values) => {
                filterAsyncRef.current.setState({
                    defaultOptions: values,
                    isLoading: false,
                });
            });
        }
    };
    const getCurrentValue = () => {
        if (currentValue && currentValue.hasOwnProperty("value")) {
            if (!currentValue.value)
                return "";
            if (currentValue.hasOwnProperty("getValue")) {
                return currentValue.value;
            }
            return currentValue;
        }
        return currentValue &&
            currentValue.hasOwnProperty("value") &&
            !currentValue.value
            ? ""
            : currentValue;
    };
    return (React.createElement("div", { className: `castorForm-inputContainer select-container ${containerSize}` },
        React.createElement(AsyncSelect, Object.assign({}, props, { name: props.label, ref: filterAsyncRef, onMenuOpen: loadOptionOnOpen, onChange: handleChange, isSearchable: customComponentParameters.isSearchable, closeOnSelect: true, isLoading: isLoading.length > 0, currentValue: currentValue, hideSelectedOptions: false, className: "react-select", classNamePrefix: "react-select", filterOption: () => true, closeMenuOnSelect: true, onSelectResetsInput: false, onBlur: () => {
                setIsOpened(false);
            }, noOptionsMessage: () => null, value: getCurrentValue(), values: currentValue && currentValue.hasOwnProperty("value")
                ? currentValue.value
                : undefined, isMulti: asyncQueryParameters.isMulti, customComponent: customComponentParameters.customComponentName, components: customComponentParameters.isMulti
                ? {
                    ValueContainer: FormatLabelMultiple,
                    Option: (props) => SwitchComponent(props),
                }
                : {
                    Option: (props) => SwitchComponent(props),
                }, defaultOptions: false, placeholder: filter.placeholder && !isOpened ? filter.placeholder : null, loadOptions: (value) => asyncLoad(value, asyncQueryParameters, facets) })),
        React.createElement("label", { className: "selectLabel" }, props.label),
        props && props.meta && props.meta.touched && props.meta.error && (React.createElement("span", { className: "form__form-group-error formError" }, props.meta.error))));
};
