import { FILTER_TYPE_SELECT, FILTER_TYPE_SELECT_AUTOC, FILTER_TYPE_TEXT } from "../constants";
import { MAX } from "../../../Shared/maxResults";
import moment from "moment";
const USERSTORED = {
    api: process.env.REACT_APP_IDENTITY_GQL_URL
        ? process.env.REACT_APP_IDENTITY_GQL_URL
        : "",
    table: "users",
    entity: "User",
    list: true,
    query: {
        fields: ["id", "_id", "firstName", "lastName"],
        maxResults: MAX,
        page: 0,
        filtersToUpdate: {
            field: "firstName",
            objectField: ["^(", 'firstName', "lastName"]
        },
        filters: [{ name: "type", value: "RM" }]
    }
};
const MISSIONSTORED = {
    api: process.env.REACT_APP_IDENTITY_GQL_URL
        ? process.env.REACT_APP_IDENTITY_GQL_URL
        : "",
    table: "societies",
    entity: "Society",
    list: true,
    query: {
        fields: ["id", "_id", "name", "mainContact{firstName lastName}"],
        maxResults: MAX,
        page: 0,
        filtersToUpdate: {
            field: "name",
            objectField: ["name", "mainContact.firstName", "mainContact.lastName"]
        },
        filters: [{ value: { mainContact_deletedAt: false }, name: "exists" }],
    }
};
const headerFilters = [
    {
        name: "society_name",
        defaultName: "",
        inputAsDefault: true,
        canBeRemove: true,
        isHeader: true,
        isModal: false,
        isSearchable: true,
        formatFilterValue: obj => {
            return obj ? obj.filter : obj;
        },
        formatCurrent: obj => {
            return obj ? obj.value : obj;
        },
        asyncParameters: MISSIONSTORED,
        label: "search.client",
        className: "label-input filter filter-with-marin-right-small",
        filterType: FILTER_TYPE_SELECT_AUTOC,
        field: "text"
    },
    {
        defaultName: "",
        inputAsDefault: true,
        canBeRemove: true,
        isHeader: true,
        asyncParameters: USERSTORED,
        formatFilterValue: obj => {
            return obj ? obj : obj;
        },
        formatCurrent: obj => {
            return obj ? obj.value.label : "";
        },
        isModal: false,
        isSearchable: true,
        name: "society_rm",
        label: "rm",
        className: "select-label-input filter filter-with-marin-right-small",
        filterType: FILTER_TYPE_SELECT_AUTOC,
        field: "text"
    }
];
const SectorStored2 = {
    api: process.env.REACT_APP_IDENTITY_GQL_URL
        ? process.env.REACT_APP_IDENTITY_GQL_URL
        : "",
    table: "activitySectors",
    entity: "ActivitySector",
    list: true,
    query: {
        fields: ["id", "_id", "name"],
        maxResults: MAX,
        page: 0,
        filtersToUpdate: {
            field: "society_activitySector_name",
            objectField: "name"
        },
        filters: []
    }
};
const MISSIONCITYSTORED = {
    api: process.env.REACT_APP_IDENTITY_GQL_URL
        ? process.env.REACT_APP_IDENTITY_GQL_URL
        : "",
    table: "missions",
    entity: "Mission",
    list: true,
    query: {
        fields: ["id", "_id", "city"],
        maxResults: 1000,
        page: 0,
        filtersToUpdate: {
            field: "city",
            objectField: "city"
        },
        filters: []
    }
};
const filters = [
    {
        name: "society_activitySector_name_list",
        label: "activity_sector",
        defaultName: "activity_sector",
        isModal: true,
        multiple: true,
        inputAsDefault: true,
        asyncParameters: SectorStored2,
        filterType: FILTER_TYPE_SELECT_AUTOC,
        formatFilterValue: obj => {
            return obj.map(o => o.label);
        },
        formatCurrent: obj => {
            return obj;
        },
        isSearchable: false,
        options: [],
        customComponent: "activity_sector"
    },
    {
        name: "city_list",
        label: "Ville",
        defaultName: "Ville",
        formatFilterValue: obj => {
            return obj.map(o => o.label);
        },
        formatCurrent: obj => {
            return obj;
        },
        isModal: true,
        multiple: true,
        inputAsDefault: true,
        asyncParameters: MISSIONCITYSTORED,
        filterType: FILTER_TYPE_SELECT_AUTOC,
        isSearchable: true,
        options: [],
        customComponent: "location"
    },
    {
        name: "startDate",
        defaultName: "search.date",
        inputAsDefault: true,
        canBeRemove: true,
        isModal: false,
        formatFilterValue: value => {
            return { after: moment(value).format("YYYY-MM-DDTHH:mm:ss.SSSZ") };
        },
        formatCurrent: obj => {
            if (obj && !obj.value.after)
                return;
            return moment(obj.value.after).format("DD-MM-YYYY");
        },
        isSearchable: true,
        label: "search.begin",
        filterType: FILTER_TYPE_TEXT,
        options: [],
        className: "label-input filter filter-with-marin-right-small",
        field: "text",
        type: "date",
        dateType: "after"
    },
    {
        name: "exists",
        label: "Candidatures",
        defaultName: "Candidatures",
        isModal: true,
        filterType: FILTER_TYPE_SELECT,
        isSearchable: false,
        inputAsDefault: true,
        formatFilterValue: obj => {
            return obj;
        },
        formatCurrent: obj => obj.value.label,
        options: [
            { value: { candidatures: true }, label: "Avec" },
            { value: { candidatures: false }, label: "Sans" }
        ]
    }
];
export default { headerFilters, filters };
