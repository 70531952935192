import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';
import { routerMiddleware } from 'connected-react-router';
import { persistStore } from 'redux-persist';
import reducers from './index';
const persisConfig = { key: 'root', storage };
const configureStore = (history, preloadedState) => {
    const store = createStore(reducers(history), preloadedState, composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk)));
    const persistor = persistStore(store);
    return { store, persistor };
};
export default configureStore;
