import { createAction } from 'redux-actions';
import { SET_MISSION_COUNT_RELOAD, SET_UPDATE_NOTIF_DASHBOARD, SET_UPDATE_NOTIF_HEADER, QUALIF_BUTTON_ENABLE } from './constant';
export const setNotifHeaderPanelReload = createAction(SET_UPDATE_NOTIF_HEADER, reload => {
    return { reload };
});
export const setNotifDashboardPanelReload = createAction(SET_UPDATE_NOTIF_DASHBOARD, reload => {
    return { reload };
});
export const setMissionCountReload = createAction(SET_MISSION_COUNT_RELOAD, reload => {
    return { reload };
});
export const setQualifButtonEnable = createAction(QUALIF_BUTTON_ENABLE, reload => {
    return { reload };
});
