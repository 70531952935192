export const EsGetFilters = filters => {
    let Allfilters = filters.map((f) => {
        return ({ [`${f.name}`]: f.value });
    });
    return ({
        "all": Allfilters,
    });
};
export const EsFormat = obj => {
    const { _meta, ...objWithoutMeta } = obj;
    let updatedObj = { ...objWithoutMeta };
    for (const prop in objWithoutMeta) {
        updatedObj = {
            ...updatedObj,
            [prop]: objWithoutMeta[prop].raw,
        };
    }
    return updatedObj;
};
export const EsFormatV2 = obj => obj._source;
