import React, { useEffect, useRef } from "react";
import Loading from "../Loading";
import { List, AutoSizer, InfiniteLoader, CellMeasurer, CellMeasurerCache } from "react-virtualized";
const InfiniteScrollWithAutoSizer = ({ data, defaultCache = {}, loadNextPage, hasNextPage, isNextPageLoading, openComponent, closedComponent, selectedIndex, className = "infinite-loader-list freelanceListContainer" }) => {
    let infiniteLoaderRef = useRef();
    let listRef = useRef();
    useEffect(() => {
        if (selectedIndex === -1) {
            return;
        }
        recalculeRow(selectedIndex);
    }, [selectedIndex]);
    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 300,
        ...defaultCache
    });
    const isRowLoaded = ({ index }) => {
        return !!data[index];
    };
    const _getDatum = index => data[index % data.length];
    const rowGetter = ({ index }) => _getDatum(index);
    const recalculeRow = index => {
        if (!infiniteLoaderRef)
            return;
        cache.clear(index, 0);
        if (listRef) {
            listRef.recomputeRowHeights(index);
        }
    };
    const loadMoreRows = () => {
        console.log({ isNextPageLoading });
        console.log({ hasNextPage });
        if (isNextPageLoading || !hasNextPage)
            return;
        return loadNextPage();
    };
    const rowRenderer = ({ key, parent, index, style }) => {
        let content;
        if (data.length === 0)
            return React.createElement(Loading, { key: `loading-${index}`, className: "zIndex-5" });
        if (data.length <= index && !isRowLoaded({ index })) {
            content = React.createElement(Loading, { key: `loading-${index}`, className: "zIndex-5" });
        }
        else {
            if (index === selectedIndex) {
                content = openComponent({ index, recalculeRow });
            }
            else
                content = closedComponent({ index });
        }
        return (React.createElement(CellMeasurer, { key: `cell-measurer-${index}`, cache: cache, parent: parent, columnIndex: 0, rowIndex: index },
            React.createElement("div", { key: key, style: style, className: "freelance-element" },
                React.createElement("div", { style: { paddingBottom: 25 } }, content))));
    };
    return (React.createElement(InfiniteLoader, { isRowLoaded: isRowLoaded, loadMoreRows: loadMoreRows, rowCount: hasNextPage ? data.length + 1 : data.length }, ({ onRowsRendered, registerChild }) => {
        return (React.createElement(AutoSizer, null, ({ width, height }) => {
            return (React.createElement(List, { width: width, className: className, height: height, onRowsRendered: onRowsRendered, rowCount: data.length, rowRenderer: rowRenderer, deferredMeasurementCache: cache, rowHeight: cache.rowHeight, rowGetter: rowGetter, ref: ref => {
                    listRef = ref;
                    registerChild(ref);
                } }));
        }));
    }));
};
export default InfiniteScrollWithAutoSizer;
