import React from "react";
import Portfolio from './portfolio';
import { useStored } from "../../../Hooks/useStored";
import Loading from "../../Loading";
import { WhH2 } from "../../../Components/WHComponents";
import { MAX } from "../../../Shared/maxResults";
const PortfolioContainer = ({ title, user, disabled = false, changePrecalState }) => {
    const PORTFOLIOSTORED = {
        api: process.env.REACT_APP_IDENTITY_GQL_URL ? process.env.REACT_APP_IDENTITY_GQL_URL : '',
        table: 'portfolios',
        entity: 'Portfolio',
        list: true,
        query: {
            fields: ['id', '_id', 'url'],
            maxResults: MAX,
            page: 0,
            filters: [{
                    value: user.profile.id,
                    name: 'profile',
                },
            ],
        },
    };
    let { data, loaded, loadData } = useStored(PORTFOLIOSTORED, '');
    return (React.createElement("div", { className: "loadingContainer portfolioContainer" },
        React.createElement("div", { "data-testid": 'freelancePortfolio' },
            React.createElement(WhH2, { light: true, style: { marginBottom: "6px" } }, title),
            React.createElement("p", null, "4 r\u00E9alisations maximum"),
            loaded ? React.createElement(Portfolio, { user: user, portfolios: data, disabled: disabled, callback: loadData, changePrecalState: changePrecalState })
                :
                    React.createElement(Loading, null))));
};
export default PortfolioContainer;
