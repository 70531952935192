import { useState, useEffect } from 'react';
import { MAX } from "../../../Shared/maxResults";
import GraphQl from "../../../Shared/graphql";
import { formatGraphQlData } from "../../../Shared/rewriteArray";
import { wrongPageNotif } from "../../../Services/notif/notif";
const useGetMission = (id, history) => {
    const [isLoading, setIsLoading] = useState(false);
    const [mission, setMission] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const [reload, setReload] = useState(false);
    useEffect(() => {
        if (!id)
            return;
        if (isLoading)
            return;
        loadSingleMission(id);
    }, [id]);
    useEffect(() => {
        if (!id)
            return;
        if (!reload)
            return;
        if (isLoading)
            return;
        loadSingleMission(id);
        setReload(false);
    }, [reload]);
    const goToProfile = (id) => {
        history.push(`/rm/freelances/freelance_edit/${id}`);
    };
    const loadSingleMission = async (missionId) => {
        let config = {
            api: process.env.REACT_APP_IDENTITY_GQL_URL
                ? process.env.REACT_APP_IDENTITY_GQL_URL
                : "",
            table: "mission",
            entity: "Mission",
            list: false,
            query: {
                maxResults: MAX,
                fields: [
                    "id",
                    "_id",
                    "title",
                    "job",
                    "hiddenSociety",
                    "description",
                    "public",
                    "startDate",
                    "endDate",
                    "duration",
                    "startNow",
                    "expertise",
                    "publicationDate",
                    "comment",
                    "activitySector{id, _id, name}",
                    "nbModification",
                    "canceledReason",
                    "geometry{id _id}",
                    "city",
                    "tjm",
                    "tags(first:20){edges{node{_id id status name}}}",
                    "society{id _id name photo{_id id content} affiliate mainContact{firstName lastName}}",
                    'candidatures(first:500){edges{node{_id, id, statusFl, type, statusClientToValid, indicationFl, contactType, statusRm, statusClient, user{_id id firstName, email, civilityLabel, photo{_id content}, lastName, profile{_id id tjm, job profileDocuments{edges{node{ _id, type, document{_id, url, type, name} }}}}} }}}',
                    "missionStatus{statusRm statusClient indicationClient indicationRm}",
                    "helped",
                    "updatedBy{id _id firstName lastName photo{_id id content}}"
                ],
                filters: [
                    {
                        value: `api/missions/${missionId}`,
                        name: "id",
                    },
                ],
            }
        };
        setIsLoading(true);
        const res = await GraphQl(config);
        if (res.datas) {
            setMission(formatGraphQlData(res.datas));
        }
        else {
            wrongPageNotif.failed();
            setNotFound(true);
        }
        setIsLoading(false);
    };
    return {
        mission,
        isLoading,
        loadSingleMission,
        notFound,
        setReload,
        goToProfile
    };
};
export default useGetMission;
