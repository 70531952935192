import React, { useState, useEffect, Fragment } from "react";
import { Field, Form } from "react-final-form";
import DisplayForm from "../../../../../Components/WHForm/DisplayForm";
import { useTranslation } from "react-i18next";
import ButtonBottomForm from "../../../../../Components/WHForm/ButtonBottomForm";
import ApiService from "../../../../../Services/ApiService";
import { SOCIETY } from "../../../../../Services/contants";
import { editClientNotif } from "../../../../../Services/notif/notif";
import classnames from "classnames";
import { Alert, Button } from "reactstrap";
import { WhFieldSelect, WhFieldTxt, WhValidator } from "../../../../../Components/WHForm/WhFields";
import FFCheckbox from "../../../../../Components/FinalForm/FFCheckbox";
import Loading from "../../../../../Components/Loading";
import { FieldArray } from "react-final-form-arrays";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import arrayMutators from "final-form-arrays";
import { formatFirstName, formatLastName } from "../../../../../helper/formatUserName";
const ContactList = ({ handleSubmit, fields, values = null, onAddField, disabled, canEdit }) => {
    const { t } = useTranslation("form");
    const deleteContactSwal = () => {
        const MySwal = withReactContent(Swal);
        return MySwal.fire({
            title: "",
            text: t("form:contact.swal.deletecontact.content"),
            confirmButtonText: t("form:contact.swal.deletecontact.confirm"),
            cancelButtonText: t("form:contact.swal.deletecontact.cancel"),
            customClass: {
                popup: "swallModal-popup",
                content: "swallModal-container",
                actions: "swallModal-actions form-bottom-button",
                confirmButton: "swallModal-orangeButton secondButton btn btn-secondary",
                cancelButton: "swallModal-blueButton firstButton btn btn-secondary"
            },
            showCancelButton: true
        });
    };
    const deleteContact = idx => {
        return deleteContactSwal().then(result => {
            if (!result.value)
                return;
            fields.remove(idx);
            handleSubmit();
        });
    };
    return (React.createElement("div", { className: "contactList" },
        fields.map((_field, idx) => {
            return (React.createElement(FFContactFields, { key: `contacts_${idx}`, name: `${_field}`, values: values, checkboxName: `${_field}-${idx}`, title: "contact.other", disabled: disabled, error: null, canEdit: canEdit, onDelete: _e => deleteContact(idx) }));
        }),
        fields.length < 3 && (React.createElement("div", { className: "content" },
            React.createElement("h2", { className: `thinTitle mb-15` }, t("form:contact.other")),
            React.createElement("p", { className: `size-small weight-light mt-15` }, t("form:contact.3max")),
            React.createElement(Button, { "data-testid": "addCommentButton", className: classnames("greyButton", {
                    "button-icon": "circle circle-gray circle-sm icon-plus_1 size-tiny"
                }, "roundBlock-addBtn"), onClick: onAddField }, t("contact.add"))))));
};
const FieldArrayContact = ({ handleSubmit, disabled, values = null, onAddField, canEdit }) => {
    return (React.createElement("div", { className: "otherContact" },
        React.createElement(FieldArray, { name: "contacts" }, ({ fields }) => (React.createElement(ContactList, { handleSubmit: handleSubmit, fields: fields, values: values, onAddField: onAddField, disabled: disabled, canEdit: canEdit })))));
};
export const FFContactFields = ({ title, subtitle, error, values = null, disabled, isCreate, onDelete, checkboxName, children, name, canEdit, loaded = true }) => {
    const { t } = useTranslation("form");
    const addAsterix = text => `${text}*`;
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: "headerFreeItem mb-25" },
            React.createElement("p", { className: "thinTitle size-missionBig" }, t(title)),
            subtitle && React.createElement("p", { className: "thinTitle size-small" }, t(subtitle))),
        React.createElement("div", null,
            !loaded && React.createElement(Loading, null),
            React.createElement("div", { className: "content" },
                error && React.createElement(Alert, { color: "danger" }, error),
                React.createElement("div", { className: "field-row mb-5" },
                    React.createElement(Field, { name: name ? `${name}.civility` : "civility", validate: WhValidator.required }, props => (React.createElement(WhFieldSelect, Object.assign({ options: [
                            { label: "Mr", value: 1 },
                            { label: "Mme", value: 2 }
                        ], name: name ? `${name}.civility` : "civility" }, props, { label: addAsterix(t("form:contact.civility")), type: "text", className: "label-input", disabled: disabled })))),
                    React.createElement(Field, { name: name ? `${name}.lastName` : "lastName", parse: (value) => formatLastName(value), format: (value) => formatLastName(value), validate: WhValidator.required }, props => (React.createElement(WhFieldTxt, Object.assign({ name: name ? `${name}.lastName` : "lastName" }, props, { label: addAsterix(t("form:contact.lastname")), type: "text", className: "label-input mb-5", disabled: disabled })))),
                    React.createElement(Field, { name: name ? `${name}.firstName` : "firstName", parse: (value) => formatFirstName(value), format: (value) => formatFirstName(value), validate: WhValidator.required }, props => (React.createElement(WhFieldTxt, Object.assign({ name: name ? `${name}.firstName` : "firstName" }, props, { label: addAsterix(t("form:contact.firstname")), type: "text", className: "label-input", disabled: disabled }))))),
                React.createElement("div", { className: "field-row mb-5" },
                    React.createElement(Field, { name: name ? `${name}.function` : "function", validate: WhValidator.required }, props => (React.createElement(WhFieldTxt, Object.assign({ name: name ? `${name}.function` : "function" }, props, { label: addAsterix(t("form:contact.function")), type: "text", className: "label-input", disabled: disabled }))))),
                React.createElement("div", { className: "field-row mb-5" },
                    React.createElement(Field, { name: name ? `${name}.email` : "email", validate: WhValidator.composeValidators(WhValidator.required, WhValidator.email) }, props => (React.createElement(WhFieldTxt, Object.assign({ name: name ? `${name}.email` : "email" }, props, { label: addAsterix(t("form:contact.email")), type: "text", className: "label-input mb-5", containerSize: "big", disabled: disabled })))),
                    React.createElement(Field, { name: name ? `${name}.phone` : "phone", validate: WhValidator.composeValidators(WhValidator.required, WhValidator.phone) }, props => (React.createElement(WhFieldTxt, Object.assign({ name: name ? `${name}.phone` : "phone" }, props, { label: addAsterix(t("form:contact.phone")), type: "text", className: "label-input", containerSize: "small", disabled: disabled }))))),
                React.createElement("div", { className: "field-row" },
                    React.createElement(FFCheckbox, { name: name ? `${name}.newsletter` : `newsletter`, disabled: disabled, containerSize: "mid", labelFor: isCreate ? `newsletterCreate` : `${checkboxName}-newsletter`, label: t("form:contact.newsletter") })),
                canEdit && onDelete && (React.createElement("span", { "data-testid": "removeButton", className: "refItem-delete littleTextButton flex-selfEnd", onClick: onDelete }, t("form:contact.delete")))),
            children)));
};
export const FFContactButtonWrapper = ({ title, loaded = true, render, values = null, reset }) => {
    const { shouldDisplayForm, displayFormToggle, } = DisplayForm();
    const { t } = useTranslation("common");
    useEffect(() => {
        if (!loaded)
            displayFormToggle();
    }, [loaded]);
    return (React.createElement("div", { className: "rm-client-create-form" },
        !shouldDisplayForm && (React.createElement("span", { className: "littleTextButton absoluteEditButton editButton", "data-testid": "updateButton", onClick: () => { displayFormToggle(); } }, t("modification"))),
        !loaded && React.createElement(Loading, { className: 'load-zIndexMin' }),
        React.createElement(ButtonBottomForm, { reset: reset, toggle: displayFormToggle, display: shouldDisplayForm },
            React.createElement(FFContactFields, { error: null, loaded: true, values: values, title: title, disabled: !shouldDisplayForm }, render({ shouldDisplayForm })))));
};
const FFContactContainer = ({ mainContact, contacts, id, setIsOpen, callback, changePrecalState }) => {
    const [loaded, setLoaded] = useState(true);
    const onSubmitContact = formData => {
        setLoaded(false);
        const { address, profile, contacts, ...updatedFormData } = formData;
        const societyService = new ApiService(SOCIETY);
        let newsletterData = null;
        if (formData.newsletter) {
            newsletterData = {
                user: mainContact["@id"] ? mainContact["@id"] : mainContact.id,
                email: formData.email,
                id: mainContact.newsletter
                    ? mainContact.newsletter["@id"]
                        ? mainContact.newsletter["@id"]
                        : mainContact.newsletter.id
                    : null
            };
        }
        return societyService
            .update({
            _id: id,
            id: id,
            mainContact: {
                ...updatedFormData,
                ["@id"]: mainContact.id,
                civility: updatedFormData.civility.value,
                newsletter: newsletterData
            },
            contacts: formData.contacts.map(c => ({
                ...c,
                civility: c.civility.value,
                newsletter: c.newsletter
                    ? { user: c, email: c.email, id: c.newsletter._id }
                    : null
            }))
        })
            .then(() => {
            editClientNotif.success();
            if (changePrecalState)
                changePrecalState();
            if (callback) {
                callback().then(() => {
                    setLoaded(true);
                });
            }
        })
            .catch(e => {
            console.error(e);
            setLoaded(true);
            editClientNotif.failed();
        });
    };
    return (React.createElement(Form, { key: JSON.stringify(contacts), onSubmit: onSubmitContact, keepDirtyOnReinitialize: true, mutators: { ...arrayMutators }, initialValues: {
            ...mainContact,
            newsletter: mainContact.newsletter ? true : false,
            civility: {
                label: mainContact.civility === 1 ? "Mr" : "Mme",
                value: mainContact.civility
            },
            contacts: contacts.map(c => ({
                ...c,
                newsletter: c.newsletter ? true : false,
                civility: {
                    label: c.civility === 1 ? "Mr" : "Mme",
                    value: c.civility
                }
            }))
        }, render: ({ handleSubmit, values, form: { reset, setConfig } }) => {
            const onReset = () => {
                setConfig("keepDirtyOnReinitialize", false);
                reset();
                setConfig("keepDirtyOnReinitialize", true);
            };
            return (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(FFContactButtonWrapper, { title: "form:contact.maincontact", loaded: loaded, values: values, reset: onReset, render: ({ shouldDisplayForm }) => {
                        return (React.createElement(FieldArrayContact, { handleSubmit: handleSubmit, onAddField: setIsOpen, values: values, disabled: !shouldDisplayForm, canEdit: true }));
                    } })));
        } }));
};
export default FFContactContainer;
