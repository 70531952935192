import React from 'react';
import FormWrapper from "../../../Components/Form/FormWrapper";
import { Field } from 'redux-form';
import { WhFieldSelect, WhFieldTxt, WhValidator } from "../../../Components/WHForm/WhFields/index";
import DateRangeInputComponent from "../../../Components/RangeDatePicker/RangeDatePicker";
import { useTranslation } from "react-i18next";
import { change } from 'redux-form';
import { useDispatch, useSelector } from "react-redux";
import { durationTable } from "../../../Shared/constants";
import moment from 'moment';
import Loading from "../../../Components/Loading";
const ChangeDateClient = ({ onSubmit, close, initialValues, isLoading }) => {
    const { t } = useTranslation('form');
    const dispatch = useDispatch();
    const endDate = useSelector((state) => state.form ? (state.form.changedate ? moment(state.form.changedate.values.endDate).format('YYYY-MM-DD') : null) : null);
    const startDate = useSelector((state) => state.form ? (state.form.changedate ? moment(state.form.changedate.values.startDate).format('YYYY-MM-DD') : null) : null);
    const duration = useSelector((state) => state.form ? (state.form.changedate ? state.form.changedate.values.duration : null) : null);
    const fixDateForAllBrowsers = dateString => { return dateString ? dateString.replace(/-/g, '/') : null; };
    const onDateUpdate = (data) => {
        dispatch(change('changedate', 'startDate', moment(data.startDate).format('YYYY-MM-DD')));
        dispatch(change('changedate', 'endDate', moment(data.endDate).format('YYYY-MM-DD')));
    };
    return (React.createElement("div", { className: "rm-mission-edit-form sidebarBody delete-mission-client" },
        isLoading && React.createElement(Loading, null),
        React.createElement(FormWrapper, { onSubmit: onSubmit, form: "changedate", initialValues: initialValues },
            React.createElement("p", { className: "size-big weight-light mt-5 mb-20" }, t('changedate.title')),
            React.createElement("div", { className: 'form-content accept-freelance-form-content' },
                React.createElement("div", { className: 'castorForm-endDate' },
                    React.createElement(Field, { name: "endDate", values: { endDate: fixDateForAllBrowsers(endDate), startDate: fixDateForAllBrowsers(startDate), duration: duration }, component: DateRangeInputComponent, onUpdate: onDateUpdate, validate: [WhValidator.required] })),
                React.createElement(Field, { name: "duration", component: WhFieldSelect, validate: [WhValidator.required], options: durationTable(), required: true, label: `${t("createmission.duration")} *`, containerSize: "big" }),
                React.createElement(Field, { name: "tjm", component: WhFieldTxt, validate: [WhValidator.required], required: true, type: 'text', label: `${t("createmission.tjm")} *`, containerSize: "big", iconafter: '€' })),
            React.createElement("div", { className: 'mission-btns btn-container flex-row' },
                React.createElement("button", { className: 'mission-btn', onClick: () => close(false) }, t('changedate.cancel')),
                React.createElement("button", { className: 'mission-btn', type: "submit" }, t('changedate.confirm'))))));
};
export default ChangeDateClient;
