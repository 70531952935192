import { SET_TB } from "/wh/redux/table/constant";
import { handleActions } from "redux-actions";
import { rmFreelance } from "/wh/mockData/rmFreelance";
const defaultState = {
    rmfreelanceTb: rmFreelance,
};
const tbReducer = handleActions({
    [SET_TB]: (state, action) => {
        state[action.payload.key] = action.payload.data;
        return state;
    },
}, defaultState);
export default tbReducer;
