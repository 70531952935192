import { withRouter } from "react-router";
import React from "react";
import ValidateBody from "./ValidateBodyES";
import LoadMissionListES from "../LoadMissionListES";
const MissionListValidate = ({ history }) => {
    return (React.createElement(LoadMissionListES, { facetsConfig: [
            { name: "activities_name", size: 100, sort: { value: "asc" } },
            { name: "city", size: 100, sort: { value: "asc" } },
            { name: "rm_fullname", size: 100, sort: { value: "asc" } },
        ], defaultFilters: [{ name: 'main_contact_enabled', value: 'true' }, { name: "rm_status_value", value: "400" }, { name: "rm_sub_status", value: "100" }], sortConfig: { created_at: "desc" }, render: ({ data, user, setRequestFilters, facets, onSubmitMission, isCurrentlyLoading, hasNextPage, isNextPageLoading, totalItems, loadNextPage, setDefaultFiltersOverride, }) => {
            return (React.createElement("div", { className: "littleHeader width-selectable-filters rightContainer rightContainer--withHeadFilters missionListContainer" },
                React.createElement(ValidateBody, { history: history, facets: facets, user: user, hasNextPage: hasNextPage, totalItems: totalItems, data: data, isCurrentlyLoading: isCurrentlyLoading, loadNextPage: loadNextPage, setDefaultFiltersOverride: setDefaultFiltersOverride, isNextPageLoading: isNextPageLoading, setRequestFilters: setRequestFilters, onSubmitMission: onSubmitMission })));
        } }));
};
export default withRouter(MissionListValidate);
