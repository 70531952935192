import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ROLES } from "../../../constants/roles";
import { addHTTPS } from "../../../Shared/utils/utils";
export const MissionInformation = ({ header, content, isMomentObject = false }) => {
    return (React.createElement("div", { className: "dateInfo" },
        React.createElement("div", { className: "dateInfo-header weight-light size-small " }, header),
        React.createElement("div", { className: "dateInfo-content size-small " }, isMomentObject ? (React.createElement("span", null,
            React.createElement(Moment, { format: "DD MMMM YYYY" }, content))) : (React.createElement("span", null, content)))));
};
export const MissionSocietyHeader = ({ logo, publishDate, name, isHidden = false }) => {
    const { t } = useTranslation("missions");
    const connectedUser = useSelector((state) => state.user);
    const [imageLoaded, setImageLoaded] = useState(false);
    useEffect(() => {
        if (logo && logo.content) {
            setImageLoaded(true);
        }
    }, [logo]);
    const canSee = () => {
        if ([ROLES.FREE].some(r => connectedUser.data.roles.indexOf(r) >= 0))
            return !isHidden;
        else
            return true;
    };
    return (React.createElement("div", { className: "missionsocietyheader mb-20" },
        canSee() && logo && logo.content ? (React.createElement("div", { className: "societyLogo" },
            React.createElement("img", { src: addHTTPS(logo.content) }))) :
            React.createElement("div", { className: "societyLogo-empty" },
                React.createElement("div", { className: 'logo' },
                    React.createElement("i", { className: `icon-image size-big` }))),
        React.createElement("div", { className: "missionsociety-publicationInfos" },
            publishDate && (React.createElement("p", { className: "size-small weight-light" },
                t("missions.information.publishdate"),
                " ",
                React.createElement("span", { className: "weight-bold" },
                    React.createElement(Moment, { format: "DD/MM/YYYY" }, publishDate)))),
            canSee() && (React.createElement("p", { className: "size-small weight-light" },
                t("missions.information.from"),
                " ",
                React.createElement("span", { className: "weight-bold" }, name))))));
};
