import React, { useState, Fragment } from "react";
import DisplayForm from "../WHForm/DisplayForm";
import StudiesForm from "./studiesForm";
import FormWrapper from "../Form/FormWrapper";
import * as profileService from "../../Services/profile";
import { SubmissionError } from "redux-form";
import Notif from "../Notif/index";
import Loading from "../../Components/Loading";
import { UseDocument } from "../../Components/Freelance/Documents/useDocument";
import { useTranslation } from "react-i18next";
import getFile from "../../Shared/getFile";
import shallowequal from "shallowequal";
import { useDispatch, useSelector } from "react-redux";
import { updateDocument } from "../../Components/Freelance/Documents/useDocument";
import { downloadFile } from "../../Shared/getFile";
import { setQualifButtonEnable } from "../../redux/reloader/action";
const FreelanceStudies = ({ profile, title, callback, changePrecalState, disabled = false }) => {
    const { shouldDisplayForm, displayFormToggle } = DisplayForm();
    const [loaded, setLoaded] = useState(true);
    const { profileDocument } = UseDocument(profile, "parcours");
    const form = useSelector(state => state.form["freelance_studies_form"]);
    const { t } = useTranslation(["notif", "document"]);
    const dispatch = useDispatch();
    const onSubmit = async (data) => {
        dispatch(setQualifButtonEnable(false));
        setLoaded(false);
        const submitData = {
            _id: profile._id ? profile._id : profile.id,
            linkedin: data.linkedin
        };
        if (!shallowequal(form.initial, form.values) && changePrecalState) {
            changePrecalState();
        }
        await updateDocument(data, "parcours", profile, profileDocument);
        profileService
            .update(submitData)
            .then(() => {
            if (callback)
                callback();
            setLoaded(true);
            Notif({
                color: "success",
                message: t("notif:success_edit"),
                duration: 100
            });
        })
            .catch(error => {
            setLoaded(true);
            Notif({
                color: "warning",
                message: t("notif:failed_edit"),
                duration: 100
            });
            throw new SubmissionError(error);
        });
        displayFormToggle();
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: "roundBlock" },
            React.createElement("div", { className: "headerFreeItem" },
                React.createElement("h2", { className: "thinTitle" }, title),
                !disabled && !shouldDisplayForm && (React.createElement("span", { className: "littleTextButton editButton", "data-testid": "updateButton", onClick: () => displayFormToggle() }, "Modifier"))),
            loaded ? (React.createElement(Fragment, null, !shouldDisplayForm ? (React.createElement("div", { className: "content castorForm" },
                profileDocument ? (React.createElement(Fragment, null,
                    React.createElement("div", { className: "studiesContainer" },
                        React.createElement("div", { className: "cvContainer green", "data-testid": "cvContainer", onClick: () => getFile(profileDocument.document._id) },
                            React.createElement("div", { className: "cvDoc fc-greenOk" },
                                React.createElement("i", { className: "icon-cv mr-10" }),
                                React.createElement("span", { "data-testid": "displayCv" }, profileDocument.document.url))),
                        React.createElement("div", { className: "fileButtonBottom" },
                            React.createElement("label", { className: "littleTextButton size-medium downloadButton", onClick: () => downloadFile(profileDocument.document.url, profileDocument.document._id) },
                                React.createElement("span", null,
                                    t("document:downloadOneFile"),
                                    React.createElement("i", { className: "icon-download size-tiny ml-10" }))))))) : (React.createElement("div", { className: "documentContainer" },
                    React.createElement("div", { className: "document red w-50 mb-15  text-center" },
                        React.createElement("span", null,
                            React.createElement("i", { className: "icon-cv mr-10" }),
                            "Vous n\u2019avez pas choisi de fichier")))),
                React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" }, profile.linkedin && (React.createElement("div", { className: `castorForm-inputContainer small size-small  pt-25 pb-5 pl-10 pr-10 linkedin socialContainer` },
                    React.createElement("i", { className: `fa fa-linkedin-square size-title` }),
                    React.createElement("span", { className: "castorForm-fakeLabel weight-light" }, "LinkedIn"),
                    React.createElement("a", { href: profile.linkedin, target: "_blank", className: `castorForm-fakeInput weight-regular ml-40 secondaryColor`, "data-testid": "displayLinkedin" }, profile.linkedin)))))) : (React.createElement("div", { "data-testid": "displayForm" },
                React.createElement(FormWrapper, { onSubmit: onSubmit, form: "freelance_studies_form", initialValues: {
                        ...profile,
                        document: profileDocument
                            ? profileDocument.document.url
                            : null
                    } },
                    React.createElement(StudiesForm, { shouldDisplayForm: shouldDisplayForm, toggle: () => displayFormToggle() })))))) : (React.createElement(Loading, null)))));
};
export default FreelanceStudies;
