import { UPDATE_MISSION_FREE_OFFER, SET_OFFERS } from './constant';
import { handleActions } from 'redux-actions';
const defaultState = {
    missionFreelanceOffer: []
};
const missionReducer = handleActions({
    [SET_OFFERS]: (state, action) => {
        const updatedState = { ...state };
        updatedState['missionFreelanceOffer'] = action.payload.data;
        return updatedState;
    },
    [UPDATE_MISSION_FREE_OFFER]: (state, action) => {
        let index = state['missionFreelanceOffer'].findIndex((el) => el.id == action.payload.data.id);
        const updatedState = { ...state };
        updatedState.missionFreelanceOffer = [...state.missionFreelanceOffer];
        updatedState['missionFreelanceOffer'][index] = action.payload.data;
        return updatedState;
    }
}, defaultState);
export default missionReducer;
