const formatSelectValue = (constantName, key) => {
    let selectArray = [];
    selectArray = constantName.find((st) => st.value === key);
    return selectArray ? selectArray : constantName[0];
};
export const formatExpertiseValue = (constantName, key) => {
    let selectArray = [];
    selectArray = constantName.find((st) => st.value === key);
    return selectArray ? selectArray : null;
};
export default formatSelectValue;
