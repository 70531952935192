import React, { useState, useEffect } from "react";
import { WhLoading, WhConfirm, } from "../../../Components/WHComponents";
import AccountForm from "./components/Form";
import { useSelector, useDispatch } from 'react-redux';
import ApiService from "../../../Services/ApiService";
import { USERS } from "../../../Services/contants";
import * as documentService from '../../../Services/document';
import { setUserDefault } from '../../../redux/user/action';
import { SubmissionError } from 'redux-form';
import moment from 'moment';
import { deleteClientNotif, editClientNotif } from "../../../Services/notif/notif";
import { logout } from "../../../Shared/session";
import { formatFirstName, formatLastName } from "../../../helper/formatUserName";
export const Account = () => {
    const [loaded, setLoaded] = useState(true);
    const [country, setCountry] = useState(null);
    const user = useSelector((state) => state.user.data);
    const lang = useSelector((state) => state.lang);
    const userService = new ApiService(USERS);
    const dispatch = useDispatch();
    useEffect(() => {
        getCountry(user);
    }, [user]);
    const getCountry = (user) => {
        const countries = lang.countries;
        let country = countries.find((c) => c.value === user.profile.address.country);
        if (country) {
            setCountry({ value: country.value, label: country.label });
        }
        else {
            setCountry({ value: "Fr", label: "France" });
        }
    };
    const userLogout = async () => {
        await logout();
        window.location.href = `${process.env.REACT_APP_WWW_HOST}/fr/logout`;
    };
    const getUserDataToPost = (submitData) => {
        const { profileDocuments, job, ...updatedProfile } = submitData.profile;
        const updatedUser = { ...submitData, birthdate: moment(submitData.birthdate).format("YYYY-MM-DD"), profile: { ...updatedProfile } };
        const { photo, ...toSubmit } = updatedUser;
        const userDefaultCountry = country ? country.value : "Fr";
        const updatedCountry = submitData.profile.address.country.hasOwnProperty('value') ? submitData.profile.address.country.value : submitData.profile.address.country;
        toSubmit.civility = submitData.civility.value;
        toSubmit.profile.address.country = submitData.profile.address.country ? updatedCountry : userDefaultCountry;
        let newsletterData = null;
        if (submitData.newsletter) {
            newsletterData = {
                email: submitData.email,
                id: user.newsletter ? user.newsletter['@id'] ? user.newsletter['@id'] : user.newsletter.id : null,
            };
        }
        return ({ ...toSubmit, newsletter: newsletterData });
    };
    const onSubmit = (submitData) => {
        if (!submitData.hasOwnProperty('googleMapCity') || (submitData.googleMapCity !== submitData.profile.address.city)) {
            alert("Tapez votre ville puis sélectionnez la dans la liste proposée.");
            return;
        }
        if (submitData.profile.address.lat === 0 || submitData.profile.address.lng === 0) {
            alert("Tapez votre ville puis sélectionnez la dans la liste proposée.");
            return;
        }
        setLoaded(false);
        const userData = getUserDataToPost(submitData);
        if (submitData.photoProfile && submitData.photoProfile.file) {
            let submitPhoto = {
                file: submitData.photoProfile.file
            };
            return documentService.postFile(submitPhoto).then((doc) => {
                let profileData = {
                    _id: user._id,
                    photo: doc['@id']
                };
                return userService.update({ ...userData, ...profileData }).then(() => {
                    setLoaded(true);
                    editClientNotif.success();
                    if (submitData.hasOwnProperty('newPassword') || (submitData.hasOwnProperty('email') && submitData.email !== user.email)) {
                        userLogout();
                    }
                    else {
                        dispatch(setUserDefault());
                    }
                });
            }).catch((e) => {
                console.error(e);
                setLoaded(true);
                editClientNotif.failed();
            });
        }
        return userService.update(userData).then((data) => {
            getCountry(data);
            setLoaded(true);
            editClientNotif.success();
            if (submitData.hasOwnProperty('newPassword') || (submitData.hasOwnProperty('email') && submitData.email !== user.email)) {
                userLogout();
            }
            else {
                dispatch(setUserDefault());
            }
        }).catch((e) => {
            editClientNotif.failed();
            setLoaded(true);
            throw new SubmissionError(e);
        });
    };
    const deleteMyAccount = () => {
        const userService = new ApiService(USERS);
        WhConfirm({
            title: "Souhaitez-vous vraiment supprimer votre compte ?",
            ifConfirm: () => {
                setLoaded(false);
                userService
                    .remove(user._id)
                    .then(() => {
                    setLoaded(true);
                    deleteClientNotif.mine_success();
                    window.location.href = `${process.env.REACT_APP_WWW_HOST}/fr/logout`;
                })
                    .catch(_e => {
                    setLoaded(true);
                    deleteClientNotif.mine_failed();
                });
            },
            ifNotConfirm: () => false,
            labelYes: "Enregistrer",
            labelNo: "Annuler"
        });
    };
    if (!country)
        return React.createElement("div", null);
    return (React.createElement("div", { className: "freelance-account" },
        !loaded && React.createElement(WhLoading, null),
        React.createElement(AccountForm, { countries: lang ? lang.countries : [], deleteMyAccount: deleteMyAccount, onSubmit: onSubmit, initialvalues: {
                ...user,
                firstName: formatFirstName(user.firstName),
                lastName: formatLastName(user.lastName),
                photoProfile: { content: user.photo ? user.photo.content : undefined },
                civility: { label: user.civility === 0 ? 'Mr' : 'Mme', value: user.civility },
                profile: {
                    ...user.profile,
                    status: parseInt(user.profile.status),
                    tjm: parseInt(user.profile.tjm),
                    mobilityRayon: parseInt(user.profile.mobilityRayon),
                    address: {
                        ...user.profile.address,
                        country: country && country.value ? country.value : ""
                    },
                }
            } })));
};
export default Account;
