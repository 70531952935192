import React from "react";
const CheckBoxField = ({ onChange, name, value, label = "", superLabel = "", disabled = false, className = "", color = "", containerSize = "mid", labelFor = null }) => {
    return (React.createElement("div", { className: `castorForm-inputContainer castorForm-inputContainer--checkbox ${containerSize}` },
        React.createElement("p", { className: "size-small weight-light secondaryColor mb-0" }, superLabel),
        React.createElement("input", { "data-testid": "form_checkbox_field", className: "checkbox-btn__checkbox", type: "checkbox", id: labelFor ? labelFor : name, name: name, onChange: onChange, checked: value, disabled: disabled }),
        React.createElement("label", { className: "checkbox-btn__label", htmlFor: labelFor ? labelFor : name }, label),
        React.createElement("span", { className: "checkbox-btn__checkbox-custom", style: color ? { background: color, borderColor: color } : {} }),
        className === "button" ? (React.createElement("span", { className: "checkbox-btn__label-svg" })) : ("")));
};
const renderCheckBoxField = ({ input, label = "", superLabel = "", disabled = false, className = "", color = "", value, containerSize = "mid", labelFor = null }) => {
    return (React.createElement(CheckBoxField, Object.assign({}, input, { value: value ? value : input.value, label: label, disabled: disabled, className: className, color: color, superLabel: superLabel, containerSize: containerSize, labelFor: labelFor })));
};
export default renderCheckBoxField;
