import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import CommentaireItem from "./commentaireItem";
import { useSelector } from "react-redux";
const CommentHeader = () => {
    const { t } = useTranslation("form");
    return (React.createElement("div", { className: "headerFreeItem" },
        React.createElement("h2", { className: "thinTitle" }, t("comment.title"))));
};
const Comments = ({ data, onDelete, editComment, onClickSeeMore = undefined }) => {
    const [visibleData, setVisibleData] = useState([]);
    const [hiddenData, setHiddenData] = useState([]);
    const [displayMoreContent, setDisplayMoreContent] = useState(false);
    const { t } = useTranslation("form");
    useEffect(() => {
        const [first = null, second = null, ...others] = data;
        setVisibleData([first, second].filter((el) => el !== null));
        setHiddenData(others);
    }, [data]);
    const rm = useSelector((state) => state.user.data);
    const showMoreContent = () => {
        setDisplayMoreContent(true);
        if (onClickSeeMore)
            onClickSeeMore();
    };
    return (React.createElement(Fragment, null,
        React.createElement(CommentHeader, null),
        visibleData.map((commentaire, index) => {
            return (React.createElement("div", { key: `comment-visible-${index}` },
                React.createElement(CommentaireItem, { commentaire: commentaire, key: index, onDelete: onDelete, user: rm, onSubmit: editComment })));
        }),
        displayMoreContent && hiddenData.map((commentaire, index) => {
            return (React.createElement("div", { key: `comment-visible-${index}` },
                React.createElement(CommentaireItem, { commentaire: commentaire, key: index, onDelete: onDelete, user: rm, onSubmit: editComment })));
        }),
        !displayMoreContent && hiddenData.length > 0 && (React.createElement("span", { className: "showMoreButton", onClick: () => showMoreContent() }, t("comment.more")))));
};
export default Comments;
