import ApolloClient from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import * as session from "./session";
export const formatResults = (res) => {
    const datas = [];
    res.data[Object.keys(res.data)[0]].edges.map((el) => {
        datas.push(el.node);
    });
    return {
        totalItem: res.data[Object.keys(res.data)[0]].totalCount,
        pageInfo: res.data[Object.keys(res.data)[0]].pageInfo,
        datas,
    };
};
export const formatErrors = (errors) => {
    if (typeof errors === "string") {
        return [{ message: errors }];
    }
    if (typeof errors === "object") {
        return [{ message: errors.message }];
    }
    const error = [];
    errors.map((er) => error.push({
        message: er.message,
    }));
    return error;
};
export const getFields = async (url, entity) => {
    const q = new ApolloClient({
        link: createHttpLink({ uri: url }),
        cache: new InMemoryCache(),
    });
    try {
        const res = await q.query({
            query: gql `{
            __type(name: "${entity}") {
            name
            fields {
            name
            }
        }
        }`,
        });
        return res.data.__type.fields;
    }
    catch (error) {
        throw error;
    }
};
export default async (request) => {
    const fields = request.query.fields.join(" ");
    const filters = [];
    request.query.filters.map((el) => {
        if (typeof el.value === "string")
            filters.push(`${el.name}: "${el.value}"`);
        if (typeof el.value === "boolean")
            filters.push(`${el.name}: ${el.value}`);
        if (typeof el.value === "number")
            filters.push(`${el.name}: ${el.value}`);
        if (Array.isArray(el.value)) {
            let value = [];
            if (el.name === "roles") {
                Object.keys(el.value).map((key) => {
                    value.push(`["${el.value[key]}"]`);
                });
                filters.push(`${el.name}: [${value.join(", ")}]`);
            }
            else {
                value = `[${el.value.map((v) => {
                    if (typeof v === "number")
                        return v;
                    else
                        return `\"${v}\"`;
                })}]`;
                filters.push(`${el.name}: ${value}`);
            }
        }
        if (typeof el.value === "object" && !Array.isArray(el.value)) {
            const value = [];
            Object.keys(el.value).map((key) => {
                if (typeof el.value[key] === "string")
                    value.push(`${key}: "${el.value[key]}"`);
                if (typeof el.value[key] === "boolean")
                    value.push(`${key}: ${el.value[key]}`);
                if (typeof el.value[key] === "number")
                    value.push(`${key}: ${el.value[key]}`);
            });
            filters.push(`${el.name}: {${value.join(", ")}}`);
        }
    });
    let query = "";
    if (request.list) {
        query += `{${request.table} (first: ${request.query.maxResults} `;
        if (request.query.after) {
            query += `after: "${request.query.after}" `;
        }
        if (request.query.orderBy && request.query.order)
            query += `order: {${request.query.orderBy.name}: "${request.query.order}"} `;
        query += `${filters.join(" ")}`;
        query += `)`;
        query += `{totalCount pageInfo `;
        query += `{ endCursor hasNextPage }`;
        query += `edges { node { ${fields} } } }`;
        query += `}`;
    }
    else {
        query += `{${request.table} ( `;
        query += `${filters.join(" ")}`;
        query += `)`;
        query += ` { ${fields} } `;
        query += `}`;
    }
    try {
        const jwtToken = await session.getJwtToken();
        const q = new ApolloClient({
            link: createHttpLink({
                uri: request.api,
                headers: { authorization: jwtToken ? `Bearer ${jwtToken}` : "" },
            }),
            cache: new InMemoryCache(),
        });
        let res = await q.query({
            query: gql `
        ${query}
      `,
        });
        let formatResult = {
            datas: [],
            pageInfo: [],
            totalItem: 0,
        };
        if (request.list) {
            formatResult = formatResults(res);
        }
        else {
            formatResult = {
                totalItem: res.data.totalCount,
                pageInfo: res.data.pageInfo,
                datas: res.data[request.table],
            };
        }
        if (res.errors) {
            console.error(res.errors);
            throw formatErrors(res.errors);
        }
        return formatResult;
    }
    catch (error) {
        console.error("Error::", error);
        let newError = formatErrors(error);
        if (newError[0] &&
            newError[0].message ==
                "Network error: Response not successful: Received status code 401") {
        }
        throw formatErrors(error);
    }
};
