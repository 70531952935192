import React, { Fragment } from "react";
import LoadFreelance from "../../../GraphQLRenderProps/LoadFreelance";
import { withRouter } from "react-router-dom";
import { useScroll } from "../../../../Hooks/useScroll";
import classNames from "classnames";
import { Element } from "react-scroll";
import FreelanceHeaderEdit from "./FreelanceHeaderEdit";
import SearchContainer from "../../../../Components/SearchContainer/SearchContainer";
import Anchors from "../../../../Components/Anchors/list";
import { sections } from "../../../../helper/anchorSection";
import { AccessEnum, checkAccess } from "../../../../Services/Access/access";
import { Button, Container } from "reactstrap";
import FreelanceAbout from "../../../../Components/Freelance/about";
import LoadCommentsFromCollectionID from "../../../GraphQLRenderProps/LoadSingleComment";
import Comments from "./Comments";
import CommentFormContainer from "./CommentForm";
import FreelanceStudies from "../../../../Components/Freelance/studies";
import FreelanceSkills from "../../../../Components/Freelance/Skill/skills";
import FreelanceReferences from "../../../../Components/Freelance/Reference/referenceContainer";
import FreelancePortfolio from "../../../../Components/Freelance/Portfolio/portfolioContainer";
import FreelanceDocuments from "../../../../Components/Freelance/documents";
import FreelanceInformations from "../../../../Components/Freelance/Information/freelanceInformations";
import { useTranslation } from "react-i18next";
import Loading from "../../../../Components/Loading";
import RoundedPhoto from "../../../../Components/BigPhoto/RoundedPhoto";
import { formatUserName } from "../../../../helper/formatUserName";
import { LoadScript } from "@react-google-maps/api";
import { ROLES } from "../../../../constants/roles";
import { useSelector } from "react-redux";
const FreelanceViewHeader = ({ data, readOnly, changePriority, changePrecalState, }) => {
    return (React.createElement(Fragment, null,
        React.createElement(SearchContainer, { title: "", withBtn: false, header: false },
            React.createElement("div", { className: "headerContent" },
                React.createElement(RoundedPhoto, { imgUrl: data.photo ? data.photo.content : undefined }),
                React.createElement("div", { className: "formContainer flex-justifyBetween w-80 pl-30" },
                    React.createElement("div", { className: `headerContent-text` },
                        React.createElement("div", { className: "prioritizeContainer" },
                            React.createElement("p", { className: `size-title weight-bold brightColor mb-0` },
                                formatUserName(data.firstName, data.lastName),
                                " "),
                            !readOnly && (React.createElement("div", { onClick: changePriority, className: `prioritizeIcon ${classNames({
                                    inactive: !data.profile.prioritized,
                                })}` },
                                React.createElement("span", { className: "priorityButton" },
                                    React.createElement("i", { className: "lnr lnr-diamond" }))))),
                        React.createElement("span", null, data.profile.job)),
                    React.createElement("div", { className: "headerForm" },
                        React.createElement(FreelanceHeaderEdit, { profile: data.profile, disabled: readOnly, changePrecalState: changePrecalState })))))));
};
const FreelanceAnchors = ({ data, readOnly, qualifFreelance, user }) => {
    const qualifButtonEnable = useSelector((state) => state.reloader.qualifButtonEnable);
    const onQualifFreelance = () => {
        if (qualifButtonEnable) {
            qualifFreelance();
        }
    };
    return (React.createElement(Fragment, null,
        React.createElement(Anchors, { sections: readOnly
                ? sections.filter((s) => readOnly && s.title !== "notes" && s.title !== "documents")
                : sections, offsetHeight: -420 }),
        data &&
            data.profile &&
            !data.profile.qualified &&
            checkAccess(AccessEnum.QUALIF_FREELANCE, user.permissions) && (React.createElement("div", { className: "container flex-row flex-row-flex-end pt-0 pb-0 pl-15 pr-15" },
            React.createElement(Button, { className: `btn-qualif size-small weight-bold ${!qualifButtonEnable ? 'disabled' : ''}`, onClick: onQualifFreelance }, "Qualifier le profil")))));
};
const FreelanceHeaderWrapper = ({ children, data }) => {
    const { scrollClass } = useScroll();
    const qualifPage = classNames({
        "rightContainer--withHeadFilters--qualif": data && data.profile && !data.profile.qualified,
    });
    const rightContainerClass = () => {
        const connectedUser = useSelector((state) => state.user);
        return [ROLES.RM].some((r) => connectedUser.data.roles.indexOf(r) >= 0) ? 'rightContainer' : '';
    };
    return (React.createElement("div", { className: `${rightContainerClass()} rightContainer--withHeadFilters rightContainer-textPage rm-freelance-edit ${scrollClass} ${qualifPage}` }, children));
};
const FreelanceMain = ({ data, readOnly, loaders, loadData, changePrecalState, onPrev, onDelete, user, }) => {
    const { t } = useTranslation(["common", "rmfreelance", "freelance"]);
    return (React.createElement(Container, { className: "pt-100" },
        React.createElement("div", { className: "freelanceContent" },
            !readOnly && (React.createElement(Button, { "data-testid": "previousButton", className: "previousButton", onClick: onPrev },
                React.createElement("i", { className: "fa fa-chevron-left" }))),
            React.createElement(Element, { name: "about", className: "roundBlocksContainer roundBlocksContainer--oneCol" },
                React.createElement(FreelanceAbout, { data: data, disabled: readOnly, changePrecalState: changePrecalState })),
            !readOnly && (React.createElement(Element, { name: "notes", className: "roundBlocksContainer roundBlocksContainer--oneCol" }, data.profile && (React.createElement(LoadCommentsFromCollectionID, { comments: data.profile.commentaires, profile_id: data.profile._id, callback: loadData, changePrecalState: changePrecalState, render: ({ data, onDelete, onSubmit, editComment }) => {
                    return (React.createElement("div", { className: "roundBlock loadingContainer" },
                        React.createElement(Comments, { data: data, onDelete: onDelete, editComment: editComment }),
                        React.createElement(CommentFormContainer, { onSubmit: onSubmit, data: data })));
                } })))),
            React.createElement(Element, { name: "studies", className: "roundBlocksContainer roundBlocksContainer--oneCol" }, data.profile && (React.createElement(FreelanceStudies, { profile: data.profile, callback: loadData, title: t("freelance:studies.title_B"), disabled: readOnly, changePrecalState: changePrecalState }))),
            React.createElement(Element, { name: "skill", className: "roundBlocksContainer roundBlocksContainer--oneCol" },
                React.createElement(FreelanceSkills, { profile: data.profile, callback: loadData, changePrecalState: changePrecalState, user: user })),
            React.createElement(Element, { name: "reference", className: "roundBlocksContainer roundBlocksContainer--oneCol" }, data && data.profile && (React.createElement(FreelanceReferences, { user: data, disabled: readOnly, changePrecalState: changePrecalState }))),
            React.createElement(Element, { name: "portfolio" }, data && data.profile && (React.createElement(FreelancePortfolio, { user: data, title: t("freelance:portefolio.title_B"), disabled: readOnly, changePrecalState: changePrecalState }))),
            !readOnly && (React.createElement(Element, { name: "documents" }, data.profile && (React.createElement(FreelanceDocuments, { data: data, disabled: readOnly, callback: loadData, changePrecalState: changePrecalState })))),
            React.createElement(Element, { name: "informations", className: "roundBlocksContainer roundBlocksContainer--oneCol" }, data.profile && (React.createElement(FreelanceInformations, { userData: data, callback: loadData, disabled: readOnly, changePrecalState: changePrecalState })))),
        React.createElement("div", { className: "listFooter" },
            loaders.delete && React.createElement(Loading, { className: "load-zIndexMin" }),
            React.createElement("div", { onClick: onPrev },
                React.createElement("span", { className: "backBtn" }, t("common:returnlist"))),
            data && checkAccess(AccessEnum.DELETE_FREELANCE, user.permissions) && (React.createElement("div", null,
                React.createElement(Button, { className: "btn-delete weight-bold size-small", onClick: onDelete }, t("rmfreelance:suppress")))))));
};
const libraries = ["places"];
const FreelanceIndex = ({ history, match }) => {
    return (React.createElement(LoadFreelance, { id: match.params.id, history: history, render: ({ data, readOnly, loadData, user, loaders, changePrecalState, qualifFreelance, changePriority, deleteAccount }) => {
            if (!data)
                return;
            const goBack = () => {
                return history.goBack();
            };
            return (React.createElement(LoadScript, { id: "script-loader", googleMapsApiKey: "AIzaSyBGdfFI02x30t2tNgvj21suu3Rk-rZ9_sI", libraries: libraries },
                React.createElement(FreelanceHeaderWrapper, { data: data },
                    React.createElement("div", { className: `headContainer` },
                        React.createElement(FreelanceViewHeader, { data: data, readOnly: readOnly, changePrecalState: changePrecalState, changePriority: changePriority }),
                        React.createElement(FreelanceAnchors, { data: data, readOnly: readOnly, qualifFreelance: qualifFreelance, user: user })),
                    React.createElement(FreelanceMain, { data: data, loaders: loaders, user: user, readOnly: readOnly, changePrecalState: changePrecalState, onPrev: goBack, loadData: loadData, onDelete: deleteAccount }))));
        } }));
};
export default withRouter(FreelanceIndex);
