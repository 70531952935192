import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStored } from "../../../Hooks/useStored";
import SearchContainer from "../../../Components/SearchContainer/SearchContainer";
import LogoEdition from "../../../Components/Clients/Form/LogoEdition";
import { AccountClientSections } from "../../../helper/anchorSection";
import Anchors from "../../../Components/Anchors/list";
import { Element } from "react-scroll";
import Sidebar from "../../../Components/Sidebar/View";
import { useTranslation } from "react-i18next";
import { Button, Container } from "reactstrap";
import FFSocietyContainer from "../../RessourceManager/Client/ClientEdit/Component/FFSocietyContainer";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { USERTORED } from "../../../Shared/userStored";
import { setUserThunk } from "../../../redux/user/action";
import AccountLoginForm from "../../RessourceManager/Client/ClientEdit/Component/IdentifiantContainer";
import ApiService from "../../../Services/ApiService";
import { SOCIETY, USERS } from "../../../Services/contants";
import { deleteClientNotif, editClientNotif, } from "../../../Services/notif/notif";
import Loading from "../../../Components/Loading";
import FFContactContainer, { FFContactFields, } from "../../RessourceManager/Client/ClientEdit/Component/FFContactContainer";
import { Form } from "react-final-form";
import ButtonBottomForm from "../../../Components/WHForm/ButtonBottomForm";
import { createSideBarMachine } from "./SideMachine";
import { useMachine } from "@xstate/react/lib";
import SideBarClient from "./SideBarClient";
import { logout } from "../../../Shared/session";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import i18n from "../../../i18n";
const ClientAccount = () => {
    const { t } = useTranslation([
        "form",
        "notif",
        "common",
        "rmclient",
        "missions",
        "counter",
    ]);
    const user = useSelector((state) => state.user.data);
    const sectors = useSelector((state) => state.sectors.sectors);
    const dispatch = useDispatch();
    const SOCIETYRTORED = {
        api: process.env.REACT_APP_IDENTITY_GQL_URL
            ? process.env.REACT_APP_IDENTITY_GQL_URL
            : "",
        table: "societies",
        entity: "Society",
        list: true,
        query: {
            maxResults: 1,
            page: 0,
            fields: [
                "id",
                "_id",
                "name",
                "siret",
                "affiliate",
                "nbPackHelp",
                "nopackNoHelp",
                "rm{lastName firstName _id id}",
                "photo{_id, id, content}",
                "qualified",
                "photo{id _id content}",
                "contacts{edges{node{id _id civility lastName firstName email phone function newsletter{id _id} }}}",
                "mainContact{id _id civility firstName lastName email phone function profile{_id id} newsletter{id _id}}",
                "address{id _id address country zipCode city cpltAddress }",
                "activitySector{name _id id}",
            ],
            filters: [
                {
                    value: user.id,
                    name: "mainContact",
                },
            ],
        },
    };
    const { data, loaded, loadData, setLoaded } = useStored(SOCIETYRTORED, "");
    const [isOpen, setIsOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [loadCreate, setLoadCreate] = useState(true);
    const [scrollClass, setScrollClass] = useState("");
    const [current, send] = useMachine(createSideBarMachine());
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useScrollPosition(({ prevPos, currPos }) => {
        if (currPos.y < 50) {
            setScrollClass("scrolled");
        }
        else {
            setScrollClass("");
        }
    });
    const updateUser = () => {
        dispatch(setUserThunk({
            ...USERTORED,
            query: {
                ...USERTORED.query,
                filters: [{ value: user.id, name: "id" }],
            },
        }));
        return loadData();
    };
    const onAddContact = (contactData) => {
        const c = contactData;
        const societyService = new ApiService(SOCIETY);
        setLoadCreate(false);
        const updatedContacts = [
            ...data[0].contacts,
            {
                ...c,
                roles: ["ROLE_CLIENT"],
                type: 'CLIENT',
                civility: parseInt(c.civility.value),
                newsletter: c.newsletter
                    ? { user: c, email: c.email, id: c.newsletter._id }
                    : null,
            },
        ];
        return societyService
            .update({
            _id: data[0]._id,
            mainContact: { ...data[0].mainContact },
            contacts: updatedContacts,
        })
            .then(() => {
            setLoadCreate(true);
            editClientNotif.success();
            loadData();
            setIsOpen(false);
        })
            .catch(() => {
            setLoadCreate(true);
            setIsOpen(false);
            editClientNotif.failed();
        });
    };
    const deleteUserLogout = async () => {
        deleteClientNotif.success();
        await clientLogout();
    };
    const deleteClient = () => {
        const userService = new ApiService(USERS);
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: "",
            text: "Souhaitez-vous vraiment supprimer votre compte ?",
            confirmButtonText: i18n.t("missions:swal.putendmission.confirm"),
            cancelButtonText: i18n.t("missions:swal.putendmission.cancel"),
            customClass: {
                popup: "swallModal-popup",
                content: "swallModal-container",
                actions: "swallModal-actions form-bottom-button",
                confirmButton: "swallModal-orangeButton secondButton btn btn-secondary",
                cancelButton: "swallModal-blueButton firstButton btn btn-secondary"
            },
            showCancelButton: true,
        }).then(result => {
            if (!result.value)
                return;
            setDeleteLoading(true);
            userService
                .remove(data[0].mainContact._id)
                .then(() => {
                setDeleteLoading(false);
                deleteClientNotif.mine_success();
                clientLogout();
            })
                .catch(_e => {
                setDeleteLoading(false);
                deleteClientNotif.mine_failed();
            });
        });
    };
    const clientLogout = async () => {
        await logout();
        window.location.href = `${process.env.REACT_APP_WWW_HOST}/fr/logout`;
    };
    return (React.createElement(Fragment, null,
        !loaded && React.createElement(Loading, null),
        deleteLoading && React.createElement(Loading, null),
        current.matches("deleteAccount.logout") && deleteUserLogout(),
        data && data.length > 0 && (React.createElement("div", { className: `rightContainerWithoutTable rightContainer--withHeadFilters rightContainer-textPage ${scrollClass}` },
            React.createElement("div", { className: "clientaccount-container rm-client-edit-form" },
                React.createElement("div", { className: `headContainer` },
                    React.createElement(SearchContainer, { title: "", withBtn: false },
                        React.createElement(Container, { className: "client-edit-search flex-row flex-justifyCenter" },
                            React.createElement("div", { className: "headerContent" },
                                loaded && (React.createElement(LogoEdition, { id: data[0]._id, directUpdate: true, imgAsInput: false, imgUrl: data[0].photo ? data[0].photo.content : undefined, callback: updateUser })),
                                React.createElement("div", { className: "headerContent-text" },
                                    React.createElement("div", { className: "size-title size-titleMobile weight-bold brightColor mb-0" }, data[0].name),
                                    React.createElement("div", { className: "size-missionBig weight-light brightColor mb-0" }, t("form:claccount:pagetitle")))))),
                    React.createElement(Anchors, { sections: AccountClientSections, offsetHeight: -620 })),
                React.createElement(Container, null,
                    React.createElement("div", { className: "rm-client-edit-content" },
                        React.createElement(Element, { name: "society", className: "roundBlocksContainer roundBlocksContainer--oneCol" },
                            React.createElement("div", { className: "society castorForm roundBlock" },
                                React.createElement(FFSocietyContainer, { name: data[0].name, siret: data[0].siret, address: data[0].address, affiliate: data[0].affiliate, mainContact: data[0].mainContact, activitySector: data[0].activitySector ? data[0].activitySector : "", sectors: sectors, id: data[0]._id, callback: loadData }))),
                        React.createElement(Element, { name: "contacts", className: "roundBlocksContainer roundBlocksContainer--oneCol" },
                            React.createElement("div", { className: "society castorForm roundBlock" }, loaded &&
                                data &&
                                data.length > 0 &&
                                data[0].mainContact && (React.createElement(FFContactContainer, { mainContact: data[0].mainContact, contacts: [...data[0].contacts], id: data[0]._id, callback: updateUser, setIsOpen: setIsOpen })))),
                        React.createElement(Element, { name: "identifiant", className: "roundBlocksContainer roundBlocksContainer--oneCol" },
                            React.createElement("div", { className: "society castorForm roundBlock" },
                                React.createElement(AccountLoginForm, { login: user.email, password: "", callback: clientLogout, id: user._id })))))),
            React.createElement("div", { className: "container pt-0" },
                React.createElement("div", { className: "listFooter flex-justifyEnd" }, data && (React.createElement("div", null,
                    React.createElement(Button, { className: "btn-delete", onClick: deleteClient }, t("rmclient:clientedit.suppress")))))),
            React.createElement(SideBarClient, { current: current, send: send, user: user }),
            isOpen && (React.createElement(Sidebar, { showSidebar: isOpen, close: setIsOpen, title: t("form:contact.new") },
                React.createElement("div", { className: "sidebarBody" },
                    React.createElement(Form, { onSubmit: onAddContact, render: ({ handleSubmit, values }) => {
                            return (React.createElement("form", { onSubmit: handleSubmit },
                                React.createElement(ButtonBottomForm, { toggle: () => setIsOpen(false), display: true, separation: true },
                                    React.createElement(FFContactFields, { title: "contact.other", subtitle: "contact.3max", error: null, values: values, disabled: false, loaded: loadCreate, isCreate: true }))));
                        } }))))))));
};
export default ClientAccount;
