import React, { useState } from "react";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from "reactstrap";
export const ButtonDropDown = ({ label, options, onClick }) => {
    const [dropdownOpen, setOpen] = useState(false);
    const [defaultValue, setDefaultValue] = useState(label);
    const toggle = () => setOpen(!dropdownOpen);
    const onSelect = element => {
        setDefaultValue(element.label);
        onClick(element);
    };
    return (React.createElement(ButtonDropdown, { isOpen: dropdownOpen, toggle: toggle, className: "wh-dropdown" },
        React.createElement(DropdownToggle, { caret: true, className: "wh-btn wh-dropdown-toggle" }, defaultValue),
        React.createElement(DropdownMenu, null, options.map((option, index) => (React.createElement(DropdownItem, { onClick: () => onSelect(option), key: index }, option.label))))));
};
export default ButtonDropDown;
