import request from 'superagent';
import * as session from './../Shared/session';
const prepareValidationError = _error => {
    const error = _error;
    if (!error) {
        return error;
    }
    if (error.code === 404) {
        error['_error'] = 'La page ou le service que vous demandez n’existe pas. ';
    }
    if (error['hydra:description']) {
        if (error.violations) {
            error['_error'] = '';
            error.violations.map(el => {
                error[el.propertyPath] = el.message;
                error['_error'] += `${el.message}<br />`;
                return error;
            });
        }
        else {
            error['_error'] = error['hydra:description'];
        }
    }
    else if (error.code && error.code === 401) {
        if (error.message === 'Bad credentials') {
            error['_error'] = 'Vous n’avez pas été reconnu. Merci de réessayer ou de contacter votre administrateur. ';
        }
    }
    return error;
};
const fetch = async (url, method = 'GET', data) => {
    if (data.hasOwnProperty('filters')) {
        const filters = { ...data.filters };
        let all = filters.hasOwnProperty('all') ? [...filters.all] : [];
        all.push({ "env": process.env.REACT_APP_ES_ENV });
        data = {
            ...data,
            filters: { ...filters, all: all }
        };
    }
    const jwtToken = await session.getJwtToken();
    const query = request(method, url);
    if (jwtToken)
        query.set('Authorization', `Bearer ${jwtToken}`);
    query.send(data);
    try {
        const result = await query;
        return result.body;
    }
    catch (error) {
        if (error.response) {
            throw prepareValidationError(error.response.body);
        }
        else {
            throw error;
        }
    }
};
class ElasticService {
    constructor(endpoint) {
        this.search = (data = null) => {
            return fetch(`${this.endPoint}`, 'post', data);
        };
        this.endPoint = endpoint;
    }
}
export default ElasticService;
