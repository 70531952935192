import React, { useEffect, useState, Fragment } from "react";
import { Container, Badge } from "reactstrap";
import { useTranslation } from "react-i18next";
import { WhLoading, WhNotif, WhH1 } from "../../../Components/WHComponents";
import HeaderForm from "./components/Form";
import DocumentForm from "../../../Components/Freelance/DocumentsForm/documentForm";
import { useSelector, useDispatch } from 'react-redux';
import { updateDocument } from '../../../Components/Freelance/Documents/useDocument';
import { setUserDefault } from '../../../redux/user/action';
import formatSelectvalue from '../../../Shared/formatSelectValue';
import ApiService from "../../../Services/ApiService";
import { USERS } from "../../../Services/contants";
export const MyDocuments = () => {
    const { t } = useTranslation(['freelance', 'notif', 'document']);
    const [loaded, setLoaded] = useState(false);
    const [formData, setFormData] = useState(null);
    const user = useSelector((state) => state.user.data);
    const params = useSelector(state => state.params);
    const dispatch = useDispatch();
    useEffect(() => {
        async function formData() {
            let formatData = { ...user };
            if (params.flStatuses) {
                formatData.profile = { ...formatData.profile, status: await formatSelectvalue(params.flStatuses, parseInt(user.profile.status)) };
            }
            if (formatData.profile.status && formatData.profile.status.value) {
                setFormData(formatData);
                setLoaded(true);
            }
        }
        formData();
    }, [user, params]);
    const userService = new ApiService(USERS);
    const onSubmit = (submitData) => {
        let formatedSubmitData = { ...submitData, franceWorking: submitData.franceWorking.value, profile: { id: submitData.profile.id, status: submitData.profile.status.value } };
        setLoaded(false);
        try {
            userService.update(formatedSubmitData).then(() => {
                WhNotif({
                    color: "success",
                    message: "Vos modifications d’information ont bien été prises en compte !",
                });
            });
        }
        catch (error) {
            WhNotif({
                color: "danger",
                message: "Une erreur serveur est survenue",
                error,
            });
        }
        finally {
            dispatch(setUserDefault());
            setLoaded(true);
        }
    };
    const submitFile = async (submitData, type, profileDocument) => {
        await updateDocument(submitData, type, user.profile, profileDocument);
        dispatch(setUserDefault());
    };
    return (React.createElement("div", { className: "freelance-documents" },
        !loaded && React.createElement(WhLoading, null),
        loaded &&
            React.createElement(Fragment, null,
                React.createElement("div", { className: "header" },
                    React.createElement(Container, null,
                        React.createElement(WhH1, null, t("myDocument.title")),
                        React.createElement("p", null, t("myDocument.subTitle")),
                        React.createElement(HeaderForm, { onSubmit: onSubmit, statuses: params.flStatuses, initialvalues: { ...formData, franceWorking: { label: user.franceWorking === true ? "Oui" : "Non", value: user.franceWorking } } }))),
                React.createElement(Container, { className: "main large" },
                    React.createElement("div", { className: "intro" },
                        React.createElement("span", { className: "ico icon-securite" }),
                        React.createElement("p", { dangerouslySetInnerHTML: { __html: t("freelance:myDocument.intro") } }),
                        React.createElement(Badge, null, t("freelance:myDocument.documentLimitation"))),
                    React.createElement("div", { className: "documents-form" },
                        React.createElement(DocumentForm, { data: user.profile, submitFile: submitFile, formName: 'freelance_docs_ursaff', fileType: 'urssaf', dateText: "document:ursaffAttestationDate", title: "document:ursaffAttestation", secondText: "document:noPaymentAttestationDate", secondTitle: "document:noPaymentAttestation" }),
                        React.createElement(DocumentForm, { data: user.profile, submitFile: submitFile, formName: 'freelance_docs_civile_responsability', fileType: 'CivileResponsability', dateText: "document:civilResponsabilityAttestationDate", title: "document:civilResponsabilityAttestation" }),
                        user.profile.status === 2 &&
                            React.createElement(Fragment, null,
                                React.createElement(DocumentForm, { data: user.profile, submitFile: submitFile, formName: 'freelance_docs_Kbis', fileType: 'Kbis', dateText: "document:kbisAttestationDate", title: "document:kbisAttestation" }),
                                React.createElement(DocumentForm, { data: user.profile, submitFile: submitFile, formName: 'foreign_wave_attestation', fileType: 'ForeignWaveAttestation', dateText: "document:foreignWaveAttestationDate", title: "document:foreignWaveAttestation" })),
                        user.profile.status === 1 &&
                            React.createElement(DocumentForm, { data: user.profile, submitFile: submitFile, formName: 'freelance_docs_insee', fileType: 'Insee', dateText: "document:inseeAttestationDate", title: "document:inseeAttestation" }))))));
};
export default MyDocuments;
