import { createAction } from 'redux-actions';
import { SET_SOCIETY } from './constant.js';
import { formatGraphQlData } from "../../Shared/rewriteArray";
import { MAX } from "/wh/Shared/maxResults";
import GraphQl from '../../Shared/graphql';
import * as storage from '../../Shared/storage/index';
export const setSociety = createAction(SET_SOCIETY, (society) => ({ society }));
const getDataSociety = async (graphQlConfig) => {
    const res = await GraphQl(graphQlConfig);
    return formatGraphQlData(res.datas);
};
export const setSocietyThunk = (id) => (dispatch, getState, axios) => {
    const SOCIETYTORED = (id) => ({
        api: process.env.REACT_APP_IDENTITY_GQL_URL
            ? process.env.REACT_APP_IDENTITY_GQL_URL
            : "",
        table: "societies",
        entity: "Society",
        list: true,
        query: {
            maxResults: MAX,
            page: 0,
            fields: [
                "id",
                "_id",
                "name",
                "nbPackHelp",
                "nopackNoHelp",
                "rm{firstName lastName email phone photo{_id id content}}",
                "mainContact{photo{id _id content}}",
                "photo{_id id content}"
            ],
            filters: [{ name: 'mainContact', value: id.toString() }],
        },
    });
    return getDataSociety(SOCIETYTORED(id)).then((res) => {
        if (res && res.length > 0)
            storage.save('society', res[0]);
        dispatch(setSociety(res[0]));
    });
};
