import React, { useState, Fragment, useEffect } from 'react';
import { Editor, eraser } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { DraftBold, DraftItalic, DraftUnderline } from "./DraftIcons";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
export const FieldDraftEditor = (props) => {
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const onChangeEditor = newState => {
        setEditorState(newState);
    };
    const onChange = editorState => {
        let contentState = editorState.getCurrentContent();
        let content = convertToRaw(contentState);
        let htmlContent = draftToHtml(content);
        props.onUpdate(htmlContent);
        setEditorState(editorState);
    };
    const toolbar = {
        options: ['blockType', 'colorPicker', 'fontSize', 'fontFamily', 'inline', 'link', 'list', 'textAlign', 'remove'],
        inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline'],
            bold: { icon: DraftBold, className: undefined },
            italic: { icon: DraftItalic, className: undefined },
            underline: { icon: DraftUnderline, className: undefined },
        },
        fontFamily: {
            options: ['Heebo'],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
        },
        blockType: {
            inDropdown: true,
            options: ['Normal', 'H3', 'H4', 'H5'],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
        },
        remove: { icon: eraser, className: undefined, component: undefined },
    };
    const editorLabels = {
        'components.controls.blocktype.h3': 'Titre 1',
        'components.controls.blocktype.h4': 'Titre 2',
        'components.controls.blocktype.h5': 'Titre 3',
        'components.controls.blocktype.h6': 'Titre 6',
        'components.controls.blocktype.blockquote': 'Blockquote',
        'components.controls.blocktype.code': 'Code',
        'components.controls.blocktype.blocktype': 'Block Type',
        'components.controls.blocktype.normal': 'Normal',
    };
    useEffect(() => {
        if (!props.input.value)
            return;
        const blocksFromHtml = htmlToDraft(props.input.value);
        const contentState = ContentState.createFromBlockArray(blocksFromHtml);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
    }, []);
    return (React.createElement(Fragment, null,
        React.createElement(Editor, { localization: { locale: 'en', translations: editorLabels }, editorState: editorState, toolbar: toolbar, onEditorStateChange: onChange }),
        props.meta.touched && props.meta.error && (React.createElement("span", { className: "form__form-group-error formError ckError" }, props.meta.error))));
};
