export const formMutators = {
    setUpperCase: (args, state, utils) => {
        if (!args[0]) {
            utils.changeValue(state, "new_tags", () => null);
            return;
        }
        const updatedTags = args[0].map((c) => ({ ...c, label: c.label.charAt(0).toUpperCase() + c.label.slice(1), value: c.value.charAt(0).toUpperCase() + c.value.slice(1) }));
        utils.changeValue(state, "new_tags", () => updatedTags);
    },
};
