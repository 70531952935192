import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { Card } from "reactstrap";
const Indicator = ({ color, number, label, icon }) => {
    const { t } = useTranslation("statistique");
    return (React.createElement(Card, { className: classnames("indicator", color) },
        React.createElement("div", { className: "indicator-icon" }, icon && React.createElement("i", { className: icon })),
        React.createElement("div", { className: "number" }, number),
        React.createElement("div", { className: 'flex', style: { alignItems: 'center' } },
            React.createElement("div", { className: "legend" }, t(label)))));
};
export default Indicator;
