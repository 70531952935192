import freelanceList from "./freelanceListFilters";
import newClients from "./newClientsFilters";
import clientList from "./clientListFilters";
import missionList from "./missionListFilters";
import managerList from "./managerListFilters";
import missionProposition from "./missionListPropositionFilters";
import missionValidate from "./missionListValidateFilters";
import newFreelanceList from "./newFreelanceList";
export const filtersData = {
    freelanceList: {
        headerFilters: freelanceList.headerFilters,
        filters: freelanceList.filters,
    },
    newClients: {
        headerFilters: newClients.headerFilters,
        filters: newClients.filters,
    },
    clientList: {
        headerFilters: clientList.headerFilters,
        filters: clientList.filters,
    },
    missionList: {
        headerFilters: missionList.headerFilters,
        filters: missionList.filters,
    },
    managersList: {
        headerFilters: managerList.headerFilters,
        filters: managerList.filters,
    },
    newFreelanceList: {
        headerFilters: newFreelanceList.headerFilters,
        filters: newFreelanceList.filters,
    },
    missionProposition: {
        headerFilters: missionProposition.headerFilters,
        filters: missionProposition.filters,
    },
    missionValidate: {
        headerFilters: missionValidate.headerFilters,
        filters: missionValidate.filters,
    }
};
