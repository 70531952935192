import React, { useState, Fragment, useEffect } from "react";
import Loading from "../../Loading";
import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import ButtonBottomForm from "../../WHForm/ButtonBottomForm";
import TagsFormArray from "../../../Components/Form/FFTags";
import { NewTagsForm } from "../../../Components/Form/FFTags";
import * as profileService from "../../../Services/profile";
import { SubmissionError } from "redux-form";
import DisplayForm from "../../WHForm/DisplayForm";
import Notif from "../../Notif/index";
import { formMutators } from "./../../FinalForm/FFTagsMutators";
import { Button, Popover, PopoverBody } from "reactstrap";
import { useDispatch } from "react-redux";
import { setQualifButtonEnable } from "../../../redux/reloader/action";
const Skills = ({ profile, callback, user, changePrecalState }) => {
    const { t } = useTranslation("freelance");
    const { shouldDisplayForm, displayFormToggle } = DisplayForm();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [myProfile, setProfile] = useState(profile);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const togglePop = () => setPopoverOpen(!popoverOpen);
    const dispatch = useDispatch();
    const getTags = async () => {
        const res = await profileService.getOne(profile.id);
        setProfile(res);
        setDataLoaded(true);
    };
    useEffect(() => {
        getTags();
    }, []);
    const setMaxValues = (values) => {
        let tag = values["tags"] ? values["tags"].length : 0;
        let maxValues = 10 - tag;
        return maxValues;
    };
    const onSubmit = (data) => {
        dispatch(setQualifButtonEnable(false));
        setDataLoaded(false);
        if (!data.tags)
            data.tags = [];
        let tags = data.tags.map((t) => t["@id"]);
        let newTags = data.new_tags
            ? data.new_tags.map((t) => t.__isNew__ ? { status: "draft", name: t.label } : t.value)
            : [];
        let profileTags = tags.concat(newTags);
        let toSubmitData = { _id: profile._id, tags: profileTags };
        profileService
            .update(toSubmitData)
            .then((data) => {
            if (changePrecalState)
                changePrecalState();
            if (callback)
                callback();
            setProfile(data);
            setDataLoaded(true);
            Notif({
                color: "success",
                message: t("notif:success_edit"),
                duration: 100,
            });
        })
            .catch((error) => {
            setDataLoaded(true);
            Notif({
                color: "warning",
                message: t("notif:failed_edit"),
                duration: 100,
            });
            throw new SubmissionError(error);
        });
        displayFormToggle();
    };
    const formatOptions = (datas, tags) => {
        return datas
            ? datas.filter((t) => tags.findIndex((pt) => pt["@id"] === t.value) === -1)
            : null;
    };
    return (React.createElement("div", { className: "loadingContainer" },
        React.createElement("div", { className: "roundBlock carouselRefs" }, dataLoaded ? (React.createElement(Fragment, null,
            React.createElement("div", { className: "headerFreeItem " },
                React.createElement("h2", { className: "thinTitle" }, t("main.my-skills")),
                !shouldDisplayForm && !user.roles.includes("ROLE_CLIENT") && (React.createElement("span", { className: "littleTextButton editButton", "data-testid": "updateButton", onClick: () => displayFormToggle() }, "Modifier"))),
            React.createElement("p", { className: "roundBlockSubtitle pr-30 pl-30" }, t("main.max_skills_text")),
            React.createElement("div", { className: `content castorForm pt-0 ${shouldDisplayForm ? "pb-0 pl-0 pr-0" : ""}` }, !shouldDisplayForm ? (React.createElement(Fragment, null,
                React.createElement("p", { className: "draftText" }, t("main.tags_draft_text")),
                React.createElement("div", { className: "tags-container" }, myProfile.tags &&
                    myProfile.tags
                        .filter((tag) => tag.status === "published")
                        .map((tag, index) => (React.createElement("div", { key: index, className: "tag" },
                        React.createElement("span", null, tag.name))))))) : (React.createElement("div", { className: "tags-form-container" },
                React.createElement(Form, { mutators: { ...formMutators, ...arrayMutators }, initialValues: {
                        tags: myProfile.tags,
                    }, onSubmit: onSubmit, render: ({ handleSubmit, values, form: { mutators } }) => {
                        const maxValue = setMaxValues(values);
                        return (React.createElement("form", { onSubmit: handleSubmit },
                            React.createElement("div", null,
                                React.createElement(ButtonBottomForm, { display: shouldDisplayForm, toggle: () => displayFormToggle() },
                                    React.createElement("div", { className: "form-content" },
                                        React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                                            React.createElement(TagsFormArray, { tags: myProfile.tags })),
                                        React.createElement(Fragment, null,
                                            React.createElement("div", { className: ` flex-row  mb-15 addTagsText ` },
                                                "Vous pouvez s\u00E9lectionner dans une liste ou proposer un tag",
                                                React.createElement(Button, { id: "Popover1", type: "button", className: "popoverButton" }, "?"),
                                                React.createElement(Popover, { placement: "bottom", isOpen: popoverOpen, target: "Popover1", toggle: togglePop },
                                                    React.createElement(PopoverBody, null, "Vous ne trouvez pas votre tag dans la liste propos\u00E9e ? Aucun souci, nous l'ajouterons \u00E0 notre base de connaissance apr\u00E8s validation de nos \u00E9quipes."))),
                                            React.createElement("div", { className: "tagsFormContainer" },
                                                React.createElement(NewTagsForm, { maxValue: maxValue, mutator: mutators.setUpperCase, format: (datas) => formatOptions(datas, values.tags), values: values.tags }))))))));
                    } })))))) : (React.createElement(Loading, null)))));
};
export default Skills;
