import React from "react";
import { Alert, Form } from "reactstrap";
import { reduxForm } from "redux-form";
const FormWrapper = ({ handleSubmit, children, initialValues, error }) => {
    return (React.createElement(Form, { onSubmit: handleSubmit, initialvalues: initialValues },
        children,
        error && (React.createElement(Alert, { color: "danger" },
            React.createElement("div", { dangerouslySetInnerHTML: { __html: error } })))));
};
export default reduxForm()(FormWrapper);
