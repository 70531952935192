import { FILTER_TYPE_SELECT, FILTER_TYPE_TEXT, GOOGLE_AUTOCOMPLETE, RANGE_FILTER, } from "../../../constants";
import moment from "moment";
import { formatUserName } from "../../../../../helper/formatUserName";
const headerFilters = [
    {
        name: "query",
        label: "Chercher un freelance",
        type: "text",
        field: "text",
        placeholder: "Entrez les termes de recherche",
        className: "label-input filter filter-with-marin-right-small",
        filterType: FILTER_TYPE_TEXT,
        customComponentParameters: {
            customComponentName: "default",
            isSearchable: true,
            isMulti: false
        },
        formatFilterForApi: value => {
            return { name: "query", value: value.replace(/\//gi, "\\/") };
        },
        formatCurrent: obj => {
            return obj.value.value;
        },
        asyncQueryParameters: {
            query: {
                api: process.env.REACT_APP_IDENTITY_GQL_URL
                    ? process.env.REACT_APP_IDENTITY_GQL_URL
                    : "",
                table: "users",
                entity: "User",
                list: true,
                query: {
                    fields: [
                        "id",
                        "_id",
                        "lastName",
                        "firstName",
                        "precalState",
                        "profile{job, availability, tjm, expertise, qualified}",
                        "type"
                    ],
                    maxResults: 30,
                    page: 0,
                    orderBy: { name: "lastName" },
                    order: "ASC"
                }
            },
            formatQueryResult: obj => obj,
            params: {
                type: "graphql",
                filter_name: "lastName",
                defaultFilters: [
                    { value: { deletedAt: false }, name: "exists" },
                    {
                        value: true,
                        name: "profile_qualified"
                    },
                    {
                        value: true,
                        name: "enabled"
                    },
                    {
                        name: "type",
                        value: "FREELANCE"
                    }
                ]
            },
            formatResult: obj => obj.map(o => {
                return {
                    label: formatUserName(o.firstName, o.lastName),
                    value: o.lastName,
                    filterName: "name"
                };
            })
        }
    },
    {
        name: "availability",
        defaultName: "",
        formatFilterForApi: value => {
            return {
                name: `availability`,
                value: { to: moment(value).format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
                addedFilters: [{ name: "in_progress_mission", value: "false" }]
            };
        },
        formatCurrent: obj => {
            return moment(obj.value.value).format("DD-MM-YYYY");
        },
        label: "search.date",
        filterType: FILTER_TYPE_TEXT,
        className: "label-input filter filter-with-marin-right-small",
        field: "text",
        type: "date",
        dateType: "before"
    }
];
const filters = [
    {
        name: "tjm",
        label: "profile.tjm",
        defaultName: "profile_tjm",
        filterType: FILTER_TYPE_SELECT,
        placeholder: "",
        formatFilterForApi: values => {
            return { name: "tjm", value: values.value };
        },
        formatCurrent: obj => obj.value.label,
        options: [
            { value: { from: 200, to: 351 }, label: "200€ - 350€" },
            { value: { from: 350, to: 501 }, label: "350€ - 500€" },
            { value: { from: 500, to: 701 }, label: "500€ - 700€" },
            { value: { from: 700, to: 1001 }, label: "700€ - 1 000€" },
            { value: { from: 1000 }, label: " > 1 000€" }
        ]
    },
    {
        name: "expertise",
        label: "profile.expertise",
        defaultName: "profile_expertise",
        isModal: true,
        multiple: false,
        inputAsDefault: true,
        formatFilterForApi: values => {
            return { name: "expertise", value: values.value };
        },
        formatCurrent: value => value.value.label,
        filterType: FILTER_TYPE_SELECT,
        isSearchable: false
    },
    {
        name: "mobility_city",
        label: "Ville",
        filterType: GOOGLE_AUTOCOMPLETE,
        placeholder: "Entrez une ville",
        formatCurrent: value => value.value.label,
        formatFilterForApi: values => {
            return {
                name: "mobility_city",
                value: [values.label],
                addedFilters: [{ name: "mobility_rayon", value: values.value }]
            };
        },
    },
    {
        name: "profile_mobilityRayon",
        label: "profile.rayon",
        defaultName: "rayon",
        isModal: true,
        filterType: RANGE_FILTER,
        formatFilterForApi: value => {
            return {
                name: "mobility_rayon",
                value: { from: value.min, to: value.max }
            };
        },
        formatCurrent: obj => {
            return `Dans un rayon de ${obj.value.min} km à ${obj.value.max} Km`;
        },
        asyncParameters: "",
        minRange: 0,
        maxRange: 500,
        unity: "km"
    },
    {
        name: "newsletter_exists",
        label: "newsletter",
        defaultName: "mainContact_newsletter",
        filterType: FILTER_TYPE_SELECT,
        placeholder: "",
        formatFilterForApi: values => {
            if (values.value === true)
                return { name: "newsletter", value: `true` };
            else if (values.value === false)
                return { name: "newsletter", value: `false` };
            else
                return { name: "toRemove", value: null };
        },
        formatCurrent: obj => {
            return `Newsletter : ${obj.value.label}`;
        },
        options: [
            { value: "toRemoveWhenFetch", label: "Vide" },
            { value: true, label: "Oui" },
            { value: false, label: "Non" }
        ]
    },
    {
        name: "helpCreation",
        label: "profile.helpCreation",
        defaultName: "helpCreation",
        filterType: FILTER_TYPE_SELECT,
        placeholder: "",
        formatFilterForApi: values => {
            if (values.value === true)
                return { name: "helpcreation", value: `true` };
            else
                return { name: "helpcreation", value: `false` };
        },
        formatCurrent: obj => {
            return `Accompagnement : ${obj.value.label}`;
        },
        options: [
            { value: "toRemoveWhenFetch", label: "Vide" },
            { value: true, label: "Oui" },
            { value: false, label: "Non" }
        ]
    }
];
export default { headerFilters, filters };
