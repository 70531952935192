import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import SearchContainer from "../../../../Components/SearchContainer/SearchContainer";
import DisplaySelectedFilters from "../../../../Components/Filters/DisplaySelectedFilters";
import Result from "../../../../Components/FoundResult/Result";
import DropDownOrderFilter from "../../../../Components/Filters/DropDownOrderFilter";
import { GroupedFiltersList, NormalFilters, QueryFilters } from "../../../SearchFilter/FiltersList";
import { useFilter } from "../../../../Hooks/useFilter";
import exportCSV from "../../../../Shared/exportCsv";
import { FalseReactTableHeader } from "../../../../Components/FalseReactTable/PatchHeader";
import InfiniteScroll from "../../../../Components/InfiniteScroll/InfiniteScroll";
import { NewFreelanceBody } from "../../../../Components/FalseReactTable/PatchBody";
import useWindowDimensions from '../../../../Hooks/useWindowDimensions';
const Search = ({ updateFilters, removeFilters, facets, headerFilters, currentFilters }) => {
    return (React.createElement(SearchContainer, { title: "freelances.new", header: true, withBtn: false, addClassName: "blueBackground centerContainer" }, headerFilters && (React.createElement("div", { className: "filterList castorForm-formRow" },
        React.createElement(QueryFilters, { filter: headerFilters.find((hf) => hf.name === 'query'), removeFilters: removeFilters, updateFilters: updateFilters, currentValues: currentFilters }),
        React.createElement(GroupedFiltersList, { isImmediate: true, filters: headerFilters.filter((hf) => hf.name !== 'query'), removeFilters: removeFilters, facets: facets, currentValues: currentFilters, updateFilters: updateFilters })))));
};
const NewFreelanceListBodyES = (props) => {
    const { data, history, facets, updateSort, loadNextPage, hasNextPage, isNextPageLoading, totalItems, setRequestFilters } = props;
    const { headerFilters, updateFilters, currentFilters, filters, filterRequest, removeFilters, addUpdateFilters } = useFilter('newfreelanceList', 'graphql');
    const { width } = useWindowDimensions();
    const { t } = useTranslation("rmfreelance");
    useEffect(() => {
        if (!setRequestFilters)
            return;
        setRequestFilters(filterRequest);
    }, [filterRequest]);
    const changeOrder = (name, value) => {
        updateSort({ [`${name}`]: value.toLowerCase() });
    };
    const exportFile = () => exportCSV("/api/export/freelance_unqualified", "unqualified-freelance");
    return (React.createElement(Fragment, null,
        React.createElement(Search, { updateFilters: updateFilters, removeFilters: removeFilters, facets: facets, headerFilters: headerFilters, currentFilters: currentFilters }),
        React.createElement("div", { className: "selectable-filter wrapFilters" }, filters && (React.createElement(NormalFilters, { currentValues: currentFilters, filters: filters, facets: facets, updateFilters: updateFilters, addUpdateFilters: addUpdateFilters }))),
        React.createElement(DisplaySelectedFilters, { filters: filters, headerFilters: headerFilters, selectedFilters: currentFilters, removeFilters: removeFilters }),
        React.createElement(Result, { size: totalItems }),
        React.createElement(Container, null,
            React.createElement("div", { className: "orderFilterContainer" },
                React.createElement("div", null,
                    React.createElement(DropDownOrderFilter, { title: "Trier par statut", changeOrder: changeOrder, name: "precalstate", DescText: "En Attente", AscText: "Nouveau" }),
                    React.createElement(DropDownOrderFilter, { title: "Trier par création", changeOrder: changeOrder, name: "created_at", DescText: "Du plus récent au plus ancien", AscText: "Du plus ancien au plus récent" })),
                React.createElement("span", { className: "exportButton littleTextButton", onClick: () => exportFile() }, t("export.btn"))),
            React.createElement("div", { className: "patch-wh-table " },
                React.createElement("div", { className: 'overloadGrid' },
                    React.createElement(FalseReactTableHeader, { headers: ["    ", t("table.state"), t("table.inscription"), t("table.lastname"), t("table.firstname"), t("table.title"), ""] }),
                    React.createElement(InfiniteScroll, { data: data, rowHeight: width < 767 ? 200 : 100, height: 1000, hasNextPage: hasNextPage, loadNextPage: loadNextPage, isNextPageLoading: isNextPageLoading, component: ({ index }) => {
                            return (React.createElement("div", { onClick: () => {
                                    history.push(`/rm/freelances/freelance_edit/${data[index].ref}`);
                                } },
                                React.createElement(NewFreelanceBody, { key: `freelance-${index}`, d: data[index], index: index })));
                        } }))))));
};
export default NewFreelanceListBodyES;
