import React, { Fragment } from "react";
import Loading from "../../../../Components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { useStoredElasticSearchClient } from "../../../../Hooks/useStoresElasticSearchClient";
import * as documentService from "../../../../Services/document";
import { photoCreateNotif, societyCreateNotif } from "../../../../Services/notif/notif";
import ApiService from "../../../../Services/ApiService";
import { SOCIETY } from "../../../../Services/contants";
import { fetchCounts } from "../../../../redux/count/action";
import { fetchNavParams } from "../../../../redux/nav/action";
const LoadClientListES = ({ render, defaultFilters = [], facetsConfig }) => {
    const user = useSelector((state) => state.user.data);
    const { data: clientData, facets, isLoading, loadData, reloadData, setRequestFilters, setDefaultFiltersOverride, totalItems, hasNextPage, loadNextPage, isNextPageLoading, updateSort } = useStoredElasticSearchClient(defaultFilters, facetsConfig);
    const dispatch = useDispatch();
    const onSubmitClient = async (data) => {
        let formatedData = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phone: data.phone,
            function: data.function,
            precalState: 1,
            roles: ["ROLE_CLIENT"],
            type: "CLIENT",
            civility: data.civility.value,
            nationality: "french",
        };
        if (data.newsletter) {
            formatedData = {
                ...formatedData,
                newsletter: { email: data.email }
            };
        }
        let photoId = null;
        if (data.photo && data.photo.file) {
            let submitPhoto = {
                file: data.photo.file
            };
            await documentService.postFile(submitPhoto).then((data) => {
                photoId = data["@id"];
                photoCreateNotif.success();
            }).catch((e) => {
                console.error(e);
                photoCreateNotif.failed();
            });
        }
        const { name, affiliate, siret } = data;
        const societyService = new ApiService(SOCIETY);
        return societyService.create({
            rm: user.id,
            name: name,
            photo: photoId ? photoId : null,
            affiliate: affiliate,
            siret: siret,
            address: { ...data.address, country: data.address.country.value },
            activitySector: data.activitySector.value,
            mainContact: { ...formatedData }
        }).then((_societyData) => {
            societyCreateNotif.success();
            reloadData();
            dispatch(fetchCounts());
            dispatch(fetchNavParams());
        }).catch(_e => {
            societyCreateNotif.failed();
        });
    };
    return (React.createElement(Fragment, null,
        (isLoading) && React.createElement(Loading, { className: 'load-zIndexMin' }),
        clientData && render({
            clientData,
            onSubmitClient,
            totalItems,
            facets,
            updateSort,
            reloadData,
            setRequestFilters,
            isLoading,
            loadData,
            loadNextPage,
            setDefaultFiltersOverride,
            isNextPageLoading,
            hasNextPage,
            user,
        })));
};
export default LoadClientListES;
