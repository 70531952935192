import React, { Fragment, useEffect, useState } from "react";
import MissionElementES from "../../../MissionLists/MissionElementES";
import { withRouter } from "react-router";
import { Container } from "reactstrap";
import { useFilter } from "../../../../../Hooks/useFilter";
import { GroupedFiltersList, NormalFilters, QueryFilters, } from "../../../../SearchFilter/FiltersList";
import DisplaySelectedFilters from "../../../../../Components/Filters/DisplaySelectedFilters";
import Result from "../../../../../Components/FoundResult/Result";
import { useTranslation } from "react-i18next";
import SearchContainer from "../../../../../Components/SearchContainer/SearchContainer";
import { AccessEnum, checkAccess } from "../../../../../Services/Access/access";
import HeaderMissionList from "../../../../Missions/Components/HeaderMissionList";
import InfiniteScroll from "../../../../../Components/InfiniteScroll/InfiniteScroll";
import Sidebar from "../../../../../Components/Sidebar/View";
import FFCreateMission from "../../../../Missions/SideBarComponents/FFCreateMission";
import LoadMissionListES from "../LoadMissionListES";
import useWindowDimensions from '../../../../../Hooks/useWindowDimensions';
const Search = ({ setIsOpen, removeFilters, userPermissions, facets, updateFilters, headerFilters, currentFilters, }) => {
    const { t } = useTranslation("missions");
    return (React.createElement(SearchContainer, { title: t("missions:missions.mine"), header: true, withBtn: checkAccess(AccessEnum.CREATE_EDIT_MISSION, userPermissions), textBtn: t("missions:missions.new"), onClick: (e) => {
            e.stopPropagation();
            setIsOpen(true);
        } }, headerFilters && (React.createElement("div", { className: "filterList castorForm-formRow" },
        React.createElement(QueryFilters, { filter: headerFilters.find((hf) => hf.name === "query"), removeFilters: removeFilters, updateFilters: updateFilters, currentValues: currentFilters }),
        React.createElement(GroupedFiltersList, { isImmediate: true, updateFilters: updateFilters, filters: headerFilters.filter((hf) => hf.name !== "query"), removeFilters: removeFilters, facets: facets, currentValues: currentFilters })))));
};
const MissionListESBody = ({ history, user, facets, setRequestFilters, isLoading, onSubmitMission, totalItems, data, hasNextPage, isNextPageLoading, loadNextPage, setDefaultFiltersOverride, }) => {
    const { t } = useTranslation(["notif", "missions"]);
    const [isOpen, setIsOpen] = useState(false);
    const { headerFilters, filters, updateFilters, currentFilters, filterRequest, removeFilters, } = useFilter("missionListES", "graphql");
    useEffect(() => {
        if (!setRequestFilters)
            return;
        setRequestFilters(filterRequest);
    }, [filterRequest]);
    const submitMission = (data) => {
        return onSubmitMission(data)
            .then(() => {
            setIsOpen(false);
        })
            .catch(() => {
            setIsOpen(true);
        });
    };
    const goToMission = (id) => history.push(`/rm/missions/${id}`);
    const setClose = () => setIsOpen(false);
    const updateDefaultFilters = (filters) => {
        const { value } = filters[0];
        setDefaultFiltersOverride([
            { name: "rm_status_value", value: value.map((v) => `${v}`) },
        ]);
    };
    const { width } = useWindowDimensions();
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('responsiveCreateMissionopen');
        }
        else {
            document.body.classList.remove('responsiveCreateMissionopen');
        }
    }, [isOpen]);
    return (React.createElement(Fragment, null,
        React.createElement(Search, { userPermissions: user.permissions, removeFilters: removeFilters, facets: facets, updateFilters: updateFilters, currentFilters: currentFilters, headerFilters: headerFilters, setIsOpen: setIsOpen }),
        React.createElement("div", { className: "selectable-filter" }, filters && (React.createElement(NormalFilters, { currentValues: currentFilters, facets: facets, filters: filters, updateFilters: updateFilters }))),
        React.createElement(DisplaySelectedFilters, { filters: filters, headerFilters: headerFilters, selectedFilters: currentFilters, removeFilters: removeFilters }),
        React.createElement(Result, { size: totalItems }),
        React.createElement("div", { className: "missionTable" },
            React.createElement(HeaderMissionList, { onClick: updateDefaultFilters }),
            React.createElement(Container, { className: "infinite-scroll-container mission-list-container" },
                React.createElement("div", { className: "overloadGrid" },
                    React.createElement(InfiniteScroll, { data: data, rowHeight: width < 820 ? 470 : 200, height: 1000, hasNextPage: hasNextPage, loadNextPage: loadNextPage, isNextPageLoading: isNextPageLoading, component: ({ index }) => {
                            return (React.createElement(Fragment, null,
                                React.createElement(MissionElementES, { key: `mission-${index}`, mission: data[index], addClass: "vertical", onClick: goToMission })));
                        } })))),
        React.createElement(Sidebar, { showSidebar: isOpen, close: setIsOpen, title: t("missions:missions.new") },
            React.createElement(FFCreateMission, { close: setClose, onSubmit: submitMission, permissions: user.permissions, role: "ROLE_ADMIN", entities: {}, entitiesLoaded: false }))));
};
const MissionListES = ({ history }) => {
    return (React.createElement(LoadMissionListES, { facetsConfig: [
            { name: "activities_name", size: 100, sort: { value: "asc" } },
            { name: "rm_fullname", size: 100, sort: { value: "asc" } },
            { name: "city", size: 100, sort: { value: "asc" } },
        ], defaultFilters: [{ name: 'main_contact_enabled', value: 'true' }, { name: "rm_status_value", value: ["100", "200", "250", "300", "400", '500', '600', '700', '800'] }], sortConfig: { created_at: "desc" }, render: ({ data, user, setRequestFilters, facets, onSubmitMission, isLoading, hasNextPage, isNextPageLoading, totalItems, loadNextPage, setDefaultFiltersOverride, }) => {
            return (React.createElement("div", { className: "rightContainer rightContainer--withHeadFilters littleHeader width-selectable-filters rightContainer--withHeadFilters missionListContainer" },
                React.createElement(MissionListESBody, { history: history, facets: facets, data: data, user: user, setRequestFilters: setRequestFilters, isNextPageLoading: isNextPageLoading, loadNextPage: loadNextPage, hasNextPage: hasNextPage, totalItems: totalItems, setDefaultFiltersOverride: setDefaultFiltersOverride, isLoading: isLoading, onSubmitMission: onSubmitMission })));
        } }));
};
export default withRouter(MissionListES);
