import React, { useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import LoadSociety from "../../GraphQLRenderProps/LoadSociety";
import { useScroll } from "../../../Hooks/useScroll";
import classNames from "classnames";
import LogoEdition from "../../../Components/Clients/Form/LogoEdition";
import SearchContainer from "../../../Components/SearchContainer/SearchContainer";
import Anchors from "../../../Components/Anchors/list";
import { EditClientSections } from "../../../helper/anchorSection";
import { AccessEnum, checkAccess } from "../../../Services/Access/access";
import { Button, Container } from "reactstrap";
import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";
import FFSocietyContainer from "./ClientEdit/Component/FFSocietyContainer";
import FFContactContainer, { FFContactFields, } from "./ClientEdit/Component/FFContactContainer";
import LoadCommentsFromCollectionID from "../../GraphQLRenderProps/LoadSingleComment";
import Comments from "../Freelance/FreelanceEdit/Comments";
import CommentFormContainer from "../Freelance/FreelanceEdit/CommentForm";
import { StaticContainer } from "../../../Components/Statistique/StaticBar";
import CounterContainer, { UnupdatableCounterContainer, } from "./ClientEdit/Component/CounterContainers";
import PackManList from "../../../Components/Counter/PackManList";
import Sidebar from "../../../Components/Sidebar/View";
import { Form } from "react-final-form";
import ButtonBottomForm from "../../../Components/WHForm/ButtonBottomForm";
import { useSelector } from "react-redux";
import Loading from "../../../Components/Loading";
import NewAssignRm from "./ClientEdit/Component/NewAssignRm";
const ClientEditHeader = ({ data, user, qualifClient, changePrecalState, loadData, }) => {
    const { t } = useTranslation("rmclient");
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: `headContainer` },
            React.createElement(SearchContainer, { title: "", withBtn: false },
                React.createElement("div", { className: "client-edit-search flex-row flex-justifyCenter" },
                    data && (React.createElement(LogoEdition, { id: data._id, directUpdate: true, imgAsInput: false, imgUrl: data.photo && data.photo.content ? data.photo.content : "", changePrecalState: changePrecalState })),
                    data && (React.createElement(NewAssignRm, { label: data.name, rm: data && data.rm ? data.rm : null, load: loadData, id: data._id, directUpdate: true, changePrecalState: changePrecalState })))),
            React.createElement(Anchors, { sections: data && data.qualified
                    ? EditClientSections
                    : EditClientSections.filter((s) => s.anchorName !== "statistique" &&
                        s.anchorName !== "missionsrestantes"), offsetHeight: -460 }),
            data &&
                !data.qualified &&
                checkAccess(AccessEnum.QUALIF_CLIENT, user.permissions) && (React.createElement("div", { className: "container flex-row flex-row-flex-end pt-0 pb-0 pl-15 pr-15" },
                React.createElement(Button, { className: "btn-qualif size-small weight-bold", onClick: qualifClient }, t("clientedit.qualif")))))));
};
const ClientEditWrapper = ({ children, data }) => {
    const { scrollClass } = useScroll();
    const qualifPage = classNames({
        "rightContainer--withHeadFilters--qualif": data ? !data.qualified : null,
    });
    return (React.createElement("div", { className: `rightContainer rightContainer--withHeadFilters rightContainer-textPage rm-freelance-edit ${scrollClass} ${qualifPage}` },
        React.createElement("div", { className: "rm-client-edit-form" }, children)));
};
const ClientEditMain = ({ data, loadData, onPrev, user, deleteSociety, loaders, onAddContact, changePrecalState, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const sectors = useSelector((state) => state.sectors.sectors);
    const { t } = useTranslation([
        "form",
        "notif",
        "common",
        "rmclient",
        "counter",
    ]);
    const addContact = (data) => {
        return onAddContact(data).then(() => {
            setIsOpen(false);
        });
    };
    return (React.createElement(Container, null,
        React.createElement("div", { className: "rm-client-edit-content" },
            React.createElement(Button, { "data-testid": "previousButton", className: "previousButton", onClick: onPrev },
                React.createElement("i", { className: "fa fa-chevron-left" })),
            React.createElement(Element, { name: "society roundBlocksContainer roundBlocksContainer--oneCol" },
                React.createElement("div", { className: "society castorForm roundBlock" },
                    loaders.contact && React.createElement(Loading, null),
                    data && data.mainContact && (React.createElement(FFSocietyContainer, { name: data.name, siret: data.siret, address: data.address, affiliate: data.affiliate, mainContact: data.mainContact, activitySector: data.activitySector ? data.activitySector : "", sectors: sectors, id: data._id, callback: loadData, changePrecalState: changePrecalState })))),
            React.createElement(Element, { name: "contacts", className: "roundBlocksContainer roundBlocksContainer--oneCol" },
                React.createElement("div", { className: "society castorForm roundBlock" }, data && data.mainContact && (React.createElement(FFContactContainer, { mainContact: data.mainContact, contacts: data.contacts, id: data._id, callback: loadData, setIsOpen: setIsOpen, changePrecalState: changePrecalState })))),
            React.createElement(Element, { name: "notes" }, data && (React.createElement(LoadCommentsFromCollectionID, { comments: data.commentaires, callback: loadData, profile_id: data._id, type: "society", changePrecalState: changePrecalState, render: ({ data, onDelete, editComment, onSubmit }) => {
                    return (React.createElement("div", { className: "roundBlock loadingContainer" },
                        React.createElement(Comments, { data: data, onDelete: onDelete, editComment: editComment }),
                        React.createElement(CommentFormContainer, { onSubmit: onSubmit, data: data })));
                } }))),
            data && data.mainContact && data.qualified && (React.createElement(Element, { name: "statistique" },
                React.createElement(StaticContainer, { clientId: data.id }))),
            data && data.qualified && (React.createElement(Element, { name: "missionsrestantes" },
                checkAccess(AccessEnum.ACT_COUNTER, user.permissions) ? (React.createElement(CounterContainer, { nbPackHelp: data.nbPackHelp, nbpackNoHelp: data.nopackNoHelp, callback: loadData, data: data })) : (React.createElement(UnupdatableCounterContainer, { nbPackHelp: data.nbPackHelp, nbpackNoHelp: data.nopackNoHelp })),
                React.createElement("div", null,
                    React.createElement(PackManList, { id: data._id, nbPackHelp: data.nbPackHelp, nbpackNoHelp: data.nopackNoHelp })))),
            React.createElement("div", { className: "listFooter" },
                loaders.delete && React.createElement(Loading, { className: "load-zIndexMin" }),
                React.createElement("div", { onClick: onPrev },
                    React.createElement("span", { className: "backBtn" }, t("common:returnlist"))),
                data && checkAccess(AccessEnum.DELETE_CLIENT, user.permissions) && (React.createElement("div", null,
                    React.createElement(Button, { className: "btn-delete", onClick: deleteSociety }, t("rmclient:clientedit.other_suppress"))))),
            isOpen && (React.createElement(Sidebar, { showSidebar: isOpen, close: setIsOpen, title: t("form:contact.new") },
                React.createElement("div", { className: "sidebarBody" },
                    React.createElement(Form, { onSubmit: addContact, render: ({ handleSubmit, values }) => {
                            return (React.createElement("form", { onSubmit: handleSubmit },
                                loaders.contact && React.createElement(Loading, null),
                                React.createElement(ButtonBottomForm, { toggle: () => setIsOpen(false), display: true, separation: true },
                                    React.createElement(FFContactFields, { title: "contact.other", subtitle: "contact.3max", error: null, values: values, loaded: loaders.create, disabled: false, isCreate: true }))));
                        } })))))));
};
const ClientEdit = ({ history, match }) => {
    const goBack = () => {
        return history.goBack();
    };
    return (React.createElement(LoadSociety, { history: history, id: match.params.id, render: ({ data, changePrecalState, onAddContact, deleteSociety, loaders, qualifSociety, user, loadData, }) => {
            if (!data)
                return;
            return (React.createElement(ClientEditWrapper, { data: data },
                React.createElement(ClientEditHeader, { data: data, loadData: loadData, qualifClient: qualifSociety, user: user, changePrecalState: changePrecalState }),
                React.createElement(ClientEditMain, { data: data, loadData: loadData, onPrev: goBack, user: user, deleteSociety: deleteSociety, loaders: loaders, onAddContact: onAddContact, changePrecalState: changePrecalState })));
        } }));
};
export default withRouter(ClientEdit);
