import app from 'firebase/app';
import 'firebase/database';
import 'firebase/analytics';
import 'firebase/messaging';
let firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
};
if (window._HAS_SERVICE_WORKER)
    app.initializeApp(firebaseConfig);
export const fireBaseMessaging = window._HAS_SERVICE_WORKER ? app.messaging() : null;
