import React from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
export const RDButtonBottomForm = ({ toggle, display, render }) => {
    const { t } = useTranslation("form");
    return (React.createElement("div", null,
        render(display),
        React.createElement("div", { className: "form-bottom-button" },
            React.createElement(Button, { type: "reset", onClick: e => {
                    e.preventDefault();
                    toggle();
                }, className: "blueButton firstButton" }, t("btn.cancel")),
            React.createElement(Button, { type: "submit", className: "orangeButton secondButton" }, t("btn.register")))));
};
const ButtonBottomForm = ({ toggle, reset = undefined, display, children, separation }) => {
    const { t } = useTranslation("form");
    return (React.createElement("div", null,
        children,
        display && (React.createElement("div", { className: `mt-25 form-bottom-button ${separation ? "separated" : ""}` },
            React.createElement(Button, { type: "reset", onClick: e => {
                    e.preventDefault();
                    if (reset) {
                        reset();
                    }
                    toggle();
                }, className: "blueButton firstButton" }, t("btn.cancel")),
            React.createElement(Button, { type: "submit", className: "orangeButton secondButton" }, t("btn.register"))))));
};
export default ButtonBottomForm;
