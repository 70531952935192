import React, { Fragment } from "react";
import { Card } from "reactstrap";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { ROLES } from "../../constants/roles";
import { logout } from '../../Shared/session';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import i18n from "../../i18n";
import { formatNotifDate } from "../../Views/RessourceManager/Notifications/formatNotifDate";
const NotigBadge = ({ onClick, notification }) => {
    const onRedirect = () => {
        onClick(notification);
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: notification.tos[0].status === 'toread' ? 'notification_toread notificationStatus' : 'notification_readed notificationStatus' }),
        React.createElement("div", { className: 'notificationItem', onClick: onRedirect },
            React.createElement("div", { className: "notificationsFromInfos" },
                React.createElement("div", null,
                    notification.from.name,
                    "    ",
                    notification.from.role === 'FREELANCE' ? ` - ${notification.from.subInfo} ` : ""),
                notification.from.role === 'FREELANCE'
                    ?
                        React.createElement("div", null, notification.params.CUSTOMER &&
                            React.createElement("div", null,
                                React.createElement("span", null, notification.params.CUSTOMER.name),
                                React.createElement("span", null, notification.params.CUSTOMER.filiale ? ` -  ${notification.params.CUSTOMER.filiale} ` : "")))
                    :
                        React.createElement("div", null, notification.from.subInfo)),
            React.createElement("div", null,
                React.createElement("p", null,
                    "Mission : ",
                    notification.params.MISSION_NAME),
                React.createElement("p", null,
                    "Job : ",
                    notification.params.JOB)),
            React.createElement("div", null,
                React.createElement("div", { className: `notifSubject ${notification.level}` },
                    React.createElement("span", { className: `icon ${notification.icon}` }),
                    notification.push.subject),
                React.createElement("div", { className: "notificationsDate" }, formatNotifDate(notification.date))))));
};
const UserMenu = ({ open, toggleMenu, user_role, notifications = [], onClickNotif }) => {
    const { t } = useTranslation("freelance");
    const userLogoutSwal = () => {
        const MySwal = withReactContent(Swal);
        return MySwal.fire({
            title: "",
            text: i18n.t("freelance:swal.logout.content"),
            confirmButtonText: i18n.t("freelance:swal.logout.confirm"),
            cancelButtonText: i18n.t("freelance:swal.logout.cancel"),
            customClass: {
                popup: "swallModal-popup",
                content: "swallModal-container",
                actions: "swallModal-actions form-bottom-button",
                confirmButton: "swallModal-orangeButton secondButton btn btn-secondary",
                cancelButton: "swallModal-blueButton firstButton btn btn-secondary"
            },
            showCancelButton: true,
        });
    };
    const userLogout = async () => {
        return userLogoutSwal().then(async (result) => {
            if (!result.value)
                return;
            await logout();
            window.location.href = `${process.env.REACT_APP_WWW_HOST}/fr/logout`;
        });
    };
    const getDashboard = () => {
        if ([ROLES.CLIENT].some(r => user_role.indexOf(r) >= 0)) {
            return `/client/dashboard`;
        }
        if ([ROLES.RM].some(r => user_role.indexOf(r) >= 0)) {
            return `/rm/dashboard/freelance`;
        }
        return '/';
    };
    const goToDashboard = () => {
        window.location.href = getDashboard();
    };
    const getLink = (element) => {
        if ([ROLES.CLIENT].some(r => user_role.indexOf(r) >= 0)) {
            return `/client/${element}`;
        }
        if ([ROLES.FREE].some(r => user_role.indexOf(r) >= 0)) {
            return `/freelance/${element}`;
        }
        if ([ROLES.RM].some(r => user_role.indexOf(r) >= 0)) {
            return `/rm/${element}`;
        }
        return '#';
    };
    return (React.createElement(Card, { className: classnames("user-menu", { show: open }) },
        React.createElement(Fragment, null,
            notifications && notifications.length > 0 && (React.createElement("ul", { className: "notificationsList" },
                React.createElement(Fragment, null, notifications.map((n, idx) => {
                    return (React.createElement("li", { key: `badge-${idx}` },
                        React.createElement(NotigBadge, { notification: n, onClick: onClickNotif })));
                })),
                React.createElement("button", { onClick: goToDashboard, className: "all-notifs" }, t("profilMenu.allNotification")))),
            React.createElement("div", { className: "btn-group" },
                React.createElement("a", { href: getLink(([ROLES.RM].some(r => user_role.indexOf(r) >= 0)) ? 'dashboard/freelance' : 'dashboard'), onClick: toggleMenu },
                    React.createElement("span", { className: "icon-profil" }),
                    " ",
                    !([ROLES.CLIENT].some(r => user_role.indexOf(r) >= 0)) && !([ROLES.RM].some(r => user_role.indexOf(r) >= 0)) ? t("profilMenu.myProfil") : "Tableau de bord"),
                React.createElement("a", { href: getLink('account'), onClick: toggleMenu },
                    React.createElement("span", { className: "icon-compte" }),
                    " ",
                    t("profilMenu.myAccount")),
                (![ROLES.CLIENT].some(r => user_role.indexOf(r) >= 0)) && (![ROLES.RM].some(r => user_role.indexOf(r) >= 0)) &&
                    React.createElement("a", { href: getLink('my-documents'), onClick: toggleMenu },
                        React.createElement("span", { className: "icon-doc" }),
                        " ",
                        t("profilMenu.myDocuments")))),
        React.createElement("button", { className: "logout", onClick: userLogout },
            React.createElement("span", { className: "icon-deconnexion" }),
            " ",
            t("profilMenu.logout"))));
};
export default UserMenu;
