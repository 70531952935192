import React from "react";
const ReferenceItem = ({ freelanceRef, onRemove, onUpdate, disabled = false }) => {
    return (React.createElement("div", { className: 'refItem' },
        React.createElement("div", { className: "refItem-ref ", "data-testid": 'refItem' },
            React.createElement("p", { className: 'mt-15' },
                React.createElement("span", { className: "weight-light size-small" }, "Soci\u00E9t\u00E9*"),
                " ",
                React.createElement("span", { className: "weight-bold size-small" }, freelanceRef.societyName)),
            React.createElement("p", { className: 'mt-15' },
                React.createElement("span", { className: "weight-light size-small" }, "Poste*"),
                " ",
                React.createElement("span", { className: "weight-bold size-small" }, freelanceRef.job)),
            React.createElement("p", { className: 'mt-15' },
                React.createElement("span", { className: "weight-light size-small" }, "Nom du contact*"),
                " ",
                React.createElement("span", { className: "weight-bold size-small" }, freelanceRef.contactName)),
            React.createElement("p", { className: 'mt-15' },
                React.createElement("span", { className: "weight-light size-small" }, "Email du contact"),
                " ",
                React.createElement("span", { className: "weight-bold size-small" }, freelanceRef.contactEmail)),
            React.createElement("p", { className: 'mt-15' },
                React.createElement("span", { className: "weight-light size-small" }, "T\u00E9l\u00E9phone du contact*"),
                " ",
                React.createElement("span", { className: "weight-bold size-small" }, freelanceRef.contactPhone))),
        !disabled &&
            React.createElement("div", { className: "flex flex-justifyCenter  flex-directionColumn mt-auto" },
                React.createElement("span", { className: ' editButton size-tiny', "data-testid": 'updateButton', onClick: () => onUpdate(freelanceRef) }, "Modifier cette r\u00E9f\u00E9rence"),
                React.createElement("span", { "data-testid": 'removeButton', className: "refItem-delete size-tiny mt-15", onClick: () => {
                        if (window.confirm('Êtes-vous sur de vouloir supprimer cette reference ?'))
                            onRemove(freelanceRef._id);
                    } }, "Supprimer la r\u00E9f\u00E9rence"))));
};
export default ReferenceItem;
