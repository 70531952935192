import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AccessEnum, checkAccess } from "../../../Services/Access/access";
import { addHTTPS } from "../../../Shared/utils/utils";
import { UseDocument } from "../../../Components/Freelance/Documents/useDocument";
import getFile from "../../../Shared/getFile";
import { formatUserName } from "../../../helper/formatUserName";
const SelectedFreelance = ({ firstName, lastName, photo, job, tjm, profile, statusFl, goToProfile, id, onValidation, permissions = [] }) => {
    const { t } = useTranslation("missions");
    const { profileDocument } = UseDocument(profile, "parcours");
    const [focused, setFocused] = useState(false);
    const addFocusedClass = (bool) => {
        setFocused(bool);
    };
    return (React.createElement("div", { className: "selected-freelance" },
        React.createElement("div", { className: "rm-selected-freelance", style: { display: "flex", flexDirection: "row" } },
            React.createElement("div", { className: "rm-selected-freelance-info border-right" },
                React.createElement("div", { className: "picture-name-container border-right" },
                    React.createElement("div", { className: "picture picture-container" }, photo ? (React.createElement("img", { id: "image", src: photo ? addHTTPS(photo) : "" })) : (React.createElement("div", { className: "logo-empty" },
                        React.createElement("i", { className: `icon-image size-big` })))),
                    React.createElement("div", null,
                        React.createElement("div", null,
                            React.createElement("span", { className: "weight-light size-medium" },
                                React.createElement("strong", null, formatUserName(firstName, lastName)))),
                        React.createElement("div", null,
                            React.createElement("span", { className: "weight-light size-medium" }, `${job}`)))),
                React.createElement("div", { className: "tjm-block" },
                    React.createElement("div", null,
                        React.createElement("span", null,
                            `${t("missions.candidatures.tjm")} :`,
                            React.createElement("strong", null, ` ${tjm} €`))))),
            React.createElement("ul", { className: "selected-freelance-btns" },
                React.createElement("li", { className: "selected-freelance-btn" },
                    React.createElement("span", { className: "cursor", onClick: goToProfile ? () => goToProfile(id) : () => null },
                        React.createElement("strong", null, t("missions.candidatures.profil"))),
                    React.createElement("span", { className: "arrowRight" })),
                React.createElement("li", { className: "selected-freelance-btn" },
                    React.createElement("span", { className: "cursor", onClick: profileDocument ? () => getFile(profileDocument.document._id) : () => null },
                        React.createElement("strong", null, t("missions.candidatures.cv"))),
                    React.createElement("span", { className: "arrowRight" })))),
        statusFl === 1200 &&
            onValidation &&
            checkAccess(AccessEnum.VALIDATE_FOLDER, permissions) && (React.createElement("div", { className: `mission-btns ${focused ? "mission-btns--focused" : ""}`, onClick: () => addFocusedClass(true), onMouseOut: () => addFocusedClass(false) },
            React.createElement("button", { onClick: onValidation }, t("missions.candidatures.validatefolder"))))));
};
export default SelectedFreelance;
