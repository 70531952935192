import React, { Fragment, useState } from "react";
import ImageOverlay from "../../../../Components/ImageOverlay/ImageOverlay";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import 'moment/locale/fr';
import { Form } from 'react-final-form';
import DisplayForm from '../../../../Components/WHForm/DisplayForm';
import CommentFormFields from "./commentFormFields";
const CommentaireItem = ({ commentaire, onDelete, user, onSubmit }) => {
    const { t } = useTranslation(['form', 'notif']);
    const { shouldDisplayForm, displayFormToggle } = DisplayForm();
    const [initialData, setInitialData] = useState(null);
    const onUpdateComment = (comment) => {
        setInitialData(comment);
        displayFormToggle();
    };
    const onSubmitForm = (data) => {
        onSubmit(data);
        displayFormToggle();
    };
    return (React.createElement(Fragment, null, !shouldDisplayForm ?
        React.createElement("div", { className: 'noteItem pl-30 pt-30 pr-30', "data-testid": 'commentItem' },
            React.createElement("p", null, commentaire.message),
            commentaire.user && (React.createElement("div", { className: 'userComment' },
                React.createElement("div", { className: 'image' }, commentaire.user.photo && commentaire.user.photo.content &&
                    React.createElement(ImageOverlay, { icon: null, media_src: commentaire.user.photo.content })),
                React.createElement("div", { className: "text" },
                    React.createElement("p", null,
                        React.createElement("strong", null,
                            t('form:comment.from'),
                            " ",
                            commentaire.user.firstName,
                            " ",
                            commentaire.user.lastName)),
                    React.createElement("p", null, `Le ${moment(commentaire.created).format('DD MMMM YYYY')}`)),
                user.id === commentaire.user.id &&
                    React.createElement("div", { className: "actions" },
                        React.createElement("span", { "data-testid": "removeButton", className: "refItem-delete littleTextButton", onClick: () => onDelete(commentaire._id) }, "Supprimer ma note"),
                        React.createElement("span", { className: "littleTextButton editButton", "data-testid": "updateButton", onClick: () => onUpdateComment(commentaire) }, "Modifier ma note")))))
        :
            React.createElement("div", null,
                React.createElement(Form, { onSubmit: onSubmitForm, initialValues: initialData ? initialData : {}, render: ({ handleSubmit }) => {
                        return (React.createElement("form", { onSubmit: handleSubmit },
                            React.createElement(CommentFormFields, { name: "message", shouldDisplayForm: shouldDisplayForm, toggle: () => displayFormToggle() })));
                    } }))));
};
export default CommentaireItem;
