import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { formatGraphQlData } from "../../../Shared/rewriteArray";
import useGetAll from "../../../Services/getAllHooks/getAll";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ApiService from "../../../Services/ApiService";
import { MISSIONSEVENT } from "../../../Services/contants";
import { missionStatusUpdateNotif } from "../../../Services/notif/notif";
import { createMission } from "../Functions/createMission";
import { getApiError } from "../../../Shared/utils/getApiErrorTranslations";
import { Container } from "reactstrap";
import HeaderMissionBar from "../Components/HeaderMissionBar";
import Loading from "../../../Components/Loading";
import InfiniteScrollWithAutoSizer from "../../../Components/InfiniteScroll/InfiniteScrollWithAutoSizer";
import CardMission from "../Components/Mission";
import Mission from "../Mission";
import CreateMission from "../SideBarComponents/FFCreateMission";
import Sidebar from "../../../Components/Sidebar/View";
import useGetMission from "./useGetMission";
import { useStoredWithPagination } from "../../../Hooks/useStoredWithPagination";
const ClientMission = ({ history, match }) => {
    const connectedSociety = useSelector((state) => state.society);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add("responsiveCreateMissionopen");
        }
        else {
            document.body.classList.remove("responsiveCreateMissionopen");
        }
    }, [isOpen]);
    const [isShown, setIsShown] = useState(false);
    const user = useSelector((state) => state.user.data);
    const [config] = useState({
        baseConfig: {
            type: "graphql",
            api: process.env.REACT_APP_IDENTITY_GQL_URL
                ? process.env.REACT_APP_IDENTITY_GQL_URL
                : "",
            endPoint: {
                table: "missions",
                entity: "Mission",
                list: true,
                query: {
                    order: "DESC",
                    orderBy: { name: "createdAt" },
                    fields: [
                        "id",
                        "_id",
                        "title",
                        "job",
                        "hiddenSociety",
                        "description",
                        "public",
                        "startDate",
                        "endDate",
                        "duration",
                        "startNow",
                        "geometry{id _id latitude longitude}",
                        "expertise",
                        "publicationDate",
                        "tjm",
                        "canceledReason",
                        "city",
                        "society{id _id name photo{_id id content} affiliate mainContact{firstName lastName}}",
                        "missionStatus{id _id statusRm statusClient indicationClient subStatusClient subStatusRm}",
                        "updatedBy{id _id firstName lastName photo{_id id content}}",
                        "helped",
                    ],
                    maxResults: 30,
                    page: 0,
                },
            },
            resultKey: "datas",
        },
        pagination: {
            type: "pages",
            pages: {
                page: 1,
                pageSize: 20,
            },
        },
        filters: [
            { name: "society", value: connectedSociety.data._id.toString() },
            {
                name: "missionStatus_statusClient_list",
                value: [2100, 2200, 2300, 2400, 2500, 2600, 2700, 2800, 2900],
            },
        ],
        uniqueFilters: [],
        formatResult: (res) => formatGraphQlData(res),
    });
    const { data, isLoading, reloadData, hasNextPage, loadNextPage, isNextPageLoading, setDefaultFiltersOverride, } = useStoredWithPagination(config);
    const { mission, isLoading: isMissionLoading, setReload, notFound, } = useGetMission(match.params.id);
    const { entities, entitiesLoaded } = useGetAll([
        {
            table: "societies",
            entity: "Society",
            fields: [
                "name",
                "id",
                "_id",
                "nbPackHelp",
                "nopackNoHelp",
                "mainContact{firstName lastName}",
                "affiliate",
            ],
            filters: [
                { name: "exists", value: { mainContact_deletedAt: false } },
                { name: "mainContact_type", value: "CLIENT" },
                { name: "qualified", value: true },
            ],
            format: (obj) => ({
                label: `${obj.name}${obj.affiliate ? `-${obj.affiliate}` : ""} (${obj.mainContact.firstName} ${obj.mainContact.lastName})`,
                value: obj.id,
                pack: { helped: obj.nbPackHelp, noHelped: obj.nopackNoHelp },
            }),
        },
    ], "ROLE_CLIENT");
    const { t } = useTranslation(["notif", "missions"]);
    useEffect(() => {
        if (!data || data.length === 0 || isNextPageLoading || isLoading)
            return;
        if (match.params.id)
            return;
        history.push({
            pathname: "/client/missions" + "/" + data[0]._id,
        });
    }, [data, isLoading]);
    useEffect(() => {
        if (!notFound)
            return;
        history.push({
            pathname: "/client/missions",
        });
    }, [notFound]);
    const getPackFromEntities = () => {
        let defaultPack = { helped: 0, noHelped: 0 };
        if (connectedSociety && connectedSociety.data) {
            const { nbPackHelp, nopackNoHelp } = connectedSociety.data;
            return { helped: nbPackHelp, noHelped: nopackNoHelp };
        }
        return defaultPack;
    };
    const updateDefaultFilters = (filters) => {
        const { value } = filters[0];
        setDefaultFiltersOverride([
            { name: "society", value: connectedSociety.data._id.toString() },
            { name: "missionStatus_statusClient_list", value: value },
        ]);
    };
    const updateMissionsStatus = (mission_id, code) => {
        const missionService = new ApiService(MISSIONSEVENT);
        missionService
            .create({ mission: mission_id, code: code })
            .then(() => {
            if (reloadData)
                reloadData();
            missionStatusUpdateNotif.success();
        })
            .catch(() => {
            missionStatusUpdateNotif.failed();
        });
    };
    const getTags = (data) => {
        let tags = data.tags ? data.tags.map((t) => t.id) : [];
        let newTags = data.new_tags
            ? data.new_tags.map((t) => t.__isNew__ ? { status: "published", name: t.label } : t.value)
            : [];
        let missionTags = tags.concat(newTags);
        return missionTags;
    };
    const onSubmitMission = (data) => {
        let missionTags = getTags(data);
        let formatedData = {
            ...data,
            society: { value: connectedSociety.data.id },
            tags: missionTags,
        };
        return createMission({ ...formatedData })
            .then((_data) => {
            updateMissionsStatus(_data["@id"], data.status);
            missionStatusUpdateNotif.success();
            setIsOpen(false);
        })
            .catch((e) => {
            missionStatusUpdateNotif.failed(getApiError(e));
        });
    };
    const goToProfile = (id) => {
        history.push(`/client/freelance_view/${id}`);
    };
    const onUpdate = (reloadAll = true) => {
        if (reloadAll)
            reloadData();
        setReload(true);
    };
    return (React.createElement("div", { className: "client-layout-mission" },
        React.createElement(Container, { className: "large" },
            React.createElement("div", { className: "title mb-25" }, t("missions:missions.mine")),
            React.createElement("div", { className: "client-layout-header-container" },
                React.createElement(HeaderMissionBar, { role: "ROLE_CLIENT", onClick: updateDefaultFilters }),
                React.createElement("div", { className: "btn-create-mission cursor", onClick: () => {
                        setIsOpen(true);
                    } },
                    React.createElement("span", null,
                        "+ ",
                        t("missions:missions.create")))),
            React.createElement("div", { className: "mission-view" },
                React.createElement("div", { className: "sidebar-left", style: { minHeight: "800px" } },
                    data && data.length === 0 && (React.createElement("div", null, t("missions:missions.nomission"))),
                    isLoading && React.createElement(Loading, { className: "load-zIndexMin" }),
                    data && (React.createElement(InfiniteScrollWithAutoSizer, { key: JSON.stringify({ value: isNextPageLoading }), data: [...data], loadNextPage: loadNextPage, hasNextPage: hasNextPage, defaultCache: {
                            fixedWidth: true,
                            minHeight: data.length === 1 ? 220 : 193,
                            defaultHeight: 300,
                        }, selectedIndex: -1, className: "infinite-loader-list mission-list", isNextPageLoading: isNextPageLoading, openComponent: () => React.createElement("div", null), closedComponent: ({ index }) => {
                            const { _id } = data[index];
                            return (React.createElement("div", { key: index, className: `mission ${match.params.id && match.params.id === _id.toString()
                                    ? "select-border"
                                    : ""}` },
                                React.createElement("div", { className: "item", onClick: () => {
                                        setIsShown(true);
                                        history.push({
                                            pathname: "/client/missions" + "/" + data[index]._id,
                                        });
                                    } },
                                    React.createElement(CardMission, { mission: data[index], userRole: "ROLE_CLIENT" }))));
                        } }))),
                isMissionLoading && React.createElement(Loading, { className: "load-zIndexMin" }),
                mission && (React.createElement(Mission, { key: JSON.stringify(data), goToProfile: goToProfile, mission: mission, isShown: isShown, setIsShown: setIsShown, callback: (value) => onUpdate(value), userRole: "ROLE_CLIENT", redirect: () => onUpdate() })))),
        React.createElement(Sidebar, { showSidebar: isOpen, close: setIsOpen, title: t("missions:missions.new") },
            React.createElement(CreateMission, { close: setIsOpen, onSubmit: onSubmitMission, role: "ROLE_CLIENT", entities: entities, entitiesLoaded: entitiesLoaded, packed: getPackFromEntities() }))));
};
export default withRouter(ClientMission);
