import React, { Fragment } from "react";
import { useStored } from "../../../Hooks/useStored";
import Reference from './references';
import { MAX } from "../../../Shared/maxResults";
const ReferenceContainer = ({ user, changePrecalState, disabled = false }) => {
    const REFERENCESSTORED = {
        api: process.env.REACT_APP_IDENTITY_GQL_URL ? process.env.REACT_APP_IDENTITY_GQL_URL : '',
        table: 'references',
        entity: 'Reference',
        list: true,
        query: {
            fields: ['id', '_id', 'societyName', 'job', 'contactName', 'contactEmail', 'contactPhone', 'profile{ id, _id}'],
            maxResults: MAX,
            page: 0,
            filters: [{
                    value: user.profile.id,
                    name: 'profile',
                },
            ],
        },
    };
    let { data, loaded, loadData } = useStored(REFERENCESSTORED, '');
    return (React.createElement(Fragment, null, loaded && (React.createElement(Reference, { callback: loadData, user: user, data: data, disabled: disabled, changePrecalState: changePrecalState }))));
};
export default ReferenceContainer;
