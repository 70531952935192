import React from 'react';
import { FFRenderImgFileUpload } from "../../FinalForm/FFRenderCustomFileField";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
const FFLogoCreate = ({ label, imgAsInput }) => {
    const { t } = useTranslation('document');
    return React.createElement("div", { className: 'logo-create' },
        React.createElement(Field, { name: 'photo' }, props => React.createElement(FFRenderImgFileUpload, Object.assign({}, props, { imgAsInput: imgAsInput, label: label ? (t(label)) : '' }))));
};
export default FFLogoCreate;
