import React, { useEffect, useState } from "react";
import { Collapse, Navbar, NavbarToggler, Nav } from "reactstrap";
import HeaderMainContent from "./HeaderMainContent";
import UserSnapshot from "../../../Components/UserSnapshot/UserSnapshot";
import { useLastThreeNotifications } from "../../RessourceManager/Notifications/useNotifications";
import { withRouter } from "react-router";
import { ROLES } from "../../../constants/roles";
const Header = ({ history, picture, user_role, firstName, lastName, toggleRmMenu }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { notifications, fetchNotifications, markNotificationAsRead } = useLastThreeNotifications();
    useEffect(() => {
        if (user_role.includes('ROLE_ADMIN'))
            fetchNotifications();
    }, []);
    const onClickNotif = (notif) => {
        if (user_role.includes(ROLES.CLIENT)) {
            markNotificationAsRead(notif);
            return history.push(`/client/missions/${notif.params.MISSION_ID}`);
        }
        if (user_role.includes(ROLES.RM)) {
            markNotificationAsRead(notif);
            return history.push(`/rm/missions/${notif.params.MISSION_ID}`);
        }
    };
    const toggle = () => toggleRmMenu ? toggleRmMenu() : setIsOpen(!isOpen);
    return (React.createElement(Navbar, { fluid: "true", className: "Header", expand: "md" },
        React.createElement("div", { className: "headerContainer" },
            React.createElement("div", { className: "logo" },
                React.createElement("a", { href: `${process.env.REACT_APP_WWW_HOST}` },
                    React.createElement("img", { src: require("../../../../public/img/logo-castor-1.svg") }))),
            React.createElement(NavbarToggler, { onClick: toggle }),
            React.createElement(Collapse, { isOpen: isOpen, navbar: true },
                React.createElement(Nav, { className: "ml-auto", navbar: true },
                    React.createElement(HeaderMainContent, { user_role: user_role, toggleMenu: toggle }))),
            React.createElement(UserSnapshot, { picture: picture, onClickNotif: onClickNotif, notifications: notifications, user_role: user_role, firstname: firstName, lastname: lastName }))));
};
export default withRouter(Header);
