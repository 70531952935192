import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { FFEditableInformationSociety } from "../../../../../Components/Clients/Form/FFInformationSociety";
import ApiService from "../../../../../Services/ApiService";
import { SOCIETY } from "../../../../../Services/contants";
import { societyUpdateNotif } from "../../../../../Services/notif/notif";
import { useSelector } from "react-redux";
const FFSocietyContainer = ({ name, siret, address, mainContact, sectors, id, affiliate, activitySector, callback, changePrecalState }) => {
    const [loaded, setLoaded] = useState(false);
    const lang = useSelector((state) => state.lang);
    const onSubmitIS = formData => {
        if (!formData.hasOwnProperty('googleMapCity') || (formData.googleMapCity !== formData.address.city)) {
            alert("Tapez une ville puis sélectionnez la dans la liste proposée.");
            return;
        }
        setLoaded(true);
        if (changePrecalState) {
            changePrecalState();
        }
        const { googleMapCity, ...updatedFormData } = formData;
        const societyService = new ApiService(SOCIETY);
        return societyService
            .update({
            ...updatedFormData,
            activitySector: formData.activitySector.value,
            _id: id,
            id: id,
            mainContact: {
                ...mainContact,
                ['@id']: mainContact.id,
            },
            affiliate: formData.hasOwnProperty('affiliate') ? formData.affiliate : null,
            address: { ...formData.address, country: formData.address.country.value }
        })
            .then(() => {
            setLoaded(false);
            societyUpdateNotif.success();
            if (callback)
                callback();
        }).catch((e) => {
            console.error(e);
            setLoaded(false);
            societyUpdateNotif.failed();
        });
    };
    let country = lang.countries.find((c) => c.value === address.country);
    const mutators = {
        setPlaces: (_args, state, utils) => {
            const countries = lang.countries;
            const country = countries.find((c) => c.label === _args[4]);
            utils.changeValue(state, 'address.address', () => _args[0] + ' ' + _args[2]);
            utils.changeValue(state, 'address.zipCode', () => _args[3]);
            utils.changeValue(state, 'address.city', () => _args[1]);
            utils.changeValue(state, 'googleMapCity', () => _args[1]);
            if (country)
                utils.changeValue(state, 'address.country', () => country);
        }
    };
    return (React.createElement(Form, { onSubmit: onSubmitIS, keepDirtyOnReinitialize: true, mutators: { ...mutators }, initialValues: {
            name: name,
            siret: siret,
            affiliate: affiliate,
            newsletter: mainContact.newsletter ? true : false,
            googleMapCity: address.city,
            address: {
                ...address,
                country: { label: country ? country.label : address.country, value: address.country }
            },
            activitySector: { label: activitySector.name, value: activitySector.id }
        }, render: ({ handleSubmit, form: { reset, setConfig, mutators }, initialValues }) => {
            const onReset = () => {
                setConfig('keepDirtyOnReinitialize', false);
                reset();
                setConfig('keepDirtyOnReinitialize', true);
            };
            return React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(FFEditableInformationSociety, { countries: lang.countries, mutators: mutators, initialValues: initialValues, reset: onReset, sectors: sectors.map((s) => ({ label: s.name, value: s.id })), loaded: loaded }));
        } }));
};
export default FFSocietyContainer;
