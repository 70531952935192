import React, { useEffect, useState } from "react";
import { Field } from "redux-form";
import FormWrapper from "../../../../../Components/Form/FormWrapper";
import ApiService from "../../../../../Services/ApiService";
import { USERS } from "../../../../../Services/contants";
import { useTranslation } from "react-i18next";
import DisplayForm from "../../../../../Components/WHForm/DisplayForm";
import Loading from "../../../../../Components/Loading";
import ButtonBottomForm from "../../../../../Components/WHForm/ButtonBottomForm";
import { Alert } from "reactstrap";
import { WhFieldTxt } from "../../../../../Components/WHForm/WhFields";
import { SubmissionError } from "redux-form";
import { societyUpdateNotif } from "../../../../../Services/notif/notif";
export const EditableLoginContainer = ({ loaded }) => {
    const { shouldDisplayForm, displayFormToggle } = DisplayForm();
    const { t } = useTranslation(["form", "common"]);
    useEffect(() => {
        if (!loaded)
            displayFormToggle();
    }, [loaded]);
    return (React.createElement("div", null,
        React.createElement("div", { className: "headerFreeItem" },
            React.createElement("h2", { className: "thinTitle" }, t("form:claccount.title")),
            !shouldDisplayForm && (React.createElement("span", { className: "littleTextButton editButton", "data-testid": "updateButton ", onClick: () => displayFormToggle() }, t("common:modification")))),
        React.createElement(ButtonBottomForm, { toggle: displayFormToggle, display: shouldDisplayForm },
            React.createElement(AccountLoginContainer, { error: null, disabled: !shouldDisplayForm })),
        " ",
        " ",
        !loaded &&
            React.createElement("div", { className: "loading-container" },
                React.createElement(Loading, null))));
};
export const AccountLoginContainer = ({ error, disabled }) => {
    const { t } = useTranslation("form");
    return (React.createElement("div", { className: "content" },
        error && React.createElement(Alert, { color: "danger" }, error),
        React.createElement("div", { className: "field-row" },
            React.createElement(Field, { label: t("claccount.login"), disabled: disabled, name: "email", className: "label-input", required: true, type: "text", component: WhFieldTxt })),
        React.createElement("div", { className: "field-row password-clientaccount" },
            React.createElement(Field, { label: t("claccount.password"), disabled: disabled, name: "newPassword", className: "label-input", required: true, type: "password", component: WhFieldTxt }),
            React.createElement("p", { className: "mb-0 mb-0Mobile mt-5 mt-5Mobile ml-5 ml-5Mobile size-tiny weight-light" }, t("claccount.validPassword"))),
        React.createElement("div", { className: "field-row password-clientaccount" },
            React.createElement(Field, { label: t("claccount.confirmPassword"), disabled: disabled, name: "newPasswordConfirm", className: "label-input", required: true, type: "password", component: WhFieldTxt }))));
};
const AccountLoginForm = ({ login, password, id, callback }) => {
    const [loaded, setLoaded] = useState(true);
    const onSubmitLogin = formData => {
        setLoaded(false);
        const userService = new ApiService(USERS);
        return userService
            .update({
            ...formData,
            _id: id
        })
            .then(() => {
            setLoaded(true);
            societyUpdateNotif.success();
            if (callback)
                callback();
        })
            .catch(e => {
            console.error(e);
            setLoaded(true);
            societyUpdateNotif.failed();
            throw new SubmissionError(e);
        });
    };
    return (React.createElement(FormWrapper, { onSubmit: onSubmitLogin, form: "identifiantForm", initialValues: {
            email: login,
            newPassword: password
        } },
        React.createElement(EditableLoginContainer, { loaded: loaded })));
};
export default AccountLoginForm;
