import React, { useState } from "react";
import { Carousel, CarouselIndicators, } from 'reactstrap';
const WhCarousel = ({ items, slides, animating }) => {
    const [activeIndex, setActiveIndex] = useState(1);
    const next = () => {
        if (animating)
            return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating)
            return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex) => {
        if (animating)
            return;
        setActiveIndex(newIndex);
    };
    return (React.createElement(Carousel, { activeIndex: activeIndex, next: next, previous: previous },
        React.createElement(CarouselIndicators, { items: items, activeIndex: activeIndex, onClickHandler: goToIndex }),
        slides,
        React.createElement("span", { className: "carouselArrowLeft", onClick: previous },
            React.createElement("i", { className: 'fa fa-arrow-left' })),
        React.createElement("span", { className: "carouselArrowRight", onClick: next },
            React.createElement("i", { className: 'fa fa-arrow-right' }))));
};
export default WhCarousel;
