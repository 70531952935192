import React from "react";
import { Field } from 'react-final-form';
import { WhFieldSelect } from "../WHForm/WhFields";
const FFSelect = ({ validate, parse = null, placeholder, name, label, options, containerSize = "big", onChange = null, values = [], isSearchable = false, multiple = false, className, creatable = false, maxValue, asyncParameters, format = null }) => {
    const createPropsField = () => {
        let obj = { name };
        if (parse)
            obj = { ...obj, parse };
        if (format)
            obj = { ...obj, format };
        if (validate)
            obj = { ...obj, validate };
        return obj;
    };
    return (React.createElement(Field, Object.assign({}, createPropsField()), props => (React.createElement(WhFieldSelect, Object.assign({}, props, { label: label, className: className, isSearchable: isSearchable, onChange: onChange, multiple: multiple, options: options, creatable: creatable, containerSize: containerSize, maxValue: maxValue, asyncParameters: asyncParameters, format: format, values: values, placeholder: placeholder })))));
};
export default FFSelect;
