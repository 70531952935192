import React, { Fragment } from 'react';
import ImageOverlay from "../../../Components/ImageOverlay/ImageOverlay";
import { addHTTPS } from "../../../Shared/utils/utils";
import { ImagePlaceholder } from "../../../Components/Image/Image";
import PopOver from "../../../Components/PopOver/PopOver";
import { formatNotifDate } from "./formatNotifDate";
const NotifClientCard = ({ notification, onClick, removeNotif, onClickPuce, isSelect }) => {
    if (!notification)
        return React.createElement("div", null);
    return (React.createElement("div", { className: `notificationCard ${isSelect ? 'notificationCardSelected' : ''}`, onClick: () => onClick(notification) },
        React.createElement("div", { className: `notificationCardContainer` },
            React.createElement("div", { className: "picture" }, notification.from.picture ? (React.createElement(ImageOverlay, { icon: null, media_src: addHTTPS(`${notification.from.picture}`) })) : (React.createElement(ImagePlaceholder, { size: "big" }))),
            React.createElement("div", { className: "name" }, notification.from.role === 'CLIENT' &&
                React.createElement(Fragment, null,
                    React.createElement("div", null, notification.from.name),
                    React.createElement("div", null, notification.from.subInfo))),
            React.createElement("div", { className: notification.tos[0].status === 'toread' ? 'notification_toread' : 'notification_readed', onClick: (e) => { e.stopPropagation(); onClickPuce(notification); } })),
        React.createElement("div", { className: "textMission" },
            React.createElement(PopOver, { text: notification.params.MISSION_NAME, condition: (_text) => true, sliceNumber: 30, className: 'toolTip-popover', render: ({ setIsHover, formattedText }) => {
                    return (React.createElement("p", { onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false) },
                        "Mission : ",
                        formattedText));
                } }),
            React.createElement("p", null,
                "Job : ",
                notification.params.JOB)),
        React.createElement("div", { className: "notifInfosContainer" },
            React.createElement("div", { className: `notifSubject ${notification.level}` },
                React.createElement("span", { className: `icon ${notification.icon}` }),
                notification.push.subject),
            React.createElement("div", { className: "dateInfos" }, formatNotifDate(notification.date)),
            React.createElement("span", { className: "buttonDelete", onClick: (e) => removeNotif(e, notification) }))));
};
export default NotifClientCard;
