import React from 'react';
import { CounterList } from "../../../Components/Counter/CounterList";
import { useTranslation } from "react-i18next";
const CreateMissionCounter = ({ number, onUpdate, isSelected, counterwith = true }) => {
    const { t } = useTranslation("form");
    return (React.createElement("div", { className: isSelected
            ? "counter counter-right counter-selected"
            : "counter counter-right", onClick: () => onUpdate(true) },
        React.createElement(CounterList, { number: number, cssType: "small" }),
        React.createElement("div", { className: "text size-small flex-directionColumn text-left" },
            React.createElement("span", { className: "weight-light" }, t("createmission.counterremaining")),
            React.createElement("span", { className: "weight-bold" }, counterwith ? t("createmission.counterwith") : t("createmission.counterwithout")))));
};
export default CreateMissionCounter;
