import React, { Fragment } from "react";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";
import { updateDocument } from '../../Components/Freelance/Documents/useDocument';
import DocumentForm from '../../Components/Freelance/DocumentsForm/documentForm';
import { useDispatch } from "react-redux";
import { setQualifButtonEnable } from "../../redux/reloader/action";
const Documents = ({ data, disabled = false, callback, changePrecalState }) => {
    const { t } = useTranslation(['notif', 'document']);
    const dispatch = useDispatch();
    const submitFile = async (submitData, type, profileDocument) => {
        dispatch(setQualifButtonEnable(false));
        await updateDocument(submitData, type, data.profile, profileDocument);
        if (callback) {
            callback();
        }
    };
    return (React.createElement(Fragment, null,
        React.createElement("h2", { className: "thinTitle marginTitle" }, "Mes Documents"),
        React.createElement("div", { className: "documentTextContainer" },
            React.createElement("span", { className: "icon-securite" }),
            React.createElement("p", { dangerouslySetInnerHTML: { __html: t("freelance:myDocument.intro") } }),
            React.createElement("span", { className: "acceptedFormatDoc" }, "Formats accept\u00E9s : .PDF / .JPG / .PNG et la pi\u00E8ce ne doit pas d\u00E9passer 5Mo. ")),
        React.createElement("div", { className: 'roundBlocksContainer roundBlocksContainer--twoCol documentsList loadingContainer' }, data.profile ?
            React.createElement(Fragment, null,
                React.createElement(DocumentForm, { data: data.profile, submitFile: submitFile, disabled: disabled, formName: 'freelance_docs_ursaff', fileType: 'urssaf', dateText: "document:ursaffAttestationDate", title: "document:ursaffAttestation", secondText: "document:noPaymentAttestationDate", secondTitle: "document:noPaymentAttestation", changePrecalState: changePrecalState }),
                React.createElement(DocumentForm, { data: data.profile, submitFile: submitFile, disabled: disabled, formName: 'freelance_docs_civile_responsability', fileType: 'CivileResponsability', dateText: "document:civilResponsabilityAttestationDate", title: "document:civilResponsabilityAttestation", changePrecalState: changePrecalState }),
                data.profile && data.profile.status && parseInt(data.profile.status) === 2 &&
                    React.createElement(Fragment, null,
                        React.createElement(DocumentForm, { data: data.profile, submitFile: submitFile, disabled: disabled, formName: 'freelance_docs_Kbis', fileType: 'Kbis', dateText: "document:kbisAttestationDate", title: "document:kbisAttestation", changePrecalState: changePrecalState }),
                        React.createElement(DocumentForm, { data: data.profile, submitFile: submitFile, disabled: disabled, formName: 'foreign_wave_attestation', fileType: 'ForeignWaveAttestation', dateText: "document:foreignWaveAttestationDate", title: "document:foreignWaveAttestation", changePrecalState: changePrecalState })),
                data.profile && data.profile.status && parseInt(data.profile.status) && data.profile.status === 1 &&
                    React.createElement(DocumentForm, { data: data.profile, submitFile: submitFile, disabled: disabled, formName: 'freelance_docs_insee', fileType: 'Insee', dateText: "document:inseeAttestationDate", title: "document:inseeAttestation", changePrecalState: changePrecalState }))
            :
                React.createElement(Loading, null))));
};
export default Documents;
