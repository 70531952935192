import React from "react";
import ButtonBottomForm from '../../../../Components/WHForm/ButtonBottomForm';
import { useTranslation } from "react-i18next";
import FFTextArea from "../../../../Components/FinalForm/FFTextArea";
const CommentFormFields = ({ toggle, shouldDisplayForm, name }) => {
    const { t } = useTranslation('form');
    return (React.createElement("div", null,
        React.createElement(ButtonBottomForm, { toggle: toggle, display: shouldDisplayForm },
            React.createElement("div", { className: 'form-content' },
                React.createElement(FFTextArea, { name: name, label: t('comment.comment') })))));
};
export default CommentFormFields;
