import io from "socket.io-client";
import { NOTIFICATIONS } from "../../../Services/contants";
import feathers from "@feathersjs/client";
const socket = io(NOTIFICATIONS);
const app = feathers();
app.configure(feathers.socketio(socket));
app.configure(feathers.authentication({
    storage: window.localStorage,
    storageKey: "jwt_token",
    jwtStrategy: "jwt",
}));
export const authFeathers = async () => {
    try {
        return app.reAuthenticate();
    }
    catch (error) {
        console.log({ error });
    }
};
export default app;
