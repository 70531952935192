import React from "react";
import { withRouter } from "react-router";
import ImageOverlay from "../../../../Components/ImageOverlay/ImageOverlay";
import moment from "moment";
import "moment/locale/fr";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { AccessEnum, checkAccess } from "../../../../Services/Access/access";
import { accessNotif } from "../../../../Services/notif/notif";
import { addHTTPS } from "../../../../Shared/utils/utils";
import { ImagePlaceholder } from "../../../../Components/Image/Image";
import { formatUserName } from "../../../../helper/formatUserName";
import PopOver from "../../../../Components/PopOver/PopOver";
import getFile from "../../../../Shared/getFile";
const FreelanceListElement = ({ history, freelance, openModal }) => {
    const user = useSelector((state) => state.user.data);
    const storedExpertise = useSelector(state => state.params.expertises);
    const getExpertise = value => {
        const expertise = storedExpertise.find(st => st.value === value);
        if (expertise) {
            return expertise.value;
        }
        else {
            return null;
        }
    };
    moment.updateLocale("fr", {
        relativeTime: {
            future: "Dans %s",
            past: "Il y a %s"
        }
    });
    const { t } = useTranslation(["common", "rmfreelance"]);
    const getDiffTime = () => {
        const t = moment(new Date()).format("DD-MM-YYYY");
        const t2 = moment(freelance.availability).format("DD-MM-YYYY");
        const first = moment(t, "DD-MM-YYY");
        const second = moment(t2, "DD-MM-YYY");
        const diff = second.diff(first, "days");
        return diff;
    };
    return (React.createElement("div", { className: "freelanceList" },
        React.createElement("div", null,
            React.createElement("div", { className: `prioritizeIcon ${classNames({
                    inactive: freelance.prioritized === "false" || !freelance.prioritized
                })}` },
                React.createElement("span", { className: "priorityButton littleIcon" },
                    React.createElement("i", { className: "lnr lnr-diamond" })))),
        React.createElement("div", { style: { cursor: "pointer" }, onClick: openModal },
            React.createElement("div", { className: "prioritizeIcon " },
                React.createElement("i", { className: "lnr lnr-book" }))),
        React.createElement("div", { className: "picture" }, freelance.photo ? (React.createElement(ImageOverlay, { icon: null, media_src: addHTTPS(`${process.env.REACT_APP_IDENTITY_API_URL}/documents/file${freelance.photo}`) })) : (React.createElement(ImagePlaceholder, { size: "big" }))),
        React.createElement("div", { className: "nameBlock", onClick: () => {
                if (!checkAccess(AccessEnum.ACCESS_PROFIL, user.permissions)) {
                    accessNotif.failed();
                    return null;
                }
                return history.push(`/rm/freelances/freelance_edit/${freelance.ref}`);
            } },
            React.createElement("span", { className: `weight-bold mb-5 ${freelance.completed === "true" ? "font-green" : "font-red"}` }, freelance.completed === "true"
                ? t("rmfreelance:freelancedit.full")
                : t("rmfreelance:freelancedit.unfull")),
            React.createElement(PopOver, { text: formatUserName(freelance.firstname, freelance.lastname), condition: _text => true, className: "toolTip-popover toolTip-popover-withNegTop", render: ({ setIsHover, formattedText }) => {
                    return (React.createElement("span", { onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false), className: "weight-bold mb-5" }, formattedText));
                } }),
            React.createElement(PopOver, { text: freelance.job, className: "toolTip-popover toolTip-popover-withNegTop", condition: _text => true, render: ({ setIsHover, formattedText }) => {
                    return (React.createElement("span", { onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false), className: "weight-light" }, formattedText));
                } })),
        React.createElement("div", { className: "cvBlock", style: { cursor: "pointer" } }, freelance.cv_url ? freelance.cv_url.includes('.pdf') ? (React.createElement("span", { onClick: () => window.open(`${process.env.REACT_APP_IDENTITY_API_URL}/documents/file${freelance.cv_url}`, "_blank"), className: "showCvButton", style: { cursor: "pointer" } }, t("rmfreelance:freelancedit.cv"))) : (React.createElement("span", { onClick: () => getFile(freelance.cv_url.split("/")[1]), className: "showCvButton", style: { cursor: "pointer" } }, t("rmfreelance:freelancedit.cv"))) : (React.createElement("span", { className: "showCvButton showCvButton--disabled" }, t("rmfreelance:freelancedit.cv")))),
        React.createElement("div", { className: "freelanceDetails" },
            React.createElement("div", null,
                React.createElement("i", { className: "icon-xp" }),
                React.createElement("span", { className: "weight-medium" }, freelance.expertise && getExpertise(freelance.expertise)
                    ? t(`rmfreelance:freelancedit.expertise.${getExpertise(freelance.expertise)}`)
                    : "-")),
            React.createElement("div", null,
                React.createElement("i", { className: "icon-date" }),
                React.createElement("span", { className: "weight-medium" }, freelance.availability && getDiffTime() > 0
                    ? moment(new Date(freelance.availability), "YYYYMMDD HH:mm").fromNow()
                    : "Disponible")),
            React.createElement("div", null,
                React.createElement("i", { className: "icon-tjm" }),
                React.createElement("span", { className: "weight-medium" }, freelance.tjm
                    ? ` ${freelance.tjm} ${t("common:currency")} (HT)`
                    : "-")),
            React.createElement("div", null,
                React.createElement("i", { className: "icon-lieu" }),
                React.createElement("span", { className: "weight-medium" },
                    freelance.mobility_city ? freelance.mobility_city : `-`,
                    freelance.mobility_rayon
                        ? `   ${t("common:radius")} ${freelance.mobility_rayon} ${t("common:miles")}`
                        : "")))));
};
export default withRouter(FreelanceListElement);
