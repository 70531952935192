export const save = (name, value) => {
    const newValue = JSON.stringify(value);
    localStorage.setItem(name, newValue);
};
export const get = (name) => {
    const value = localStorage.getItem(name);
    if (!value)
        return false;
    try {
        return JSON.parse(value);
    }
    catch (error) {
        return value;
    }
};
export const destroy = (name) => {
    localStorage.removeItem(name);
};
