import React from "react";
import { Link } from "react-router-dom";
import { addHTTPS } from "../../Shared/utils/utils";
import { formatUserName } from "../../helper/formatUserName";
const nameInfos = ({ picture, firstName, lastName, job, extendedClassName, withLink = false, id, }) => {
    return (React.createElement("div", { className: `nameInfos ${extendedClassName ? extendedClassName : ''}` },
        React.createElement("div", { className: "picture mr-15" }, picture && picture.content ? (React.createElement("img", { id: "image", src: picture ? addHTTPS(picture.content) : "" })) : (React.createElement("div", { className: "logo-empty" },
            React.createElement("i", { className: `icon-image size-big` })))),
        React.createElement("div", { className: "nameInfos-header flex-directionColumn flex-justifyCenter flex-alignStart" },
            !withLink && (React.createElement("span", { className: "heebo size-small weight-bold nameInfos-name" }, formatUserName(firstName, lastName))),
            withLink && (React.createElement(Link, { to: `/client/freelance_view/${id}`, target: "_blank", className: "heebo size-small weight-bold" }, formatUserName(firstName, lastName))),
            React.createElement("span", { className: "heebo size-small weight-light mt-5 nameInfos-job" }, job))));
};
export default nameInfos;
