import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { GEOMETRIES, MISSIONS, MISSIONSEVENT } from "../../../Services/contants";
import ApiService from "../../../Services/ApiService";
import { setEndDate } from "../../../Shared/constants";
import i18n from "../../../i18n";
import { Error } from "tslint/lib/error";
import { createMission } from "../Functions/createMission";
import { SubmissionError } from "redux-form";
import { editNotif, missionCandidatureNotif, missionCreateNotif, missionEditNotif, missionRemoveNotif, missionStatusUpdateNotif, missionCandidatureAllUpdateNotif, } from "../../../Services/notif/notif";
import { getApiError } from "../../../Shared/utils/getApiErrorTranslations";
class MissionService {
    constructor(mission, user_id, onUpdate = null, redirect = null) {
        this.setMission = mission => {
            this.mission = mission;
        };
        this.getFreelanceCandidature = () => {
            let candidature = this.mission.candidatures.find(c => c.user._id === this.userId);
            return candidature;
        };
        this.getCurrentCandidature = () => {
            const c = this.mission.candidatures.find(c => (parseInt(c.statusFl) !== 1400 && parseInt(c.statusFl) >= 1300) ||
                (parseInt(c.statusFl) === 1200 &&
                    c.indicationFlNew === 100));
            if (!c)
                return null;
            return c._id;
        };
        this.NotifEditSuccess = () => missionEditNotif.success();
        this.NotifEditFailed = () => missionEditNotif.failed();
        this.submitChangeDate = data => {
            const endDate = moment(data.endDate).format("YYYY-MM-DD");
            const startDate = moment(data.startDate).format("YYYY-MM-DD");
            return this.missionService
                .update({
                _id: this.mission._id,
                id: this.mission.id,
                endDate: endDate,
                startDate: startDate,
                duration: data.duration.value,
            })
                .then(() => {
                this.NotifEditSuccess();
                if (this.onUpdate)
                    this.onUpdate();
            })
                .catch(e => {
                this.NotifEditFailed();
                throw new SubmissionError(e);
            });
        };
        this.updateMissionStatusCandidature = (code, candidatureId, showNotif = true) => {
            return this.missionEventService
                .create({
                mission: this.mission.id,
                code: code,
                candidature: `/api/candidatures/${candidatureId}`,
            })
                .then(() => {
                if (this.onUpdate)
                    this.onUpdate();
                if (showNotif)
                    missionStatusUpdateNotif.success();
            })
                .catch((e) => {
                missionStatusUpdateNotif.failed(getApiError(e));
                throw new Error("Candidature update failed");
            });
        };
        this.updateMissionsStatus = (code, id = null) => {
            let mission_id = id ? id : this.mission.id;
            return this.missionEventService
                .create({ mission: mission_id, code: code })
                .then(() => {
                if (this.onUpdate)
                    this.onUpdate();
                missionEditNotif.success();
            })
                .catch((e) => {
                missionEditNotif.failed(getApiError(e));
            });
        };
        this.onSubmitMission = data => {
            let missionTags = this.getTags(data);
            let formatedData = { ...data, tags: missionTags };
            return createMission(formatedData)
                .then(_data => {
                this.updateMissionsStatus(data.status, _data["@id"]);
                missionCreateNotif.success();
            })
                .catch(e => {
                missionCreateNotif.failed(getApiError(e));
            });
        };
        this.getTags = data => {
            let tags = data.tags ? data.tags.map(t => t.id) : [];
            let newTags = data.new_tags
                ? data.new_tags.map(t => t.__isNew__ ? { status: "draft", name: t.label } : t.value)
                : [];
            let missionTags = tags.concat(newTags);
            return missionTags;
        };
        this.putEndToMissionSwal = () => {
            const MySwal = withReactContent(Swal);
            return MySwal.fire({
                title: "",
                text: i18n.t("missions:swal.putendmission.content"),
                confirmButtonText: i18n.t("missions:swal.putendmission.confirm"),
                cancelButtonText: i18n.t("missions:swal.putendmission.cancel"),
                customClass: {
                    popup: "swallModal-popup",
                    content: "swallModal-container",
                    actions: "swallModal-actions form-bottom-button",
                    confirmButton: "swallModal-orangeButton secondButton btn btn-secondary",
                    cancelButton: "swallModal-blueButton firstButton btn btn-secondary"
                },
                showCancelButton: true,
            });
        };
        this.updateStatusCandidature = (code, candidatureId, shouldReload = true, showNotif = true) => {
            return this.missionEventService
                .create({
                mission: this.mission.id,
                code: code,
                candidature: "/api/candidatures/" + candidatureId,
            })
                .then(() => {
                if (shouldReload && this.onUpdate)
                    this.onUpdate();
                if (showNotif)
                    missionEditNotif.success();
            })
                .catch((e) => {
                const error = getApiError(e);
                error ? missionEditNotif.failed(error) : missionEditNotif.failed();
            });
        };
        this.putEndMission = role => {
            return this.putEndToMissionSwal().then(result => {
                if (!result.value)
                    return;
                let c_id = this.getCurrentCandidature();
                if (!c_id)
                    console.error("No current candidature");
                return this.missionService
                    .update({
                    _id: this.mission._id,
                    endDate: moment(new Date()).format("YYYY-MM-DD"),
                })
                    .then(() => {
                    return this.updateStatusCandidature(role === "admin" ? 110 : 207, c_id)
                        .then(() => {
                        missionEditNotif.success();
                    })
                        .catch(() => {
                        missionEditNotif.failed();
                    });
                })
                    .catch(() => {
                    missionEditNotif.failed();
                });
            });
        };
        this.editMission = data => {
            if (!data.tags)
                data.tags = [];
            let tags = data.tags.map(t => t.id);
            let newTags = data.new_tags
                ? data.new_tags.map(t => t.__isNew__ ? { status: "draft", name: t.label } : t.value)
                : [];
            let profileTags = tags.concat(newTags);
            const endDate = setEndDate(data.duration.value, data.startDate);
            const { geometry, ...spreadData } = data;
            return this.missionService
                .update({
                _id: this.mission._id,
                id: this.mission.id,
                ...spreadData,
                public: !spreadData.public,
                tags: profileTags,
                startDate: moment(data.startDate).format("YYYY-MM-DD"),
                activitySector: data.activitySector.value,
                society: data.society.value,
                endDate: endDate,
                duration: data.duration.value,
                expertise: data.expertise.value,
            })
                .then(() => {
                const GeometryService = new ApiService(GEOMETRIES);
                GeometryService.update({ ...geometry });
                if (this.mission.missionStatus &&
                    (this.mission.missionStatus.statusRm === 200 ||
                        this.mission.missionStatus.statusRm === 250)) {
                    this.updateMissionsStatus(103);
                }
                else {
                    if (this.onUpdate)
                        this.onUpdate();
                }
                missionEditNotif.success();
            })
                .catch(() => {
                missionEditNotif.failed();
            });
        };
        this.refuseMission = data => {
            const canceledReason = data.comment;
            if (!canceledReason)
                return;
            return this.missionService
                .update({ _id: this.mission._id, canceledReason: canceledReason })
                .then(() => {
                return this.updateMissionsStatus(105);
            })
                .catch(e => {
                missionEditNotif.failed();
                throw new SubmissionError(e);
            });
        };
        this.validateCandidature = () => {
            try {
                let c_id = this.getCurrentCandidature();
                if (!c_id) {
                    alert("Candidature non trouvé");
                    return;
                }
                return this.updateStatusCandidature(108, c_id);
            }
            catch (e) {
                console.error("Error::", e);
                return;
            }
        };
        this.AClientAcceptAFreelance = (data, candidatureDatas, role) => {
            const endDate = moment(data.endDate).format("YYYY-MM-DD");
            const startDate = moment(data.startDate).format("YYYY-MM-DD");
            let formatedData = {
                startDate: startDate,
                endDate: endDate,
                duration: data.duration.value,
                tjm: data.tjm,
            };
            const { candidatures, acceptedIndex } = candidatureDatas;
            const acceptedCandidatures = candidatures[acceptedIndex];
            const otherCandidatures = candidatures.filter((c) => c.statusClientToValid !== 'accepted');
            const candidaturePromises = otherCandidatures.map((c) => this.updateStatusCandidature(c.statusClientToValid === "refused" ? 208 : 209, c._id, false, false));
            return Promise.all(candidaturePromises).then(() => {
                return this.missionService.update({ _id: this.mission._id, ...formatedData }).then(() => {
                    missionCandidatureAllUpdateNotif.success();
                    return this.updateMissionStatusCandidature(role === "admin" ? 113 : 205, acceptedCandidatures._id, false).then(() => {
                        missionEditNotif.success();
                        if (this.onUpdate)
                            this.onUpdate();
                    }).catch(() => missionCandidatureAllUpdateNotif.failed("notif:mission.candidatures.all_update_failed"));
                });
            });
        };
        this.acceptFreelance = (data, candidature_id, role) => {
            const endDate = moment(data.endDate).format("YYYY-MM-DD");
            const startDate = moment(data.startDate).format("YYYY-MM-DD");
            let formatedData = {
                startDate: startDate,
                endDate: endDate,
                duration: data.duration.value,
                tjm: data.tjm,
            };
            return this.missionService
                .update({ _id: this.mission._id, ...formatedData })
                .then(() => {
                missionEditNotif.success();
                return this.updateMissionStatusCandidature(role === "admin" ? 113 : 205, candidature_id);
            })
                .catch(() => {
                missionCandidatureNotif.failed("notif:mission.candidatures.accepted_failed");
            });
        };
        this.deleteSwal = () => {
            const MySwal = withReactContent(Swal);
            return MySwal.fire({
                title: "",
                text: i18n.t("missions:swal.deletemission.content"),
                confirmButtonText: i18n.t("missions:swal.deletemission.confirm"),
                cancelButtonText: i18n.t("missions:swal.deletemission.cancel"),
                customClass: {
                    popup: "swallModal-popup",
                    content: "swallModal-container",
                    actions: "swallModal-actions form-bottom-button",
                    confirmButton: "swallModal-orangeButton secondButton btn btn-secondary",
                    cancelButton: "swallModal-blueButton firstButton btn btn-secondary",
                },
                showCancelButton: true,
            });
        };
        this.clientRefuseSwal = () => {
            const MySwal = withReactContent(Swal);
            return MySwal.fire({
                title: "",
                text: i18n.t("missions:swal.refusemission.content"),
                confirmButtonText: i18n.t("missions:swal.refusemission.confirm"),
                customClass: {
                    popup: "swallModal-popup",
                    content: "swallModal-container",
                    actions: "swallModal-actions form-bottom-button",
                    confirmButton: "swallModal-orangeButton secondButton btn btn-secondary",
                    cancelButton: "swallModal-blueButton firstButton btn btn-secondary",
                },
                showCancelButton: false,
            });
        };
        this.clientDeleteRefuseMission = () => {
            return this.clientRefuseSwal().then(result => {
                if (!result.value)
                    return;
                return this.updateMissionsStatus(206).then(() => {
                    if (this.onUpdate)
                        this.onUpdate();
                });
            });
        };
        this.sendFinishFreelanceNotif = () => {
            const canceledService = new ApiService('/cancelation_request');
            return canceledService.update({ _id: this.getCurrentCandidature() }).then(() => {
                editNotif.success();
                if (this.onUpdate)
                    this.onUpdate();
            }).catch(() => {
                editNotif.failed();
            });
        };
        this.finishFreelanceSwal = () => {
            const MySwal = withReactContent(Swal);
            return MySwal.fire({
                title: "",
                text: i18n.t("missions:swal.stopflmission.content"),
                confirmButtonText: i18n.t("missions:swal.stopflmission.confirm"),
                cancelButtonText: i18n.t("missions:swal.stopflmission.cancel"),
                customClass: {
                    popup: "swallModal-popup",
                    content: "swallModal-container",
                    actions: "swallModal-actions form-bottom-button",
                    confirmButton: "swallModal-orangeButton secondButton btn btn-secondary",
                    cancelButton: "swallModal-blueButton firstButton btn btn-secondary",
                },
                showCancelButton: true,
            });
        };
        this.endMissionFreelance = () => {
            return this.finishFreelanceSwal().then(result => {
                if (!result.value)
                    return;
                return this.sendFinishFreelanceNotif();
            });
        };
        this.shallowDeleteMission = (data, role) => {
            const canceledReason = data.need
                ? i18n.t("missions:missions.endMission.need")
                : data.more
                    ? i18n.t("missions:missions.endMission.more")
                    : data.other
                        ? data.comment
                        : i18n.t("missions:missions.endMission.other");
            return this.missionService
                .update({ _id: this.mission._id, canceledReason: canceledReason ? canceledReason : 'Aucune raison spécifiée' })
                .then(() => {
                missionRemoveNotif.success();
                const currentCandidature = this.getCurrentCandidature();
                if (!currentCandidature)
                    return this.updateMissionsStatus(role === "admin" ? 109 : 206);
                return this.updateMissionStatusCandidature(role === "admin" ? 109 : 206, this.getCurrentCandidature());
            })
                .catch(() => {
                missionRemoveNotif.failed();
                throw new Error("Erreur a la suppression(cht de status) d'une mission");
            });
        };
        this.deleteMission = () => {
            return this.deleteSwal().then(result => {
                if (!result.value)
                    return;
                return this.missionService
                    .remove(this.mission._id)
                    .then(() => {
                    if (this.redirect)
                        this.redirect();
                    missionRemoveNotif.success();
                })
                    .catch(() => {
                    missionRemoveNotif.failed();
                });
            });
        };
        this.mission = mission;
        this.userId = user_id;
        this.onUpdate = onUpdate;
        this.redirect = redirect;
        this.missionEventService = new ApiService(MISSIONSEVENT);
        this.missionService = new ApiService(MISSIONS);
    }
}
export default MissionService;
