import React, { Fragment, useState, useEffect } from 'react';
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
const libraries = ["places"];
const GoogleMapPlaces = ({ idOverload, inputClassName, onUpdate, defaultCity = undefined, type = undefined, children, updateAllAddressForm = false, disabled = false, label = '', input, placeholder }) => {
    const [standAloneSearchBox, setStandAloneSearchBox] = useState(null);
    const [city, setCity] = useState(defaultCity);
    const [error, setError] = useState(null);
    const onLoad = (standAloneSearchBox) => {
        setStandAloneSearchBox(standAloneSearchBox);
    };
    useEffect(() => {
        if (defaultCity)
            setCity(defaultCity);
    }, [defaultCity]);
    const onPlaceChanged = () => {
        if (standAloneSearchBox) {
            const places = standAloneSearchBox.getPlaces();
            const { address_components } = places[0];
            const city = address_components.find((addr) => addr.types.includes("locality") || addr.types.includes("colloquial_area"));
            const road = address_components.find((addr) => addr.types.includes("route"));
            const zipCode = address_components.find((addr) => addr.types.includes("postal_code"));
            const country = address_components.find((addr) => addr.types.includes("country"));
            const number = address_components.find((addr) => addr.types.includes("street_number"));
            if (type === 'mission' && !city) {
                setError('Tapez votre ville puis sélectionnez la dans la liste proposée.');
                onUpdate(null, null, null);
                setCity(address_components.name);
                return;
            }
            if (type === 'mission' && city)
                setError(null);
            if (type === 'society') {
                updateAllAddressForm ?
                    setCity(`${number ? number.long_name : ''} ${city ? city.long_name : ''} ${road ? road.long_name : ''} ${zipCode ? zipCode.long_name : ''}  ${country ? country.long_name : ''}`)
                    : setCity(city.long_name);
            }
            if (type === 'mission')
                setCity(city.long_name);
            const location = places[0].geometry.location;
            updateAllAddressForm ? onUpdate(number ? number.long_name : '', city ? city.long_name : '', road ? road.long_name : '', zipCode ? zipCode.long_name : '', country ? country.long_name : '') : onUpdate(city.long_name, location.lat(), location.lng(), zipCode ? zipCode.long_name : null);
        }
    };
    return (React.createElement(LoadScript, { id: idOverload ? idOverload : "script-loader", googleMapsApiKey: "AIzaSyBGdfFI02x30t2tNgvj21suu3Rk-rZ9_sI", libraries: libraries },
        React.createElement(StandaloneSearchBox, { onLoad: onLoad, onPlacesChanged: onPlaceChanged },
            React.createElement(Fragment, null,
                React.createElement("div", { className: inputClassName },
                    label &&
                        React.createElement("label", null, label),
                    error &&
                        React.createElement("span", { className: "form__form-group-error formError" }, error),
                    React.createElement("input", Object.assign({}, input, { type: "text", value: city, placeholder: placeholder ? placeholder : '', disabled: disabled, onChange: (e) => { setCity(e.target.value); if (input) {
                            input.onChange(e);
                        } } })),
                    children),
                error &&
                    React.createElement("span", { className: 'formErrorDisplay' }, error)))));
};
export default GoogleMapPlaces;
