import { createAction } from 'redux-actions';
import { SET_COUNTS } from './constant.js';
import { fetch } from "/wh/Shared/fetch";
import ElasticService from '/wh/Services/ElasticService';
import { MISSIONSES } from '/wh/Services/contants';
import { setMissionCountReload } from '/wh/redux/reloader/action';
const setCounts = createAction(SET_COUNTS, (new_freelances, new_clients, mission_validates) => ({ new_freelances, new_clients, mission_validates }));
const endPoints = '/api/menu_count';
export const fetchCounts = () => async (dispatch) => {
    try {
        const counts = await fetch(`${process.env.REACT_APP_IDENTITY_API_URL}${endPoints}`, 'get');
        let req = {
            query: process.env.REACT_APP_ELASTIC_VER === 'V2' ? "*" : '',
            filters: {
                all: [
                    { 'main_contact_enabled': 'true' }, { "rm_status_value": "400" }, { "rm_sub_status": "100" }
                ]
            },
            page: { current: 1, size: 1 },
        };
        const missionService = new ElasticService(MISSIONSES);
        const result = await missionService.search(req);
        let total_results = 0;
        if (process.env.REACT_APP_ELASTIC_VER === 'V1')
            total_results = result.meta.page.total_results;
        else {
            total_results = result.hits.total.value;
        }
        dispatch(setCounts(counts.unqualified_freelance, counts.unqualified_client, total_results));
        dispatch(setMissionCountReload(false));
    }
    catch (error) {
        console.error({ error });
    }
};
