import { useEffect, useState } from "react";
import GraphQl from "../Shared/graphql";
import { formatGraphQlData } from "../Shared/rewriteArray";
export const useStoredWithPagination = (defaultConfig) => {
    const [config] = useState(defaultConfig);
    const [fireUseEffect, setFireUseEffect] = useState(Math.random());
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isNextPageLoading, setIsNextPageLoading] = useState(false);
    const [nextCursor, setNextCursor] = useState(null);
    const [requestFilters, setRequestFilters] = useState([]);
    const [defaultFiltersOverride, setDefaultFiltersOverride] = useState(null);
    const [defaultOrder, setOrders] = useState(null);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const constructExistsFilter = (selectedFilters) => {
        const existFilters = selectedFilters.filter(sf => (sf.name === "exists"));
        if (existFilters.length === 0)
            return [];
        const newFilter = existFilters.reduce((value, acc) => ({ name: value.name, value: { ...value.value, ...acc.value } }));
        return newFilter;
    };
    const getQueryFilters = () => {
        const { filters } = config;
        const defaultFilters = defaultFiltersOverride ? defaultFiltersOverride : filters;
        const allFilters = [...defaultFilters, ...requestFilters];
        const existFilters = constructExistsFilter(allFilters);
        const filtersWithoutExist = allFilters.filter((f) => f.name !== "exists");
        return [...filtersWithoutExist, existFilters];
    };
    const loadData = async () => {
        setIsLoading(true);
        if (nextCursor && hasNextPage)
            setIsNextPageLoading(true);
        const { baseConfig: { api, endPoint, resultKey } } = config;
        let order = defaultOrder ? { order: defaultOrder.value, orderBy: { name: defaultOrder.name } } : {};
        let query = {
            ...endPoint.query,
            filters: getQueryFilters(), ...order
        };
        if (nextCursor) {
            query = { ...query, after: nextCursor };
        }
        const graphqlConfig = { ...endPoint, api: api, query: query };
        const res = await GraphQl(graphqlConfig);
        const newResult = formatGraphQlData(res[resultKey]);
        setData(data && nextCursor ? [...data, ...newResult] : newResult);
        setNextCursor(res.pageInfo.endCursor);
        setTotalItems(res.totalItem);
        setHasNextPage(res.pageInfo.hasNextPage);
        setIsLoading(false);
        if (nextCursor && hasNextPage)
            setIsNextPageLoading(false);
    };
    const updateOrders = (order) => { setOrders(order); };
    const loadNextPage = () => {
        if (hasNextPage) {
            setFireUseEffect(Math.random());
        }
    };
    const reloadData = () => {
        setNextCursor(null);
        setFireUseEffect(Math.random());
    };
    useEffect(() => {
        setNextCursor(null);
        setFireUseEffect(Math.random());
    }, [requestFilters]);
    useEffect(() => {
        setNextCursor(null);
        setFireUseEffect(Math.random());
    }, [defaultFiltersOverride]);
    useEffect(() => {
        (async function () {
            await loadData();
        })();
    }, [fireUseEffect]);
    return {
        data,
        isLoading,
        totalItems,
        setDefaultFiltersOverride,
        hasNextPage,
        getQueryFilters,
        updateOrders,
        isNextPageLoading,
        setRequestFilters,
        loadNextPage,
        loadData,
        reloadData
    };
};
