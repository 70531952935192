import React, { Fragment, useState } from "react";
import { useStoredNoFiltersWithConfig } from "../../Hooks/useStoredNoFiltersWithConfig";
import Loading from "../../Components/Loading";
import ApiService from "../../Services/ApiService";
import { USERS } from "../../Services/contants";
import { rightNotif } from "../../Services/notif/notif";
import { getApiError } from "../../Shared/utils/getApiErrorTranslations";
const LoadRmList = ({ defaultConfig, render, keys }) => {
    const [config] = useState(defaultConfig);
    const { data, isCurrentlyLoading, setRequestFilters, totalItems, reloadData } = useStoredNoFiltersWithConfig(config, keys);
    const [formLoaded, setFormLoaded] = useState(false);
    const create = submitData => {
        setFormLoaded(true);
        let permissions = [];
        for (let [key, value] of Object.entries(submitData)) {
            if (key !== "civility" &&
                key !== "lastName" &&
                key !== "firstName" &&
                key !== "email" &&
                key !== "phone" &&
                key !== "type" &&
                key !== "roles" &&
                key !== "function") {
                let permission = { permission: key, value: value === "1" };
                permissions.push(permission);
            }
        }
        let userService = new ApiService(USERS);
        return userService
            .create({
            ...submitData,
            civility: submitData.civility.value,
            permissions: permissions
        })
            .then(() => {
            reloadData();
            setFormLoaded(false);
            rightNotif.success();
        })
            .catch((e) => {
            setFormLoaded(false);
            rightNotif.failed(getApiError(e));
        });
    };
    return (React.createElement(Fragment, null,
        (isCurrentlyLoading) && React.createElement(Loading, { className: 'load-zIndexMin' }),
        data && render({ data, setRequestFilters, totalItems, create, formLoaded })));
};
export default LoadRmList;
