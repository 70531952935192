import moment from "moment";
import { FILTER_TYPE_TEXT } from "../../../../../Components/Filters/constants";
const headerFilters = [
    {
        name: "from",
        defaultName: "search.date",
        fusionWith: {
            name: 'to',
            formatLabel: (value, nextValue) => {
                return `Du ${moment(value.value.hasOwnProperty("value") ? value.value.value : value.value).format("DD-MM-YYYY")} jusqu'au ${moment(nextValue.value.hasOwnProperty("value") ? nextValue.value.value : nextValue.value).format("DD-MM-YYYY")}`;
            }
        },
        formatFilterForApi: (value) => {
            return {
                name: `from`,
                value: { from: moment(value).format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
            };
        },
        formatCurrent: (obj) => {
            return `Du ${moment(obj.value.hasOwnProperty("value") ? obj.value.value : obj.value).format("DD-MM-YYYY")}`;
        },
        label: "search.date",
        filterType: FILTER_TYPE_TEXT,
        className: "label-input filter filter-with-marin-right-small",
        field: "text",
        type: "date",
        dateType: "after",
    },
    {
        name: "to",
        defaultName: "search.date",
        formatFilterForApi: (value) => {
            return {
                name: `to`,
                value: { to: moment(value).format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
            };
        },
        formatCurrent: (obj) => {
            return `Jusqu'au ${moment(obj.value.hasOwnProperty("value") ? obj.value.value : obj.value).format("DD-MM-YYYY")}`;
        },
        label: "search.date",
        filterType: FILTER_TYPE_TEXT,
        className: "label-input filter filter-with-marin-right-small",
        field: "text",
        type: "date",
        dateType: "after",
    }
];
const filters = [];
export default { headerFilters, filters };
