import React, { useState, Fragment } from "react";
import DisplayForm from "../WHForm/DisplayForm";
import AboutForm from "../Freelance/aboutForm";
import FormWrapper from "../Form/FormWrapper";
import * as userService from "../../Services/user";
import { SubmissionError } from "redux-form";
import Notif from "../Notif/index";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Loading";
import { useDispatch, useSelector } from "react-redux";
import shallowequal from "shallowequal";
import { setQualifButtonEnable } from "../../redux/reloader/action";
const FreelanceAbout = ({ data, changePrecalState, disabled = false }) => {
    const { shouldDisplayForm, displayFormToggle } = DisplayForm();
    const [user, setUser] = useState(data);
    const [loaded, setLoaded] = useState(true);
    const dispatch = useDispatch();
    const { t } = useTranslation(["form", "notif", "freelance"]);
    const form = useSelector(state => state.form["freelance_about_form"]);
    const onSubmit = async (data) => {
        dispatch(setQualifButtonEnable(false));
        setLoaded(false);
        let submitData = {
            _id: data.user._id ? data.user._id : data.user.id,
            profile: {
                id: data.user.profile["@id"]
                    ? data.user.profile["@id"]
                    : data.user.profile.id,
                description: data.user.profile.description
            }
        };
        if (!shallowequal(form.initial, form.values) && changePrecalState) {
            changePrecalState();
        }
        await userService
            .update(submitData)
            .then(data => {
            setUser(data);
            setLoaded(true);
            Notif({
                color: "success",
                message: t("notif:success_edit"),
                duration: 100
            });
        })
            .catch(error => {
            setLoaded(true);
            Notif({
                color: "warning",
                message: t("notif:failed_edit"),
                duration: 100
            });
            throw new SubmissionError(error);
        });
        displayFormToggle();
    };
    return (React.createElement("div", { className: "roundBlock" },
        !loaded && React.createElement(Loading, null),
        React.createElement(Fragment, null,
            React.createElement("div", { className: "headerFreeItem" },
                React.createElement("h2", { className: "thinTitle" }, t("freelance:main.aboutMe.title")),
                !disabled && !shouldDisplayForm && (React.createElement("span", { className: "littleTextButton editButton", "data-testid": "updateButton", onClick: () => displayFormToggle() }, "Modifier"))),
            React.createElement("p", { className: `roundBlockSubtitle pr-30 pl-30` }, t("freelance:main.aboutMe.maxLenght")),
            !shouldDisplayForm && (React.createElement("div", { className: "content" },
                React.createElement("p", null, user.profile.description))),
            shouldDisplayForm && (React.createElement("div", { "data-testid": "displayForm" },
                React.createElement(FormWrapper, { form: "freelance_about_form", onSubmit: onSubmit, initialValues: { user: user } },
                    React.createElement(AboutForm, { shouldDisplayForm: shouldDisplayForm, toggle: () => displayFormToggle() })))))));
};
export default FreelanceAbout;
