import request from "superagent";
import * as session from "./session";
const prepareValidationError = (_error) => {
    const error = _error;
    if (!error) {
        return error;
    }
    if (error.code === 404) {
        error["_error"] = "La page ou le service que vous demandez n’existe pas. ";
    }
    if (error["hydra:description"]) {
        if (error.violations) {
            error["_error"] = "";
            error.violations.map((el) => {
                error[el.propertyPath] = el.message;
                error["_error"] += `${el.message}<br />`;
                return error;
            });
        }
        else {
            error["_error"] = error["hydra:description"];
        }
    }
    else if (error.code && error.code === 401) {
        if (error.message === "Bad credentials") {
            error["_error"] =
                "Vous n’avez pas été reconnu. Merci de réessayer ou de contacter votre administrateur. ";
        }
    }
    return error;
};
export const fetch = async (url, _method = "GET", data = {}, files = {}) => {
    const method = _method.toUpperCase();
    const query = request(method, url);
    const jwtToken = await session.getJwtToken();
    switch (method) {
        case "GET":
            query.query(data);
            break;
        case "POST":
            query.send(data);
            break;
        case "PUT":
        case "PATCH":
            query.send(data);
            break;
        case "DELETE":
            query.send(data);
            break;
        case "UPLOAD":
            query.method = "POST";
            Object.keys(files).forEach((key) => {
                const d = files[key];
                query.attach("file", d);
            });
            break;
        default:
    }
    if (jwtToken)
        query.set("Authorization", `Bearer ${jwtToken}`);
    try {
        const result = await query;
        return result.body;
    }
    catch (error) {
        if (error.response) {
            throw prepareValidationError(error.response.body);
        }
        else {
            throw error;
        }
    }
};
export default null;
