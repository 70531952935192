import React, { useReducer, useEffect } from 'react';
import { DateRangeInput } from '@datepicker-react/styled';
import moment from 'moment';
const initialState = {
    startDate: null,
    endDate: null,
    focusedInput: null,
};
function reducer(state, action) {
    switch (action.type) {
        case 'focusChange':
            return { ...state, focusedInput: action.payload };
        case 'dateChange':
            return action.payload;
        default:
            throw new Error();
    }
}
const DateRangeInputComponent = ({ onUpdate, values }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        dispatch({ type: 'focusChange', payload: 'startDate' });
    }, []);
    useEffect(() => {
        if (!values.duration)
            return;
        const newDate = moment(new Date(values.startDate)).clone().add(parseInt(values.duration.value), 'months');
        dispatch({ type: 'dateChange', payload: { startDate: new Date(values.startDate), endDate: new Date(newDate.format("YYYY/MM/DD")), focusedInput: "startDate" } });
    }, [values.duration]);
    useEffect(() => {
        if (state.startDate !== null && state.endDate !== null) {
            onUpdate(state);
        }
    }, [state]);
    const cancelFocus = (_focusedInput) => {
        if (_focusedInput === null)
            return;
        dispatch({ type: 'focusChange', payload: _focusedInput });
        return;
    };
    const onDateChange = (data) => {
        const newDate = moment(new Date(data.startDate)).clone().add(parseInt(values.duration.value), 'months');
        onUpdate(data);
        dispatch({ type: 'dateChange', payload: { startDate: new Date(data.startDate), endDate: new Date(newDate.format("YYYY/MM/DD")), focusedInput: "startDate" } });
    };
    const formatMonth = (date) => moment(date).format('MMMM-YYYY');
    const formatWeekDay = (date) => moment(date).format('dddd');
    return (React.createElement(DateRangeInput, { onDatesChange: data => onDateChange(data), onFocusChange: focusedInput => cancelFocus(focusedInput), numberOfMonths: 1, weekdayLabelFormat: formatWeekDay, monthLabelFormat: (date) => formatMonth(date), showSelectedDates: false, showResetDates: false, startDate: state.startDate, endDate: state.endDate, focusedInput: state.focusedInput }));
};
export default DateRangeInputComponent;
