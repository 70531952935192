import React from 'react';
import { useTranslation } from "react-i18next";
export const Result = ({ size }) => {
    const { t } = useTranslation('common');
    return React.createElement("div", { className: "resultList" },
        React.createElement("span", null,
            React.createElement("strong", null, size),
            " ",
            t('foundresult', { count: size })));
};
export default Result;
