import { useState, useEffect } from "react";
import * as documentService from '../../../Services/document';
import Notif from "../../Notif/index";
import { SubmissionError } from 'redux-form';
import * as profileDocumentService from '../../../Services/profileDoc';
import { updateNotif } from "../../../Services/notif/notif";
import * as Sentry from "@sentry/browser";
export const UseDocument = (profile, type) => {
    const [profileDocument, setProfileDocument] = useState();
    useEffect(() => {
        if (!profile.hasOwnProperty('profileDocuments'))
            return;
        const fileIndex = profile.profileDocuments && profile.profileDocuments.findIndex(profileDocument => profileDocument.type === type);
        const returnIndex = fileIndex >= 0 ? fileIndex : null;
        if (returnIndex !== null) {
            setProfileDocument(profile.profileDocuments[fileIndex]);
        }
        else {
            setProfileDocument(null);
        }
    }, [profile && profile.hasOwnProperty('profileDocuments') && profile.profileDocuments]);
    return {
        profileDocument
    };
};
export const updateDocument = async (submitData, type, profile, profileDocument) => {
    if (!submitData || !submitData.document && profileDocument) {
        await documentService.remove(profileDocument.document._id).then(() => {
            updateNotif.success();
        }).catch((error) => {
            updateNotif.failed();
            throw new SubmissionError(error);
        });
    }
    else if (submitData.document) {
        let submitPhoto = {
            file: submitData.document.file
        };
        await documentService.postFile(submitPhoto).then(async (data) => {
            let profileDocumentData = {
                type: type,
                profile: profile,
                document: {
                    ...data,
                    type: submitData.document.type
                }
            };
            if (!profileDocument) {
                await profileDocumentService.create(profileDocumentData).then(() => {
                    updateNotif.success();
                }).catch((error) => {
                    updateNotif.failed();
                    throw new SubmissionError(error);
                });
            }
            else {
                let updatedDoc = {
                    document: {
                        ...data,
                        type: submitData.document.type
                    },
                    _id: profileDocument._id
                };
                await profileDocumentService.update(updatedDoc).then(() => {
                    updateNotif.success();
                }).catch((error) => {
                    updateNotif.failed();
                    throw new SubmissionError(error);
                });
            }
        }).catch((e) => {
            console.error(e);
            Sentry.captureException(e);
            Notif({ color: "danger", message: "Erreur à l'upload du document", duration: 200 });
        });
    }
};
