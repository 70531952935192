import React from "react";
import ButtonBottomForm from "../../WHForm/ButtonBottomForm";
import { Field, change } from "redux-form";
import { WhFieldTxt, WhFieldSelect, WhFieldCheckbox, WhValidator } from "../../../Components/WHForm/WhFields";
import { parseDate } from "../../../Shared/parserForm";
import { useTranslation } from "react-i18next";
import countryList from "react-select-country-list";
import { useDispatch, useSelector } from "react-redux";
import { formatFirstName, formatLastName } from "../../../helper/formatUserName";
import { addAsterix } from "../../../helper/addAsterix";
import GoogleMapWithoutLoadScript from "../../GoogleMap/GoogleMapWithouLoadScript";
const InformationsForm = ({ toggle, shouldDisplayForm, statuses }) => {
    const { t } = useTranslation("form");
    const dispatch = useDispatch();
    const nationalities = useSelector((state) => state.params.nationalities);
    const form = useSelector((state) => state.form["freelance_informations_form"]);
    const onUpdateGoogleMap = (cityName, lat, long) => {
        dispatch(change("freelance_informations_form", "googleMapCity", cityName));
        dispatch(change("freelance_informations_form", "profile.address.city", cityName));
        dispatch(change("freelance_informations_form", "profile.address.lat", lat));
        dispatch(change("freelance_informations_form", "profile.address.lng", long));
    };
    return (React.createElement("div", null,
        React.createElement(ButtonBottomForm, { toggle: toggle, display: shouldDisplayForm },
            React.createElement("div", { className: "form-content castorForm" },
                React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                    React.createElement(Field, { label: `${t("contact.lastname")}*`, required: true, name: "lastName", format: value => formatLastName(value), className: "label-input", type: "text", validate: WhValidator.required, component: WhFieldTxt }),
                    React.createElement(Field, { label: `${t("contact.firstname")}*`, required: true, name: "firstName", format: value => formatFirstName(value), className: "label-input", type: "text", validate: WhValidator.requiredSelect, component: WhFieldTxt })),
                React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                    React.createElement(Field, { label: `${t("contact.civility")}*`, dataTestId: "civility", required: true, name: "civility", component: WhFieldSelect, validate: WhValidator.required, options: [
                            { label: "Mr", value: 1, name: "Mr" },
                            { label: "Mme", value: 2, name: "Mme" }
                        ] }),
                    React.createElement(Field, { name: "birthdate", component: WhFieldTxt, dateValue: form.birthdate, validate: WhValidator.required, placeholder: "date", componentName: "UpdateDate", label: `${t("contact.birthdate")}*`, parse: parseDate, type: "date" }),
                    React.createElement(Field, { label: `${t("contact.nationality")}*`, name: "nationality", component: WhFieldSelect, validate: WhValidator.required, format: value => nationalities.find(n => n.value === value), parse: value => value.value, "data-testid": "nationality", className: "label-input", options: nationalities })),
                React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                    React.createElement(Field, { label: `${t("contact.zipcode")}*`, name: "profile.address.zipCode", required: true, validate: WhValidator.required, className: "label-input", type: "text", component: WhFieldTxt, containerSize: "small" }),
                    false && (React.createElement(Field, { label: `${t("contact.address")}*`, name: "profile.address.address", required: true, validate: WhValidator.required, className: "label-input", type: "text", component: WhFieldTxt, containerSize: "mid" })),
                    React.createElement(Field, { label: addAsterix(t("contact.city")), validate: WhValidator.required, name: "profile.address.city", idOverload: "script-loader-form", component: GoogleMapWithoutLoadScript, inputClassName: "castorForm-inputContainer mid googlemap-input", defaultCity: form ? form.values.profile.address.city : null, onUpdate: onUpdateGoogleMap, type: "mission" })),
                React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                    React.createElement(Field, { label: `${t("contact.email")}*`, name: "email", required: true, validate: WhValidator.required, className: "label-input", type: "text", component: WhFieldTxt, containerSize: "big" }),
                    React.createElement(Field, { label: `${t("contact.phone")}*`, name: "phone", validate: [WhValidator.required, WhValidator.phone], required: true, className: "label-input", type: "text", component: WhFieldTxt, containerSize: "small" })),
                React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                    React.createElement(Field, { label: `${t("contact.country")}*`, name: "profile.address.country", validate: WhValidator.required, required: true, className: "label-input", type: "text", containerSize: "mid", component: WhFieldSelect, options: countryList().getData() }),
                    React.createElement(Field, { label: `${t("contact.status")}*`, name: "profile.status", validate: WhValidator.required, component: WhFieldSelect, containerSize: "mid", options: statuses, type: "text" })),
                React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                    React.createElement(Field, { component: WhFieldCheckbox, label: t("contact.helpCreation"), name: `helpCreation`, type: "checkbox", containerSize: "mid" })),
                React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                    React.createElement(Field, { component: WhFieldCheckbox, label: t("contact.newsletter"), name: `newsletter`, type: "checkbox", containerSize: "mid" }))))));
};
export default InformationsForm;
