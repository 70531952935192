import React, { Fragment } from "react";
import { MissionInformation } from "./MissionInformation";
import { useTranslation } from "react-i18next";
import { getDuration } from "../../../Shared/constants";
const MissionDate = ({ startDate, isRm = false, startNow, endDate, expirationDate = null, duration, withIcons = false, suppData = false, city = "", tjm = "", level = "", isFinish = false }) => {
    const { t } = useTranslation("missions");
    return (React.createElement("div", { className: "date-container mt-15 mt-15Mobile" },
        React.createElement("div", { className: "infos" },
            React.createElement(Fragment, null, withIcons && (React.createElement("div", { className: "icon" },
                React.createElement("i", { className: "icon-etat mr-10" })))),
            React.createElement(MissionInformation, { header: t("missions.header.begin"), content: !startNow ? startDate : t("missions.header.possible"), isMomentObject: startNow ? false : true }),
            withIcons && isFinish && (React.createElement("div", { className: "icon" },
                React.createElement("i", { className: "icon-date mr-10" }))),
            endDate && isFinish && (React.createElement(MissionInformation, { header: t("missions.header.finish"), content: endDate, isMomentObject: true })),
            withIcons && !isFinish && endDate && (React.createElement("div", { className: "icon" },
                React.createElement("i", { className: "icon-date mr-10" }))),
            !isFinish && endDate && (React.createElement(MissionInformation, { header: t("missions.header.end"), content: endDate, isMomentObject: true })),
            withIcons && (React.createElement("div", { className: "icon" },
                React.createElement("i", { className: "icon-attente mr-10" }))),
            React.createElement(MissionInformation, { header: t("missions.header.duration"), content: getDuration(duration).label, isMomentObject: false }),
            suppData && (React.createElement(Fragment, null,
                city && (React.createElement(MissionInformation, { header: t("missions.header.place"), content: city, isMomentObject: false })),
                level && (React.createElement(MissionInformation, { header: t("missions.header.level"), content: level, isMomentObject: false })),
                tjm && (React.createElement(MissionInformation, { header: t("missions.header.tjm"), content: tjm, isMomentObject: false })),
                isRm && process.env.NODE_ENV !== 'production' &&
                    React.createElement(MissionInformation, { header: expirationDate ? t("missions.header.expiration") : t("missions.header.expirationnotpublish"), content: expirationDate ? expirationDate : '', isMomentObject: expirationDate !== null }))))));
};
export default MissionDate;
