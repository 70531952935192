import React, { useReducer, useEffect, Fragment, useState } from "react";
import { rmEvent } from "./rmEventConstant";
import { useTranslation } from "react-i18next";
import ApiService from "../../../Services/ApiService";
import { CANDIDATURE, MISSIONSEVENT } from "../../../Services/contants";
import RmCandidatureTableNew from "./RmCandidatureTableNew";
import { candidatureDeleteNotif, candidatureUpdateNotif } from "../../../Services/notif/notif";
import Loading from "../../../Components/Loading";
import { WhConfirm } from "../../../Components/WHComponents";
const initialState = { loaded: false };
const reducer = (state, action) => {
    switch (action.type) {
        case "init": {
            return action.payload;
        }
        case "setStatusRm": {
            const updatedState = { ...state };
            if (action.payload.value.value === rmEvent["REFUSER"]) {
                updatedState.rows[action.payload.idx].statusRm = action.payload.value.value;
                return updatedState;
            }
            updatedState.rows[action.payload.idx].statusRm = action.payload.value.value;
            return updatedState;
        }
        case "setMailType": {
            const updatedState = { ...state };
            updatedState.rows[action.payload.idx].contactType = action.payload.value;
            return updatedState;
        }
        case "resetExpanded": {
            let updatedState = { ...state };
            let updatedExpanded = { ...updatedState.rowExpanded };
            for (let key in updatedExpanded) {
                updatedExpanded[`${key}`] = false;
            }
            updatedState.rowExpanded = { ...updatedExpanded };
            return updatedState;
        }
        case 'expandrow': {
            let updatedState = { ...state };
            let updatedExpanded = { ...updatedState.rowExpanded };
            updatedExpanded[`${action.payload.index}`] = true;
            updatedState.rowExpanded = { ...updatedExpanded };
            return updatedState;
        }
        default:
            return initialState;
    }
};
const RmCandidatureWrapper = ({ candidatures, missionTitle = "", missionId, callback, readonly, onAcceptFreelance }) => {
    const { t } = useTranslation('candidatures');
    const [loaded, setLoaded] = useState(true);
    const initialState = {
        rows: {},
        options: [
            { value: rmEvent["TRANSFERT"], label: t("rmaction.transfert") },
            { value: rmEvent["REFUSER"], label: t("rmaction.refuse") },
            { value: rmEvent["ATTENTE"], label: t("rmaction.waiting") },
            { value: rmEvent["ACCEPT"], label: t("rmaction.accept") }
        ],
        rowExpanded: {},
        loaded: false
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        let updatedInitialState = { ...initialState };
        let rowExpanded = {};
        let idx = 0;
        for (const c of candidatures) {
            let statusRm = initialState.options.find((opt) => opt.value === c.statusRm);
            if (state.rows.hasOwnProperty[idx])
                updatedInitialState.rows[idx] = { ...state.rows[idx] };
            else {
                updatedInitialState.rows[idx] = {
                    statusRm: statusRm ? statusRm.value : null,
                    statusClient: c.statusClient,
                    statusFl: c.statusFl,
                    contactType: c.contactType,
                    previousContactTypeValue: c.contactType,
                    previousRmValue: statusRm ? statusRm.value : null,
                };
            }
            rowExpanded = {
                ...rowExpanded,
                [`${idx}`]: false
            };
            idx++;
        }
        updatedInitialState.rowExpanded = { ...rowExpanded };
        updatedInitialState.loaded = true;
        dispatch({ type: 'init', payload: updatedInitialState });
    }, [candidatures]);
    const updateMissionsStatus = (code, candidatureId) => {
        const missionService = new ApiService(MISSIONSEVENT);
        missionService.create({ mission: '/api/missions/' + missionId, code: code, candidature: "/api/candidatures/" + candidatureId }).then(() => {
            if (callback)
                callback();
            candidatureUpdateNotif.success();
            setLoaded(true);
        }).catch(() => {
            candidatureUpdateNotif.failed();
            setLoaded(true);
        });
    };
    const updateStatusRm = (value, idx) => { dispatch({ type: 'setStatusRm', payload: { idx: idx, value: value } }); };
    const resetExpanded = () => { dispatch({ type: "resetExpanded" }); };
    const setExpandRow = (idx) => { dispatch({ type: "expandrow", payload: { index: idx } }); };
    const setMailType = (idx, value) => { dispatch({ type: 'setMailType', payload: { idx: idx, value: value } }); };
    const cancel = (idx) => {
        const status = state.rows[idx].previousRmValue;
        const newState = { ...state };
        newState.rows[idx].statusRm = status;
        newState.rows[idx].contactType = newState.rows[idx].previousContactTypeValue;
        dispatch({ type: "init", payload: state });
    };
    const deleteCandidature = (value) => {
        WhConfirm({
            title: "Confirmez vous la suppression du freelance sur la mission ? ",
            ifConfirm: () => {
                setLoaded(false);
                const candidatureService = new ApiService(CANDIDATURE);
                candidatureService.remove(value).then(() => {
                    candidatureDeleteNotif.success();
                    if (callback)
                        callback();
                    setLoaded(true);
                }).catch(() => {
                    candidatureDeleteNotif.failed();
                    setLoaded(true);
                });
            },
            ifNotConfirm: () => false,
            labelYes: "Supprimer",
            labelNo: "Annuler",
        });
    };
    const updateCandidatureContactType = (row, withLoader) => {
        const candidatureService = new ApiService(CANDIDATURE);
        if (state.rows[row.index].contactType === "PERSO") {
            console.log("roxw original", row.original);
            let subject = t("mail.subject");
            let body_before = `${t("mail.body_before")}  ${row.original.user.civilityLabel}, `;
            let body = t("mail.body_main");
            let body_after = t("mail.body_after");
            let mainBody = `${body_before} ${body} ${missionTitle} ${body_after}`;
            let strWindowFeatures = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
            location.href = `mailto:${row.original.user.email}?subject=${subject}&body=${encodeURIComponent(mainBody)}`;
        }
        withLoader && setLoaded(false);
        candidatureService
            .update({ _id: row.original._id, contactType: state.rows[row.index].contactType })
            .then(() => {
            candidatureUpdateNotif.success();
            if (withLoader) {
                setLoaded(true);
                if (callback)
                    callback();
            }
        }).catch(() => {
            if (withLoader) {
                setLoaded(true);
                if (callback)
                    callback();
            }
        });
    };
    const save = (row) => {
        const status = state.rows[row.index].statusRm;
        if (status === candidatures[row.index].statusRm) {
            updateCandidatureContactType(row, status === candidatures[row.index].statusRm);
            return;
        }
        if (status === rmEvent["ACCEPT"]) {
            onAcceptFreelance(row.original._id);
            return;
        }
        setLoaded(false);
        if (status === rmEvent["TRANSFERT"]) {
            updateMissionsStatus(107, row.original._id);
        }
        if (status === rmEvent["REFUSER"]) {
            updateMissionsStatus(111, row.original._id);
        }
        if (status === rmEvent["ATTENTE"]) {
            updateMissionsStatus(114, row.original._id);
        }
    };
    return React.createElement(Fragment, null,
        !loaded && React.createElement(Loading, { className: 'zIndex-5' }),
        state && state.loaded &&
            React.createElement(RmCandidatureTableNew, { missionId: missionId, readonly: readonly, missionTitle: missionTitle, candidatures: candidatures, callback: callback, rowData: state, actions: {
                    updateStatusRm: updateStatusRm,
                    resetExpanded: resetExpanded,
                    setMailType: setMailType,
                    setExpandRow: setExpandRow,
                    save: save,
                    cancel: cancel,
                    deleteCandidature: deleteCandidature
                } }));
};
export default RmCandidatureWrapper;
