import React, { Fragment, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import ConnectedMain from "./Views/Route/ConnectedMain";
import * as moment from "moment";
import Login from './Views/Login/View';
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga';
import AccountTempLoginForm from "./Views/Route/Login";
import FirebaseContext from './Components/Firebase/FirebaseContext';
import { fireBaseMessaging } from "./Components/Firebase/firebase-messaging-sw";
import * as MaintenanceService from "./Services/MaintenanceService";
if (process.env.NODE_ENV === 'staging')
    ReactGA.initialize('UA-158000507-2');
const App = () => {
    moment.locale("fr");
    const { pathname } = useLocation();
    useEffect(() => {
        try {
            MaintenanceService
                .check()
                .then((data) => {
                if (data.api || data.connect) {
                    window.location.href =
                        `${process.env.REACT_APP_MAINTENANCE_SERVICE}`;
                }
            })
                .catch(async () => {
            });
        }
        catch (error) {
        }
        if (process.env.NODE_ENV === 'staging')
            ReactGA.pageview(window.location.pathname + window.location.search);
    }, [pathname]);
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: "main_container" },
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: "/login", name: "Login Page", component: AccountTempLoginForm }),
                React.createElement(Route, { exact: true, path: "/auth_error", name: "Login Page", component: () => React.createElement("div", null, "Auth error") }),
                React.createElement(Route, { exact: true, path: "/app/login/:token", name: "Login Page", component: Login }),
                React.createElement(Route, { exact: true, path: "/404", name: "Page 404", component: () => React.createElement("div", null, "404 Login") }),
                "//@ts-ignore",
                React.createElement(FirebaseContext.Provider, { value: { messaging: fireBaseMessaging } },
                    React.createElement(Route, { path: "/", name: "Home", component: ConnectedMain }))))));
};
export default App;
