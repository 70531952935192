import React, { useState, useEffect, Fragment } from 'react';
import { MAX } from "../../Shared/maxResults";
import Loading from "../../Components/Loading";
import ApiService from "../../Services/ApiService";
import { PROFILES, SOCIETY } from "../../Services/contants";
import { WhConfirm } from "../../Components/WHComponents";
import * as commentaireService from "../../Services/commentaires";
import { useSelector } from "react-redux";
import { editNotif, notesNotif } from "../../Services/notif/notif";
import { useStoredNoFilters } from "../../Hooks/useStoredNoFilters";
const LoadCommentsFromCollectionID = ({ comments, profile_id, onDataLoaded, changePrecalState, render, type = "profile", callback, commentCollectionId }) => {
    const [storedQuery, setStoredQuery] = useState({
        api: process.env.REACT_APP_IDENTITY_GQL_URL
            ? process.env.REACT_APP_IDENTITY_GQL_URL
            : "",
        table: "commentaires",
        entity: "Commentaire",
        list: true,
        query: {
            order: "DESC",
            orderBy: { name: "created" },
            fields: [
                "id",
                "_id",
                "message",
                "created",
                "user{id, _id, firstName, lastName, photo{_id id content}}"
            ],
            maxResults: MAX,
            page: 0,
            filters: [
                {
                    value: `${commentCollectionId ? `api/commentaire_collections/${commentCollectionId}` : comments ? comments.id : "-1"}`,
                    name: "collection"
                }
            ]
        }
    });
    const rm = useSelector((state) => state.user.data);
    const { data, isCurrentlyLoading, loadData } = useStoredNoFilters(storedQuery);
    const [submitLoaded, setSubmitLoaded] = useState(true);
    useEffect(() => {
        const newStored = {
            api: process.env.REACT_APP_IDENTITY_GQL_URL
                ? process.env.REACT_APP_IDENTITY_GQL_URL
                : "",
            table: "commentaires",
            entity: "Commentaire",
            list: true,
            query: {
                order: "DESC",
                orderBy: { name: "created" },
                fields: [
                    "id",
                    "_id",
                    "message",
                    "created",
                    "user{id, _id, firstName, lastName, photo{_id id content}}"
                ],
                maxResults: MAX,
                page: 0,
                filters: [
                    {
                        value: `${commentCollectionId ? `api/commentaire_collections/${commentCollectionId}` : comments ? comments.id : "-1"}`,
                        name: "collection"
                    }
                ]
            }
        };
        setStoredQuery(newStored);
    }, [comments]);
    useEffect(() => {
        if (onDataLoaded)
            onDataLoaded();
    }, [data]);
    const onDelete = commentId => {
        WhConfirm({
            title: "Voulez-vous supprimer la note ?",
            ifConfirm: async () => {
                setSubmitLoaded(false);
                try {
                    await commentaireService.remove(commentId).then(() => {
                        notesNotif.success_delete();
                        if (changePrecalState)
                            changePrecalState();
                        loadData();
                    });
                }
                catch (error) {
                    notesNotif.failed_delete();
                }
                finally {
                    setSubmitLoaded(true);
                }
            },
            ifNotConfirm: () => false,
            labelYes: "Valider",
            labelNo: "Annuler"
        });
    };
    const editComment = async (data) => {
        const { user, ...submitData } = data;
        setSubmitLoaded(false);
        commentaireService.update(submitData).then(() => {
            notesNotif.success_edit();
            if (changePrecalState)
                changePrecalState();
            setSubmitLoaded(true);
            loadData();
        }).catch((e) => {
            console.error("Error while editing comment::", e);
            notesNotif.failed_edit();
            setSubmitLoaded(true);
        });
    };
    const onSubmit = async (sumitvalues) => {
        setSubmitLoaded(false);
        let NewComment = {
            user: rm.id,
            created: new Date(),
            ...sumitvalues["commentaires"]
        };
        if (!comments) {
            return createCommentCollection(NewComment).then(() => {
                editNotif.success();
                callback();
                changePrecalState();
                setSubmitLoaded(true);
            }).catch(_error => {
                editNotif.failed();
                loadData();
                setSubmitLoaded(true);
            });
        }
        NewComment = {
            ...NewComment,
            collection: comments.id
        };
        try {
            return commentaireService.create(NewComment).then(() => {
                notesNotif.success_add();
                changePrecalState();
                loadData();
                setSubmitLoaded(true);
            });
        }
        catch (error) {
            notesNotif.failed_add();
        }
    };
    const createCommentCollection = comments => {
        const profileService = new ApiService(type === "profile" ? PROFILES : SOCIETY);
        return profileService.update({ _id: profile_id, commentaires: { commentaires: [{ ...comments }] } });
    };
    return (React.createElement(Fragment, null,
        (isCurrentlyLoading || !submitLoaded) && React.createElement(Loading, { className: 'load-zIndexMin' }),
        render({ data, loadData, editComment, onSubmit, onDelete, createCommentCollection })));
};
export default LoadCommentsFromCollectionID;
