import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import ApiService from "../../../../Services/ApiService";
import { PROFILES } from "../../../../Services/contants";
import { useSelector } from "react-redux";
import Notif from "../../../../Components/Notif";
import ColLeftForm from './ColLeftForm';
import PhotoEdition from './PhotoEdition';
import { Form } from "react-final-form";
import moment from "moment";
export const ColLeft = ({ data, loadData, goToMission }) => {
    const { t } = useTranslation("freelance");
    const [formData, setFormData] = useState(data);
    const [loaded, setLoaded] = useState(false);
    const expertises = useSelector((state) => state.params.expertises);
    useEffect(() => {
        getFormData();
    }, [expertises]);
    const getFormData = async () => {
        const { tags, ...profile } = formData.profile;
        let formatData = { ...formData, profile: { ...profile } };
        setFormData(formatData);
        setLoaded(true);
    };
    const onSubmit = (data) => {
        if (!data.hasOwnProperty('googleMapCity') || (data.googleMapCity !== data.address.mobilityCity)) {
            alert("Tapez votre ville puis sélectionnez la dans la liste proposée.");
            return;
        }
        const profileService = new ApiService(PROFILES);
        let submitData = { ...data };
        submitData.address.mobilityRayon = submitData.address.mobilityRayon ? parseInt(submitData.address.mobilityRayon) : null;
        submitData.tjm = submitData.tjm ? parseInt(submitData.tjm) : null;
        submitData.status = submitData.status ? parseInt(submitData.status) : null;
        submitData.availability = data.availability ? moment(data.availability).format("YYYY-MM-DD") : null;
        delete submitData.profileDocuments;
        profileService.update(submitData).then(() => {
            loadData();
            Notif({ color: "success", message: t('notif:success_edit'), duration: 100 });
        });
    };
    const mutators = {
        setPlaces: (_args, state, utils) => {
            utils.changeValue(state, 'address.mobilityCity', () => _args[0]);
            utils.changeValue(state, 'googleMapCity', () => _args[0]);
            utils.changeValue(state, 'address.mobilityLat', () => _args[1]);
            utils.changeValue(state, 'address.mobilityLng', () => _args[2]);
            utils.changeValue(state, 'address.mobilityZipCode', () => _args[3]);
        }
    };
    return (React.createElement(Fragment, null, loaded && expertises &&
        React.createElement(Fragment, null,
            React.createElement(Card, { className: "infos" },
                React.createElement(PhotoEdition, { directUpdate: true, userId: data._id, photoProfile: data.photo, callback: loadData, disablePositionRelative: true }),
                React.createElement(CardBody, null,
                    React.createElement(Form, { mutators: { ...mutators }, onSubmit: onSubmit, initialValues: { ...formData.profile }, render: ({ handleSubmit, form: { mutators } }) => {
                            return (React.createElement("form", { onSubmit: handleSubmit },
                                React.createElement(ColLeftForm, { expertises: expertises, data: data, mutators: mutators })));
                        } }))),
            React.createElement(Card, { className: "pub" },
                React.createElement(CardBody, { className: 'flex-directionColumn' },
                    React.createElement("p", null, t("colLeft.pub_mission.content")),
                    React.createElement("div", { className: "wh-btn wh-btn-color-default size-medium w-50", onClick: goToMission }, t("colLeft.pub_mission.btn")))))));
};
export default ColLeft;
