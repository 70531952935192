import React, { Fragment } from 'react';
import { Button } from "reactstrap";
import MissionHeader from "../Components/MissionHeader";
import { MissionSocietyHeader } from "../Components/MissionInformation";
import MissionDate from "../Components/MissionDate";
import MissionDescription from "../Components/MissionDescription";
import { useTranslation } from "react-i18next";
import { AccessEnum, checkAccess } from "../../../Services/Access/access";
import DisplayTags from "../Components/Tags";
import { formatFirstName, formatLastName } from "../../../helper/formatUserName";
const MissionSideBarView = ({ mission, status, userRole, indication, permissions, close, edit }) => {
    const { t } = useTranslation(['form', 'missions']);
    const expTab = [
        { value: "junior", label: t("expertise.junior") },
        { value: "confirmed", label: t("expertise.confirmed") },
        { value: "expert", label: t("expertise.expert") }
    ];
    const getExp = () => {
        if (!mission.expertise)
            return "";
        const e = expTab.find(e => e.value === mission.expertise);
        if (e)
            return e.label;
        return "";
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: 'header header-side-bar-view' },
            React.createElement(MissionHeader, { title: mission.title, jobTitle: mission.job, indication: indication, status: status, sideBarDescription: false }),
            React.createElement(Button, { className: "closeBtn", "data-testid": 'closeButton', onClick: () => close(false) })),
        React.createElement("div", { style: { width: '100%', height: '2px', opacity: 0.74, backgroundColor: '#ffffff' } }),
        React.createElement("div", { style: { marginTop: '30px', marginLeft: '20px', marginRight: '20px', backgroundColor: "#f8f8f9", padding: '20px' }, className: "missionElement" },
            React.createElement(MissionSocietyHeader, { logo: mission.society.photo, publishDate: mission.publicationDate ? mission.publicationDate : null, name: `${mission.society.name} - ${mission.society.affiliate ? ` ${mission.society.affiliate}` : ''} (${formatLastName(mission.society.mainContact.lastName)} - ${formatFirstName(mission.society.mainContact.firstName)})`, isHidden: false }),
            React.createElement(MissionDate, { startNow: mission.startNow, duration: mission.duration, startDate: mission.startDate, endDate: mission.endDate, suppData: true, isRm: userRole === 'ROLE_ADMIN', city: mission.city, level: getExp(), expirationDate: mission.expirationDate, tjm: mission.tjm ? `${mission.tjm} €` : undefined })),
        React.createElement("div", { style: { marginTop: '30px', marginLeft: '20px', marginRight: '20px' } },
            React.createElement(DisplayTags, { tags: mission.tags }),
            React.createElement("div", { className: 'mt-30' },
                React.createElement(MissionDescription, { description: mission.description }))),
        React.createElement("div", { className: 'mission-btns mt-30' },
            React.createElement("div", { className: 'container-flex container-flex-row container-flex-center' },
                React.createElement("button", { className: 'mission-btn mission-cancel', onClick: () => close(false) }, t('form:btn:cancel')),
                checkAccess(AccessEnum.CREATE_EDIT_MISSION, permissions) && status.value !== 600 && status.value !== 700 &&
                    React.createElement("button", { onClick: edit, className: 'mission-btn mission-brouillon' }, t('form:btn:edit'))))));
};
export default MissionSideBarView;
