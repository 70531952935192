import React, { Fragment, useEffect } from "react";
import { Field } from "react-final-form";
import { Alert } from "reactstrap";
import DisplayForm from "../../../WHForm/DisplayForm";
import ButtonBottomForm from "../../../WHForm/ButtonBottomForm";
import { WhFieldTxt, WhFieldSelect } from "../../../../Components/WHForm/WhFields";
import { useTranslation } from "react-i18next";
import Loading from "../../../Loading";
import { WhValidator } from "../../../WHForm/WhFields";
import FFCheckbox from "../../../FinalForm/FFCheckbox";
import Contact from "./Contact";
export const FFContactButtonWrapper = ({ title, loaded = true, children }) => {
    const { shouldDisplayForm, displayFormToggle } = DisplayForm();
    const { t } = useTranslation("common");
    useEffect(() => {
        if (!loaded)
            displayFormToggle();
    }, [loaded]);
    return (React.createElement("div", { className: "rm-client-create-form" },
        !shouldDisplayForm && (React.createElement("span", { className: "littleTextButton absoluteEditButton editButton", "data-testid": "updateButton", onClick: () => displayFormToggle() }, t("modification"))),
        React.createElement(ButtonBottomForm, { toggle: displayFormToggle, display: shouldDisplayForm },
            React.createElement(Contact, { error: null, loaded: loaded, title: title, disabled: !shouldDisplayForm }, children))));
};
const FFContact = ({ title, error, disabled, isCreate, onDelete, children, name, canEdit, loaded = true }) => {
    const { t } = useTranslation("form");
    const addAsterix = text => `${text}*`;
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: "headerFreeItem mb-25" },
            React.createElement("span", { className: "thinTitle " }, t(title))),
        React.createElement("div", null,
            loaded ? (React.createElement("div", { className: "content" },
                error && React.createElement(Alert, { color: "danger" }, error),
                React.createElement("div", { className: "field-row mb-5" },
                    React.createElement(Field, { name: name ? `${name}.civility` : "civility", validate: WhValidator.required }, props => (React.createElement(WhFieldSelect, Object.assign({ options: [
                            { label: "Mr", value: 1 },
                            { label: "Mme", value: 2 }
                        ], name: name ? `${name}.civility` : "civility" }, props, { label: addAsterix(t("form:contact.civility")), type: "text", className: "label-input", disabled: disabled })))),
                    React.createElement(Field, { name: name ? `${name}.lastName` : "lastName", validate: WhValidator.required }, props => (React.createElement(WhFieldTxt, Object.assign({ name: name ? `${name}.lastName` : "lastName" }, props, { label: addAsterix(t("form:contact.lastname")), type: "text", className: "label-input mb-5", disabled: disabled })))),
                    React.createElement(Field, { name: name ? `${name}.firstName` : "firstName", validate: WhValidator.required }, props => (React.createElement(WhFieldTxt, Object.assign({ name: name ? `${name}.firstName` : "firstName" }, props, { label: addAsterix(t("form:contact.firstname")), type: "text", className: "label-input", disabled: disabled }))))),
                React.createElement("div", { className: "field-row mb-5" },
                    React.createElement(Field, { name: name ? `${name}.function` : "function", validate: WhValidator.required }, props => (React.createElement(WhFieldTxt, Object.assign({ name: name ? `${name}.function` : "function" }, props, { label: addAsterix(t("form:contact.function")), type: "text", className: "label-input", disabled: disabled }))))),
                React.createElement("div", { className: "field-row mb-5" },
                    React.createElement(Field, { name: name ? `${name}.email` : "email", validate: WhValidator.composeValidators(WhValidator.required, WhValidator.email) }, props => (React.createElement(WhFieldTxt, Object.assign({ name: name ? `${name}.email` : "email" }, props, { label: addAsterix(t("form:contact.email")), type: "text", className: "label-input mb-5", containerSize: "big", disabled: disabled })))),
                    React.createElement(Field, { name: name ? `${name}.phone` : "phone", validate: WhValidator.composeValidators(WhValidator.required, WhValidator.phone) }, props => (React.createElement(WhFieldTxt, Object.assign({ name: name ? `${name}.phone` : "phone" }, props, { label: addAsterix(t("form:contact.phone")), type: "text", className: "label-input", containerSize: "small", disabled: disabled }))))),
                React.createElement("div", { className: "field-row mb-25" },
                    React.createElement(FFCheckbox, { name: name ? `${name}.newsletter` : "newsletter", disabled: disabled, containerSize: "mid", labelFor: isCreate ? `newsletterCreate` : "newsletter", label: t("form:contact.newsletter") })),
                canEdit && onDelete && (React.createElement("span", { "data-testid": "removeButton", className: "refItem-delete littleTextButton  flex-selfEnd", onClick: onDelete }, t("form:contact.delete"))))) : (React.createElement(Loading, null)),
            children)));
};
export default FFContact;
