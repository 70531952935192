import * as storage from "../storage";
import * as api from "./api";
export const setJwtToken = jwtToken => storage.save("jwt_token", jwtToken);
export const getJwtToken = () => {
    return storage.get("jwt_token");
};
export const deleteJwtToken = () => storage.destroy("jwt_token");
export const getUserStored = () => storage.get("user");
export const setUserStored = user => storage.save("user", user);
export const deleteUserStored = () => storage.destroy("user");
export const login = async (token) => {
    try {
        const response = await api.login(token);
        setJwtToken(response.token);
    }
    catch (error) {
        throw error;
    }
};
export const logout = async () => {
    try {
        await deleteJwtToken();
        await deleteUserStored();
    }
    catch (error) {
        throw error;
    }
};
export const lock = async () => {
    try {
        await deleteJwtToken();
    }
    catch (error) {
        throw error;
    }
};
export const getUser = async () => {
    try {
        const user = await api.me();
        await setUserStored(user);
        return user;
    }
    catch (error) {
        throw error;
    }
};
