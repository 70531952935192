import React, { Fragment } from "react";
import Sidebar, { SideBarOnlyChild } from "../../Components/Sidebar/View";
import { getDuration } from "../../Shared/constants";
import FFEditMissionRm from "./SideBarComponents/FFEditMissionRm";
import Loading from "../../Components/Loading";
import FFCreateMission from "./SideBarComponents/FFCreateMission";
import AcceptFreelance from "./SideBarComponents/AcceptFreelance";
import MissionSideBarView from "./SideBarComponents/MissionSideBarView";
import DeleteMissionClient from "./SideBarComponents/DeleteMissionClient";
import RefuseMission from "./SideBarComponents/RefuseMission";
import ChangeDateClient from "./SideBarComponents/ChangeDateClient";
import { doesThisArrayIncludeThoseString } from "../../helper/arrayHelper";
import moment from 'moment';
const SideBarManager = ({ isOpen, userRole, indication, onSubmit, permissions = [], entities, entitiesLoaded, onClose, title, current, mission, status }) => {
    const arrayPath = userRole === 'ROLE_CLIENT' ? 5 : 4;
    const loadingStates = ["submit_edit", "submit_create", "submit_end_mission", "submit_refuse_mission", "submit_change_date", "submit_delete"];
    const isLoading = doesThisArrayIncludeThoseString(current.toStrings(), loadingStates);
    const switchSideBarContent = () => {
        if (current.matches(`${current.toStrings()[arrayPath]}.change_date`) || current.matches(`${current.toStrings()[arrayPath]}.submit_change_date`))
            return React.createElement(ChangeDateClient, { close: onClose, initialValues: { startDate: mission.startDate, endDate: mission.endDate, duration: getDuration(mission.duration) }, onSubmit: (data) => onSubmit("SUBMIT", data) });
        if (current.matches(`${current.toStrings()[arrayPath]}.end_mission`))
            return React.createElement(DeleteMissionClient, { close: onClose, onSubmit: (data) => onSubmit("SUBMIT", data) });
        if (current.matches(`${current.toStrings()[arrayPath]}.shallow_delete`))
            return React.createElement(DeleteMissionClient, { close: onClose, onSubmit: (data) => onSubmit("SUBMIT", data) });
        if ((current.matches("role_admin.candidature_display.candidature.editable.open") || current.matches("role_admin.candidature_display.candidature.editable.submit_candidature")) || (current.matches("role_client.client_candidatures.open") || current.matches("role_client.client_candidatures.submit_freelance")))
            return React.createElement(AcceptFreelance, { close: onClose, onSubmit: (data) => onSubmit("SUBMIT", data), isLoading: (current.matches("role_client.client_candidatures.submit_freelance") || current.matches("role_admin.candidature_display.candidature.editable.submit_candidature")), initialValues: { endDate: mission.endDate, startDate: mission.startDate, duration: getDuration(mission.duration) } });
        if (current.matches({ role_admin: { mission_create: "create" } }) || current.matches("role_admin.mission_create.submit_create"))
            return React.createElement(FFCreateMission, { close: onClose, onSubmit: (data) => onSubmit("SUBMIT", data), role: "ROLE_ADMIN", permissions: permissions, entities: entities, entitiesLoaded: entitiesLoaded });
        if (current.matches(`${current.toStrings()[arrayPath]}.refuse_mission`))
            return React.createElement(RefuseMission, { onSubmit: (data) => onSubmit("SUBMIT", data), close: onClose });
        if (current.matches(`${current.toStrings()[arrayPath]}.edit`) || current.matches(`${current.toStrings()[arrayPath]}.submit_edit`)) {
            return React.createElement(FFEditMissionRm, { close: onClose, onSubmit: (data) => onSubmit("SUBMIT", data), entities: entities, userRole: userRole, entitiesLoaded: entitiesLoaded, initialValues: {
                    userRole: current.matches('role_admin'),
                    public: !mission.public,
                    job: mission.job,
                    title: mission.title,
                    description: mission.description,
                    geometry: { ...mission.geometry },
                    city: mission.city,
                    expertise: mission.expertise,
                    startDate: moment(mission.startDate).format('YYYY-MM-DD'),
                    startNow: mission.startNow,
                    hiddenSociety: mission.hiddenSociety,
                    tags: mission.tags,
                    duration: getDuration(mission.duration),
                    activitySector: { value: mission.activitySector.id, label: mission.activitySector.name },
                    society: { value: mission.society.id, label: `${mission.society.name} - ${mission.society.affiliate ? ` ${mission.society.affiliate}` : ''} (${mission.society.mainContact.lastName} ${mission.society.mainContact.firstName})` }
                } });
        }
        return React.createElement(Fragment, null);
    };
    return React.createElement(Fragment, null, current.matches(`${current.toStrings()[arrayPath]}.description`) && !current.matches(`${current.toStrings()[arrayPath]}.description.edit`) ?
        React.createElement(SideBarOnlyChild, { showSidebar: isOpen, close: onClose, title: title },
            React.createElement(MissionSideBarView, { mission: mission, userRole: userRole, indication: indication, status: status, permissions: permissions, close: onClose, edit: () => onSubmit("EDIT") })) :
        React.createElement(Sidebar, { showSidebar: isOpen, close: onClose, title: title },
            isLoading && React.createElement(Loading, null),
            switchSideBarContent()));
};
export default SideBarManager;
