import { assign, Machine } from "xstate";
import ApiService from "../../../Services/ApiService";
import { USERS } from "../../../Services/contants";
export const createSideBarMachine = () => {
    return Machine({
        id: 'client_side_bar_machine',
        initial: "idle",
        context: { sideBarTitle: 'form:contact.new', error: null },
        states: {
            idle: {
                on: {
                    DELETE_ACCOUNT: {
                        target: 'deleteAccount', actions: ['setDeleteAccountData']
                    }
                },
            },
            deleteAccount: {
                on: { CLOSE: 'idle' },
                initial: 'idle',
                states: {
                    idle: {
                        on: { SUBMIT: 'submit' }
                    },
                    logout: {},
                    submit: {
                        invoke: {
                            src: 'deleteAccount',
                            onDone: { target: 'logout' },
                            onError: {}
                        }
                    }
                }
            }
        }
    }, {
        actions: {
            setDeleteAccountData: assign({
                sideBarTitle: () => 'client_account.delete_account.title'
            }),
        },
        services: {
            deleteAccount: (_context, event) => {
                const userService = new ApiService(USERS);
                return userService.removeWithData({ ...event.data });
            }
        }
    });
};
