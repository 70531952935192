import { useTranslation } from "react-i18next";
import moment from 'moment';
export const setEndDate = (duration, startDate) => {
    const newDate = moment(startDate).clone().add(parseInt(duration), 'months');
    return newDate.format('YYYY-MM-DD');
};
export const getDuration = (value) => {
    const table = durationTable();
    const row = table.find((t) => t.value === value);
    if (row)
        return row;
    return table[0];
};
export const durationTable = () => {
    const { t } = useTranslation('form');
    return [
        { value: "6", label: t("createmission.durationItem.Less6") },
        { value: "12", label: t("createmission.durationItem.6-12") },
        { value: "24", label: t("createmission.durationItem.13-24") },
        { value: "024", label: t("createmission.durationItem.More24") }
    ];
};
