import saveAs from 'file-saver';
import * as session from './session';
import ApiService from "../Services/ApiService";
const exportCSV = (partialUrl, name, data = []) => fetch(`${process.env.REACT_APP_IDENTITY_API_URL}${partialUrl}`, {
    mode: 'cors',
    method: 'post',
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${session.getJwtToken()}`,
    },
    body: JSON.stringify(data)
})
    .then(response => response.blob())
    .then(blob => saveAs(blob, `${name}.csv`));
export const newExportCsv = (partialUrl, filters, name) => {
    const exportService = new ApiService(partialUrl);
    return exportService.create(filters).then(response => response.blob())
        .then(blob => saveAs(blob, `${name}.csv`));
};
export default exportCSV;
