import React, { Fragment, useEffect } from "react";
import { useMission } from "./useMission";
import { useMachine } from "@xstate/react";
import { createDefaultMissionMachine } from "./MissionStateMachine/missionStateMachine";
import SearchContainer from "../../Components/SearchContainer/SearchContainer";
import { withRouter } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import MissionHeader from "./Components/MissionHeader";
import MissionButtons from "./Components/MissionButtons";
import SideBarManager from "./SideBarManager";
import useGetAll from "../../Services/getAllHooks/getAll";
import MissionDate from "./Components/MissionDate";
import { MissionSocietyHeader } from "./Components/MissionInformation";
import MissionDescription from "./Components/MissionDescription";
import RmCandidatureWrapper from "./Candidature/RmCandidatureWrapper";
import Historic from "./Candidature/HistoricFreelance";
import SelectedFreelance from "./Components/SelectedFreelance";
import Bandeau from "./Components/Bandeau/Bandeau";
import MissionRefuse from "./Components/MissionRefuse";
import DisplayTags from "./Components/Tags";
import RmSnapshot from "../../Components/UserSnapshot/RmSnapshot";
import { useDispatch, useSelector } from "react-redux";
import { AccessEnum, checkAccess } from "../../Services/Access/access";
import ClientCandidatures from "./Candidature/ClientCandidatures";
import { fetchCounts } from "../../redux/count/action";
import Loading from "../../Components/Loading";
import { formatFirstName, formatLastName } from "../../helper/formatUserName";
const Mission = ({ mission, userRole, withoutHeader = false, callback, isShown = false, setIsShown, redirect, goToProfile, history }) => {
    const connectedUser = useSelector((state) => state.user);
    const connectedSociety = useSelector((state) => state.society);
    const dispatch = useDispatch();
    const { entities, entitiesLoaded } = useGetAll([
        {
            table: "geometries",
            entity: "Geometry",
            fields: ["id", "_id"],
            filters: [{ name: "mission_id", value: mission._id }],
            format: (obj) => ({
                label: `${obj.id}`,
                value: obj.id,
            }),
        },
        {
            table: "societies",
            entity: "Society",
            fields: [
                "name",
                "id",
                "_id",
                "nbPackHelp",
                "nopackNoHelp",
                "mainContact{firstName lastName}",
                "affiliate",
            ],
            filters: [
                { name: "exists", value: { mainContact_deletedAt: false } },
                { name: "mainContact_type", value: "CLIENT" },
                { name: "qualified", value: true },
            ],
            format: (obj) => ({
                label: `${obj.name}${obj.affiliate ? `-${obj.affiliate}` : ""} (${obj.mainContact.firstName} ${obj.mainContact.lastName})`,
                value: obj.id,
                pack: { helped: obj.nbPackHelp, noHelped: obj.nopackNoHelp },
            }),
        },
    ], userRole);
    const { candidature, status, indication, currentCandidature } = useMission(mission, userRole, connectedUser.data.id);
    const onUpdate = () => {
        callback();
    };
    const [current, send] = useMachine(createDefaultMissionMachine(onUpdate, { ...mission }, redirect, connectedUser.data._id));
    const { t } = useTranslation(["form", "notif", "missions"]);
    const expTab = [
        { value: "junior", label: t("expertise.junior") },
        { value: "confirmed", label: t("expertise.confirmed") },
        { value: "expert", label: t("expertise.expert") },
    ];
    const getExp = () => {
        if (!mission.expertise)
            return "";
        const e = expTab.find((e) => e.value === mission.expertise);
        if (e)
            return e.label;
        return "";
    };
    useEffect(() => {
        send("CHANGE_CONTEXT", { mission });
    }, [mission]);
    useEffect(() => {
        send("RETURN");
    }, [status]);
    useEffect(() => {
        if (current.context.sidebarOpen) {
            document.body.classList.add('responsiveCreateMissionopen');
        }
        else {
            document.body.classList.remove('responsiveCreateMissionopen');
        }
    }, [current.context.sidebarOpen]);
    useEffect(() => {
        if (status.value === 0)
            return;
        if ((userRole === 'ROLE_ADMIN' && indication && indication.value === 100) || (userRole === 'ROLE_CLIENT' && indication && indication.value === 300)) {
            send("VALIDATION", { to: "candidatureValidation" });
        }
        if (userRole === 'ROLE_CLIENT' && (!indication || (indication && indication.value !== 300))) {
            send("NONE", { to: "candidatureValidation" });
        }
        send(status.value.toString());
    }, [status]);
    useEffect(() => {
        if (status.value === 300)
            send("EDITABLE", { to: "candidatureDisplay" });
        if (status.value >= 400) {
            send("HISTORIC", { to: "candidatureDisplay" });
        }
        if (candidature &&
            candidature.indicationFl === "Attente validation dossier")
            send("VALIDATION", { to: "candidatureValidation" });
    }, [candidature, status]);
    useEffect(() => {
        send(userRole);
    }, [userRole]);
    const displayEndDate = () => {
        if (status.value === 700 || status.value === 2900 || status.value === 1500)
            return true;
        return ((status.value >= 400 && status.value <= 800) ||
            (status.value >= 1200 && status.value <= 1500) ||
            (status.value >= 2600 && status.value <= 2900));
    };
    const changeMachineState = (stateType, data = null, to = "") => {
        if (!data) {
            send(stateType);
            return;
        }
        if (!to) {
            send(stateType, { data });
            return;
        }
    };
    const getMissionRefuseLabel = () => {
        if (!status)
            return "";
        if (status.value === 2400 || status.value === 800)
            return "refused";
        if (status.value === 2800 || status.value === 600)
            return "canceled";
        return "";
    };
    const goBack = () => {
        return history.goBack();
    };
    const getSocietyName = () => {
        if (userRole === 'ROLE_CLIENT' || userRole === 'ROLE_USER')
            return mission.society.name;
        return `${mission.society.name} - ${mission.society.affiliate ? ` ${mission.society.affiliate}` : ''} (${formatLastName(mission.society.mainContact.lastName)} - ${formatFirstName(mission.society.mainContact.firstName)})`;
    };
    const getTjm = () => {
        if (userRole === "ROLE_USER" && candidature) {
            const statusFl = parseInt(candidature.statusFl);
            if (statusFl !== 1400 && statusFl !== 1100) {
                return mission.tjm ? `${mission.tjm} €` : undefined;
            }
            return undefined;
        }
        return mission.tjm ? `${mission.tjm} €` : undefined;
    };
    useEffect(() => {
        if (current.matches('role_admin.candidature_display.validation.validation')) {
            dispatch(fetchCounts());
        }
    }, [current.matches('role_admin.candidature_display.validation.validation')]);
    return (React.createElement(Fragment, null,
        current.matches('role_user.status.wait_activation.active') || current.matches('role_user.status.wait_activation.decline') || current.matches('role_user.status.in_progress.end_mission') || current.matches('role_user.status.wait_activation.active') && React.createElement(Loading, { className: "load-zIndexMin" }),
        !withoutHeader && current.matches("role_admin") && (React.createElement(SearchContainer, { title: t("missions:missions.mine"), header: true, withBtn: checkAccess(AccessEnum.CREATE_EDIT_MISSION, connectedUser.data.permissions), textBtn: t("missions:missions.new"), onClick: (e) => {
                e.stopPropagation();
                changeMachineState("CREATE");
            } })),
        React.createElement(Container, { className: `${isShown ? "show" : ""} little w-60 pt-0 pt-20Mobile viewMissionContainer` },
            !withoutHeader &&
                React.createElement("div", { onClick: () => {
                        if (setIsShown)
                            setIsShown(false);
                        else {
                            goBack();
                        }
                    }, className: "viewMissionContainer-close" }, "<"),
            React.createElement("div", { className: "roundBlock missionContainer p-20" },
                React.createElement(MissionHeader, { userRole: userRole, title: mission.title, jobTitle: mission.job, indication: indication, status: status, sideBarDescription: current.matches("role_admin") && status.value >= 300, onClick: () => changeMachineState("DESCRIPTION") }),
                (current.matches("role_admin.status.canceled") ||
                    current.matches("role_client.status.canceled")) && (React.createElement(Bandeau, { canceledReason: mission.canceledReason, updatedBy: mission.updatedBy, refuseLabel: getMissionRefuseLabel() })),
                React.createElement(MissionButtons, { status: status, indication: indication, userRole: userRole, permissions: connectedUser.data.permissions, userTakenCare: userRole === 'ROLE_CLIENT' && indication && indication.value === 500, onSubmit: changeMachineState }),
                React.createElement("span", { className: "separator separator-horizontal withPadding boxShadow" }),
                (current.matches("role_admin.status.refuse") ||
                    current.matches("role_client.status.refuse") ||
                    current.matches("role_client.status.refuse_updated")) && (React.createElement(MissionRefuse, { canceledReason: mission.canceledReason })),
                current.matches("role_client.status.open") &&
                    mission.candidatures.length > 0 && (React.createElement(ClientCandidatures, { mission: mission, reload: callback, onAccept: (candidaturesData) => changeMachineState("OPEN", { candidaturesData }) })),
                React.createElement("div", { className: "missionElement" },
                    React.createElement(MissionSocietyHeader, { publishDate: status.value >= 300 || status.value >= 2500
                            ? mission.publicationDate
                            : null, logo: mission.society.photo, name: getSocietyName(), isHidden: mission.hiddenSociety ? mission.hiddenSociety : false }),
                    React.createElement(MissionDate, { duration: mission.duration, startNow: mission.startNow, isRm: current.matches('role_admin'), startDate: mission.startDate, expirationDate: mission.expirationDate, endDate: displayEndDate() ? mission.endDate : null, suppData: true, tjm: getTjm(), city: mission.city, level: getExp(), isFinish: status.value === 700 ||
                            status.value === 1500 ||
                            status.value === 2900 })),
                current.matches("role_user") && (React.createElement(Fragment, null,
                    React.createElement(DisplayTags, { tags: mission.tags, removeText: true }),
                    React.createElement("div", { className: "mt-30" },
                        React.createElement(MissionDescription, { description: mission.description })))),
                current.matches("role_admin") && status.value < 300 && (React.createElement(Fragment, null,
                    React.createElement(DisplayTags, { tags: mission.tags }),
                    React.createElement("div", { className: "mt-30" },
                        React.createElement(MissionDescription, { description: mission.description })))),
                current.matches("role_client") && (React.createElement(Fragment, null,
                    React.createElement(DisplayTags, { tags: mission.tags }),
                    React.createElement("div", { className: "mt-30" },
                        React.createElement(MissionDescription, { description: mission.description })),
                    React.createElement("div", { className: "mt-30" }, connectedSociety.data.rm && (React.createElement(RmSnapshot, { picture: connectedSociety.data.rm.photo, firstName: connectedSociety.data.rm.firstName, lastName: connectedSociety.data.rm.lastName, email: connectedSociety.data.rm.email, phone: connectedSociety.data.rm.phone })))))),
            currentCandidature &&
                (current.matches("role_admin.candidature_display.validation.validation")
                    || current.matches("role_admin.candidature_display.validation.send_validate")
                    ||
                        current.matches("role_client.candidature_display.validation.validation")
                    || current.matches("role_client.candidature_display.validation.send_validate")) && (React.createElement("div", { className: " w-100 pt-0 mt-30 mb-30" },
                React.createElement("p", { className: "weight-light size-missionBig mb-20 " }, t("missions:missions.selectedFreelance")),
                React.createElement(SelectedFreelance, { id: currentCandidature.user._id, firstName: currentCandidature.user.firstName, lastName: currentCandidature.user.lastName, photo: currentCandidature.user.photo
                        ? currentCandidature.user.photo.content
                        : null, goToProfile: goToProfile, permissions: connectedUser.data.permissions, job: currentCandidature.user.profile.job, profile: currentCandidature.user.profile, tjm: mission.tjm, statusFl: parseInt(currentCandidature.statusFl), onValidation: current.matches("role_admin")
                        ? () => { send("SEND_VALIDATION"); }
                        : null }))),
            current.matches("role_admin") &&
                status.value >= 300 &&
                status.value !== 800 && (React.createElement("div", { className: "mt-20" },
                current.matches("role_admin.candidature_display.candidature.editable") &&
                    current.matches("role_admin.candidature_display.validation.none") &&
                    checkAccess(AccessEnum.ACT_CANDIDATURE, connectedUser.data.permissions) && (React.createElement(RmCandidatureWrapper, { missionId: mission._id.toString(), missionTitle: mission.title, candidatures: mission.candidatures, callback: callback, readonly: false, onAcceptFreelance: (candidature_id) => changeMachineState("OPEN", {
                        candidature_id: candidature_id,
                    }) })),
                (current.matches("role_admin.candidature_display.candidature.historic") ||
                    current.matches("role_admin.candidature_display.validation.validation")) && (React.createElement(Historic, { missionId: mission._id.toString(), callback: callback, permissions: connectedUser.data.permissions, candidatures: mission.candidatures }))))),
        React.createElement(SideBarManager, { mission: mission, userRole: userRole, status: status, indication: indication, permissions: connectedUser.data.permissions, entities: entities, entitiesLoaded: entitiesLoaded, isOpen: current.context.sidebarOpen, current: current, onSubmit: changeMachineState, onClose: () => changeMachineState("CANCEL"), title: t(current.context.sidebarTitle ? current.context.sidebarTitle : "") })));
};
export default withRouter(Mission);
