import React from 'react';
import { CounterElement, IncBtn } from "./CounterElement";
export const CounterList = ({ number, cssType }) => {
    const getNumberArray = (number, divider) => {
        const formatedNumbers = [];
        const getNb = (number, divider) => {
            const nb = parseInt("" + number / divider + "");
            formatedNumbers.push(nb);
            if (divider === 1)
                return;
            else
                getNb(number % divider, divider / 10);
        };
        getNb(number, divider);
        let switchLightGray = true;
        return formatedNumbers.map((nb, idx) => {
            if (nb === 0 && switchLightGray) {
                switchLightGray = true;
            }
            else {
                switchLightGray = false;
            }
            return React.createElement(CounterElement, { key: `counterElement_${idx}`, number: nb, cssType: cssType, switchLightGray: switchLightGray });
        });
    };
    return React.createElement("div", { className: 'counterList' }, getNumberArray(number, 100));
};
export const CounterListWithBtn = ({ number, type, cssType, onUpdate }) => {
    const onClick = (_value) => {
        if (onUpdate)
            onUpdate(_value, type);
    };
    return (React.createElement("div", { className: 'flex-row flex-row-align-items-center flex-row-center' },
        React.createElement(IncBtn, { onClick: onClick, type: '-' }),
        React.createElement(CounterList, { number: number, cssType: cssType, onUpdate: onUpdate }),
        React.createElement(IncBtn, { onClick: onClick, type: '+' })));
};
