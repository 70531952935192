import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
export const useMission = (mission, userRole, user_id) => {
    const missionsStatus = useSelector((state) => state.params.missionsStatus);
    const defaultStatus = { label: "", value: 0, color: "" };
    const [candidature, setCandidature] = useState(null);
    const [currentCandidature, setCurrentCandidature] = useState(null);
    const [status, setStatus] = useState(defaultStatus);
    const [indication, setIndication] = useState(null);
    const getCandidature = () => mission.candidatures ? mission.candidatures.find(c => c.user.id === user_id) : null;
    const getCurrentCandidature = () => {
        return mission.candidatures ?
            mission.candidatures.find((c => ((parseInt(c.statusFl) === 1300) || (parseInt(c.statusFl) === 1200 && c.indicationFlNew === 100))))
            : null;
    };
    const getIndication = () => {
        if (!mission.missionStatus)
            return null;
        if (userRole === "ROLE_CLIENT") {
            return missionsStatus.clientSub.find((i) => i.value === mission.missionStatus.subStatusClient);
        }
        if (userRole === "ROLE_ADMIN") {
            return missionsStatus.rmSub.find((i) => i.value === mission.missionStatus.subStatusRm);
        }
        if (userRole === "ROLE_USER") {
            let candidature = getCandidature();
            if (!candidature)
                return null;
            return missionsStatus.flSub.find((i) => i.value === candidature.indicationFlNew);
        }
        return null;
    };
    const getStatusClient = () => missionsStatus.client.find(st => st.value === mission.missionStatus.statusClient);
    const getStatusRm = () => missionsStatus.rm.find(st => st.value === mission.missionStatus.statusRm);
    const getStatusFl = () => {
        const candidature = getCandidature();
        if (!candidature || !candidature.statusFl || candidature.statusFl === "" || isNaN(parseInt(candidature.statusFl)))
            return null;
        return missionsStatus.fl.find(st => st.value === parseInt(candidature.statusFl));
    };
    const getIndicationLabel = (subIndicStatus) => {
        let indication = null;
        if (userRole === 'ROLE_ADMIN') {
            indication = missionsStatus.rmSub.find((i) => i.value === subIndicStatus);
        }
        if (userRole === 'ROLE_CLIENT') {
            indication = missionsStatus.clientSub.find((i) => i.value === subIndicStatus);
        }
        if (indication) {
            return indication.label;
        }
        return null;
    };
    const getStatus = () => {
        let status = defaultStatus;
        const mStatus = mission && mission.missionStatus ? { ...mission.missionStatus } : null;
        if (!mStatus)
            return status;
        if (userRole === "ROLE_CLIENT") {
            if (!mStatus.statusClient)
                return status;
            return getStatusClient();
        }
        if (userRole === "ROLE_ADMIN") {
            if (!mStatus.statusRm)
                return status;
            return getStatusRm();
        }
        if (userRole === "ROLE_USER") {
            if (mStatus.statusClient === 2800) {
                return missionsStatus.client.find(st => st.value === 2800);
            }
            if (mStatus.statusRm === 600) {
                return missionsStatus.rm.find(st => st.value === 2800);
            }
            const statusFl = getStatusFl();
            if (!statusFl)
                return status;
            return statusFl;
        }
        return status;
    };
    useEffect(() => {
        let candidature = getCandidature();
        if (candidature)
            setCandidature(candidature);
        let currentCandidature = getCurrentCandidature();
        if (currentCandidature)
            setCurrentCandidature(currentCandidature);
        let status = getStatus();
        if (status)
            setStatus(status);
        let indication = getIndication();
        setIndication(indication);
    }, [mission]);
    return {
        candidature,
        currentCandidature,
        getIndicationLabel,
        getIndication,
        status,
        indication,
    };
};
