import React, { Fragment, useEffect } from "react";
import { Button } from "reactstrap";
import { buttonsText, statusParameters } from "./buttonText";
import { useTranslation } from "react-i18next";
import { AccessEnum, checkAccess } from "../../../../Services/Access/access";
const RessourceManagerButtonList = ({ status, permissions = [], onSubmit, setNumberButton }) => {
    const { t } = useTranslation("missions");
    useEffect(() => {
        if (status.label === statusParameters["DRAFT"] ||
            status.label === statusParameters["VALIDATION_WAIT"])
            setNumberButton(4);
        if (status.label === statusParameters["UPDATED"] ||
            status.label === statusParameters["IN_PROGRESS"])
            setNumberButton(3);
        if (status.label === statusParameters["OPEN"] ||
            status.label === statusParameters["ACTIVATION_WAIT"])
            setNumberButton(2);
    }, [status]);
    return (React.createElement(Fragment, null,
        status.label === statusParameters["DRAFT"] && (React.createElement("div", { className: "container-flex container-flex-row all-border" },
            React.createElement("div", null,
                checkAccess(AccessEnum.DELETE_MISSION, permissions) &&
                    React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-blue wh-btn", onClick: () => {
                            if (onSubmit)
                                onSubmit("TRUE_DELETE");
                        } }, t(buttonsText["DELETE"])),
                checkAccess(AccessEnum.CREATE_EDIT_MISSION, permissions) &&
                    React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-edit wh-btn", onClick: () => {
                            if (onSubmit)
                                onSubmit("EDIT");
                        } }, t(buttonsText["EDIT"]))),
            React.createElement("div", null,
                checkAccess(AccessEnum.PUBLISH_MISSION, permissions) &&
                    React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-primary wh-btn", onClick: () => {
                            if (onSubmit)
                                onSubmit("PUBLISH");
                        } }, t(buttonsText["PUBLISH"])),
                React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-send wh-btn", onClick: () => {
                        if (onSubmit)
                            onSubmit("SEND_TO_CLIENT");
                    } }, t(buttonsText["SEND_TO_CLIENT"]))))),
        status.label === statusParameters["UPDATED"] && (React.createElement("div", { className: "container-flex container-flex-row" },
            checkAccess(AccessEnum.DELETE_MISSION, permissions) &&
                React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-blue wh-btn", onClick: () => {
                        if (onSubmit)
                            onSubmit("TRUE_DELETE");
                    } }, t(buttonsText["DELETE"])),
            checkAccess(AccessEnum.CREATE_EDIT_MISSION, permissions) &&
                React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-edit wh-btn", onClick: () => {
                        if (onSubmit)
                            onSubmit("EDIT");
                    } }, t(buttonsText["EDIT"])),
            checkAccess(AccessEnum.PUBLISH_MISSION, permissions) &&
                React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-primary wh-btn", onClick: () => {
                        if (onSubmit)
                            onSubmit("PUBLISH");
                    } }, t(buttonsText["PUBLISH"])))),
        status.label === statusParameters["VALIDATION_WAIT"] && (React.createElement("div", { className: "container-flex container-flex-row all-border" },
            React.createElement("div", null,
                checkAccess(AccessEnum.CREATE_EDIT_MISSION, permissions) &&
                    React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-edit wh-btn", onClick: () => {
                            if (onSubmit)
                                onSubmit("EDIT");
                        } }, t(buttonsText["EDIT"])),
                React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-blue wh-btn", onClick: () => {
                        if (onSubmit)
                            onSubmit("REFUSE_MISSION");
                    } }, t(buttonsText["DECLINE_MISSION"]))),
            React.createElement("div", null,
                React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-send wh-btn", onClick: () => {
                        if (onSubmit)
                            onSubmit("SEND_TO_CLIENT");
                    } }, t(buttonsText["SEND_TO_CLIENT"])),
                checkAccess(AccessEnum.PUBLISH_MISSION, permissions) &&
                    React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-primary wh-btn", onClick: () => {
                            if (onSubmit)
                                onSubmit("PUBLISH");
                        } }, t(buttonsText["PUBLISH_MISSION"]))))),
        status.label === statusParameters["OPEN"] && (React.createElement("div", { className: "container-flex container-flex-row" },
            checkAccess(AccessEnum.DELETE_MISSION, permissions) &&
                React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-blue wh-btn", onClick: () => {
                        if (onSubmit)
                            onSubmit("SHALLOW_DELETE");
                    } }, t(buttonsText["DELETE"])),
            checkAccess(AccessEnum.CREATE_EDIT_MISSION, permissions) &&
                React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-edit wh-btn", onClick: () => {
                        if (onSubmit)
                            onSubmit("EDIT");
                    } }, t(buttonsText["EDIT_MISSION"])))),
        status.label === statusParameters["ACTIVATION_WAIT"] && (React.createElement("div", { className: "container-flex container-flex-row" },
            checkAccess(AccessEnum.DELETE_MISSION, permissions) &&
                React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-blue wh-btn", onClick: () => {
                        if (onSubmit)
                            onSubmit("SHALLOW_DELETE");
                    } }, t(buttonsText["DELETE"])),
            checkAccess(AccessEnum.CREATE_EDIT_MISSION, permissions) &&
                React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-edit wh-btn", onClick: () => {
                        if (onSubmit)
                            onSubmit("EDIT");
                    } }, t(buttonsText["EDIT"])))),
        status.label === statusParameters["IN_PROGRESS"] && (React.createElement("div", { className: "container-flex container-flex-row" },
            checkAccess(AccessEnum.DELETE_MISSION, permissions) &&
                React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-color-blue wh-btn", onClick: () => {
                        if (onSubmit)
                            onSubmit("SHALLOW_DELETE");
                    } }, t(buttonsText["DELETE"])),
            checkAccess(AccessEnum.CLOSE_MISSION, permissions) &&
                React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-send wh-btn", onClick: () => {
                        if (onSubmit)
                            onSubmit("END_MISSION");
                    } }, t(buttonsText["PUT_END_TO_MISSION"])),
            checkAccess(AccessEnum.CREATE_EDIT_MISSION, permissions) &&
                React.createElement(Button, { "data-testid": "missionButton", className: "wh-btn-edit wh-btn", onClick: () => {
                        if (onSubmit)
                            onSubmit("EDIT");
                    } }, t(buttonsText["EDIT"]))))));
};
export default RessourceManagerButtonList;
