import React from 'react';
import ImageOverlay from "../ImageOverlay/ImageOverlay";
import { useTranslation } from "react-i18next";
import { addHTTPS } from "../../Shared/utils/utils";
const RmSnapshot = ({ picture, firstName, lastName, email, phone }) => {
    const { t } = useTranslation('missions');
    return (React.createElement("div", { className: "rmSnapshot size-small weight-light" },
        picture &&
            React.createElement(ImageOverlay, { icon: "", media_src: addHTTPS(picture.content) }),
        React.createElement("div", { className: "user-content flex-directionColumn flex-alignStart flex-justifyCenter" },
            React.createElement("span", { className: 'weight-bold lh-16' }, t('missions.rm.header')),
            React.createElement("span", { className: "user-name lh-16" }, `${firstName} ${lastName}`),
            React.createElement("span", { className: 'lh-16' }, email),
            React.createElement("span", { className: 'lh-16' }, phone))));
};
export default RmSnapshot;
