import React, { Fragment, useState } from "react";
import { Button, Popover, PopoverBody } from 'reactstrap';
import { useSelector } from "react-redux";
import { Form, Field } from "react-final-form";
import { WhValidator } from "../../../Components/WHForm/WhFields/index";
import { useTranslation } from "react-i18next";
import { durationTable } from "../../../Shared/constants";
import TagsFormArray from "../../../Components/Form/FFTags";
import arrayMutators from "final-form-arrays";
import FFCheckbox from "../../../Components/FinalForm/FFCheckbox";
import FFSelect from "../../../Components/FinalForm/FFSelect";
import FFText from "../../../Components/FinalForm/FFText";
import { NewTagsForm } from "../../../Components/Form/FFTags";
import { formMutators } from "../../../Components/FinalForm/FFTagsMutators";
import GoogleMapPlaces from "../../../Components/GoogleMap/GoogleMap";
import { FilterAsyncSelect } from "../../SearchFilter/FilterFormComponent/FilterAsyncSelect";
import { FieldDraftEditor } from "../../../Components/WHForm/WhFields/FieldDraftEditor";
const FFEditMissionRm = ({ onSubmit, close, userRole, initialValues, entities, entitiesLoaded }) => {
    const { t } = useTranslation("form");
    const sectors = useSelector((state) => state.sectors.sectors);
    const [currentCity, setCurrentCity] = useState({ city: initialValues.city, latitude: initialValues.geometry.latitude, longitude: initialValues.geometry.longitude });
    const [popoverOpen, setPopoverOpen] = useState(false);
    const togglePop = () => setPopoverOpen(!popoverOpen);
    const expTab = [
        { value: "junior", label: t("expertise.junior") },
        { value: "confirmed", label: t("expertise.confirmed") },
        { value: "expert", label: t("expertise.expert") }
    ];
    const exp = expTab.find(e => e.value === initialValues.expertise);
    const mutators = {
        setCKEditorValue: (_args, state, utils) => {
            utils.changeValue(state, "description", () => _args[0]);
        },
        setPlaces: (_args, state, utils) => {
            utils.changeValue(state, "city", () => _args[0]);
            utils.changeValue(state, "geometry.latitude", () => _args[1]);
            utils.changeValue(state, "geometry.longitude", () => _args[2]);
        }
    };
    const formatOptions = (datas, tags) => {
        return datas ? datas.filter(t => tags.findIndex(pt => pt.id === t.value) === -1) : null;
    };
    const setMaxValues = values => {
        let tag = values["tags"] ? values["tags"].length : 0;
        let maxValues = 15 - tag;
        return maxValues;
    };
    const ClientAsyncData = {
        customComponentParameters: {
            customComponentName: 'default',
            isSearchable: true,
            isMulti: false
        },
        asyncQueryParameters: {
            query: {
                api: process.env.REACT_APP_IDENTITY_GQL_URL
                    ? process.env.REACT_APP_IDENTITY_GQL_URL
                    : "",
                table: "societies",
                entity: "Society",
                list: true,
                query: {
                    fields: ["name", "id", "_id", "nbPackHelp", "nopackNoHelp", "mainContact{firstName lastName}", "affiliate"],
                    maxResults: 30,
                    page: 0,
                },
            },
            formatQueryResult: (obj) => obj,
            params: {
                type: 'elasticsearchcustomer',
                filter_name: 'name',
                defaultFilters: [
                    {
                        value: "true",
                        name: "qualified",
                    },
                    {
                        value: "true",
                        name: "main_contact_enabled"
                    }
                ],
            },
            formatResult: (obj) => obj.map((o) => {
                return ({
                    label: `${o.name}${o.affiliate ? ` - ${o.affiliate}` : ''} (${o.main_contact_lastname} ${o.main_contact_firstname})`,
                    displayLabel: `${o.name}${o.affiliate ? ` - ${o.affiliate}` : ''} (${o.main_contact_lastname} ${o.main_contact_firstname})`,
                    value: `/api/societies/${o.ref}`,
                    all: o,
                    filterName: 'name'
                });
            })
        }
    };
    const onSubmitEdit = async (values) => {
        if (values.city !== initialValues.city && values.geometry.latitude === initialValues.geometry.latitude && values.geometry.longitude === initialValues.geometry.longitude) {
            alert("Tapez une ville puis sélectionnez la dans la liste proposée.");
            return { city: "Obligatoire" };
        }
        return onSubmit(values);
    };
    return (React.createElement("div", { className: "rm-mission-edit-form sidebarBody" },
        React.createElement(Form, { mutators: { ...mutators, ...arrayMutators, ...formMutators }, onSubmit: onSubmitEdit, initialValues: { ...initialValues, expertise: exp }, render: ({ handleSubmit, values, form: { mutators } }) => {
                const maxValue = setMaxValues(values);
                console.log("Values", values);
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement("p", { className: "size-big weight-light mt-5 mb-20" }, t("createmission.description")),
                    React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                        React.createElement(FFCheckbox, { name: "public", value: values.public, label: `${t("createmission.maskpublic")}`, containerSize: "mid" }),
                        React.createElement(FFCheckbox, { name: "hiddenSociety", value: values.hiddenSociety, label: t("createmission.hiddenSociety"), containerSize: "mid" })),
                    React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" }, userRole !== "ROLE_CLIENT" &&
                        React.createElement(Field, { name: "society", validate: WhValidator.required, label: `${t("createmission.client")} *` }, props => (React.createElement(FilterAsyncSelect, { props: props, currentValue: values.society, filter: ClientAsyncData })))),
                    React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                        React.createElement(FFText, { name: "title", validate: WhValidator.required, label: `${t("createmission.objectmission")} *`, type: "text", containerSize: "big" })),
                    React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                        React.createElement(FFText, { name: "job", validate: WhValidator.required, label: `${t("createmission.job")} *`, type: "text", containerSize: "big" })),
                    React.createElement("div", { className: "castorForm-formRow mt-5 mb-5 castorForm-googlemap-container" },
                        React.createElement(Field, { name: "city", validate: WhValidator.required }, ({ input, meta: { touched, error, submitError } }) => (React.createElement(GoogleMapPlaces, { input: input, defaultCity: values.city, type: 'mission', inputClassName: "castorForm-inputContainer big googlemap-input", onUpdate: mutators.setPlaces, label: `${t("createmission.place")} *` },
                            React.createElement(Fragment, null,
                                submitError && React.createElement("span", { className: "form__form-group-error formError" }, error),
                                touched && error && (React.createElement("span", { className: "form__form-group-error formError" }, error))))))),
                    React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                        React.createElement(FFSelect, { name: "expertise", validate: WhValidator.required, label: `${t("createmission.expertise")} *`, options: [
                                { value: "junior", label: t("expertise.junior") },
                                { value: "confirmed", label: t("expertise.confirmed") },
                                { value: "expert", label: t("expertise.expert") }
                            ] })),
                    React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                        React.createElement(FFSelect, { name: "activitySector", validate: WhValidator.required, isSearchable: true, label: `${t("society.activity")} *`, options: sectors
                                ? sectors.map(s => ({ label: s.name, value: s.id }))
                                : [] })),
                    React.createElement("div", { className: "separator-v mt-20 mb-15" }),
                    React.createElement("p", { className: "size-big weight-light mt-5 mb-20" }, `${t("createmission.descriptionmission")}*`),
                    React.createElement("p", { className: "size-small weight-light secondaryColor mb-10" },
                        " ",
                        `${t("createmission.editor_text")}`),
                    React.createElement("div", { className: "castorForm-formRow ck-form-row flex-directionColumn mt-5 mb-5" },
                        React.createElement(Field, { name: "description", validate: WhValidator.required }, props => (React.createElement(FieldDraftEditor, Object.assign({}, props, { onUpdate: mutators.setCKEditorValue }))))),
                    React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                        React.createElement("p", { className: "size-big weight-light mb-20" }, t("createmission.planning"))),
                    React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                        !values.startNow && (React.createElement(FFText, { name: "startDate", validate: values.startNow ? null : WhValidator.required, label: `${t("createmission.startDate")} *`, type: "date", containerSize: "mid", componentName: "UpdateDate" })),
                        React.createElement(FFCheckbox, { name: "startNow", value: values.startNow, label: t("createmission.now"), superLabel: t("createmission.begin") })),
                    React.createElement("div", { className: "castorForm-formRow mt-5 mb-5" },
                        React.createElement(FFSelect, { name: "duration", validate: WhValidator.required, label: `${t("createmission.duration")} *`, options: durationTable(), containerSize: "mid" }),
                        React.createElement("div", { className: `castorForm-inputContainer castorForm-inputContainer--blank mid` })),
                    React.createElement("div", { className: "separator-v mt-15 mb-15" }),
                    React.createElement("p", { className: "size-big weight-light mb-20" }, "Tags"),
                    React.createElement("div", { className: "flex-row mb-15" },
                        React.createElement("p", { className: "weight-light size-small mb-0" }, t("createmission.add_tags_text")),
                        React.createElement(Button, { id: "Popover1", type: "button", className: "popoverButton" }, "?"),
                        React.createElement(Popover, { placement: "bottom", isOpen: popoverOpen, target: "Popover1", toggle: togglePop },
                            React.createElement(PopoverBody, null, t("createmission.tags_draft_text")))),
                    React.createElement("div", { className: "castorForm-formRow mb-5" },
                        React.createElement(TagsFormArray, { tags: values.tags })),
                    React.createElement("div", { className: "castorForm-formRow mb-5 tagsFormContainer" },
                        React.createElement(NewTagsForm, { maxValue: maxValue, mutator: mutators.setUpperCase, format: datas => formatOptions(datas, values.tags), values: values.tags })),
                    React.createElement("div", { className: "btn-container flex-row" },
                        React.createElement("button", { onClick: () => close(false) }, t("btn.cancel")),
                        React.createElement("button", { type: "submit" }, t("btn.register")))));
            } })));
};
export default FFEditMissionRm;
