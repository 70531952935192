import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import GraphQl from '../Shared/graphql';
import { filtersData } from '../Components/Filters/filterData';
import { formatGraphQlData } from "../Shared/rewriteArray";
import { change } from 'redux-form';
export const useStored = (defaultStored, filter_name, directLoading = true) => {
    const [stored, setStored] = useState(defaultStored);
    const [immediateLoading, setImmediateLoading] = useState(directLoading);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState(defaultStored.query.filters);
    const [otherFilters, setOtherFilters] = useState([]);
    const [headerFilters, setHeaderFilters] = useState([]);
    const [filters, setFilters] = useState([]);
    const [endCursor, setEndCursor] = useState(null);
    const [hasNextPage, setHasNextPage] = useState(false);
    const dispatch = useDispatch();
    const form = useSelector(state => state.form);
    const [data, setData] = useState(defaultStored.list ? [] : null);
    const [pageSize, setPageSize] = useState(0);
    const [pages, setPages] = useState(0);
    const [error, setError] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [firstLoaded, setFirstLoaded] = useState(false);
    const [orderFilter, setOrderFilter] = useState({ name: "", order: "" });
    useEffect(() => {
        if (immediateLoading) {
            loadData();
        }
    }, [stored.query.filters, stored.query.order, immediateLoading === true]);
    useEffect(() => {
        if (filter_name === '')
            return;
        const { headerFilters, filters } = filtersData[`${filter_name}`];
        setHeaderFilters(headerFilters);
        setFilters(filters);
    }, [filter_name]);
    useEffect(() => {
        const newStored = { ...stored };
        newStored.query.orderBy = orderFilter && orderFilter.name ? { name: orderFilter.name } : newStored.query.orderBy;
        newStored.query.order = orderFilter && orderFilter.order ? orderFilter.order : newStored.query.order;
        setStored(newStored);
    }, [orderFilter, otherFilters]);
    const replaceDefaultFilter = (filters) => {
        const newStored = { ...stored, query: { ...stored.query, filters: [...stored.query.filters] } };
        newStored.query.filters = filters;
        setStored(newStored);
    };
    const removeNullFilters = (sf) => {
        if (sf.value && sf.value.hasOwnProperty('value')) {
            return sf.value.value !== "donotfetch";
        }
        return (sf.value !== undefined && sf.value !== null);
    };
    const constructExistsFilter = (selectedFilters) => {
        let existFilter = { name: "exists", value: {} };
        const existFilters = selectedFilters.filter(sf => (sf.name === "exists" || sf.name === "exist"));
        existFilters.map((f) => {
            for (const [key, value] of Object.entries(f.value.hasOwnProperty('value') ? f.value.value : f.value)) {
                existFilter.value = {
                    ...existFilter.value,
                    [key]: value
                };
            }
        });
        return existFilter;
    };
    useEffect(() => {
        const newStored = { ...stored, query: { ...stored.query, filters: [...stored.query.filters] } };
        const selectedFiltersWithoutNull = selectedFilters.filter(sf => removeNullFilters(sf));
        const existsFilter = constructExistsFilter(selectedFiltersWithoutNull);
        const defaultFilters = selectedFilters.length > 1 ? selectedFilters.map((sf) => sf.hasOwnProperty('defaultFilter') ? sf.defaultFilter : []).reduce((acc, value) => [...acc, ...value]) : [];
        newStored.query.filters = selectedFiltersWithoutNull.filter((sf) => (sf.name !== "exists" && sf.name !== "exist")).map((sf) => {
            if (sf.value.hasOwnProperty('value')) {
                return { name: sf.name, value: sf.value.value };
            }
            else
                return sf;
        });
        if (defaultFilters.length > 0)
            newStored.query.filters = [...newStored.query.filters, ...defaultFilters];
        if (Object.entries(existsFilter.value).length > 0)
            newStored.query.filters.push(existsFilter);
        setStored(newStored);
    }, [selectedFilters]);
    const addArrayOtherFilters = (newOtherFilter) => {
        setOtherFilters(newOtherFilter);
    };
    const loadData = async () => {
        if (!firstLoaded)
            setLoaded(false);
        if (isLoading)
            return;
        setIsLoading(true);
        setRowsPerPage(0);
        try {
            const res = await GraphQl({ ...stored, query: { ...stored.query, filters: stored.query.filters.filter((f) => f.name !== "exist") } });
            if (res.pageInfo) {
                const { endCursor, hasNextPage } = res.pageInfo;
                setEndCursor(endCursor);
                setHasNextPage(hasNextPage);
            }
            setData(formatGraphQlData(res.datas));
            setPageSize(res.datas.lenght);
            setPages(Math.ceil(res.totalItem / rowsPerPage));
            setLoaded(true);
            setIsLoading(false);
            setFirstLoaded(true);
        }
        catch (queryError) {
            console.error(queryError);
            setError(queryError);
        }
        finally {
            setIsLoading(false);
            setLoaded(true);
            setFirstLoaded(true);
        }
    };
    const removeFromForm = (filterName, value) => {
        const values = form[filterName].values[filterName];
        if (Array.isArray(values)) {
            const isCityFilter = filterName.includes("city_list");
            const filterByLabel = (v) => v.label !== value;
            const filterByValue = (v) => v.value !== value;
            dispatch(change(filterName, filterName, values.filter(isCityFilter ? filterByValue : filterByLabel)));
        }
        else {
            dispatch(change(filterName, filterName, ''));
        }
    };
    const removeFilters = (filterName, value) => {
        removeFromForm(filterName, value);
        const updatedFilters = [...selectedFilters];
        const idx = updatedFilters.findIndex((f) => f.name === filterName);
        const isArray = Array.isArray(updatedFilters[idx].value);
        if (isArray) {
            updatedFilters.splice(idx, 1);
        }
        else {
            updatedFilters.splice(idx, 1);
        }
        const headerFilterIdx = headerFilters.findIndex((hf) => hf.name === filterName);
        const filterIdx = filters.findIndex((f) => f.name === filterName);
        if (headerFilterIdx > -1 && filterIdx > -1) {
            const newFilters = [...filters];
            newFilters.splice(filterIdx, 1);
            setFilters(newFilters);
        }
        setSelectedFilters(updatedFilters);
    };
    const updateFilters = (filtersToUpdate) => {
        const updatedFilters = [...selectedFilters];
        filtersToUpdate.map((filter) => {
            const idx = updatedFilters.findIndex((f) => f.name === filter.name);
            const value = form[`${filter.name}`].values[`${filter.name}`];
            if (idx > -1) {
                updatedFilters[idx].value = filter.formatFilterValue(value);
            }
            else {
                let newFilter = { name: filter.name, value: filter.formatFilterValue(value) };
                if (filter.hasOwnProperty('defaultFilter'))
                    newFilter = { ...newFilter, defaultFilter: filter.defaultFilter };
                updatedFilters.push(newFilter);
            }
        });
        setSelectedFilters(updatedFilters);
    };
    return {
        setOrderFilter,
        selectedFilters,
        stored,
        endCursor,
        hasNextPage,
        firstLoaded,
        removeFilters,
        otherFilters,
        addArrayOtherFilters,
        replaceDefaultFilter,
        isLoading,
        setImmediateLoading,
        updateFilters,
        headerFilters,
        filters,
        data,
        error,
        loadData,
        loaded,
        setLoaded,
        pages,
        pageSize,
        setStored,
        setData
    };
};
