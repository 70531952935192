import React, { useState, Fragment, useEffect } from "react";
import LoadRmList from "../../GraphQLRenderProps/LoadRms";
import { formatGraphQlData } from "../../../Shared/rewriteArray";
import { MAX } from "../../../Shared/maxResults";
import ReactTable from "react-table-6";
import { columns } from "./Columns";
import { AccessEnum, checkAccess } from "../../../Services/Access/access";
import { accessNotif } from "../../../Services/notif/notif";
import { Container } from "reactstrap";
import { useSelector } from "react-redux";
import SearchContainer from "../../../Components/SearchContainer/SearchContainer";
import { useFilter } from "../../../Hooks/useFilter";
import { GroupedFiltersList } from "../../SearchFilter/FiltersList";
import DisplaySelectedFilters from "../../../Components/Filters/DisplaySelectedFilters";
import Result from "../../../Components/FoundResult/Result";
import FFCreateManager from "./CreateManagerForm";
import Sidebar from "../../../Components/Sidebar/View";
import { useTranslation } from "react-i18next";
const RmListBody = ({ history, user, setModalOpen, data, setRequestFilters }) => {
    const { headerFilters, filters, updateFilters, currentFilters, filterRequest, removeFilters } = useFilter('managerList', 'graphql');
    useEffect(() => {
        if (!setRequestFilters)
            return;
        setRequestFilters(filterRequest);
    }, [filterRequest]);
    return (React.createElement(Fragment, null,
        React.createElement(SearchContainer, { title: "managers.title", header: true, withBtn: checkAccess(AccessEnum.ADD_MEMBER, user.permissions), textBtn: "managers.btn", iconBtn: "icon-plus_1", onClick: () => setModalOpen(true), addClassName: "blueBackground" }, headerFilters && (React.createElement("div", { className: "filterList castorForm-formRow" },
            React.createElement(GroupedFiltersList, { filters: headerFilters, currentValues: currentFilters, updateFilters: updateFilters })))),
        React.createElement(DisplaySelectedFilters, { filters: filters, headerFilters: headerFilters, selectedFilters: currentFilters, removeFilters: removeFilters }),
        React.createElement(Result, { size: data.length }),
        React.createElement(Container, null,
            React.createElement(ReactTable, { manual: true, className: "-striped -highlight RMTable", data: data, columns: columns, showPagination: false, pageSize: 0, getTrProps: (_state, rowInfo) => {
                    return {
                        onClick: () => {
                            if (!checkAccess(AccessEnum.HANDLING_RIGHT, user.permissions)) {
                                accessNotif.failed();
                                return null;
                            }
                            return history.push(`/rm/managers/${rowInfo.original._id}`);
                        }
                    };
                }, pages: 0 }))));
};
const RmList = ({ history }) => {
    const config = {
        baseConfig: {
            type: 'graphql',
            api: process.env.REACT_APP_IDENTITY_GQL_URL ? process.env.REACT_APP_IDENTITY_GQL_URL : "",
            endPoint: {
                table: "users",
                entity: "User",
                list: true,
                query: {
                    fields: ["_id", "id", "firstName", "lastName", "email", "phone"],
                    maxResults: MAX,
                    page: 0,
                },
            },
            resultKey: 'datas',
        },
        pagination: {
            type: "pages",
            pages: {
                page: 1,
                pageSize: 20,
            }
        },
        filters: [
            { value: "RM", name: "type" },
            { value: { deletedAt: false }, name: "exists" }
        ],
        uniqueFilters: [],
        formatResult: (res) => formatGraphQlData(res)
    };
    const user = useSelector((state) => state.user.data);
    const [modalOpen, setModalOpen] = useState(false);
    const { t } = useTranslation("managers");
    return (React.createElement(LoadRmList, { defaultConfig: config, keys: [], render: ({ data, setRequestFilters, create, formLoaded }) => {
            const onSubmit = (data) => {
                return create(data).then(() => {
                    setModalOpen(false);
                });
            };
            return (React.createElement("div", { className: `managers_container rightContainer rightContainer--withHeadFilters littleHeader` },
                React.createElement(RmListBody, { history: history, data: data, user: user, setRequestFilters: setRequestFilters, setModalOpen: () => setModalOpen(true) }),
                React.createElement(Sidebar, { showSidebar: modalOpen, close: setModalOpen, title: t("btn-create") },
                    React.createElement(FFCreateManager, { close: () => setModalOpen(false), onSubmit: onSubmit, isLoading: formLoaded }))));
        } }));
};
export default RmList;
