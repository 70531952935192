import React from 'react';
import { useTranslation } from "react-i18next";
const MissionRefuse = ({ canceledReason }) => {
    const { t } = useTranslation('missions');
    return (React.createElement("div", { className: 'missionrefuse' },
        React.createElement("div", { className: 'missionrefuse-header' },
            React.createElement("i", { className: 'icon-refus size-blockTitle mr-15' }),
            React.createElement("h1", null, t("missions.clientrefuse.title"))),
        React.createElement("div", { className: "missionrefuse-content size-medium" },
            React.createElement("p", null, canceledReason))));
};
export default MissionRefuse;
