import React, { useState } from "react";
import { ROLES } from "../../../constants/roles";
import FreelanceButtonsList from "./Buttonslist/FreelanceButtonsList";
import RessourceManagerButtonList from "./Buttonslist/RessourceManagerButtonList";
import ClientListButtons from "./Buttonslist/ClientButtonsList";
const MissionButtons = ({ status, userRole, permissions, userTakenCare, onSubmit, indication = null }) => {
    const [numberButtons, setNumberButtons] = useState(0);
    return (React.createElement("div", { className: `missions-btns btns-${numberButtons}` },
        userRole === ROLES.FREE && (React.createElement("div", { "data-testid": "freeLanceListButtons", className: `missions-btns btns-${numberButtons}` },
            React.createElement(FreelanceButtonsList, { status: status, onSubmit: onSubmit, indication: indication, setNumberButton: setNumberButtons }))),
        userRole === ROLES.RM && (React.createElement("div", { "data-testid": "ressourceManagerListButtons", className: `missions-btns btns-${numberButtons}` },
            React.createElement(RessourceManagerButtonList, { status: status, onSubmit: onSubmit, permissions: permissions, indication: indication, setNumberButton: setNumberButtons }))),
        userRole == ROLES.CLIENT && (React.createElement("div", { "data-testid": "clientListButtons", className: `missions-btns btns-${numberButtons}` },
            React.createElement(ClientListButtons, { status: status, userTakenCare: userTakenCare, indication: indication, onSubmit: onSubmit, setNumberButton: setNumberButtons })))));
};
export default MissionButtons;
