import saveAs from 'file-saver';
import * as session from './session';
const getFile = (documentId) => fetch(`${process.env.REACT_APP_IDENTITY_API_URL}/api/documents/files/${documentId}`, {
    mode: 'cors',
    method: 'GET',
    headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${session.getJwtToken()}`,
    },
    responseType: 'blob',
}).then(function (response) {
    return response;
})
    .then(function (myBlob) {
    myBlob.blob().then((t) => {
        var fileURL = URL.createObjectURL(t);
        window.open(fileURL);
    }).catch((e) => { console.log("myBlob::error", e); });
});
export const downloadFile = (name, documentId) => fetch(`${process.env.REACT_APP_IDENTITY_API_URL}/api/documents/files/${documentId}`, {
    mode: 'cors',
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${session.getJwtToken()}`,
    },
})
    .then(response => response.blob())
    .then(blob => saveAs(blob, name));
export default getFile;
