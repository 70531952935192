import React, { useEffect } from "react";
import { Field, Form } from "react-final-form";
import { FilterAsyncSelect } from "../../SearchFilter/FilterFormComponent/FilterAsyncSelect";
import { FILTER_ASYNC_SELECT } from "../../SearchFilter/constants";
import { isEmptyObj } from "../../../helper/isEmpty";
import { formatUserName } from "../../../helper/formatUserName";
const FreelanceField = ({ filter, values, submit }) => {
    useEffect(() => {
        if (isEmptyObj(values))
            return;
        submit(values);
    }, [values]);
    return (React.createElement(Field, { name: name }, props => {
        return React.createElement(FilterAsyncSelect, { filter: filter, currentValue: isEmptyObj(values) ? null : values, props: props });
    }));
};
const FreelanceSearch = ({ onSubmit, loadFreelance, candNumber = 0, candidatures }) => {
    let filter = {
        name: 'lastName',
        label: 'search.client',
        placeholder: 'Entrez un freelance',
        className: 'label-input filter filter-with-marin-right-small',
        filterType: FILTER_ASYNC_SELECT,
        field: 'text',
        customComponentParameters: {
            customComponentName: 'default',
            isSearchable: true,
            isMulti: false
        },
        formatFilterForApi: (value) => { return ({ name: "lastName", value: value }); },
        formatCurrent: (obj) => { return obj.value.label; },
        asyncQueryParameters: {
            query: {
                api: process.env.REACT_APP_IDENTITY_GQL_URL
                    ? process.env.REACT_APP_IDENTITY_GQL_URL
                    : "",
                table: "users",
                entity: "User",
                list: true,
                query: {
                    fields: [
                        "id",
                        "_id",
                        "lastName",
                        "firstName",
                        "precalState",
                        "profile{job, availability, tjm, expertise, qualified}",
                        "type",
                    ],
                    maxResults: 30,
                    page: 0,
                },
            },
            formatQueryResult: (obj) => obj,
            params: {
                type: 'elasticsearch',
                filter_name: 'lastName',
                defaultFilters: [
                    {
                        value: "true",
                        name: "qualified",
                    },
                    {
                        value: "true",
                        name: "enabled"
                    }
                ],
            },
            formatResult: (obj) => obj.filter((o) => candidatures.findIndex((c) => `${c.user._id}` === o.ref) === -1).map((o) => {
                return ({
                    label: formatUserName(o.firstname, o.lastname),
                    value: o.ref,
                    filterName: 'name'
                });
            }),
        }
    };
    return (React.createElement("div", { className: "freelance-search" },
        React.createElement("div", { className: "freelance-search-form" },
            React.createElement("div", null,
                React.createElement("span", null,
                    React.createElement("strong", null, candNumber),
                    candNumber > 1 ? " Profils" : " Profil")),
            React.createElement(Form, { onSubmit: onSubmit, render: ({ handleSubmit, values, form: { submit } }) => {
                    return (React.createElement("form", { onSubmit: handleSubmit },
                        React.createElement(FreelanceField, { filter: filter, values: values, submit: submit })));
                } }))));
};
export default FreelanceSearch;
