import React from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import ClientListBody from "./ClientListBody";
import LoadClientListES from "../ClientListES/LoadClientListES";
export const ClientList = ({ history }) => {
    const user = useSelector((state) => state.user.data);
    return (React.createElement(LoadClientListES, { defaultFilters: [{ name: "qualified", value: 'true' }, { name: 'main_contact_enabled', value: 'true' }], facetsConfig: [
            { name: "activity_sector_name", size: 100, sort: { value: "asc" } },
            { name: "rm_fullname", size: 100, sort: { value: "asc" } },
            { name: "city", size: 100, sort: { value: "asc" } },
        ], render: ({ totalItems, loadNextPage, hasNextPage, facets, isNextPageLoading, clientData, setRequestFilters, onSubmitClient }) => {
            return (React.createElement(ClientListBody, { filterConfig: 'graphql', facets: facets, user: user, history: history, hasNextPage: hasNextPage, totalItems: totalItems, isNextPageLoading: isNextPageLoading, loadNextPage: loadNextPage, data: clientData, onSubmitClient: onSubmitClient, setRequestFilters: setRequestFilters }));
        } }));
};
export default withRouter(ClientList);
