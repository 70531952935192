import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Container, Row, Col, Card, CardHeader, CardBody, ListGroup, ListGroupItem, ListGroupItemText, Badge, Tooltip } from "reactstrap";
import Loading from "../../../Components/Loading";
import SearchContainer from "../../../Components/SearchContainer/SearchContainer";
import FormFilter from "./FormFilter";
import Indicator from "./components/Indicator";
import Donut from "./components/Donut";
import FormWrapper from "../../../Components/Form/FormWrapper";
import exportCSV from "../../../Shared/exportCsv";
import { useStats } from "./useStats";
import { DisplayDateFilter } from "./DisplayDateFilters";
const Statistics = () => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const { t } = useTranslation("statistique");
    const { allStatsLoading, allStats, onlyFromStatsLoading, headerFilters, filters, removeMultipleFilters, updateFilters, currentFilters, removeFilters, } = useStats();
    const download = () => {
        alert("Exporter");
    };
    const onFilter = dataSubmited => {
        let newDate = {};
        if (dataSubmited.hasOwnProperty('from')) {
            newDate = {
                ...newDate,
                from: {
                    name: 'from', value: dataSubmited.from.hasOwnProperty("_isAMomentObject")
                        ? moment(dataSubmited.from)
                        : dataSubmited.from
                }
            };
        }
        if (dataSubmited.hasOwnProperty('to')) {
            newDate = {
                ...newDate,
                to: {
                    name: 'to', value: dataSubmited.to.hasOwnProperty("_isAMomentObject")
                        ? moment(dataSubmited.to)
                        : dataSubmited.to
                }
            };
        }
        updateFilters(newDate, null, true);
    };
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const exportFile = () => {
        exportCSV('/api/kpis/export', 'statistiques', filters);
    };
    const formatFilters = () => {
        let newFilters = {};
        currentFilters.map((f) => {
            newFilters = {
                ...newFilters,
                [f.name]: f.value.value
            };
        });
        return newFilters;
    };
    return (React.createElement("div", { className: `statistics_container rightContainer rightContainer--withHeadFilters littleHeader` },
        React.createElement(SearchContainer, { title: "statistics.title", withBtn: true, addClassName: "blueBackground", textBtn: "statistics.btn-export", iconBtn: "icon-download", onClick: download },
            React.createElement(FormWrapper, { onSubmit: onFilter, form: "StatisticFormFilter", initialValues: formatFilters(), key: JSON.stringify(formatFilters()) },
                React.createElement(FormFilter, { initialValues: formatFilters() }))),
        React.createElement(DisplayDateFilter, { selectedFilters: currentFilters, headerFilters: headerFilters, onRemove: removeFilters, onRemoveMultiple: removeMultipleFilters }),
        React.createElement(Container, { className: "little" },
            (onlyFromStatsLoading || allStatsLoading) && React.createElement(Loading, { className: "load-zIndexMin" }),
            React.createElement("div", { className: "orderFilterContainer" },
                React.createElement("span", { className: "exportButton littleTextButton ml-auto", onClick: () => exportFile() }, t("export.btn"))),
            React.createElement("div", { className: "bloc" },
                React.createElement("h2", null, t("costumer-title")),
                React.createElement(Row, null,
                    React.createElement(Col, { md: 4 },
                        React.createElement(Indicator, { number: allStats.costumer.new, label: "indicator.new-subscribe", color: "success", icon: 'ml-1 icon-calendrier dateBadge-orangeColor' })),
                    React.createElement(Col, { md: 4 },
                        React.createElement(Indicator, { number: allStats.costumer.total, label: "indicator.costumer-nbr", color: "black", icon: 'ml-1 icon-calendrier dateBadge-greenColor' })),
                    React.createElement(Col, { md: 4 },
                        React.createElement(Indicator, { number: allStats.costumer.deleted, label: "indicator.costumer-deleted", color: "danger", icon: 'ml-1 icon-calendrier dateBadge-orangeColor' })))),
            React.createElement("div", { className: "bloc" },
                React.createElement("h2", null, t("freelance-title")),
                React.createElement(Row, null,
                    React.createElement(Col, { md: 4 },
                        React.createElement(Indicator, { number: allStats.freelance.new, label: "indicator.new-subscribe", color: "success", icon: 'ml-1 icon-calendrier dateBadge-orangeColor' })),
                    React.createElement(Col, { md: 4 },
                        React.createElement(Indicator, { number: allStats.freelance.total, label: "indicator.freelance-nbr", color: "black", icon: 'ml-1 icon-calendrier dateBadge-greenColor' })),
                    React.createElement(Col, { md: 4 },
                        React.createElement(Indicator, { number: allStats.freelance.deleted, label: "indicator.account-deleted", color: "danger", icon: 'ml-1 icon-calendrier dateBadge-orangeColor' })))),
            React.createElement("div", { className: "bloc missionStatistiquesCard" },
                React.createElement("h2", null, t("mission-title")),
                React.createElement(Indicator, { number: allStats.totalOnDate, label: "indicator.totalMission", color: "success", icon: 'ml-1 icon-calendrier dateBadge-orangeColor ' })),
            React.createElement("div", { className: "bloc" },
                React.createElement("h2", null, t("status-mission-title")),
                React.createElement(Row, null,
                    React.createElement(Col, { md: 6 },
                        React.createElement(Card, null,
                            React.createElement(CardHeader, null,
                                React.createElement("div", { className: "card-title" }, t("canceled-missions"))),
                            React.createElement(CardBody, null,
                                React.createElement(ListGroup, { className: "missions-list" }, allStats.canceledMissions.map((el, index) => (React.createElement(ListGroupItem, { key: index },
                                    React.createElement(Badge, { className: "badge--stat op-" + (10 - index) * 10 }, el.nbr),
                                    React.createElement(ListGroupItemText, null, el.label))))))),
                        React.createElement(Card, null,
                            React.createElement(CardHeader, null,
                                React.createElement("div", { className: "card-title" }, t("waiting-missions"))),
                            React.createElement(CardBody, null,
                                React.createElement(ListGroup, { className: "missions-list missions-wait" }, allStats.waitingMission.map((el, index) => {
                                    const labelSplited = el.label.split('&');
                                    return (React.createElement(ListGroupItem, { key: index },
                                        React.createElement(Badge, { className: "badge--stat op-" + (10 - index) * 10 }, el.nbr),
                                        labelSplited.length === 2 ?
                                            React.createElement("div", { className: "statistique-nextline", style: { margin: "0", padding: "0" } }, labelSplited.map((label) => React.createElement(ListGroupItemText, null, label))) : React.createElement(ListGroupItemText, null, el.label)));
                                }))))),
                    React.createElement(Col, { md: 6 },
                        React.createElement(Card, null,
                            React.createElement(CardHeader, null,
                                React.createElement("div", { className: "card-title flex flex-alignCenter" },
                                    React.createElement("p", null, t("total-status")),
                                    React.createElement("p", { className: "circle circle-tiny circle-black ml-auto size-tiny", id: "tooltipDonut" }, "i"),
                                    React.createElement(Tooltip, { placement: "right", isOpen: tooltipOpen, target: "tooltipDonut", toggle: toggle, trigger: "click", hideArrow: "true" }, "Pour toutes questions, nous vous invitons \u00E0 prendre contact avec votre Resource Manager."))),
                            React.createElement("div", { className: "stats-donut" },
                                React.createElement(Donut, { data: allStats })))))))));
};
export default Statistics;
