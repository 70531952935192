import React, { Fragment } from "react";
import { Element } from "react-scroll";
import FreelanceAbout from "../../../../Components/Freelance/about";
import FreelanceStudies from "../../../../Components/Freelance/studies";
import FreelanceReferences from "../../../../Components/Freelance/Reference/referenceContainer";
import FreelanceSkills from "../../../../Components/Freelance/Skill/skills";
import FreelancePortfolio from "../../../../Components/Freelance/Portfolio/portfolioContainer";
import { useTranslation } from "react-i18next";
export const Main = ({ data, loadData }) => {
    const { t } = useTranslation("freelance");
    return (React.createElement(Fragment, null,
        React.createElement(Element, { name: "about", className: "roundBlocksContainer roundBlocksContainer--oneCol" },
            React.createElement(FreelanceAbout, { data: data, disabled: false })),
        React.createElement(Element, { name: "studies", className: "roundBlocksContainer roundBlocksContainer--oneCol" }, data.profile && (React.createElement(FreelanceStudies, { profile: data.profile, title: t("studies.title_A"), callback: loadData }))),
        React.createElement(Element, { name: "skill", className: "roundBlocksContainer roundBlocksContainer--oneCol" },
            React.createElement(FreelanceSkills, { profile: data.profile, callback: loadData, user: { roles: ["ROLE_FL"] } })),
        React.createElement(Element, { name: "references" }, data.profile && React.createElement(FreelanceReferences, { user: data })),
        React.createElement(Element, { name: "portfolio" }, data.profile && (React.createElement(FreelancePortfolio, { user: data, title: t("portefolio.title_A") })))));
};
export default Main;
