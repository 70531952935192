import { createAction } from 'redux-actions';
import { SET_NAV_NUMBERS, SET_NEW_FREELANCES_NUMBER, SET_NEW_VALIDATE_MISSION_NUMBER } from './constant.js';
import GraphQl from '../../Shared/graphql';
import { MAX } from "../../Shared/maxResults";
export const setNewClientsNumber = createAction(SET_NAV_NUMBERS, (data) => (data));
export const setNewFreelanceNumber = createAction(SET_NEW_FREELANCES_NUMBER, (data) => (data));
export const setValidateMissionNumber = createAction(SET_NEW_VALIDATE_MISSION_NUMBER, (data) => (data));
const getData = async (graphQlConfig) => {
    const res = await GraphQl(graphQlConfig);
    return res.totalItem;
};
export const setNewClientsNumberThunk = (storeConfig) => (dispatch) => {
    return getData(storeConfig).then((res) => {
        dispatch(setNewClientsNumber(res));
    });
};
export const setFreelanceNumberThunk = (storeConfig) => (dispatch) => {
    return getData(storeConfig).then((res) => {
        dispatch(setNewFreelanceNumber(res));
    });
};
export const setValidateMissionNumberThunk = (storeConfig) => (dispatch) => {
    return getData(storeConfig).then((res) => {
        dispatch(setValidateMissionNumber(res));
    });
};
const SOCIETYSTORED = {
    api: process.env.REACT_APP_IDENTITY_GQL_URL
        ? process.env.REACT_APP_IDENTITY_GQL_URL
        : "",
    table: "societies",
    entity: "Society",
    list: true,
    query: {
        fields: ['_id'],
        maxResults: MAX,
        page: 0,
        filters: [
            {
                name: "mainContact_type",
                value: "CLIENT",
            },
            {
                value: false,
                name: "qualified",
            },
        ],
    },
};
const USERSTORED = {
    api: process.env.REACT_APP_IDENTITY_GQL_URL
        ? process.env.REACT_APP_IDENTITY_GQL_URL
        : "",
    table: "users",
    entity: "User",
    list: true,
    query: {
        fields: ["_id"],
        maxResults: MAX,
        page: 0,
        filters: [
            {
                value: false,
                name: "profile_qualified",
            },
            {
                name: "type",
                value: "FREELANCE",
            },
        ],
    },
};
let MISSIONSTORED = {
    api: process.env.REACT_APP_IDENTITY_GQL_URL ? process.env.REACT_APP_IDENTITY_GQL_URL : "",
    table: "missions",
    entity: "Mission",
    list: true,
    query: {
        fields: ["id"],
        maxResults: MAX,
        page: 0,
        filters: [
            { name: "candidatures_statusFl", value: "1200" }, { name: "candidatures_statusClient", value: "accepter" }
        ],
    },
};
export const fetchNavParams = () => (dispatch) => {
    dispatch(setNewClientsNumberThunk(SOCIETYSTORED)),
        dispatch(setFreelanceNumberThunk(USERSTORED));
    dispatch(setValidateMissionNumberThunk(MISSIONSTORED));
};
