import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Field, Form } from "react-final-form";
import FFTextArea from "../../../Components/FinalForm/FFTextArea";
import { WhFieldCheckbox } from "../../../Components/WHForm/WhFields";
const FFDeleteMissionClient = ({ onSubmit, close }) => {
    const { t } = useTranslation("missions");
    const mutators = {
        unsetAll: (_args, state, utils) => {
            utils.changeValue(state, "need", () => false);
            utils.changeValue(state, "more", () => false);
            utils.changeValue(state, "other", () => false);
        },
        setRadioBox: (_args, state, utils) => {
            utils.changeValue(state, _args[0], () => true);
        }
    };
    return (React.createElement("div", { className: "rm-mission-edit-form sidebarBody delete-mission-client" },
        React.createElement(Form, { mutators: mutators, onSubmit: onSubmit, initialValues: { need: false, more: false, other: false }, render: ({ handleSubmit, values, form: { mutators } }) => {
                useEffect(() => {
                    if (values.need) {
                        mutators.unsetAll();
                        mutators.setRadioBox("need");
                    }
                }, [values.need]);
                useEffect(() => {
                    if (values.more) {
                        mutators.unsetAll();
                        mutators.setRadioBox("more");
                    }
                }, [values.more]);
                useEffect(() => {
                    if (values.other) {
                        mutators.unsetAll();
                        mutators.setRadioBox("other");
                    }
                }, [values.other]);
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement("div", { className: "form-content" },
                        React.createElement(Field, { component: WhFieldCheckbox, value: values.need ? values.need : false, label: t("missions.endMission.need"), name: `need`, type: "radio", containerSize: "mid mb-15" }),
                        React.createElement(Field, { component: WhFieldCheckbox, value: values.more ? values.more : false, label: t("missions.endMission.more"), name: `more`, type: "radio", containerSize: "mid mb-15" }),
                        React.createElement(Field, { component: WhFieldCheckbox, value: values.other ? values.other : false, label: t("missions.endMission.other"), name: `other`, type: "radio", containerSize: "mid mb-15" }),
                        values.other && (React.createElement(FFTextArea, { name: "comment", label: t("missions.endMission.description") }))),
                    React.createElement("div", { className: "btn-container flex-row" },
                        React.createElement("button", { onClick: () => close(false) }, t("missions.endMission.cancel")),
                        React.createElement("button", { type: "submit" }, t("missions.endMission.confirm")))));
            } })));
};
export default FFDeleteMissionClient;
