import React, { Fragment } from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import GraphQl from "../../../Shared/graphql";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AsyncCreatableSelect from "react-select/async-creatable";
const FormatOptionSector = props => {
    const handleChange = () => {
        const isChecked = props.selectProps.values.findIndex(v => v.label === props.data.label) >
            -1;
        props.selectProps.onChange(props.data, isChecked);
    };
    const isChecked = props.selectProps.values.findIndex(v => v.label === props.data.label) > -1;
    return (React.createElement("div", { style: { display: "flex" }, className: "formatoption-sector-container", onClick: handleChange },
        React.createElement("div", { className: isChecked
                ? "formatoption-checkbox formatoption-checkbox-selected"
                : "formatoption-checkbox" }),
        React.createElement("div", { className: `formatoption-label` }, props.label)));
};
const FormatOptionLocation = props => {
    const handleChange = () => {
        const isChecked = props.selectProps.values.findIndex(v => v.label === props.data.label) >
            -1;
        props.selectProps.onChange(props.data, isChecked);
    };
    const isChecked = props.selectProps.values.findIndex(v => v.label === props.data.label) > -1;
    return (React.createElement("div", { style: { display: "flex" }, className: "formatoption-sector-container formatoption-sector-container--location", onClick: handleChange },
        React.createElement("div", { className: isChecked
                ? "formatoption-checkbox formatoption-checkbox-selected"
                : "formatoption-checkbox" }),
        React.createElement("div", { className: `formatoption-label` }, props.label)));
};
const FormatUser = props => {
    return (React.createElement("div", { style: { display: "flex" }, className: "formatoption-sector-container formatoption-sector-container--location" },
        React.createElement("div", { className: `formatoption-label` }, props.label.display)));
};
const FormatLabelMultiple = props => {
    if (props.selectProps.values.length === 0)
        return (React.createElement("div", { className: `displaySelected` },
            React.createElement("div", { className: "selectName" }, ``),
            React.createElement("div", { className: "select-dropdown noShowDropdown" }, props.children)));
    return (React.createElement("div", { className: `displaySelected` },
        React.createElement("div", { className: "selectName" }, `${props.selectProps.name}-${props.selectProps.values.length}`),
        React.createElement("div", { className: "select-dropdown noShowDropdown" }, props.children)));
};
const FormatLabel = props => {
    if (props.selectProps.values && props.selectProps.values.length === 0)
        return (React.createElement("div", { className: `displaySelected` },
            React.createElement("div", { className: "selectName" }, `${props.selectProps.name}`),
            React.createElement("div", { className: `noShowDropdown` }, props.children)));
    return (React.createElement("div", { className: `displaySelected` },
        React.createElement("div", { className: "selectName" }, `${props.selectProps.values ? props.selectProps.values.label : ""}`),
        React.createElement("div", { className: `noShowDropdown` }, props.children)));
};
const get = (p, o) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);
const formatWithParenthesis = (objectField, d) => {
    let label = "";
    let filter = "";
    objectField.map((of, idx) => {
        if (idx === 0) {
            label += get(of.split("."), d);
            filter = get(of.split("."), d);
        }
        else if (idx === 1)
            label += ` (${get(of.split("."), d)}`;
        else
            label += ` ${get(of.split("."), d)}`;
    });
    label += ")";
    return { label, filter };
};
const formatWithoutParenthesis = (objectField, d) => {
    let label = "";
    let filter = "";
    objectField.map((of, idx) => {
        if (idx === 0) {
            label += get(of.split("."), d);
            filter = get(of.split("."), d);
        }
        else if (idx === 1)
            label += ` ${get(of.split("."), d)}`;
        else
            label += ` ${get(of.split("."), d)}`;
    });
    return { label, filter };
};
const formatArray = (arr, filtersToUpdate) => {
    return arr.map(d => {
        if (Array.isArray(filtersToUpdate.objectField)) {
            if (filtersToUpdate.objectField.length > 0 &&
                filtersToUpdate.objectField[0] === "^(") {
                let { label, filter } = formatWithoutParenthesis(filtersToUpdate.objectField.slice(1), d);
                return {
                    label: label,
                    value: get(filtersToUpdate.field.split("."), d),
                    filter: filter
                };
            }
            else {
                let { label, filter } = formatWithParenthesis(filtersToUpdate.objectField, d);
                return {
                    label: label,
                    value: get(filtersToUpdate.field.split("."), d),
                    filter: filter
                };
            }
        }
        return {
            label: get(filtersToUpdate.objectField.split("."), d),
            value: get(filtersToUpdate.field.split("."), d)
        };
    });
};
const asyncLoad = async (value, params) => {
    const updatedParams = { ...params };
    const { filtersToUpdate, ...newQuery } = params.query;
    const updatedFilters = [...newQuery.filters];
    if (value && filtersToUpdate && filtersToUpdate.field)
        updatedFilters.push({ name: filtersToUpdate.field, value: value });
    newQuery.filters = updatedFilters;
    updatedParams.query = newQuery;
    const res = await GraphQl(updatedParams);
    const data = formatArray(res.datas, filtersToUpdate);
    const unique = [...new Map(data.filter(data => data['value'] !== 'none').map(data => [data['label'], data])).values()];
    return [...unique];
};
export const FieldAsyncSelect = ({ valueField, labelField, value, disabled, values, customComponent, className, classNamePrefix, name, isSearchable, onChange, asyncParameters, isMulti }) => {
    if (!valueField)
        valueField = "value";
    if (!labelField)
        labelField = "name";
    const handleChange = (value, toRemove) => {
        if (isMulti) {
            const updatedValues = [...values];
            if (toRemove) {
                onChange([]);
                return;
            }
            updatedValues.push(value);
            onChange(updatedValues);
        }
        else {
            onChange(value);
        }
    };
    const SwitchComponent = props => {
        return {
            location: React.createElement(FormatOptionLocation, Object.assign({}, props)),
            activity_sector: React.createElement(FormatOptionSector, Object.assign({}, props)),
            default: React.createElement(FormatOptionSector, Object.assign({}, props)),
            user: React.createElement(FormatUser, Object.assign({}, props))
        }[props.selectProps.customComponent];
    };
    const { t } = useTranslation("common");
    return (React.createElement(AsyncSelect, { name: name, isMulti: isMulti, onChange: handleChange, hideSelectedOptions: false, value: value, onMenuClose: () => {
            console.log("onMenuClose::");
        }, isSearchable: isSearchable, customComponent: customComponent, components: customComponent
            ? isMulti
                ? {
                    ValueContainer: FormatLabelMultiple,
                    Option: props => SwitchComponent(props)
                }
                : {
                    ValueContainer: FormatLabel,
                    Option: props => SwitchComponent(props)
                }
            : {}, defaultOptions: true, clearable: false, closeOnSelect: true, isDisabled: disabled, closeMenuOnSelect: true, values: values, onSelectResetsInput: false, className: `${className}`, classNamePrefix: `react-select ${classNamePrefix}`, cacheOptions: true, placeholder: t("search_2"), loadOptions: value => asyncLoad(value, asyncParameters) }));
};
export const FieldSelect = ({ valueField, labelField, placeholder, options, onChange, value, disabled, selectComponent, isMulti }) => {
    if (!valueField)
        valueField = "value";
    if (!labelField)
        labelField = "name";
    const handleChange = value => {
        if (value.hasOwnProperty("value"))
            onChange(value);
        else
            onChange(value);
    };
    return (React.createElement(Select, { name: name, value: value, isDisabled: disabled, components: selectComponent, onChange: handleChange, options: options, clearable: false, closeOnSelect: false, removeSelected: false, className: "react-select", placeholder: placeholder, classNamePrefix: "react-select", isMulti: isMulti }));
};
export const CreatableSelectField = ({ valueField, labelField, placeholder, onChange, value, disabled, isMulti, format, maxValue, asyncParameters, values = [], }) => {
    if (!valueField)
        valueField = "value";
    if (!labelField)
        labelField = "name";
    const { t } = useTranslation("common");
    let errorMessage = t("no_option_select");
    const promiseOptions = async (inputValue, asyncParameters, format = null) => {
        let datas = null;
        let filters = [];
        if (asyncParameters.table === 'tags' && inputValue) {
            filters = [{ name: 'name', value: inputValue }];
        }
        const res = await GraphQl({ ...asyncParameters,
            query: {
                ...asyncParameters.query,
                filters: asyncParameters.query.filters ? [...asyncParameters.query.filters].concat(filters) : filters
            }
        });
        datas = res.datas.map(s => ({ label: s.name, value: s.id }));
        let data = format ? format(datas) : datas;
        return data;
    };
    const formatCreateLabelCreator = value => {
        return t("create_tag") + value;
    };
    const isValidNewOption = (inputValue, selectValue) => {
        if (inputValue.length > 0 && !selectValue.length < maxValue) {
            errorMessage = t("max_option_select");
        }
        return inputValue.length > 0 && selectValue.length < maxValue;
    };
    const Menu = props => {
        const optionSelectedLength = props.getValue().length || 0;
        return (React.createElement(components.Menu, Object.assign({}, props), maxValue && optionSelectedLength < maxValue ? (props.children) : (React.createElement("div", { style: { margin: 15 } }, t("max_option_select")))));
    };
    return (React.createElement(AsyncCreatableSelect, { cacheOptions: true, defaultOptions: true, name: name, value: value, isDisabled: disabled, onChange: onChange, className: "react-select", placeholder: placeholder, classNamePrefix: "react-select", isMulti: isMulti, isClearable: true, formatCreateLabel: formatCreateLabelCreator, key: values, components: { Menu }, isValidNewOption: inputValue => maxValue ? isValidNewOption(inputValue, value) : true, noOptionsMessage: () => errorMessage, loadOptions: inputValue => promiseOptions(inputValue, asyncParameters, format) }));
};
export const renderFieldSelect = ({ valueField, labelField, placeholder, className = "", options, disabled, selectComponent, asyncParameters, customComponent, multiple, onChange, label, isSearchable, input, meta: { touched, error }, values, type, containerSize = "mid", creatable, maxValue, format }) => {
    if (!valueField)
        valueField = "value";
    if (!labelField)
        labelField = "name";
    const state = useSelector(state => state);
    const get = (p, o) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);
    return (React.createElement("div", { className: `castorForm-inputContainer select-container ${containerSize} ${type} ${className ? className : ""}` },
        asyncParameters && !creatable ? (React.createElement(FieldAsyncSelect, Object.assign({}, input, { name: label, disabled: disabled ? disabled : false, values: values, isSearchable: isSearchable, selectComponent: selectComponent, customComponent: customComponent, asyncParameters: asyncParameters, isMulti: multiple, options: options, placeholder: placeholder }))) : (React.createElement(Fragment, null, creatable ? (React.createElement(CreatableSelectField, Object.assign({}, input, { name: label, disabled: disabled ? disabled : false, values: values, customComponent: customComponent, selectComponent: selectComponent, isMulti: multiple, creatable: creatable, options: options.redux ? get(options.params.split("."), state) : options, placeholder: placeholder, onChange: onChange, maxValue: maxValue, asyncParameters: asyncParameters, format: format }))) : (React.createElement(FieldSelect, Object.assign({}, input, { name: label, disabled: disabled ? disabled : false, values: values, customComponent: customComponent, selectComponent: selectComponent, isMulti: multiple, options: options.redux ? get(options.params.split("."), state) : options, placeholder: placeholder, format: format }))))),
        React.createElement("label", { className: "selectLabel" }, label),
        type === "length" && React.createElement("span", { className: "icon-attente inputIcon" }),
        touched && error && (React.createElement("span", { className: "form__form-group-error formError" }, error))));
};
export default renderFieldSelect;
