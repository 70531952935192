import { useEffect, useState } from "react";
import ElasticService from "../Services/ElasticService";
import { CLIENTS } from "../Services/contants";
import { EsFormatV2 } from "../Shared/utils/esformat";
const maxPageResult = 20;
export const useStoredElasticSearchClient = (defaultFilters = [], facetsConfig, sortConfig = null) => {
    const [baseFilters] = useState(defaultFilters);
    const [facets, setFacets] = useState(null);
    const [fireUseEffect, setFireUseEffect] = useState(Math.random());
    const [data, setData] = useState(null);
    const [sort, setSort] = useState(sortConfig);
    const [isLoading, setIsLoading] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [isNextPageLoading, setIsNextPageLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [requestFilters, setRequestFilters] = useState([]);
    const [defaultFiltersOverride, setDefaultFiltersOverride] = useState(null);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const EsFormat = obj => {
        const { _meta, ...objWithoutMeta } = obj;
        let updatedObj = { ...objWithoutMeta };
        for (const prop in objWithoutMeta) {
            updatedObj = {
                ...updatedObj,
                [prop]: objWithoutMeta[prop].raw
            };
        }
        return updatedObj;
    };
    const getFacets = () => {
        let newFacets = {};
        facetsConfig.map((f) => {
            newFacets = {
                ...newFacets,
                [`${f.name}`]: { type: "value", size: f.size, sort: f.sort }
            };
        });
        return newFacets;
    };
    const updateSort = sort => {
        setPage(1);
        setFireUseEffect(Math.random());
        setSort(sort);
    };
    const getFilters = () => {
        const allFilters = defaultFiltersOverride
            ? [
                ...requestFilters.filter(f => f.name !== "query"),
                ...defaultFiltersOverride
            ]
            : [...requestFilters.filter(f => f.name !== "query"), ...baseFilters];
        let addedFilters = [];
        let Allfilters = allFilters
            .filter(f => !f.hasOwnProperty("type"))
            .map((f) => {
            if (f.hasOwnProperty("addedFilters")) {
                f.addedFilters.map(af => {
                    addedFilters.push({ [`${af.name}`]: af.value });
                });
            }
            return { [`${f.name}`]: f.value };
        });
        const NoneFilters = allFilters
            .filter(f => f.hasOwnProperty("type") && f.type === "none")
            .map((f) => ({
            [`${f.name}`]: f.value
        }));
        const AnyFilters = allFilters
            .filter(f => f.hasOwnProperty("type") && f.type === "any")
            .map((f) => ({
            [`${f.name}`]: f.value
        }));
        return {
            all: Allfilters.concat(addedFilters),
            none: NoneFilters,
            any: AnyFilters
        };
    };
    const getQuery = () => {
        const idx = requestFilters.findIndex(f => f.name === "query");
        if (idx === -1)
            return "";
        return requestFilters[idx].value;
    };
    const loadData = async () => {
        setIsLoading(true);
        if (page > 1)
            setIsNextPageLoading(true);
        const clientService = new ElasticService(CLIENTS);
        let query = getQuery();
        let req = {
            query: query,
            filters: getFilters(),
            page: { current: page, size: maxPageResult }
        };
        if (facetsConfig && facetsConfig.length > 0) {
            req = { ...req, facets: getFacets() };
        }
        if (requestFilters.length === 0 && sort) {
            req = {
                ...req,
                facets: getFacets(),
                sort: { ...sort }
            };
        }
        try {
            const result = await clientService.search(req);
            if (process.env.REACT_APP_ELASTIC_VER === "V1") {
                const { current, total_pages, total_results } = result.meta.page;
                setCurrentData(result.meta.page);
                setFacets(result.facets ? result.facets : null);
                const newResult = result.results.map(r => EsFormat(r));
                setData(data && page > 1 ? [...data, ...newResult] : newResult);
                setTotalItems(total_results);
                setHasNextPage(current < total_pages);
                setIsLoading(false);
                if (page > 1)
                    setIsNextPageLoading(false);
            }
            else {
                const newResult = result.hits.hits.map(r => EsFormatV2(r));
                const current = page;
                const total_results = result.hits.total.value;
                const total_pages = Math.ceil(total_results / maxPageResult);
                let updatedFacets = {};
                result.hasOwnProperty("aggregations") &&
                    Object.keys(result.aggregations).map(k => {
                        updatedFacets = {
                            ...updatedFacets,
                            [`${k}`]: [
                                {
                                    type: "value",
                                    data: result.aggregations[k].buckets.map(b => ({
                                        value: b.key
                                    }))
                                }
                            ]
                        };
                    });
                setFacets(Object.keys(updatedFacets).length > 0 ? updatedFacets : null);
                setData(data && page > 1 ? [...data, ...newResult] : newResult);
                setTotalItems(total_results);
                setHasNextPage(current < total_pages);
                setIsLoading(false);
                if (page > 1)
                    setIsNextPageLoading(false);
            }
        }
        catch (e) {
            setIsLoading(false);
            setHasNextPage(false);
            setIsNextPageLoading(false);
        }
    };
    const loadNextPage = () => {
        if (hasNextPage) {
            setPage(page + 1);
            setFireUseEffect(Math.random());
        }
    };
    const reloadData = () => {
        setPage(1);
        setFireUseEffect(Math.random());
    };
    useEffect(() => {
        setPage(1);
        setFireUseEffect(Math.random());
    }, [requestFilters]);
    useEffect(() => {
        setPage(1);
        setFireUseEffect(Math.random());
    }, [defaultFiltersOverride]);
    useEffect(() => {
        (async function () {
            await loadData();
        })();
    }, [fireUseEffect]);
    useEffect(() => {
        if (!currentData)
            return;
    }, [currentData]);
    return {
        data,
        isLoading,
        totalItems,
        updateSort,
        setDefaultFiltersOverride,
        hasNextPage,
        facets,
        currentData,
        isNextPageLoading,
        setRequestFilters,
        loadNextPage,
        loadData,
        reloadData
    };
};
