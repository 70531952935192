import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";
export const CounterElement = ({ number, cssType, switchLightGray }) => {
    return React.createElement("div", { className: `counterElement counterElement-${cssType}` },
        React.createElement("span", { className: switchLightGray ? 'font-color-lightgray' : 'font-color-darkblue' }, number));
};
export const IncBtn = ({ type, onClick }) => {
    return (React.createElement("div", { onClick: (_e) => onClick(type === '+' ? 1 : -1), className: 'counter-btn flex-row flex-row-align-items-center flex-row-center' },
        React.createElement("span", null, type)));
};
export const CounterFooter = ({ onUpdate, type, footerNumber }) => {
    const [value, setValue] = useState('00');
    const { t } = useTranslation('counter');
    useEffect(() => {
        setValue(footerNumber.value);
    }, [footerNumber]);
    const inputChange = (e) => {
        const value = parseInt(e.target.value);
        if (value) {
            setValue(value < 10 ? '0' + value % 10 : value.toString());
        }
        else
            setValue('');
    };
    const onKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (onUpdate)
                onUpdate(parseInt(value), type);
        }
    };
    return React.createElement("div", { className: 'counterFooter flex-row flex-row-align-items-center flex-row-center' },
        React.createElement("span", null, t('counter.add')),
        React.createElement("div", { className: 'input' },
            React.createElement(Input, { placeholder: '00', type: "text", step: "1", pattern: "[0-9]+", onKeyPress: onKeyPress, onChange: inputChange, value: value, className: parseInt(value) > 0 ? 'counter-white' : '' })),
        React.createElement("span", null, "missions"));
};
export const CounterHeader = ({ subtitle }) => {
    const { t } = useTranslation('counter');
    return React.createElement("div", { className: 'counterHeader ' },
        React.createElement("div", { className: 'header' },
            React.createElement("span", null, t('counter.left_missions'))),
        React.createElement("div", { className: 'subtitle' },
            React.createElement("span", null, t(subtitle))));
};
