import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-xhr-backend';
i18n.use(Backend).use(initReactI18next).init({
    fallbackLng: 'en',
    initImmediate: false,
    preload: ["en", "fr"],
    lng: 'fr',
    react: {
        useSuspense: false
    },
    debug: false,
    interpolation: {
        escapeValue: false
    }
}).then(() => {
    console.log("i18 has been load");
});
export default i18n;
