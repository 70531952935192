import React, { Fragment, useState } from "react";
import DatePicker from "../DatePicker";
import DateTimePicker from "../DateTimePicker";
export const PasswordField = ({ disabled, placeholder, input }) => {
    const [fieldType, setFieldType] = useState("password");
    const [iconClass, setIconClass] = useState("icon-oeil");
    const showPassword = () => {
        if (fieldType === "password") {
            setFieldType("text"),
                setIconClass("icon-oeil-ferme");
        }
        else {
            setFieldType("password"),
                setIconClass("icon-oeil");
        }
    };
    return (React.createElement(Fragment, null,
        React.createElement("input", Object.assign({ type: fieldType, disabled: disabled, placeholder: placeholder }, input)),
        React.createElement("span", { className: `${iconClass} showPassword size-big`, onClick: () => showPassword() })));
};
const FieldTxt = (props) => {
    const returnComponent = () => {
        if (type === "date")
            return DatePicker;
        if (type === "datetime")
            return DateTimePicker;
        return "input";
    };
    const returnType = () => {
        if (!props.type)
            return "text";
        if (["date", "datetime"].includes(props.type))
            return "text";
        return props.type;
    };
    const { input, label, iconbefore, iconafter, dateValue, componentName = null, btnbefore, btnafter, type, withMinDate = false, minDate, placeholder, className = '', disabled = false, help, meta: { touched, error }, containerSize = "mid", iconDate = true, maxLength, size = '' } = props;
    return (React.createElement("div", { className: `castorForm-inputContainer ${containerSize} ${className}` },
        iconbefore ? React.createElement("label", { className: "ml-30 ml-30Mobile" }, label) : React.createElement("label", null, label),
        iconbefore && React.createElement(Fragment, null, props.iconbefore),
        btnbefore && btnbefore(),
        returnComponent() === "input" && type === "password" && (React.createElement(PasswordField, { disabled: disabled, placeholder: placeholder, input: input })),
        returnComponent() === "input" && type === "text" && (React.createElement("input", Object.assign({ type: returnType(), disabled: disabled, placeholder: placeholder, maxLength: maxLength, size: size }, input))),
        returnComponent() === "input" && type === "number" && (React.createElement("input", Object.assign({ type: returnType(), disabled: disabled, placeholder: placeholder, maxLength: maxLength }, input))),
        returnComponent() === "input" && type === "textarea" && (React.createElement("textarea", Object.assign({ type: returnType(), disabled: disabled, placeholder: placeholder, maxLength: maxLength }, input))),
        type === "date" &&
            React.createElement(Fragment, null, withMinDate ?
                React.createElement(DatePicker, { className: className, input: input, value: dateValue, minDate: minDate, disabled: disabled, componentName: componentName }) :
                React.createElement(DatePicker, { className: className, value: dateValue, input: input, disabled: disabled, componentName: componentName })),
        (type === "date" || type === "datetime") && iconDate == true && (React.createElement("span", { className: "icon-date inputIcon" })),
        iconafter && React.createElement("div", { className: "iconAfter" }, iconafter),
        btnafter && btnafter(),
        help && React.createElement("span", null, help),
        touched && error && (React.createElement("span", { className: "form__form-group-error formError" }, error))));
};
export default FieldTxt;
