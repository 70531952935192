import React, { useState } from "react";
const HeaderMissionBar = ({ role, onClick }) => {
    const [current, setCurrent] = useState(0);
    const sendStatus = (type, current) => {
        setCurrent(current);
        let name = role === "ROLE_CLIENT" ? `missionStatus_statusClient_list` : `candidatures_statusFl_list`;
        if (type === "toutes") {
            const value = role === "ROLE_CLIENT" ? [2100, 2200, 2300, 2400, 2500, 2600, 2700, 2800, 2900] : ["1100", "1200", "1300", "1400", "1500"];
            onClick([{
                    name: name,
                    value: value
                }]);
        }
        if (type === "validation")
            onClick([{ name: name, value: [2100, 2200, 2300, 2400] }]);
        if (type === "relation") {
            const value = role === "ROLE_CLIENT" ? [2500, 2600] : ["1100"];
            onClick([{ name: name, value: value }]);
        }
        if (type === "encours")
            onClick([{ name: name, value: [2700] }]);
        if (type === "finish")
            onClick([{ name: name, value: [2800, 2900] }]);
        if (type === "validate")
            onClick([{ name: name, value: ["1200", "1300"] }]);
        if (type === "historic")
            onClick([{ name: name, value: ["1400", "1500"] }]);
    };
    return (React.createElement("div", { className: 'header-mission-bar' },
        React.createElement("div", { className: 'header-mission-bar-container' },
            React.createElement("div", { className: current === 0 ? 'selected' : '', onClick: () => sendStatus('toutes', 0) },
                React.createElement("span", null, "Toutes")),
            role === "ROLE_CLIENT" &&
                React.createElement("div", { className: current === 1 ? 'selected' : '', onClick: () => sendStatus('validation', 1) },
                    React.createElement("span", null, "En attente de validation")),
            React.createElement("div", { className: current === 2 ? 'selected' : '', onClick: () => sendStatus('relation', 2) },
                React.createElement("span", null, "En attente de mise en relation")),
            role === "ROLE_CLIENT" &&
                React.createElement("div", { className: current === 3 ? 'selected' : '', onClick: () => sendStatus('encours', 3) },
                    React.createElement("span", null, "En cours")),
            role === "ROLE_CLIENT" &&
                React.createElement("div", { className: current === 4 ? 'selected' : '', onClick: () => sendStatus('finish', 4) },
                    React.createElement("span", null, "Termin\u00E9es")),
            role === "ROLE_FREE" &&
                React.createElement("div", { className: current === 5 ? 'selected' : '', onClick: () => sendStatus('validate', 5) },
                    React.createElement("span", null, "Valid\u00E9e")),
            role === "ROLE_FREE" &&
                React.createElement("div", { className: current === 6 ? 'selected' : '', onClick: () => sendStatus('historic', 6) },
                    React.createElement("span", null, "Historique")))));
};
export default HeaderMissionBar;
