export const buttonsText = {
    DECLINE_MISSION: "missions.btn.decline",
    DECLINE_MISSION_FREELANCE: "missions.btn.decline_freelance",
    ACTIVE_MISSION: "missions.btn.active",
    SEND_RM: "missions.btn.sendrm",
    INFORM_END_MISSION: "missions.btn.informendmission",
    EDIT_MISSION: "missions.btn.eedit",
    SEND_TO_CLIENT: "missions.btn.sendclient",
    PUBLISH_MISSION: "missions.btn.epublish",
    CANCEL: "missions.btn.cancel",
    SAVE: "missions.btn.save",
    VALIDATE_FOLDER: "missions.btn.evalidate",
    PUT_END_TO_MISSION: "missions.btn.putendtomission",
    UPDATE_MISSION: "missions.btn.updatemission",
    CANCEL_MISSION: "missions.btn.cancelmission",
    VALIDATE_MISSION: "missions.btn.validatemission",
    DECLINE_UPDATE: "missions.btn.declineupdate",
    CHANGE_END_DATE_MISSION: "missions.btn.changeenddatemission",
    VALIDE_CHOICE: "missions.btn.validatechoice",
    CHANGE_DATES: "missions.btn.editupdate",
    PUBLISH: "missions.btn.publish",
    DRAFT: "missions.btn.draft",
    DELETE: "missions.btn.delete",
    EDIT: "missions.btn.edit",
    VALIDATE: "missions.btn.validate"
};
export const statusParameters = {
    DRAFT: "Brouillon",
    VALIDATION_WAIT: "Attente de validation",
    UPDATED: "Creation",
    OPEN: "Ouverte",
    RELATION_WAIT: "Attente de mise en relation",
    ACTIVATION_WAIT: 'Attente d\'activation',
    IN_PROGRESS: "En cours",
    CANCELED: "Annulée",
    FINISHED: "Terminée",
    REFUSED: "Refusée"
};
