import { assign } from "xstate";
import { candidatureValidation } from "../candidatureStateMachine";
const getEvent = (events) => {
    let event = {};
    const existingEvent = {
        CREATE: {
            target: "create",
            actions: assign({ sidebarOpen: (_context, _event) => true, sidebarTitle: "missions:missions.create" })
        },
        END_MISSION: "end_mission",
        REFUSE_MISSION: { target: "refuse_mission", actions: assign({ sidebarOpen: (_context, _event) => true, sidebarTitle: "missions:missions.refuse.title" }) },
        TRUE_DELETE: "true_delete",
        PUBLISH: "publish",
        SEND_TO_CLIENT: "send_to_client",
        SHALLOW_DELETE: {
            target: "shallow_delete",
            actions: assign({ sidebarOpen: (_context, _event) => true, sidebarTitle: "missions:missions.delete" })
        },
        DESCRIPTION: {
            target: "description",
            actions: assign({ sidebarOpen: (_context, _event) => true, sidebarTitle: "" })
        },
        EDIT: { target: "edit", actions: assign({ sidebarOpen: (_context, _event) => true, sidebarTitle: "missions:missions.edit" }) },
    };
    events.map((ev) => {
        event[ev] = existingEvent[ev];
    });
    return event;
};
const getEventStates = (events) => {
    let event = {};
    const existingState = {
        end_mission: {
            invoke: {
                src: (context, _event) => {
                    return context.missionService.putEndMission("admin");
                },
                onDone: { target: "idle" },
                onError: { target: "idle" }
            }
        },
        true_delete: {
            invoke: {
                src: (context, _event) => {
                    return context.missionService.deleteMission();
                },
                onDone: { target: 'idle' },
                onError: { target: 'idle' }
            }
        },
        publish: {
            invoke: {
                src: (context, _event) => {
                    return context.missionService.updateMissionsStatus(106);
                },
                onDone: { target: 'idle' },
                onError: { target: 'idle' }
            },
        },
        send_to_client: {
            invoke: {
                src: (context, _event) => {
                    return context.missionService.updateMissionsStatus(102);
                },
                onDone: { target: 'idle' },
                onError: { target: 'idle' }
            },
        },
        edit: { on: { CANCEL: { target: 'idle' }, SUBMIT: "submit_edit" } },
        submit_edit: {
            invoke: {
                src: (context, event) => {
                    return context.missionService.editMission(event.data);
                },
                onDone: {
                    target: 'idle'
                },
                onError: {
                    target: 'edit'
                }
            },
        },
        shallow_delete: { on: { SUBMIT: "submit_delete", CANCEL: { target: "idle" } } },
        submit_delete: {
            invoke: {
                src: (context, event) => {
                    return context.missionService.shallowDeleteMission(event.data, "admin");
                },
                onDone: { target: "idle" },
                onError: { target: "idle" }
            }
        },
        description: {
            on: { CANCEL: { target: 'idle' }, EDIT: { target: "edit", actions: assign({ sidebarOpen: (_context, _event) => true, sidebarTitle: "missions:missions.edit" }) } }
        },
        create: {
            on: { CANCEL: { target: 'idle' }, SUBMIT: "submit_create" }
        },
        refuse_mission: { on: { SUBMIT: "submit_refuse_mission", CANCEL: { target: "idle" } } },
        submit_refuse_mission: {
            invoke: {
                src: (context, event) => {
                    return context.missionService.refuseMission(event.data);
                },
                onDone: {
                    target: 'idle'
                },
                onError: {
                    target: 'idle'
                }
            }
        },
        submit_create: {
            invoke: {
                src: (context, event) => {
                    return context.missionService.onSubmitMission(event.data);
                },
                onDone: {
                    target: 'idle'
                },
                onError: {}
            },
        },
    };
    events.map((ev) => {
        event[ev] = existingState[ev];
    });
    return event;
};
const rmMissionWaitValidation = {
    id: "rmMissionWaitValidation",
    initial: "idle",
    states: {
        idle: {
            on: {
                ...getEvent(["REFUSE_MISSION", "PUBLISH", "EDIT", "SEND_TO_CLIENT"])
            },
            entry: ['closeSideBar'],
        },
        ...getEventStates(["refuse_mission", "submit_refuse_mission", "publish", "edit", "send_to_client", "submit_edit"])
    }
};
const rmMissionCanceled = {
    id: "rmMissionCanceled",
    initial: "idle",
    states: {
        idle: {
            on: { ...getEvent(["DESCRIPTION"]) },
            entry: ['closeSideBar'],
        },
        ...getEventStates(["description", "edit", "submit_edit"])
    },
};
const rmMissionInProgress = {
    id: "rmMissionActivation",
    initial: "idle",
    states: {
        idle: {
            on: { ...getEvent(["SHALLOW_DELETE", "DESCRIPTION", "EDIT", "END_MISSION"]) },
            entry: ['closeSideBar'],
        },
        ...getEventStates(["shallow_delete", "description", "end_mission", "edit", "submit_edit", "submit_delete"])
    }
};
const rmMissionWaitActivation = {
    id: "rmMissionActivation",
    initial: "idle",
    states: {
        idle: {
            on: { ...getEvent(["SHALLOW_DELETE", "DESCRIPTION", "EDIT"]) },
            entry: ['closeSideBar'],
        },
        ...getEventStates(["shallow_delete", "description", "edit", "submit_edit", "submit_delete"])
    }
};
const rmMissionOpen = {
    id: "rmMissionOpen",
    initial: "idle",
    states: {
        idle: {
            on: {
                ...getEvent(["DESCRIPTION", "EDIT", "SHALLOW_DELETE"]),
            },
            entry: ['closeSideBar'],
        },
        ...getEventStates(["shallow_delete", "description", "edit", "submit_edit", "submit_delete"])
    },
};
const rmMissionDraft = {
    id: "rmMission",
    initial: "idle",
    states: {
        idle: {
            on: {
                ...getEvent(["TRUE_DELETE", "EDIT", "PUBLISH", "SEND_TO_CLIENT"]),
            },
            entry: ['closeSideBar'],
        },
        ...getEventStates(["true_delete", "publish", "send_to_client", "edit", "submit_edit"])
    },
};
const rmMissionUpdated = {
    id: "rmMission",
    initial: "idle",
    states: {
        idle: {
            on: {
                ...getEvent(["TRUE_DELETE", "EDIT", "PUBLISH"])
            },
            entry: ['closeSideBar'],
        },
        ...getEventStates(["true_delete", "publish", "edit", "submit_edit"])
    },
};
const missionCreate = {
    id: "mission_create",
    initial: 'idle',
    states: {
        idle: {
            on: {
                ...getEvent(["CREATE"])
            },
            entry: ['closeSideBar'],
        },
        ...getEventStates(["create", "submit_create"])
    }
};
const rmMissionsStatusState = {
    id: "rmMissionsStatus",
    initial: "none",
    on: { RETURN: "status" },
    states: {
        none: {
            on: { "100": "draft", "200": "wait_validation", "250": "updated", "300": "open", "400": "wait_activation", "500": "in_progress", "600": "canceled", "700": "finish", "800": "refuse" }
        },
        draft: {
            ...rmMissionDraft
        },
        wait_validation: { ...rmMissionWaitValidation },
        updated: { ...rmMissionUpdated },
        open: { ...rmMissionOpen },
        wait_activation: { ...rmMissionWaitActivation },
        in_progress: { ...rmMissionInProgress },
        canceled: { ...rmMissionCanceled },
        finish: { ...rmMissionCanceled },
        refuse: { ...rmMissionCanceled }
    }
};
export const rmMissionsState = {
    id: "rmMissions",
    type: "parallel",
    states: {
        status: {
            ...rmMissionsStatusState
        },
        mission_create: { ...missionCreate },
        candidature_display: {
            ...candidatureValidation
        }
    }
};
