import React, { Fragment } from "react";
import getFile from "../../../Shared/getFile";
import { downloadFile } from "../../../Shared/getFile";
import { useTranslation } from "react-i18next";
const ShowDocument = ({ doc, disabled = false, toggle }) => {
    const { t } = useTranslation("document");
    return (React.createElement("div", { className: "documentContainer" }, doc ? (React.createElement("div", null,
        React.createElement("div", { className: "document green mb-15  text-center", onClick: () => getFile(doc.document._id) },
            React.createElement("span", { className: "flex-directionRow flex-justifyCenter flex-alignCenter" },
                React.createElement("i", { className: "icon-profil mr-10" }),
                doc.document.url)),
        !disabled &&
            React.createElement("div", { className: "documentButtonContainer" },
                React.createElement("span", { onClick: toggle, className: "littleTextButton editButton", "data-testid": "updateButton" }, t("update"))),
        React.createElement("div", { className: "fileButtonBottom text-right" },
            React.createElement("label", { className: "littleTextButton", onClick: () => downloadFile(doc.document.url, doc.document._id) },
                React.createElement("span", null,
                    React.createElement("i", { className: "icon-download size-medium mr-10" }),
                    t("download")))))) : (React.createElement("div", null, !disabled &&
        React.createElement(Fragment, null,
            React.createElement("div", { className: "document red  mb-15  text-center" },
                React.createElement("span", { className: "flex-directionRow flex-justifyCenter flex-alignCenter" },
                    React.createElement("i", { className: "icon-profil mr-10" }),
                    t("noFile"))),
            React.createElement("span", { className: "roundBlock-addBtn", onClick: toggle },
                t("selectFile"),
                React.createElement("span", { className: "circle circle-gray circle-sm circle-absolute" },
                    React.createElement("i", { className: "icon-plus_1 size-small" }))))))));
};
export default ShowDocument;
