import React from "react";
import { useTranslation } from "react-i18next";
import { usePhotoFromSocietyOrUser } from "../../../../Hooks/usePhotoFromSocietyOrUser";
import { addHTTPS } from "../../../../Shared/utils/utils";
const Bandeau = ({ canceledReason, updatedBy, refuseLabel }) => {
    const { t } = useTranslation("missions");
    const { photo } = usePhotoFromSocietyOrUser(updatedBy);
    return (React.createElement("div", { className: "bandeau-error" },
        React.createElement("div", { className: `bandeau-reason` },
            React.createElement("div", { className: "bandeau-header" }, `${t(`missions.mission_${refuseLabel}`)} :`),
            React.createElement("div", { className: "bandeau-content" }, canceledReason)),
        React.createElement("div", { className: "bandeau-lastupdated" },
            photo ? (React.createElement("div", { className: "refuseBandeau-societyLogo" },
                React.createElement("img", { src: addHTTPS(photo) }))) : (React.createElement("div", { className: "refuseBandeau-societyLogo-empty" },
                React.createElement("i", { className: `icon-image size-big` }))),
            React.createElement("div", { className: `bandeau-updatedBy` },
                React.createElement("div", { className: "bandeau-header" }, `${t("missions.lastupdated")}`),
                updatedBy && (React.createElement("div", { className: "bandeau-content" }, updatedBy.society
                    ? updatedBy.society.name
                    : `${updatedBy.firstName} ${updatedBy.lastName}`))))));
};
export default Bandeau;
