const getEvent = (events) => {
    let event = {};
    const existingEvent = {
        DECLINE: "decline",
        ACTIVE: "active",
        END_MISSION: {
            target: "end_mission",
        }
    };
    events.map((ev) => {
        event[ev] = existingEvent[ev];
    });
    return event;
};
const getEventStates = (events) => {
    let event = {};
    const existingState = {
        end_mission: {
            invoke: {
                src: (context) => {
                    return context.missionService.endMissionFreelance();
                },
                onDone: { target: "idle" },
                onError: { target: "idle" }
            }
        },
        decline: {
            invoke: {
                src: (context, _event) => {
                    const candidature = context.missionService.getFreelanceCandidature();
                    return context.missionService.updateMissionStatusCandidature(302, candidature._id);
                },
                onDone: { target: "idle" },
                onError: { target: "idle" }
            }
        },
        active: {
            invoke: {
                src: (context, _event) => {
                    const candidature = context.missionService.getFreelanceCandidature();
                    return context.missionService.updateMissionStatusCandidature(303, candidature._id);
                },
                onDone: { target: "idle" },
                onError: { target: "idle" }
            }
        }
    };
    events.map((ev) => {
        event[ev] = existingState[ev];
    });
    return event;
};
const template = (eventArray, eventStates) => ({
    initial: "idle",
    states: {
        idle: {
            on: {
                ...getEvent(eventArray)
            },
            entry: ['closeSideBar'],
        },
        ...getEventStates(eventStates)
    }
});
const freelanceMissionsStatusState = {
    id: "freelanceMissionsStatus",
    initial: "none",
    on: { RETURN: "status", CHANGE_CONTEXT: { actions: ["updateMission", "setMission", "raiseStatus"], STATUS: "status" } },
    states: {
        none: {
            on: { "1100": "wait", "1200": "wait_activation", "1300": "in_progress", "1400": "canceled", "1500": "finish" },
            entry: ['closeSideBar'],
        },
        wait: {},
        wait_activation: { ...template(["DECLINE", "ACTIVE"], ["decline", "active"]) },
        in_progress: { ...template(["END_MISSION"], ["end_mission"]) },
        canceled: {},
        finish: {}
    }
};
export const freelanceMissionsState = {
    id: "freelanceMissions",
    initial: "status",
    states: {
        status: {
            ...freelanceMissionsStatusState
        },
    }
};
