import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import NameInfos from "../../../Components/Freelance/nameInfos";
import ApiService from "../../../Services/ApiService";
import { CANDIDATURE } from "../../../Services/contants";
import { missionCandidatureAllUpdateNotif } from "../../../Services/notif/notif";
import Loading from "../../../Components/Loading";
import { useValidateCandidature } from "../../../Hooks/useValidateCandidature";
const RadioComponent = (props) => (React.createElement("input", Object.assign({ id: props.id, type: "radio" }, props.input)));
const ClientCandidatureFormField = ({ isSelect, isFull, name, label, value, isDisabled = false, classNameExtend, id, }) => {
    const getIcoClass = (value) => {
        if (value === "refused")
            return "icon-refus";
        else if (value === "waiting")
            return "icon-attente";
        else
            return "icon-check_1";
    };
    const borderClassName = isFull
        ? "nameInfos-selected-full"
        : isSelect
            ? "nameInfos-selected"
            : "";
    return (React.createElement("div", { className: `nameInfos-selectable-element weight-light size-tiny ${borderClassName}` },
        React.createElement("i", { className: `mr-10 ${getIcoClass(value)}` }),
        React.createElement(Field, { name: name, type: "radio", disabled: isDisabled, value: value, id: id, component: RadioComponent, className: `castorRadio-input ${classNameExtend}` }),
        " ",
        React.createElement("label", { htmlFor: id, className: `castorRadio-label-candidatures ${classNameExtend}` }, label)));
};
const ClientCandidatureForm = ({ onSubmit, candidature, rowIdx, }) => {
    return (React.createElement(Form, { onSubmit: onSubmit, initialValues: {
            candidature: candidature,
            idx: rowIdx,
            statusClientToValid: candidature.statusClientToValid
        }, render: ({ handleSubmit, values }) => {
            const [hasChanged, setHasChanged] = useState(false);
            useEffect(() => {
                if (values.statusClientToValid === "none") {
                    setHasChanged(false);
                    return;
                }
                if (candidature.statusFL === '1400') {
                    setHasChanged(false);
                    return;
                }
                values.statusClientToValid === candidature.statusClientToValid
                    ? setHasChanged(false)
                    : setHasChanged(true);
            }, [values.statusClientToValid]);
            return (React.createElement("form", { onSubmit: handleSubmit, style: { display: "flex" } },
                React.createElement(NameInfos, { picture: candidature.user.photo, extendedClassName: `nameInfos-options nameInfos-options-flname ${candidature.statusFl === '1400' ? 'refuseFlRed' : ''}`, firstName: candidature.user.firstName, lastName: candidature.user.lastName, job: candidature.user.profile.job, id: candidature.user._id, withLink: true }),
                React.createElement("div", { className: `nameInfos-options nameInfos-options${candidature.statusFl === '1400' ? '-refuseFlRed' : ''}` },
                    React.createElement(ClientCandidatureFormField, { name: `statusClientToValid`, isSelect: values.statusClientToValid === "refused", isFull: values.statusClientToValid === "refused" &&
                            candidature.statusClientToValid === "refused", classNameExtend: `${candidature.statusFl === '1400' ? 'refuseFlRed cursorEventNone' : ''}`, label: "Refuser", value: "refused", id: `candidature-client-${rowIdx}-0` }),
                    React.createElement(ClientCandidatureFormField, { name: `statusClientToValid`, isSelect: values.statusClientToValid === "waiting", isFull: values.statusClientToValid === "waiting" &&
                            candidature.statusClientToValid === "waiting", classNameExtend: `${candidature.statusFl === '1400' ? 'refuseFlRed cursorEventNone' : ''}`, label: "En attente", value: "waiting", id: `candidature-client-${rowIdx}-1` }),
                    React.createElement(ClientCandidatureFormField, { name: `statusClientToValid`, isSelect: values.statusClientToValid === "accepted", isDisabled: candidature.statusRm === 'accepted' && candidature.statusFl === '1400', isFull: values.statusClientToValid === "accepted", classNameExtend: `${candidature.statusFl === '1400' ? 'refuseFlRed cursorEventNone' : ''}`, label: "Accepter", value: "accepted", id: `candidature-client-${rowIdx}-2` }),
                    React.createElement(Button, { className: `size-small weight-bold freelanceoffer-submit ${hasChanged ? "freelanceoffer-submit-opacity" : ""}`, onClick: () => setHasChanged(false), type: "submit" }, "OK"))));
        } }));
};
const ClientCandidatures = ({ mission, onAccept, reload, }) => {
    const { t } = useTranslation(["notif", "missions"]);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const iconRef = useRef(null);
    const { disabled, validateSelectedCandidature, loading: allLoading, cancelCandidature, } = useValidateCandidature(mission, mission.candidatures, onAccept, reload);
    const onSubmit = (_data) => {
        setLoading(true);
        const candidatureService = new ApiService(CANDIDATURE);
        candidatureService
            .update({
            _id: _data.candidature._id,
            statusClientToValid: _data.statusClientToValid,
        })
            .then(() => {
            missionCandidatureAllUpdateNotif.success();
            setLoading(false);
            reload(false);
        })
            .catch((_e) => {
            missionCandidatureAllUpdateNotif.failed();
            setLoading(false);
        });
        return null;
    };
    return (React.createElement("div", { className: "list-freelance-offers mt-10", ref: iconRef },
        (loading || allLoading) && React.createElement(Loading, { className: 'zIndex-5' }),
        React.createElement("div", { className: "header-offer-list" },
            React.createElement("p", { className: "heebo strong size-big" },
                mission.candidatures.filter((c) => (c.statusRm === "transfert" || c.statusRm === "accepted"))
                    .length,
                " ",
                t("missions:missions.candidatures.propose"),
                " "),
            React.createElement("p", { className: "heebo" },
                t("missions:missions.candidatures.choose"),
                " : ",
                React.createElement("br", null),
                "-",
                " ",
                t("missions:missions.candidatures.one"),
                React.createElement("br", null),
                "- ",
                t("missions:missions.candidatures.status")),
            React.createElement("div", { className: "toolTip-container", onMouseEnter: () => setIsTooltipOpen(!isTooltipOpen), onMouseLeave: () => setIsTooltipOpen(false) },
                React.createElement("i", { className: "icon-info" }),
                isTooltipOpen && (React.createElement("div", { className: "toolTip-content", style: {
                        top: iconRef.current.getBoundingClientRect().top - 73,
                    } },
                    React.createElement("p", { className: "size-medium" }, t("missions:popup_client")))))),
        mission.candidatures
            .filter((c) => c.statusRm === "transfert" || c.statusRm === "accepted")
            .map((c, idx) => {
            return (React.createElement("div", { className: `nameInfos-row`, key: idx },
                React.createElement("div", { className: "nameInfos-selectable-container" },
                    React.createElement("div", { className: `nameInfos-selectable` },
                        React.createElement(ClientCandidatureForm, { onSubmit: onSubmit, candidature: c, rowIdx: idx })))));
        }),
        React.createElement("div", { className: "freelanceProposition-btns" },
            React.createElement(Button, { className: "cancel", onClick: () => cancelCandidature() }, t("missions:missions.candidatures.cancel")),
            React.createElement(Button, { className: disabled ? "validate no-validate" : "validate", "data-testid": "buttonValiderFreelance", disabled: disabled, onClick: () => validateSelectedCandidature() }, t("missions:missions.candidatures.validate")))));
};
export default ClientCandidatures;
