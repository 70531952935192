import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
const WHConfirm = (data) => {
    const { title, message, ifConfirm, ifNotConfirm, labelYes, labelNo } = data;
    confirmAlert({
        title,
        message,
        buttons: [
            {
                label: labelNo,
                onClick: ifNotConfirm,
            },
            {
                label: labelYes,
                onClick: ifConfirm,
            },
        ],
    });
};
export default WHConfirm;
